import React from "react";
import { PromotionTag } from "./PromotionTag";
import { AuthorizedTag } from "./AuthorizedTag";
import { FREE_LISTING_PRICE_ID } from "../constants";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import styled from "styled-components";
// export interface ListingBasicInfoSmMdProps {
//   listingName: string;
//   showAuthorizedTag?: boolean;
//   showPromotionTag?: boolean;
//   interstate?: string;
//   milemarker?: string;
//   address: string;
//   city: string;
//   state: string;
//   inCity: boolean;
//   showDistance: boolean;
//   distance: number;
//   compassDirection: string;
//   priceId: number;
// }
export const ListingBasicInfoSmMd = (props) => {
    const { listingName, showAuthorizedTag, showPromotionTag, interstate, milemarker } = props;
    const { address, city, state, inCity, showDistance, distance, compassDirection, priceId } = props;
    const miles = distance?.toFixed(2);
    const isFree = priceId === FREE_LISTING_PRICE_ID;
    const showBadges = showAuthorizedTag || showPromotionTag;
    const locationInfo = `${interstate || ""}${interstate && milemarker ? " & " : ""}${milemarker || ""}`;
    return (React.createElement(FtsWrapper, { flex: 1, gap: "sm", display: "flex", direction: "column" },
        showBadges && (React.createElement(FtsWrapper, { display: "flex", gap: "lg" },
            showAuthorizedTag && React.createElement(AuthorizedTag, null),
            showPromotionTag && React.createElement(PromotionTag, null))),
        isFree && React.createElement(FreeListingName, null, listingName),
        !isFree && React.createElement(PaidListingNameSm, null, listingName),
        !isFree && React.createElement(PaidListingNameMd, null, listingName),
        React.createElement(FtsWrapper, null,
            React.createElement(Span, { color: ColorsValue["greyscale-800"], variant: VariantsValues["text-3xs"], weight: WeightSize.regular }, `${address},`),
            React.createElement(FtsWrapper, { display: "flex", justify: "space-between" },
                React.createElement(Span, { color: ColorsValue["greyscale-800"], variant: VariantsValues["text-3xs"], weight: WeightSize.regular }, `${city}, ${state}`),
                showDistance && !inCity && miles && (React.createElement(Span, { color: ColorsValue["greyscale-800"], variant: VariantsValues["text-3xs"], weight: WeightSize.regular }, `${miles} mi ${compassDirection}`)))),
        (interstate || milemarker) && (React.createElement(Span, { color: ColorsValue["greyscale-800"], variant: VariantsValues["text-3xs"], weight: WeightSize.regular }, locationInfo))));
};
const FreeListingName = styled(Span).attrs({
    weight: WeightSize.regular,
    variant: VariantsValues["text-xs"],
    color: ColorsValue["greyscale-800"],
}) ``;
const PaidListingNameSm = styled(Span).attrs({
    weight: WeightSize.medium,
    variant: VariantsValues["text-sm"],
    color: ColorsValue["greyscale-800"],
    display: { xs: "none", sm: "block", md: "none", lg: "none" },
}) ``;
const PaidListingNameMd = styled(Span).attrs({
    weight: WeightSize.semibold,
    variant: VariantsValues["text-sm"],
    color: ColorsValue["greyscale-800"],
    display: { xs: "none", sm: "none", md: "block", lg: "none" },
}) ``;
