import { call, put, takeEvery } from "redux-saga/effects";
import FtsV2Instance from "../../../axios/ftsv2.instance";

import { toast } from "react-toastify";

import { getShareProvidersSuccessAction } from "../../actions/v2/shareProviders";
import { SEND_LISTING_BY_AD_ID_REQUEST } from "../../reducers/v2/sendListing";
import { sendListingByAdIdFailedAction } from "../../actions/v2/sendListing";

function* sendListingByAdIdRequestSaga(action) {
  try {
    console.log(action);
    const { data } = yield call(
      FtsV2Instance.put,
      "/ads/admin/send",
      action.payload.data,
    );

    if (
      action.payload.onSuccess &&
      typeof action.payload.onSuccess === "function"
    ) {
      action.payload.onSuccess(data);
    }
    yield put(
      getShareProvidersSuccessAction({
        data: data.data,
      }),
    );
    toast.success(
      `Listing successfully sent on ${action.payload.data.recipient}`,
    );
  } catch (e) {
    console.log(e);
    const message = e.response
      ? e.response.data.message
      : "Error while sending listing";
    yield put(sendListingByAdIdFailedAction({ data: message }));
    toast.error(message);
  }
}

export default function* sendListingWatcher() {
  yield takeEvery(SEND_LISTING_BY_AD_ID_REQUEST, sendListingByAdIdRequestSaga);
}
