import React from "react";
import { Chips } from "../../Chips";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsChipsSizes, FtsChipsVariants } from "@find-truck-service/types/ui/chips";
import { LineHeightVariantsValues, WeightSize } from "../../../../../../types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
export const AuthorizedTag = () => {
    return (React.createElement(Chips, { label: "Authorized", prependIcon: { iconName: IconNames.CreditCard }, labelProps: {
            lineHeightVariant: LineHeightVariantsValues.relaxed,
            color: ColorsValue["greyscale-800"],
            weight: WeightSize.medium,
        }, size: FtsChipsSizes.md, variant: FtsChipsVariants.contained, color: ColorsValue["greyscale-50"] }));
};
