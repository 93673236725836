export class SORTING_TYPES {
    constructor() {
        Object.defineProperty(this, "ASC", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "DESC", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "NONE", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
export const SORTING_TYPES_VALUES = {
    ASC: "asc",
    DESC: "desc",
    NONE: "",
};
