import React, { useCallback, useEffect } from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { P } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { WeightSize } from "@find-truck-service/types/ui/text";
import { Table } from "@find-truck-service/ui/src/components/react/Table";
import {
  FtsTableHeadTypesValues,
  FtsTableHeadVariantsValues,
} from "@find-truck-service/types/ui/table-head";
import { FtsTableCellSizesValues } from "@find-truck-service/types/ui/table-cell";

import { useDispatch, useSelector } from "react-redux";
import {
  getAccountProofsRequest,
  setAccountProofsPage,
  setAccountProofsPageSize,
  setAccountProofsSortColumnId,
  setAccountProofsSortOrder,
} from "../../../redux/actions/v2/account-proofs";
import {
  selectAccountProofsCount,
  selectAccountProofsData,
  selectAccountProofsError,
  selectAccountProofsIsLoading,
  selectAccountProofsPage,
  selectAccountProofsPageSize,
  selectAccountProofsSortColumnId,
  selectAccountProofsSortOrder,
} from "../../../redux/reducers/v2/account-proofs";
import { TableCellTypesValues } from "@find-truck-service/ui/src/components/react/TableCell";
import {
  openListingPreviewSideOutAction,
  openListingProofSideOutAction,
} from "../../../redux/actions/v2/sideOut";

import { Loader } from "@find-truck-service/ui/src/components/react/Loader";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { PROOF_STATUSES_ENUM } from "@find-truck-service/types/constants/proofStatuses";

const SentProofsAccount = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(selectAccountProofsData);
  const isLoading = useSelector(selectAccountProofsIsLoading);
  const page = useSelector(selectAccountProofsPage);
  const pageSize = useSelector(selectAccountProofsPageSize);
  const count = useSelector(selectAccountProofsCount);
  const sortOrder = useSelector(selectAccountProofsSortOrder);
  const sortColumnId = useSelector(selectAccountProofsSortColumnId);
  const error = useSelector(selectAccountProofsError);

  const getProofs = (params) =>
    dispatch(
      getAccountProofsRequest({
        data: {
          params: {
            ...params,
            userId: props.userId,
            sortOrder: params.sortOrder || undefined,
          },
        },
      }),
    );

  const onSetProofPageSize = useCallback(
    (newPageSize) => {
      dispatch(setAccountProofsPageSize({ data: newPageSize }));
      getProofs({
        page,
        pageSize: newPageSize,
        sortOrder,
        sortColumn: sortColumnId,
      });
    },
    [page, pageSize, sortOrder, sortColumnId],
  );

  const onSetProofPage = useCallback(
    (newPage) => {
      dispatch(setAccountProofsPage({ data: newPage }));
      getProofs({
        page: newPage,
        pageSize,
        sortOrder,
        sortColumn: sortColumnId,
      });
    },
    [page, pageSize],
  );

  const onSetSortProof = useCallback(
    (sortOrder, sortedColumn) => {
      dispatch(setAccountProofsSortColumnId({ data: sortedColumn.id }));
      dispatch(setAccountProofsSortOrder({ data: sortOrder }));
      getProofs({
        page,
        pageSize,
        sortOrder: sortOrder,
        sortColumn: sortedColumn.id,
      });
    },
    [page, pageSize],
  );

  useEffect(() => {
    getProofs({
      page,
      pageSize,
      sortOrder,
      sortColumn: sortColumnId,
    });
  }, []);

  if (error)
    return (
      <FtsWrapper
        m={"sm"}
        boxShadow={"sm"}
        borderRadius={"2xs"}
        px={"3xl"}
        py={"2xl"}
        gap={"2xl"}
        display="flex"
        direction="column"
        minHeight={"40vh"}
      >
        <P
          weight={WeightSize.semibold}
          variant={"text-xs"}
          color={ColorsValue["greyscale-800"]}
        >
          Proofs
        </P>
        <FtsWrapper
          overflow="auto"
          position="relative"
          height="100%"
          display="flex"
          justify="center"
          align="center"
          direction="column"
          minHeight={"40vh"}
          gap={"4xl"}
        >
          <Error
            iconName={IconNames.AlertTriangle}
            title={"There was an error loading your data."}
            subtitle={
              "We couldn't load your data. Please check your internet connection or try again later."
            }
            outlinedButton={{
              onClick: () =>
                getProofs({
                  page,
                  pageSize,
                  sortOrder,
                  sortColumn: sortColumnId,
                }),
              type: ButtonTypes.outlined,
              color: ButtonColorsValue.accent,
              size: ButtonSizes.sm,
              label: "Retry",
              iconName: IconNames.Refresh,
            }}
          />
        </FtsWrapper>
      </FtsWrapper>
    );
  if (!data)
    return (
      <FtsWrapper
        m={"sm"}
        boxShadow={"sm"}
        borderRadius={"2xs"}
        px={"3xl"}
        py={"2xl"}
        gap={"2xl"}
        display="flex"
        direction="column"
        minHeight={"40vh"}
      >
        <P
          weight={WeightSize.semibold}
          variant={"text-xs"}
          color={ColorsValue["greyscale-800"]}
        >
          Proofs
        </P>
        <FtsWrapper
          overflow="auto"
          position="relative"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          minHeight={"40vh"}
        >
          <Loader />
        </FtsWrapper>
      </FtsWrapper>
    );
  return (
    <FtsWrapper
      bg={ColorsValue["greyscale-0"]}
      m={"sm"}
      boxShadow={"sm"}
      borderRadius={"2xs"}
      px={"3xl"}
      py={"2xl"}
      gap={"2xl"}
      display="flex"
      direction="column"
      minHeight={"40vh"}
      position="relative"
    >
      <P
        weight={WeightSize.semibold}
        variant={"text-xs"}
        color={ColorsValue["greyscale-800"]}
      >
        Proofs
      </P>

      <FtsWrapper overflow="auto">
        <Table
          onSort={onSetSortProof}
          onRowClick={(data) => {
            if (data.status === PROOF_STATUSES_ENUM.APPROVED) {
              return dispatch(
                openListingPreviewSideOutAction({
                  data: {
                    listingId: data.adId,
                  },
                }),
              );
            }
            dispatch(
              openListingProofSideOutAction({
                data: { proofAdId: data.adId },
              }),
            );
          }}
          columns={columns}
          data={data}
          isLoading={isLoading}
          pageSize={pageSize}
          page={page}
          setPageSize={onSetProofPageSize}
          setPage={onSetProofPage}
          count={count}
          sortedOrder={sortOrder}
          sortedColumnId={sortColumnId}
        />
      </FtsWrapper>
    </FtsWrapper>
  );
};

const def = {
  size: FtsTableCellSizesValues.sm,
  type: FtsTableHeadTypesValues.text,
  isLoading: false,
  sortable: true,
};

const defaultTableCellProps = {
  isLoading: false,
  type: TableCellTypesValues.text,
  size: FtsTableCellSizesValues.sm,
};

const columns = [
  {
    id: "status",
    tableHeaderProps: {
      ...def,
      variant: FtsTableHeadVariantsValues.Status,
    },
    tableCellProps: {
      ...defaultTableCellProps,
      propsBasedOnColumnData: (data) => {
        const proofStatusesColor = {
          Approved: "green-success-300",
          Rejected: "red-error-300",
          Pending: "yellow-warning-300",
          "Not Sent": "blue-accent-300",
        };
        return {
          color: proofStatusesColor[data.status],
        };
      },
    },
  },
  {
    id: "sentOnDate",
    tableHeaderProps: {
      ...def,
      variant: FtsTableHeadVariantsValues["Sent On"],
    },
    tableCellProps: {
      ...defaultTableCellProps,
      type: TableCellTypesValues.date,
    },
  },
  {
    id: "sentBy",
    tableHeaderProps: {
      ...def,
      variant: FtsTableHeadVariantsValues["Sent By"],
    },
    tableCellProps: defaultTableCellProps,
  },
  {
    id: "adId",
    tableHeaderProps: {
      ...def,
      variant: FtsTableHeadVariantsValues.LID,
    },

    tableCellProps: defaultTableCellProps,
  },
  {
    id: "companyName",
    tableHeaderProps: {
      ...def,
      variant: FtsTableHeadVariantsValues.Company,
    },

    tableCellProps: defaultTableCellProps,
  },
  {
    id: "address",
    tableHeaderProps: {
      ...def,
      variant: FtsTableHeadVariantsValues.Street,
    },

    tableCellProps: defaultTableCellProps,
  },

  {
    id: "city",
    tableHeaderProps: {
      ...def,
      variant: FtsTableHeadVariantsValues.City,
    },

    tableCellProps: defaultTableCellProps,
  },
  {
    id: "state",
    tableHeaderProps: {
      ...def,
      variant: FtsTableHeadVariantsValues.ST,
    },

    tableCellProps: defaultTableCellProps,
  },
  {
    id: "type",
    tableHeaderProps: {
      ...def,
      variant: FtsTableHeadVariantsValues.Type,
    },

    tableCellProps: defaultTableCellProps,
  },
  {
    id: "rejectedOn",
    tableHeaderProps: {
      ...def,
      variant: FtsTableHeadVariantsValues.Rejected,
    },

    tableCellProps: defaultTableCellProps,
  },
  {
    id: "timesSent",
    tableHeaderProps: {
      ...def,
      variant: FtsTableHeadVariantsValues.Times,
    },

    tableCellProps: defaultTableCellProps,
  },
];

export default SentProofsAccount;
