import React, { useCallback } from "react";
import {
  selectListingData,
  selectListingRates,
} from "../../../../redux/reducers/v2/listing";
import { useDispatch, useSelector } from "react-redux";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { selectRates } from "../../../../redux/reducers/essentialLists";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import { ADS_RATES_TYPE } from "@find-truck-service/types/constants/adsRatesType";

export const minimumChargeOptions = [
  { label: "30 min", value: "30 min" },
  { label: "1h", value: "1h" },
  { label: "1h 30 min", value: "1h 30 min" },
  { label: "2h", value: "2h" },
  { label: "2h 30 min", value: "2h 30 min" },
];

export const ListingRatesRow = () => {
  const selectedRates = useSelector(selectListingRates);
  const rates = useSelector(selectRates);
  const listingData = useSelector(selectListingData);
  const dispatch = useDispatch();
  const onChange = useCallback(
    (rateId, value) => {
      let newListingData = { ...listingData };
      const indexOfRate = selectedRates.findIndex((e) => e.rateId === rateId);
      if (indexOfRate !== -1) {
        newListingData.adsRates[indexOfRate] = {
          ...newListingData.adsRates[indexOfRate],
          value,
        };
      } else {
        newListingData.adsRates = [
          ...newListingData.adsRates,
          {
            adId: listingData.id,
            rateId,
            type: ADS_RATES_TYPE.PUBLIC,
            userId: listingData.userId,
            value,
          },
        ];
      }
      dispatch(getListingDataByIdSuccess({ data: newListingData }));
    },
    [listingData],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} mt={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          Rates
        </Span>
      </FtsColumn>

      {rates.map((e) => {
        const value = selectedRates.find((f) => f.rateId === e.id)?.value || "";
        if (e.id === 5) {
          return (
            <FtsColumn key={e.id} size={{ xs: 3 }}>
              <Dropdown
                size={DropdownSizesValues.sm}
                variant={DropdownVariantsValues.outlined}
                options={minimumChargeOptions}
                label={e.name}
                name={e.name}
                value={{ label: value, value }}
                // onChange={onChange}
                onChange={(event) => onChange(e.id, event?.value || "")}
              />
            </FtsColumn>
          );
        }
        return (
          <FtsColumn key={e.id} size={{ xs: 3 }}>
            <Input
              name={e.id}
              value={value}
              onChange={(event) => onChange(e.id, event.target.value)}
              label={e.name}
              variant={InputVariants.outlined}
              size={InputSizes.sm}
            />
          </FtsColumn>
        );
      })}
    </FtsRow>
  );
};
