import React from "react";
import { useSelector } from "react-redux";
import {
  selectShareProvidersData,
  selectShareProvidersIsLoading,
} from "../../../redux/reducers/v2/shareProviders";
import { ShareProviderEmails } from "./components/ShareProviderEmails";
import { Loader } from "@find-truck-service/ui/src/components/react/Loader";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import TextDivider from "@find-truck-service/ui/src/components/react/TextDivider";
import { ShareProvidersPhoneNumbers } from "./components/ShareProvidersPhoneNumbers";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";

export const ShareProviders = () => {
  const shareProviders = useSelector(selectShareProvidersData);
  const isLoading = useSelector(selectShareProvidersIsLoading);
  if (isLoading)
    return (
      <FtsWrapper position="relative" height={"100%"}>
        <Loader />
      </FtsWrapper>
    );

  if (!shareProviders) return <EmptyComponent />;

  return (
    <FtsWrapper
      py={"3xl"}
      px={"2xl"}
      gap={"3xl"}
      display={"flex"}
      direction={"column"}
    >
      <ShareProviderEmails />
      <TextDivider text="or" />
      <ShareProvidersPhoneNumbers />
    </FtsWrapper>
  );
};
