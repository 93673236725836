import { createSelector } from "reselect";
import { SideOutEditListing } from "../../../../components/fts-components-v2/SideOutEditListing/SideOutEditListing";
import { SideOutEditAccount } from "../../../../components/fts-components-v2/SideOutEditAccount/SideOutEditAccount";
import { SideOutListingProof } from "../../../../components/fts-components-v2/SideOutListingProof/SideOutListingProof";
import { SideOutSizesValues } from "@find-truck-service/ui/src/FtsSideOut/FtsSideOutContainer";
import { SideOutSendProof } from "../../../../components/fts-components-v2/SideOutSendProof/SideOutSendProof";
import { SideOutListingPreview } from "../../../../components/fts-components-v2/SideOutPreviewListing/SideOutListingPreview";
import { SideOutSendListing } from "../../../../components/fts-components-v2/SideOutSendListing/SideOutSendListing";

export const OPEN_EDIT_LISTING = "SIDE_OUT/OPEN_EDIT_LISTING";
export const OPEN_EDIT_ACCOUNT = "SIDE_OUT/OPEN_EDIT_ACCOUNT";
export const OPEN_LISTING_PROOF = "SIDE_OUT/OPEN_LISTING_PROOF";
export const OPEN_LISTING_PREVIEW = "SIDE_OUT/OPEN_LISTING_PREVIEW";

//
export const OPEN_SUB_SIDE_OUT_SEND_LISTING =
  "SIDE_OUT/OPEN_SUB_SIDE_OUT_SEND_LISTING";
export const OPEN_SUB_SIDE_OUT_EDIT_LISTING =
  "SIDE_OUT/OPEN_SUB_SIDE_OUT_EDIT_LISTING";
export const OPEN_SUB_SIDE_OUT_SEND_PROOF =
  "SIDE_OUT/OPEN_SUB_SIDE_OUT_SEND_PROOF";
export const OPEN_SUB_SIDE_OUT_EDIT_ACCOUNT =
  "SIDE_OUT/OPEN_SUB_SIDE_OUT_EDIT_ACCOUNT";

//
export const CLOSE_SIDE_OUT = "SIDE_OUT/CLOSE_SIDEBAR";
export const CLOSE_SUB_SIDE_OUT = "SIDE_OUT/CLOSE_SUB_SIDE_OUT";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  isOpen: false,
  isSubSideOutOpen: false,
  sideOutComponent: "",
  subSideOutComponent: "",
  onSuccess: null,
  subOnSuccess: null,
  size: SideOutSizesValues.md,
  subSideOutSize: SideOutSizesValues.md,
  getListingInfo: null,
};

export const SIDE_OUT_COMPONENTS_LIST = {
  [OPEN_EDIT_LISTING]: SideOutEditListing,
  [OPEN_EDIT_ACCOUNT]: SideOutEditAccount,
  [OPEN_LISTING_PROOF]: SideOutListingProof,
  [OPEN_LISTING_PREVIEW]: SideOutListingPreview,
  //
  [OPEN_SUB_SIDE_OUT_SEND_PROOF]: SideOutSendProof,
  [OPEN_SUB_SIDE_OUT_EDIT_LISTING]: SideOutEditListing,
  [OPEN_SUB_SIDE_OUT_EDIT_ACCOUNT]: SideOutEditAccount,
  [OPEN_SUB_SIDE_OUT_SEND_LISTING]: SideOutSendListing,
};

/// Reducer
export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_EDIT_LISTING:
      return {
        ...state,
        isLoading: false,
        isOpen: true,
        data: action.payload.data,
        onSuccess: action.payload.onSuccess,
        size: SideOutSizesValues.md,
        sideOutComponent: SIDE_OUT_COMPONENTS_LIST[action.type],
      };
    case OPEN_EDIT_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        isOpen: true,
        data: action.payload.data,
        onSuccess: action.payload.onSuccess,
        size: SideOutSizesValues.md,
        sideOutComponent: SIDE_OUT_COMPONENTS_LIST[action.type],
      };
    case OPEN_LISTING_PROOF:
      return {
        ...state,
        isLoading: false,
        isOpen: true,
        data: action.payload.data,
        onSuccess: action.payload.onSuccess,
        // this is hack for get listing info
        getListingInfo: action.payload.getListingInfo || state.getListingInfo,
        size: SideOutSizesValues.lg,
        sideOutComponent: SIDE_OUT_COMPONENTS_LIST[action.type],
      };
    case OPEN_LISTING_PREVIEW:
      return {
        ...state,
        isLoading: false,
        isOpen: true,
        data: action.payload.data,
        onSuccess: action.payload.onSuccess,
        getListingInfo: action.payload.getListingInfo || state.getListingInfo,
        size: SideOutSizesValues.lg,
        sideOutComponent: SIDE_OUT_COMPONENTS_LIST[action.type],
      };
    case OPEN_SUB_SIDE_OUT_EDIT_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        isSubSideOutOpen: true,
        data: action.payload.data,
        subSideOutSize: SideOutSizesValues.md,
        subOnSuccess: action.payload.onSuccess,
        subSideOutComponent: SIDE_OUT_COMPONENTS_LIST[action.type],
      };

    case OPEN_SUB_SIDE_OUT_SEND_PROOF:
    case OPEN_SUB_SIDE_OUT_SEND_LISTING:
      return {
        ...state,
        isLoading: false,
        isSubSideOutOpen: true,
        data: action.payload.data,
        subOnSuccess: action.payload.onSuccess,
        subSideOutSize: SideOutSizesValues.sm,
        subSideOutComponent: SIDE_OUT_COMPONENTS_LIST[action.type],
      };

    case OPEN_SUB_SIDE_OUT_EDIT_LISTING:
      return {
        ...state,
        isLoading: false,
        isSubSideOutOpen: true,
        data: action.payload.data,
        subSideOutSize: SideOutSizesValues.md,
        subOnSuccess: action.payload.onSuccess,
        subSideOutComponent: SIDE_OUT_COMPONENTS_LIST[action.type],
      };

    case CLOSE_SUB_SIDE_OUT:
      return {
        ...state,
        isLoading: false,
        data: null,
        isSubSideOutOpen: false,
        subSideOutComponent: "",
      };
    case CLOSE_SIDE_OUT:
      return initialState;
    default:
      return state;
  }
}

/// Selectors
const selectSideOutState = (state) => state.sideOutState;

export const selectIsSideOutOpen = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.isOpen;
  },
);
export const selectIsSubSideOutOpen = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.isSubSideOutOpen;
  },
);

export const selectSideOutComponent = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.sideOutComponent;
  },
);

export const selectSubSideOutSize = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.subSideOutSize;
  },
);

export const selectSideOutSize = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.size;
  },
);

export const selectSubSideOutComponent = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.subSideOutComponent;
  },
);

export const selectSideOutData = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.data;
  },
);

export const selectSideOutOnSuccess = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.onSuccess;
  },
);

export const selectSideOutSubOnSuccess = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.subOnSuccess;
  },
);

export const selectSideOutGetListingInfo = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.getListingInfo;
  },
);
