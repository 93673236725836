import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { device } from "@find-truck-service/types/ui/breakpoints";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
export const FtsContainer = styled(FtsWrapper) `
  margin-left: ${Spacing["4xl"]};
  margin-right: ${Spacing["4xl"]};
  max-width: 1200px;
  box-sizing: border-box;

  @media ${device.sm} {
    margin-left: ${Spacing["2xl"]};
    margin-right: ${Spacing["2xl"]};
  }
  @media ${device.xs} {
    margin-left: ${Spacing["0"]};
    margin-right: ${Spacing["0"]};
    padding: ${Spacing["0"]};
  }

  @media (min-width: 1280px) {
    margin: auto;
  }
`;
