import { call, put, race, select, take, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import FTSAxios from "../../../axios/fts.instance";
import {
  GET_LISTING_DATA_BY_ID_REQUEST,
  GET_LOCKED_LISTINGS_REQUEST,
  LOCK_LISTING_REQUEST,
  selectListingData,
  UNLOCK_LISTING_REQUEST,
  UPDATE_LISTING_DATA_BY_ID_REQUEST,
  UPDATE_LISTING_PUT_ON_HOLD_BY_ID_REQUEST,
  UPLOAD_LISTING_IMAGE_FAILED,
  UPLOAD_LISTING_IMAGE_REQUEST,
  UPLOAD_LISTING_IMAGE_SUCCESS,
} from "../../reducers/v2/listing";
import {
  getListingDataByIdFailed,
  getListingDataByIdSuccess,
  getLockedListingsFailed,
  getLockedListingsRequest,
  getLockedListingsSuccess,
  lockListingRequest,
  updateListingDataByIdSuccess,
  updateListingPutOnHoldByIdFailed,
  updateListingPutOnHoldByIdSuccess,
  uploadListingImageFailed,
  uploadListingImageRequest,
  uploadListingImageSuccess,
} from "../../actions/v2/listings";

import moment from "moment";
import { selectLoggedUser } from "../../reducers/auth/loginReducer";
import socketHandler from "../../../utility/socket";
import { handleError, handleResponseError } from "../../../handlers/error";

import { SagaErrorMsg } from "../../../constants";
import FtsV2Instance from "../../../axios/ftsv2.instance";
import axios from "axios";
import { createProofRequestSaga } from "../proof";
import { PROOF_STATUSES_ENUM } from "@find-truck-service/types/constants/proofStatuses";
import { getDayValues } from "../../../helpers";
import { weekDaysFullName } from "./constants";

const checkIsWorkingHoursSkipped = (workingHours) => {
  return !weekDaysFullName.filter((d) => workingHours[d] !== " - ").length;
};
// all sagas need to have Sata in suffix eg getListingDataByIdRequestSaga
function* getListingDataByIdRequestSaga(action) {
  try {
    const { data } = yield call(
      FtsV2Instance.get,
      `/ads/admin/${action.payload.data.id}`,
    );
    let { data: listingData } = data;

    yield put(getLockedListingsRequest());

    if (
      listingData.proof &&
      listingData.proof.status !== PROOF_STATUSES_ENUM.APPROVED
    ) {
      listingData = JSON.parse(listingData.proof.listingData);
    }

    listingData.skipHours =
      !listingData.alwaysOpen &&
      !!listingData.workingHours &&
      checkIsWorkingHoursSkipped(listingData.workingHours);
    const listingId = listingData.id;

    const { data: lockedListingsData } = yield call(
      FTSAxios.get,
      "admin/listings/locked",
    );

    yield put(getLockedListingsSuccess({ data: lockedListingsData }));
    const loggedUserData = yield select(selectLoggedUser);

    const { listings } = lockedListingsData;
    const { id } = loggedUserData;

    let lockedListing = listings[listingId];
    let isLockedWithAnotherAdmin = lockedListing && lockedListing.userId !== id;

    if (isLockedWithAnotherAdmin) {
      listingData.lockedListingMessage = `${lockedListing.firstName} ${lockedListing.lastName} is currently editing this listing.`;
    }

    yield put(getListingDataByIdSuccess({ data: listingData }));

    if (!lockedListing) {
      yield put(lockListingRequest({ data: listingId }));
    }
  } catch (e) {
    console.log(e);
    const message = e.response
      ? e.response.data.message
      : "Error while getting listing";
    yield put(getListingDataByIdFailed(message));
    toast.error(message);
  }
}

const formatListingDataForUpdate = (listingData) => {
  const weekDays = moment.weekdays();

  let formatted = { ...listingData };

  formatted.adsSubCategories = listingData.adsSubCategories.map(
    (e) => e.subCategoryId,
  );
  formatted.adsAmenities = listingData.adsAmenities.map((e) => e.amenityId);
  formatted.adsServiceAmenities = listingData.adsServiceAmenities.map(
    (e) => e.serviceAmenityId,
  );
  formatted.adsCategories = listingData.adsCategories.map(
    (e) => e.mainCategoryId,
  );
  const hoursOfOperation = {
    alwaysOpen: listingData.alwaysOpen,
    specialHours: {
      towingRecovery: !!listingData.towingRecoveryOpen,
      mobileRoad: !!listingData.mobileRoadServiceOpen,
    },
    weekDays: weekDays.map((day) => {
      let data = getDayValues(day, listingData.workingHours);

      if (
        listingData?.workingHours?.skipHours ||
        listingData?.skipHours ||
        !listingData?.workingHours
      ) {
        data = {
          toValue: "",
          fromValue: "",
        };
      }

      return {
        day,
        from: data.fromValue,
        to: data.toValue,
      };
    }),
  };

  formatted.hoursOfOperation = hoursOfOperation;
  return formatted;
};

function* uploadListingImageSaga({ payload: { data } }) {
  try {
    const { image: filePath, fileForUpload } = data;
    const { type: mimeType } = fileForUpload;

    //Get upload url
    const GET_UPLOAD_URL = "/ads/admin/images/image-upload-url";
    const params = { filePath, mimeType };
    const response = yield call(FtsV2Instance.get, GET_UPLOAD_URL, { params });
    const uploadUrl = response.data?.data?.uploadUrl;

    if (!uploadUrl) throw new Error("Upload URL not found");

    //Upload image to S3
    const uploadConfig = { headers: { "Content-Type": mimeType } };
    yield call(axios.put, uploadUrl, fileForUpload, uploadConfig);

    yield put(uploadListingImageSuccess());
  } catch (e) {
    yield put(uploadListingImageFailed());
    toast.error(SagaErrorMsg.UPLOADING_ASSET_ERROR);
  }
}

function* updateListingDataByIdRequestSaga(action) {
  try {
    const loggedUserData = yield select(selectLoggedUser);
    const listingDataForUpdate = action.payload.data;
    // debugger;
    let updatedListingData = {};

    if (listingDataForUpdate.priceId === 5) {
      const payload = formatListingDataForUpdate(listingDataForUpdate);
      const { data } = yield call(
        FTSAxios.put,
        `admin/listings/${listingDataForUpdate.id}`,
        payload,
      );

      updatedListingData = data;
    } else {
      yield call(createProofRequestSaga, {
        payload: {
          data: {
            adId: listingDataForUpdate.id,
            listingData: {
              ...listingDataForUpdate,
              fileForUpload: null,
              updatedAt: moment().toISOString(),
              adminLastUpdatedBy: loggedUserData.id,
              // remap old ad type
              adType: listingDataForUpdate.priceId,
              proof: null,
            },
          },
        },
      });
    }

    const { image, fileForUpload } = listingDataForUpdate;
    if (!!image && !!fileForUpload) {
      const data = { image, fileForUpload };
      yield put(uploadListingImageRequest({ data }));

      const { failure } = yield race({
        success: take(UPLOAD_LISTING_IMAGE_SUCCESS),
        failure: take(UPLOAD_LISTING_IMAGE_FAILED),
      });

      if (failure) throw new Error("Upload failed");
    }

    yield put(updateListingDataByIdSuccess());

    if (
      action.payload.onSuccess &&
      typeof action.payload.onSuccess === "function"
    ) {
      action.payload.onSuccess(updatedListingData);
    }

    if (listingDataForUpdate.doNotChangeUpdateDate)
      return toast.success(
        "Listings updated successfully - updated at not changed",
      );

    return toast.success(
      `Listings updated successfully - updated at set to ${moment().format(
        "ll",
      )}`,
    );
  } catch (e) {
    console.log(e);
    if (
      action.payload.onError &&
      typeof action.payload.onError === "function"
    ) {
      action.payload.onError(e);
    }
    const message = e.response
      ? e.response.data.message
      : "Error while updating listing data";
    yield put(getListingDataByIdFailed(message));
    toast.error(message);
  }
}

function* updateListingPutOnHoldByIdRequestSaga(action) {
  try {
    const putOnHoldPayload = action.payload.data;
    const listingData = yield select(selectListingData);
    let onHoldCount = listingData.onHoldCount || 0;
    onHoldCount++;

    putOnHoldPayload.onHoldCount = onHoldCount;
    putOnHoldPayload.id = listingData.id;
    putOnHoldPayload.updatedAt = listingData.updatedAt;

    const { data: updatedListingData } = yield call(
      FTSAxios.post,
      "admin/updates/on-hold",
      putOnHoldPayload,
    );
    yield put(updateListingPutOnHoldByIdSuccess());
    if (
      action.payload.onSuccess &&
      typeof action.payload.onSuccess === "function"
    ) {
      action.payload.onSuccess(updatedListingData);
    }

    return toast.success(
      `Listings updated successfully - put on hold set to ${moment()
        .add(putOnHoldPayload.onHold, "days")
        .format("ll")}`,
    );
  } catch (e) {
    handleError(e);
    const message = handleResponseError(e, true);
    if (
      action.payload.onError &&
      typeof action.payload.onError === "function"
    ) {
      action.payload.onError(e);
    }

    yield put(updateListingPutOnHoldByIdFailed(message));
  }
}

function* getLockedListingsSaga() {
  try {
    const { data: lockedListingsData } = yield call(
      FTSAxios.get,
      "admin/listings/locked",
    );
    yield put(getLockedListingsSuccess({ data: lockedListingsData }));
  } catch (e) {
    handleError(e);
    yield put(getLockedListingsFailed());
    toast.error("Cannot get locked listings");
  }
}

function* lockListingSaga() {
  try {
    console.log("LOCK_LISTING_REQUEST: ");
    const loggedUserData = yield select(selectLoggedUser);
    const listingData = yield select(selectListingData);
    const { id, firstName, lastName } = loggedUserData;
    const { id: listingId } = listingData;

    socketHandler.handleSocket("subscribeToLockListing", {
      listingId: listingId,
      userId: id,
      firstName,
      lastName,
    });
  } catch (e) {
    handleError(e);
  }
}

function* unLockListingSaga() {
  try {
    const loggedUserData = yield select(selectLoggedUser);
    const listingData = yield select(selectListingData);
    const { id, firstName, lastName } = loggedUserData;
    const { id: listingId } = listingData;
    socketHandler.handleSocket("unSubscribeToLockListing", {
      listingId: listingId,
      userId: id,
      firstName,
      lastName,
    });
  } catch (e) {
    handleError(e);
  }
}

export default function* listingWatcher() {
  yield takeEvery(
    GET_LISTING_DATA_BY_ID_REQUEST,
    getListingDataByIdRequestSaga,
  );
  yield takeEvery(
    UPDATE_LISTING_DATA_BY_ID_REQUEST,
    updateListingDataByIdRequestSaga,
  );
  yield takeEvery(
    UPDATE_LISTING_PUT_ON_HOLD_BY_ID_REQUEST,
    updateListingPutOnHoldByIdRequestSaga,
  );
  yield takeEvery(UPLOAD_LISTING_IMAGE_REQUEST, uploadListingImageSaga);
  yield takeEvery(GET_LOCKED_LISTINGS_REQUEST, getLockedListingsSaga);
  yield takeEvery(LOCK_LISTING_REQUEST, lockListingSaga);
  yield takeEvery(UNLOCK_LISTING_REQUEST, unLockListingSaga);
}
