import React from "react";
import { ListingItemImage } from "./ListingItemImage";
import { EmptyComponent } from "../../EmptyComponent";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { RichTextWrapper } from "../../RichTextEditor/RichTextWrapper";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { DescriptionWrapper } from "../../ListingItem/styled/DescriptionWrapper";
import { getImageSize } from "@find-truck-service/ui/src/components/react/ListingItem/helpers";
export const ImageAndDescription = (props) => {
    const { description = "", imageSrc, priceData } = props;
    const showImage = priceData?.showImage;
    if (!showImage)
        return React.createElement(EmptyComponent, null);
    const { width, height } = getImageSize(priceData?.id);
    return (React.createElement(FtsWrapper, { display: "flex", gap: { xs: "lg", sm: "lg", md: "lg", lg: "2xl" } },
        React.createElement(ListingItemImage, { src: imageSrc, width: width.toString(), height: height.toString(), priceId: priceData?.id }),
        React.createElement(RichTextWrapper, null,
            React.createElement(DescriptionWrapper, { priceId: priceData?.id, variant: VariantsValues["text-3xs"], color: ColorsValue["greyscale-800"], dangerouslySetInnerHTML: { __html: description } }))));
};
