import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Divider } from "@find-truck-service/ui/src/components/react/Divider";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
export const ModalFooterComponent = (props) => {
    const showSuccessButton = props.successLabel && props.onSuccess;
    const showErrorButton = props.errorLabel && props.onError;
    const showInfoButton = props.infoLabel && props.onInfo;
    return (React.createElement(React.Fragment, null,
        React.createElement(Divider, null),
        React.createElement(FtsWrapper, { px: {
                xs: "xl",
                sm: "2xl",
                md: "3xl",
                lg: "4xl",
            }, py: "lg", gap: "lg", display: "flex", justify: "flex-end", direction: { xs: "column-reverse", sm: "row", md: "row", lg: "row" } },
            !!showInfoButton && (React.createElement(Button, { onClick: props.onInfo, disabled: props.infoDisabled, loading: props.infoIsLoading, size: ButtonSizes.md, type: ButtonTypes.text, color: ButtonColorsValue.accent, width: {
                    xs: "100%",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                }, label: props.infoLabel })),
            !!showErrorButton && (React.createElement(Button, { onClick: props.onError, disabled: props.errorDisabled, loading: props.errorIsLoading, size: ButtonSizes.md, type: ButtonTypes.outlined, color: ButtonColorsValue.accent, label: props.errorLabel, width: {
                    xs: "100%",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                } })),
            !!showSuccessButton && (React.createElement(Button, { onClick: props.onSuccess, disabled: props.successDisabled, loading: props.successIsLoading, size: ButtonSizes.md, type: ButtonTypes.contained, color: ButtonColorsValue.accent, label: props.successLabel, width: {
                    xs: "100%",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                } })))));
};
