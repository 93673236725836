import {
  GET_SHARE_PROVIDERS_FAILED,
  GET_SHARE_PROVIDERS_REQUEST,
  GET_SHARE_PROVIDERS_SUCCESS,
  SET_INVALID_FORM_FIELDS,
} from "../../../reducers/v2/shareProviders";

export const getShareProvidersRequestAction = (payload) => ({
  type: GET_SHARE_PROVIDERS_REQUEST,
  payload,
});

export const getShareProvidersSuccessAction = (payload) => ({
  type: GET_SHARE_PROVIDERS_SUCCESS,
  payload,
});

export const getShareProvidersFailedAction = (payload) => ({
  type: GET_SHARE_PROVIDERS_FAILED,
  payload,
});

export const setShareProvidersInvalidFormFields = (payload) => ({
  type: SET_INVALID_FORM_FIELDS,
  payload,
});
