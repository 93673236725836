import React from "react";
import { LinkSizes } from "@find-truck-service/types/ui/link";
import { Link } from "@find-truck-service/ui/src/components/react/Link";
import { FtsTableCell } from "@find-truck-service/ui/src/FtsTableCell/FtsTableCell";
import { TableCellNoData } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellNoData";
import { TableCellTypesValues } from "@find-truck-service/ui/src/components/react/TableCell";
export const TableCellLink = (props) => {
    if (!props.title || !props.href)
        React.createElement(TableCellNoData, { type: TableCellTypesValues.text, ...props });
    return (React.createElement(FtsTableCell, { isLoading: props.isLoading, size: props.size },
        React.createElement(Link, { size: LinkSizes.md, label: props.title, href: props.href, iconName: props.iconName })));
};
