import { Span } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
export const ListingNameRow = (props) => {
    const showExit = !!props.interstate?.trim() || !!props.milemarker?.trim();
    return (React.createElement(FtsWrapper, { mb: "lg", display: "flex", direction: "column", gap: "2xs", px: "xl" },
        React.createElement(Span, { variant: VariantsValues["text-xs"], weight: WeightSize.semibold, color: ColorsValue["greyscale-800"] }, props.name),
        React.createElement(FtsWrapper, { display: "flex", direction: "column" },
            React.createElement(Span, { color: ColorsValue["greyscale-500"], variant: VariantsValues["text-2xs"], weight: WeightSize.regular }, props.address),
            React.createElement(Span, { color: ColorsValue["greyscale-500"], variant: VariantsValues["text-2xs"], weight: WeightSize.regular }, `${props.city}, ${props.state}`),
            showExit && (React.createElement(Span, { color: ColorsValue["greyscale-500"], variant: VariantsValues["text-2xs"], weight: WeightSize.regular }, `${props.interstate}${props.milemarker && props.interstate ? ` & ${props.milemarker}` : `${props.milemarker}`}`)))));
};
