/* eslint-disable indent */
import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Spinner } from "reactstrap";
import { isEqual } from "underscore";
import { toast } from "react-toastify";

import {
  fetchNotesAccount,
  fetchNotesListing,
} from "../../../redux/actions/notes";

import {
  fetchTodosAccount,
  fetchTodosListing,
} from "../../../redux/actions/todo";

import {
  fetchActivitiesAccount,
  fetchActivitiesListing,
} from "../../../redux/actions/activities";

import { setUserActivities } from "../../../redux/actions/user-activities";
import { setUserNotesList } from "../../../redux/actions/user-notes";
import { setUserTodos } from "../../../redux/actions/user-todos";

import SidebarHeader from "./SidebarHeader";
import MergedList from "./MergedList";

import "../../../assets/scss/components/fts/data-list-sidebar.scss";

class Sidebar extends React.Component {
  state = {
    inProgress: false,
    showTodos: true,
    showNotes: true,
    showActivities: true,
    merged: [],
  };

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    if (this.props.isListing) {
      this.getListingSidebarData();
    } else {
      this.getAccountSidebarData();
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.notes, this.props.notes) ||
      !isEqual(prevProps.todos, this.props.todos) ||
      !isEqual(prevProps.activities, this.props.activities)
    ) {
      this.handleListMerged();
    }
  }

  getListingSidebarData = () => {
    if (this.state.inProgress) {
      return;
    }

    this.setState({ inProgress: true });

    Promise.all([
      this.props.fetchNotesListing(0, this.props.listingID),
      this.props.fetchTodosListing(this.props.listingID),
      this.props.fetchActivitiesListing(this.props.listingID),
    ])
      .then(([notesRes, todosRes, activitiesRes]) => {
        this.props.setUserNotesList(notesRes.data);
        this.props.setUserTodos(todosRes.data);
        this.props.setUserActivities(activitiesRes.data);
        this.handleListMerged();
      })
      .catch((err) => {
        console.log("err = ", err);
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        this.setState({ inProgress: false });
      });
  };

  getAccountSidebarData = () => {
    if (this.state.inProgress) {
      return;
    }

    this.setState({ inProgress: true });

    Promise.all([
      this.props.fetchNotesAccount(0, this.props.accountID),
      this.props.fetchTodosAccount(this.props.accountID),
      this.props.fetchActivitiesAccount(this.props.accountID),
    ])
      .then(([notesRes, todosRes, activitiesRes]) => {
        this.props.setUserNotesList(notesRes.data);
        this.props.setUserTodos(todosRes.data);
        this.props.setUserActivities(activitiesRes.data);
        this.handleListMerged();
      })
      .catch((err) => {
        console.log("err = ", err);
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        this.setState({ inProgress: false });
      });
  };

  handleListMerged = () => {
    let merged = [];
    const { notes, todos, activities } = this.props;

    notes.forEach((note) => {
      note.type = "note";
      merged.push(note);
    });

    todos.forEach((todo) => {
      todo.type = "todo";
      merged.push(todo);
    });

    if (activities && activities.length) {
      activities.forEach((activity) => {
        if (typeof activity === "object" && activity !== null) {
          activity.type = "activity";
        }
        merged.push(activity);
      });
    }

    merged.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    this.setState({ merged });
  };

  toggleTodos = (bool) => {
    this.setState({ showTodos: bool });
  };

  toggleNotes = (bool) => {
    this.setState({ showNotes: bool });
  };

  toggleActivities = (bool) => {
    this.setState({ showActivities: bool });
  };

  render() {
    const {
      show,
      adminID,
      listingID,
      callToAction,
      switchSideTab,
      openSidebar,
      isListing,
      notes,
      todos,
      activities,
    } = this.props;
    const { showTodos, showNotes, showActivities, merged, inProgress } =
      this.state;

    return (
      show && (
        <div className={"acc-list-sidebar-wrapper show"}>
          <div className="acc-list-animation-wrapper">
            <Card>
              <CardBody>
                <SidebarHeader
                  toggleTodos={this.toggleTodos}
                  toggleNotes={this.toggleNotes}
                  toggleActivities={this.toggleActivities}
                  isListing={isListing}
                  {...{ showTodos, showNotes, showActivities, switchSideTab }}
                />

                <hr />

                {inProgress && (
                  <div className="d-flex mt-1 justify-content-center">
                    <Spinner size="sm" />
                  </div>
                )}

                <MergedList
                  list={merged}
                  {...{ adminID, listingID, callToAction }}
                  onReplyAdded={this.addReply}
                  showNotes={showNotes}
                  showTodos={showTodos}
                  showActivities={showActivities}
                  isListing={isListing}
                  openSidebar={openSidebar}
                />

                {!inProgress && (
                  <SidebarMessage
                    {...{
                      merged,
                      showNotes,
                      showTodos,
                      showActivities,
                      notes,
                      todos,
                      activities,
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      )
    );
  }
}
class SidebarMessage extends React.PureComponent {
  generateMessageBasedOnState = () => {
    const { showNotes, showTodos, showActivities, notes, todos, activities } =
      this.props;
    let message = `There are no ${
      showTodos && todos.length === 0
        ? showNotes || showActivities
          ? "todos or "
          : "todos "
        : ""
    }${
      showNotes && notes.length === 0
        ? showActivities
          ? "notes or "
          : "notes "
        : ""
    }${showActivities && activities.length === 0 ? "activities " : ""}.`;

    if (!showNotes && !showTodos && !showActivities) {
      message = "Select one of the options above.";
    }

    return message;
  };

  render() {
    const {
      merged,
      showNotes,
      showTodos,
      showActivities,
      notes,
      todos,
      activities,
    } = this.props;

    if (merged.length && showNotes && showTodos && showActivities) {
      return null;
    }

    if (
      (showNotes && notes.length) ||
      (showTodos && todos.length) ||
      (showActivities && activities.length)
    ) {
      return null;
    }

    return (
      <div>
        <p>{this.generateMessageBasedOnState()}</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const recentNotes = state.accountNotes.list.slice(0, 10);
  const recentTodos = state.accountTodos.list.slice(0, 10);
  const recentActivities = state.accountActivities.activities
    .filter(
      (activity) =>
        activity.activityType !== "posted_listing_note" ||
        "posted_account_note",
    )
    .slice(0, 10);

  return {
    notes: recentNotes,
    todos: recentTodos,
    activities: recentActivities,
  };
};

export default connect(mapStateToProps, {
  fetchNotesListing,
  fetchNotesAccount,
  fetchTodosAccount,
  fetchActivitiesAccount,
  fetchActivitiesListing,
  fetchTodosListing,
  setUserNotesList,
  setUserTodos,
  setUserActivities,
})(Sidebar);
