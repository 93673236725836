import styled from "styled-components";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { ZIndexValues } from "@find-truck-service/types/constants/zIndexValues";
import { FtsPortal } from "@find-truck-service/ui/src/FtsPortal/FtsPortal";
export const FtsModalWrapper = styled(FtsPortal) `
  display: flex;
  min-width: 100%;
  align-items: center;
  justify-content: center;
  z-index: ${ZIndexValues.MODAL_WRAPPER};
`;
export const FtsFullSizeModalWrapper = styled(FtsPortal) `
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: ${ZIndexValues.MODAL_WRAPPER};
  background-color: ${ColorsValue["greyscale-0"]};
`;
