import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectListingData } from "../../../redux/reducers/v2/listing";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
import { PROOF_STATUSES_ENUM } from "@find-truck-service/types/constants/proofStatuses";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";

export const SideOutListingPreviewProofApprovedMessage = () => {
  const listingsData = useSelector(selectListingData);
  const { proof, renewalTimestamp } = listingsData;

  if (!proof) return <EmptyComponent />;
  if (proof.status !== PROOF_STATUSES_ENUM.APPROVED) return <EmptyComponent />;

  const approvedOn = moment(proof.updatedAt).format("ll");

  const renewalDate = moment.unix(renewalTimestamp).format("ll");

  return (
    <Message
      type="success"
      title={""}
      subtitle={`Proof approved on ${approvedOn} by ${proof.lastUpdatedBy}. Listing renewal date ${renewalDate}`}
    />
  );
};
