import { P } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import React from "react";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
export const TableCellTextTitle = (props) => {
    if (!props.title)
        return React.createElement(EmptyComponent, null);
    return (React.createElement(P, { variant: VariantsValues["text-3xs"], color: ColorsValue[props.color || "greyscale-800"] }, props.title));
};
