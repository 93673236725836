import React from "react";
import { ListingItem } from "@find-truck-service/ui/src/components/react/ListingItem";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ListingItemsTypeValues } from "@find-truck-service/types/ui/listingItemComponent";
import { useSelector } from "react-redux";
import { selectListingData } from "../../../redux/reducers/v2/listing";
import { SideOutListingPreviewProofApprovedMessage } from "./SideOutListingPreviewProofApprovedMessage";
import { SideOutListingPreviewDraftListingMessage } from "./SideOutListingPreviewDraftListingMessage";
import { SideOutListingPreviewCreatedListingMessage } from "./SideOutListingPreviewCreatedListingMessage";

export const SideOutListingPreviewContent = () => {
  const listingData = useSelector(selectListingData);

  return (
    <FtsWrapper py={"2xl"} px={"3xl"} height={"100%"}>
      <SideOutListingPreviewProofApprovedMessage />
      <SideOutListingPreviewDraftListingMessage />
      <SideOutListingPreviewCreatedListingMessage />
      <FtsWrapper mt={"3xl"}>
        <ListingItem
          listingData={listingData}
          type={ListingItemsTypeValues.SEARCH}
        />
      </FtsWrapper>
    </FtsWrapper>
  );
};
