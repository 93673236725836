import { useDispatch, useSelector } from "react-redux";
import { selectProofData } from "../../../redux/reducers/v2/proof";
import React, { useCallback } from "react";
import {
  closeSubSideOutAction,
  openEditListingSubSideOutAction,
  openSendProofSubSideOutAction,
} from "../../../redux/actions/v2/sideOut";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { setSendProofData } from "../../../redux/actions/v2/proof";
import { PROOF_STATUSES_ENUM } from "@find-truck-service/types/constants/proofStatuses";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";

export const NotSendListingProofFooter = () => {
  const dispatch = useDispatch();
  const proofData = useSelector(selectProofData);

  const updateListing = useCallback(async () => {
    try {
      dispatch(
        openEditListingSubSideOutAction({
          data: { listingId: proofData.adId },
          onSuccess: () => dispatch(closeSubSideOutAction()),
        }),
      );
    } catch (e) {
      console.log(e);
    }
  }, [proofData]);

  const updateAccount = useCallback(() => {
    window.open(`/accounts/${proofData.userId}`, "_blank");
  }, [proofData]);

  const resendProof = useCallback(async () => {
    try {
      dispatch(setSendProofData({}));
      dispatch(
        openSendProofSubSideOutAction({
          data: {
            sendProofAdId: proofData.adId,
            sendProofUserId: proofData.userId,
          },
        }),
      );
    } catch (e) {
      console.log(e);
    }
  }, [proofData]);

  if (proofData.status !== PROOF_STATUSES_ENUM.NOT_SEND)
    return <EmptyComponent />;
  return (
    <FtsColumn size={{ xs: 12 }} textAlign={"end"}>
      <FtsWrapper
        display={"flex"}
        align={"center"}
        justify={"flex-end"}
        gap={"lg"}
      >
        <Button
          onClick={updateAccount}
          type={ButtonTypes.text}
          color={ButtonColorsValue.accent}
          size={ButtonSizes.sm}
          label={"Account"}
          iconName={IconNames.ExternalLink}
        />
        <Button
          onClick={updateListing}
          type={ButtonTypes.outlined}
          color={ButtonColorsValue.accent}
          size={ButtonSizes.sm}
          label={"Edit"}
          iconName={IconNames.Edit}
        />
        <Button
          onClick={resendProof}
          type={ButtonTypes.contained}
          color={ButtonColorsValue.accent}
          size={ButtonSizes.sm}
          label={"Send Proof"}
          iconName={IconNames.Send}
        />
      </FtsWrapper>
    </FtsColumn>
  );
};
