import React from "react";
import { TableCellText, } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellText";
import { TableCellLink, } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellLink";
import { TableCellBadge, } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellBadge";
import { TableCellDropdown, } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellDropdown";
import { TableCellCheckbox, } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellCheckbox";
import { TableCellSkeletonLoader } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellSkeletonLoader";
import { TableCellDate } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellDate";
export const TableCellTypesValues = {
    text: "text",
    link: "link",
    badge: "badge",
    dropdown: "dropdown",
    checkbox: "checkbox",
    date: "date",
};
const TableCellComponent = (props) => {
    if (props.isLoading) {
        return React.createElement(TableCellSkeletonLoader, { ...props });
    }
    if (props.type === TableCellTypesValues.link) {
        return React.createElement(TableCellLink, { ...props });
    }
    if (props.type === TableCellTypesValues.badge) {
        return React.createElement(TableCellBadge, { ...props });
    }
    if (props.type === TableCellTypesValues.dropdown) {
        return React.createElement(TableCellDropdown, { ...props });
    }
    if (props.type === TableCellTypesValues.checkbox) {
        return React.createElement(TableCellCheckbox, { ...props });
    }
    if (props.type === TableCellTypesValues.date) {
        return React.createElement(TableCellDate, { ...props });
    }
    return React.createElement(TableCellText, { ...props });
};
export const TableCell = React.memo(TableCellComponent);
TableCell.displayName = "TableCell";
