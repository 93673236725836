/* eslint-disable indent */

import { createSelector } from "reselect";
export const UPDATE_SERVICES_AMENITIES_SEARCH_VALUE =
  "ESSENTIALS_LIST/UPDATE_SERVICES_AMENITIES_SEARCH_VALUE";
export const UPDATE_AMENITIES_SEARCH_VALUE =
  "ESSENTIALS_LIST/UPDATE_AMENITIES_SEARCH_VALUE";

const initialState = {
  amenities: [],
  serviceAmenities: [],
  states: [],
  userStatuses: [],
  leadsStatuses: [],
  categories: [],
  subCategories: [],
  employees: [],
  types: [],
  emailsFolders: [],
  permissions: [],
  emailTypes: null,
  activitiesTypes: null,
  serviceAmenitiesSearchValue: "",
  amenitiesSearchValue: "",
  adminUsers: [],
};

const EssentialListsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_AMENITIES_LIST":
      return { ...state, amenities: action.data };

    case "UPDATE_SERVICE_AMENITIES_LIST":
      return { ...state, serviceAmenities: action.data };

    case "UPDATE_STATES_LIST":
      return {
        ...state,
        states: action.data.map((g) => ({ label: g.value, value: g.value })),
      };

    case "UPDATE_CATEGORIES_LIST":
      return { ...state, categories: action.data };

    case "UPDATE_SUBCATEGORIES_LIST":
      return { ...state, subCategories: action.data };

    case "UPDATE_EMPLOYEES_LIST":
      return { ...state, employees: action.data };

    case "UPDATE_TYPES_LIST":
      const types = action.data.map((e) => ({
        ...e,
        value: e.description.toLowerCase(),
      }));
      return { ...state, types };

    case "UPDATE_EMAILS_FOLDERS_LIST":
      return { ...state, emailsFolders: action.data };

    case "UPDATE_EMAILS_TYPES":
      return { ...state, emailTypes: action.data };

    case "UPDATE_ACTIVITIES_TYPES":
      return { ...state, activitiesTypes: action.data };

    case "UPDATE_STATUSES_LIST":
      return {
        ...state,
        userStatuses: action.userStatuses.map((status) => ({
          value: status.id,
          label: status.name,
        })),
      };

    case "UPDATE_LEADS_STATUSES_LIST":
      return {
        ...state,
        leadsStatuses: action.leadsStatuses.map((status) => ({
          value: status.id,
          label: status.name,
        })),
      };

    case "UPDATE_PERMISSIONS":
      return {
        ...state,
        permissions: action.data,
      };
    case "UPDATE_RATES":
      return {
        ...state,
        rates: action.data,
      };
    case "GET_LISTING_STATUSES_SUCCESS":
      return {
        ...state,
        listingStatuses: action.data,
      };
    case UPDATE_SERVICES_AMENITIES_SEARCH_VALUE:
      return {
        ...state,
        serviceAmenitiesSearchValue: action.payload.serviceAmenitiesSearchValue,
      };
    case UPDATE_AMENITIES_SEARCH_VALUE:
      return {
        ...state,
        amenitiesSearchValue: action.payload.amenitiesSearchValue,
      };

    case "UPDATE_ADMIN_USERS":
      return {
        ...state,
        adminUsers: action.data,
      };

    default:
      return state;
  }
};

const selectEssentialListState = (state) => state.essentialLists;

export const selectListingStatuses = createSelector(
  selectEssentialListState,
  (essentialLists) => {
    return essentialLists.listingStatuses;
  },
);

export const selectStates = createSelector(
  selectEssentialListState,
  (essentialLists) => {
    return essentialLists.states;
  },
);
export const selectServiceAmenities = createSelector(
  selectEssentialListState,
  (essentialLists) => {
    return essentialLists.serviceAmenities;
  },
);
export const selectServiceAmenitiesSearchValue = createSelector(
  selectEssentialListState,
  (essentialLists) => {
    return essentialLists.serviceAmenitiesSearchValue;
  },
);
export const selectAmenities = createSelector(
  selectEssentialListState,
  (essentialLists) => {
    return essentialLists.amenities;
  },
);

export const selectAmenitiesSearchValue = createSelector(
  selectEssentialListState,
  (essentialLists) => {
    return essentialLists.amenitiesSearchValue;
  },
);
export const selectCategories = createSelector(
  selectEssentialListState,
  (essentialLists) => {
    return essentialLists.categories;
  },
);

export const selectSubCategories = createSelector(
  selectEssentialListState,
  (essentialLists) => {
    return essentialLists.subCategories;
  },
);
export const selectRates = createSelector(
  selectEssentialListState,
  (essentialLists) => {
    return essentialLists.rates;
  },
);
export const selectUserStatuses = createSelector(
  selectEssentialListState,
  (essentialLists) => {
    return essentialLists.userStatuses;
  },
);

export const selectAdminUsers = createSelector(
  selectEssentialListState,
  (essentialLists) => {
    return essentialLists.adminUsers;
  },
);

export const selectAdsRates = createSelector(
  selectEssentialListState,
  (essential) => {
    return essential.rates;
  },
);

export const selectUsersTypes = createSelector(
  selectEssentialListState,
  (essential) => {
    return essential.userTypes;
  },
);

export const selectAdsPrices = createSelector(
  selectEssentialListState,
  (essential) => {
    return essential.adsPrices;
  },
);

export const selectTruckTypes = createSelector(
  selectEssentialListState,
  (essential) => {
    return essential.truckTypes;
  },
);

export default EssentialListsReducer;
