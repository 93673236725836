import styled from "styled-components";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { StrokesValues } from "@find-truck-service/types/ui/stroke";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { FtsTableRowColorsValues } from "@find-truck-service/types/ui/table-row";
const getStyle = (props) => {
    let style = `background-color: ${props.zebraStrips ? ColorsValue["greyscale-50"] : ColorsValue["greyscale-0"]};`;
    if (props.leftStroke && props.color) {
        style += `td:first-child { border-left: ${StrokesValues.md} solid ${FtsTableRowColorsValues[props.color]}};`;
    }
    return style;
};
export const FtsTableRow = styled.tr `
  ${getStyle};
  &:hover {
    background-color: ${ColorsValue["greyscale-100"]};
  }
  ${(props) => props.active && `background-color:  ${ColorsValue["blue-accent-50"]};`};
  &:active {
    background-color: ${ColorsValue["blue-accent-50"]};
  }
  td:first-child {
    border-top-left-radius: ${Spacing["2xs"]};
    border-bottom-left-radius: ${Spacing["2xs"]};
  }
  display: table;
  width: 100%;
  table-layout: fixed;
`;
