import React from "react";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { Message, messageTypes } from "@find-truck-service/ui/src/components/react/Message";
import { RichTextWrapper } from "@find-truck-service/ui/src/components/react/RichTextEditor/RichTextWrapper";
import { ListingItemImage } from "@find-truck-service/ui/src/components/react/ListingItem/Components/ListingItemImage";
import { ListingNameRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingNameRow";
import { ListingContactsRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingContactsRow";
import { ListingAmenitiesRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingAmenitiesRow";
import { ListingDescriptionRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingDescriptionRow";
import { ListingWorkingHoursRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingWorkingHoursRow";
import { ListingServicesAmenitiesRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingServicesRow";
export const PreviewListingContent = (props) => {
    const showImage = props.price?.showImage;
    const showAmenities = !!props.listingData?.adsAmenities?.length;
    const showServices = !!props.listingData?.adsServiceAmenities?.length;
    const showDescription = props.price?.descriptionLines || props.showBenefits;
    if (!props.listingData)
        return React.createElement(EmptyComponent, null);
    return (React.createElement(FtsWrapper, { bg: ColorsValue["greyscale-50"], p: "0", pt: "xl", overflow: "auto" },
        showImage && (React.createElement(FtsWrapper, { px: "xl", mb: "2xl" },
            React.createElement(ListingItemImage, { width: "100%", height: "100%", src: props.imageSrc, priceId: props.price?.id }))),
        !showImage && props.showBenefits && (React.createElement(FtsWrapper, { px: "xl", pb: "2xl" },
            React.createElement(Message, { type: messageTypes.warning, title: "Image not available for this listing" }))),
        React.createElement(ListingNameRow, { name: props.listingData.name, city: props.listingData.city, state: props.listingData.state, address: props.listingData.address, interstate: props.listingData.interstate, milemarker: props.listingData.milemarker }),
        React.createElement(ListingContactsRow, { fax: props.listingData.fax || "", website: props.listingData.website || "", afterHours: props.listingData.afterHours || "", phoneNumber: props.listingData.phoneNumber || "", phoneNumberSecondary: props.listingData.phoneNumberSecondary || "", showWebsite: !!props.price?.showWebsite && !!props.listingData.website }),
        showDescription && (React.createElement(RichTextWrapper, null,
            React.createElement(ListingDescriptionRow, { price: props.price, priceId: props.price?.id, showBenefits: props.showBenefits, description: props.listingData.description }))),
        React.createElement(ListingWorkingHoursRow, { mobileRoadServiceOpen: props.listingData.mobileRoadServiceOpen, towingRecoveryOpen: props.listingData.towingRecoveryOpen, specialHours: props.listingData.specialHours, alwaysOpen: props.listingData.alwaysOpen, monday: props.listingData.workingHours?.monday || "", tuesday: props.listingData.workingHours?.tuesday || "", wednesday: props.listingData.workingHours?.wednesday || "", thursday: props.listingData.workingHours?.thursday || "", friday: props.listingData.workingHours?.friday || "", saturday: props.listingData.workingHours?.saturday || "", sunday: props.listingData.workingHours?.sunday || "" }),
        showServices && (React.createElement(ListingServicesAmenitiesRow, { serviceAmenities: props.serviceAmenities, listingServiceAmenities: props.listingData.adsServiceAmenities || [] })),
        showAmenities && (React.createElement(ListingAmenitiesRow, { amenities: props.amenities, listingAmenities: props.listingData.adsAmenities || [] }))));
};
