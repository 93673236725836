import {
  dontShowWorkingHoursToDropdown,
  FROM_DEFAULT,
  TO_DEFAULT,
} from "../constants";

export function getDayValues(d, workingHours) {
  const day = d.toLowerCase();
  let [fromValue, toValue] = [FROM_DEFAULT, TO_DEFAULT];

  if (workingHours && workingHours[day]) {
    [fromValue, toValue] = workingHours[day].split(" - ");
  }

  if (dontShowWorkingHoursToDropdown.includes(fromValue)) {
    toValue = "";
  }

  return {
    day,
    fromValue,
    toValue,
  };
}
