import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Divider } from "@find-truck-service/ui/src/components/react/Divider";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
export const ConfirmationDialogFooterComponent = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Divider, null),
        React.createElement(FtsWrapper, { px: {
                xs: "xl",
                sm: "xl",
                md: "2xl",
                lg: "3xl",
            }, py: "lg", display: "flex", justify: "flex-end", gap: "lg", direction: { xs: "column-reverse", sm: "row", md: "row", lg: "row" } },
            !!props.textButton && props.textButton.color && (React.createElement(Button, { onClick: props.textButton?.onClick, size: ButtonSizes.md, type: ButtonTypes.text, color: ButtonColorsValue[props.textButton.color], label: props.textButton?.label, width: {
                    xs: "100%",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                } })),
            !!props.outlinedButton && props.outlinedButton.color && (React.createElement(Button, { onClick: props.outlinedButton?.onClick, size: ButtonSizes.md, type: ButtonTypes.outlined, color: ButtonColorsValue[props.outlinedButton.color], label: props.outlinedButton?.label, width: {
                    xs: "100%",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                } })),
            !!props.containedButton && props.containedButton.color && (React.createElement(Button, { onClick: props.containedButton?.onClick, size: ButtonSizes.md, type: ButtonTypes.contained, color: ButtonColorsValue[props.containedButton.color], label: props.containedButton?.label, width: {
                    xs: "100%",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                } })))));
};
