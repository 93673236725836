import { GET_ACCOUNT_PROOFS_REQUEST } from "../../reducers/v2/account-proofs";
import { takeEvery, call, put, all } from "redux-saga/effects";

import FtsV2Instance from "../../../axios/ftsv2.instance";

import {
  getAccountProofsFailed,
  getAccountProofsSuccess,
} from "../../actions/v2/account-proofs";
import { toast } from "react-toastify";

function* getAccountProofsRequestSaga(action) {
  try {
    const { data, countData } = yield all({
      data: call(FtsV2Instance.get, "/proofs/admin/get-proofs-list", {
        params: action.payload.data.params,
      }),
      countData: call(FtsV2Instance.get, "/proofs/admin/get-proofs-count", {
        params: action.payload.data.params,
      }),
    });

    yield put(
      getAccountProofsSuccess({
        data: {
          data: formatDataForTable(data.data.data),
          count: countData.data.data,
        },
      }),
    );
  } catch (e) {
    console.log(e);
    const message = e.response
      ? e.response.data.message
      : "Error while getting proofs";
    yield put(getAccountProofsFailed({ data: message }));
    toast.error(message);
  }
}

export default function* accountProofsWatcher() {
  yield takeEvery(GET_ACCOUNT_PROOFS_REQUEST, getAccountProofsRequestSaga);
}

const formatDataForTable = (data) => {
  const formattedData = [];
  const proofStatuses = ["", "Approved", "Rejected", "Pending", "Not Sent"];
  const proofStatusesColor = ["", "success", "error", "warning", "accent"];
  for (let proof of data) {
    let obj = {};
    for (let [key, val] of Object.entries(proof)) {
      obj[key] = val;
      obj[`tableData_${key}`] = val;

      if (key === "status") {
        obj[`tableData_${key}`] = proofStatuses[proof.status];
        obj["tableData_rejected"] = proofStatuses[proof.status];
      }
    }
    obj.tableRowProps = {
      leftStroke: true,
      color: proofStatusesColor[proof.status],
      zebraStrips: true,
    };
    formattedData.push(obj);
  }
  return formattedData;
};
