import {
  SEND_LISTING_BY_AD_ID_REQUEST,
  SEND_LISTING_BY_AD_ID_SUCCESS,
  SEND_LISTING_BY_AD_ID_FAILED,
} from "../../../reducers/v2/sendListing";

export const sendListingByAdIdRequestAction = (payload) => ({
  type: SEND_LISTING_BY_AD_ID_REQUEST,
  payload,
});

export const sendListingByAdIdSuccessAction = (payload) => ({
  type: SEND_LISTING_BY_AD_ID_SUCCESS,
  payload,
});

export const sendListingByAdIdFailedAction = (payload) => ({
  type: SEND_LISTING_BY_AD_ID_FAILED,
  payload,
});
