import React from "react";
import { FtsTableCell } from "@find-truck-service/ui/src/FtsTableCell/FtsTableCell";
import { TableCellNoData } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellNoData";
import { TableCellTextTitle } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellTextTitle";
import { TableCellTextSubTitle } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellTextSubTitle";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import moment from "moment/moment";
import { TableCellTypesValues } from "@find-truck-service/ui/src/components/react/TableCell";
const formatDate = (date) => moment(date).format("ll").toString();
const formatHour = (date) => moment(date).format("hh:mm A").toString();
export const TableCellDate = (props) => {
    if (!props.title)
        return React.createElement(TableCellNoData, { type: TableCellTypesValues.text, ...props });
    const title = formatDate(props.title);
    const subtitle = formatHour(props.title);
    return (React.createElement(FtsTableCell, { active: props.active, isLoading: props.isLoading, onClick: props.onClick, size: props.size },
        React.createElement(FtsWrapper, null,
            React.createElement(TableCellTextTitle, { title: title }),
            React.createElement(TableCellTextSubTitle, { subtitle: subtitle }))));
};
