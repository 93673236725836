import React from "react";
import { FtsTableCell } from "@find-truck-service/ui/src/FtsTableCell/FtsTableCell";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { TableCellNoData } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellNoData";
import { TableCellTypesValues } from "@find-truck-service/ui/src/components/react/TableCell";
export const TableCellCheckbox = (props) => {
    if (!props.checkboxProps)
        return React.createElement(TableCellNoData, { type: TableCellTypesValues.text, ...props });
    return (React.createElement(FtsTableCell, { isLoading: props.isLoading, size: props.size },
        React.createElement(Checkbox, { ...props.checkboxProps })));
};
