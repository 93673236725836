import React from "react";
import { MostPopularTag } from "./MostPopularTag";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { getShowMostPopularTag, getShowPrice } from "../helpers";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Divider } from "@find-truck-service/ui/src/components/react/Divider";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
export const ListingItemXsHeader = (props) => {
    const { type, price, priceId } = props;
    const showPrice = getShowPrice(priceId);
    const showMostPopularTag = getShowMostPopularTag(priceId);
    return (React.createElement(FtsWrapper, { display: "flex", direction: "column", gap: "lg", flex: 1, p: "lg", pb: "0" },
        React.createElement(FtsWrapper, { gap: "sm", display: "flex", align: "center", justify: "space-between" },
            React.createElement(FtsWrapper, { display: "flex", align: "center", gap: "sm", wrap: "wrap" },
                React.createElement(Span, { color: ColorsValue["greyscale-800"], variant: VariantsValues["text-xs"], weight: WeightSize.semibold }, type),
                showMostPopularTag && React.createElement(MostPopularTag, null)),
            showPrice && (React.createElement(Span, { color: ColorsValue["greyscale-800"], variant: VariantsValues["text-2xs"], weight: WeightSize.regular }, `$${price}/year`))),
        React.createElement(Divider, null)));
};
