import { Message } from "@find-truck-service/ui/src/components/react/Message";
import moment from "moment";
import React from "react";

import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { useSelector } from "react-redux";
import { selectProofData } from "../../../redux/reducers/v2/proof";
import { PROOF_STATUSES_ENUM } from "@find-truck-service/types/constants/proofStatuses";

export const SideOutListingProofRejectedMessage = () => {
  const proofData = useSelector(selectProofData);

  if (proofData.status !== PROOF_STATUSES_ENUM.REJECTED)
    return <EmptyComponent />;

  let subtitle = proofData.rejectedReason;
  if (proofData.rejectReasonPhoneNumber) {
    subtitle += ` Vendor requested a call: ${proofData.rejectReasonPhoneNumber}`;
  }
  return (
    <Message
      type="error"
      title={`Proof rejected on ${moment(proofData.updatedAt).format("ll")}`}
      subtitle={subtitle}
    />
  );
};
