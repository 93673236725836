import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SideOutSendProofFooter } from "./SideOutSendProofFooter";
import { SideOutSendProofHeader } from "./SideOutSendProofHeader";

import { selectSideOutData } from "../../../redux/reducers/v2/sideOut";
import { ShareProviders } from "../ShareProviders";
import { getShareProvidersRequestAction } from "../../../redux/actions/v2/shareProviders";
import { selectProofIsLoading } from "../../../redux/reducers/v2/proof";
import { SideOutLoadingState } from "@find-truck-service/ui/src/components/react/SideOut/Components/SideOutLoadingState";

export const SideOutSendProof = () => {
  const sideOutData = useSelector(selectSideOutData);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectProofIsLoading);
  useEffect(() => {
    if (sideOutData?.sendProofAdId || sideOutData?.sendProofUserId) {
      const params = {
        adId: sideOutData.sendProofAdId,
        userId: sideOutData.sendProofUserId,
      };
      dispatch(getShareProvidersRequestAction({ data: params }));
    }
  }, [sideOutData?.sendProofAdId, sideOutData?.sendProofUserId]);
  if (isLoading) return <SideOutLoadingState />;

  return (
    <>
      <SideOutSendProofHeader />
      <ShareProviders />
      <SideOutSendProofFooter />
    </>
  );
};
