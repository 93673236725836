import React, { useState } from "react";
import { TableHead } from "@find-truck-service/ui/src/components/react/TableHead";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { TableCell } from "@find-truck-service/ui/src/components/react/TableCell";
import { FtsTableRow } from "@find-truck-service/ui/src/FtsTableRow/FtsTableRow";
import styled from "styled-components";
import { FtsTableHeadWidth } from "@find-truck-service/types/ui/table-head";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { TablePagination } from "@find-truck-service/ui/src/components/react/TablePagination";
import { SORTING_TYPES_VALUES } from "@find-truck-service/types/constants/sorting";
import { P } from "@find-truck-service/ui/src/FtsText";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
export const Table = (props) => {
    const onHeaderClickInternal = (column) => {
        if (props.isLoading || !props.data)
            return;
        if (column.tableHeaderProps.sortable) {
            const isColumnAlreadySorted = props.sortedColumnId === column.id;
            let newSort = SORTING_TYPES_VALUES.ASC;
            if (props.sortedOrder === SORTING_TYPES_VALUES.ASC && isColumnAlreadySorted) {
                newSort = SORTING_TYPES_VALUES.DESC;
            }
            if (props.sortedOrder === SORTING_TYPES_VALUES.DESC && isColumnAlreadySorted) {
                newSort = SORTING_TYPES_VALUES.NONE;
                column = {};
            }
            //
            //   setSortedColumn(column);
            //   setSort(newSort);
            if (props.onSort) {
                props.onSort(newSort, column);
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FtsWrapper, { overflow: "auto", minHeight: "40vh" },
            React.createElement(FtsTable, null,
                React.createElement(FtsTableHead, null,
                    React.createElement(FtsTableHeadRow, null, props.columns.map((column, i) => {
                        let iconProps = {};
                        if (column.tableHeaderProps.sortable) {
                            iconProps = { iconName: IconNames.Sortable };
                            if (props.sortedColumnId === column.id) {
                                if (props.sortedOrder === SORTING_TYPES_VALUES.ASC)
                                    iconProps = { iconName: IconNames.ArrowUp };
                                if (props.sortedOrder === SORTING_TYPES_VALUES.DESC)
                                    iconProps = { iconName: IconNames.ArrowDown };
                            }
                        }
                        return (React.createElement(TableHead, { key: i, ...column.tableHeaderProps, onClick: () => onHeaderClickInternal(column), active: props.sortedColumnId === column.id, iconProps: iconProps }));
                    }))),
                React.createElement(FtsTableBody, null,
                    React.createElement(TableBody, { data: props.data, onRowClick: props.onRowClick, columns: props.columns, isLoading: props.isLoading, clearFilter: props.clearFilter })))),
        React.createElement(TablePagination, { page: props.page, pageSize: props.pageSize, count: props.count, setPage: props.setPage, setPageSize: props.setPageSize })));
};
const FtsTable = styled("table") `
  border-spacing: 0;
  border: unset;
  table-layout: fixed;
  border-collapse: separate;
`;
const FtsTableBody = styled("tbody") `
  display: block;
  overflow: auto;
  //height: 30vw;
`;
const FtsTableHeadRow = styled("tr") `
  display: block;
`;
const FtsTableHead = styled("thead") ``;
const TableBody = (props) => {
    const [activeRowId, setActiveRowId] = useState(0);
    if (!props.data || !props.data.length) {
        return (React.createElement(FtsWrapper, { display: "flex", justify: "center", gap: "lg", minHeight: "40vh", align: "center", direction: "column" },
            React.createElement(FtsIcons, { iconName: IconNames.Search, svgProps: { width: SvgIconSizeValues["2xxl"], height: SvgIconSizeValues["2xxl"] } }),
            React.createElement(P, { variant: VariantsValues["text-xs"] }, "No results found"),
            props.clearFilter && (React.createElement(Button, { onClick: props.clearFilter, type: ButtonTypes.outlined, color: ButtonColorsValue.onBrand, size: ButtonSizes.sm, iconName: IconNames.Trash, label: "Clear" }))));
    }
    const onRowClickInternal = (data) => {
        if (props.onRowClick) {
            setActiveRowId(data.id);
            return props.onRowClick(data);
        }
    };
    return (React.createElement(React.Fragment, null, props.data.map((tableData, i) => {
        let tableRowProps = tableData.tableRowProps;
        if (tableData.tableRowProps.propsBasedOnColumnData) {
            tableRowProps = {
                ...tableData.tableRowProps,
                ...tableData.tableRowProps.propsBasedOnColumnData(tableData),
            };
        }
        return (React.createElement(FtsTableRow, { ...tableRowProps, active: activeRowId === tableData.id, onClick: () => onRowClickInternal(tableData), zebraStrips: tableData.tableRowProps.zebraStrips && !!(i % 2), key: i }, props.columns.map((tableColumn, i) => {
            let tableCellProps = tableColumn.tableCellProps;
            if (tableColumn.tableCellProps.propsBasedOnColumnData) {
                tableCellProps = {
                    ...tableColumn.tableCellProps,
                    ...tableColumn.tableCellProps.propsBasedOnColumnData(tableData),
                };
            }
            return (React.createElement("td", { key: i, style: {
                    padding: "0px",
                    minWidth: FtsTableHeadWidth[tableColumn.tableHeaderProps.variant],
                    maxWidth: FtsTableHeadWidth[tableColumn.tableHeaderProps.variant],
                    width: FtsTableHeadWidth[tableColumn.tableHeaderProps.variant],
                } },
                React.createElement(TableCell, { ...tableCellProps, isLoading: props.isLoading || tableCellProps.isLoading, title: tableData[`tableData_${tableColumn.id}`] })));
        })));
    })));
};
