import { createSelector } from "reselect";

export const SET_DATA = "SEARCH/SET_DATA";
export const SET_TOTAL_SEARCHES = "SEARCH/SET_TOTAL_SEARCHES";
export const SET_IS_LOADING = "SEARCH/SET_IS_LOADING";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  invalidFormFields: null,
  totalSearches: null,
};

/// Reducer
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return { ...state, data: action.payload.data };
    case SET_TOTAL_SEARCHES:
      return { ...state, totalSearches: action.payload.data };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload.data };
    default:
      return state;
  }
}

/// Selectors
const selectSearchState = (state) => state.searchState;

export const selectSearchData = createSelector(selectSearchState, (search) => {
  return search.data;
});

export const selectTotalSearches = createSelector(
  selectSearchState,
  (search) => {
    return search.totalSearches;
  },
);

export const selectIsLoading = createSelector(selectSearchState, (search) => {
  return search.isLoading;
});
