import styled from "styled-components";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { device } from "@find-truck-service/types/ui/breakpoints";
export const ConfirmationDialogContainer = styled(FtsContainer) `
  z-index: 2;
  width: 100%;

  @media ${device.lg} {
    animation: ${(props) => (props.isOpen ? `slideIn 0.3s ease-in-out` : "slideOut 0.3s ease-in-out")};
  }

  @keyframes slideIn {
    from {
      transform: translateY(-50px);
      opacity: 60%;
    }
    to {
      opacity: 100%;
    }
  }
  @keyframes slideOut {
    from {
      opacity: 1;
    }
    to {
      transform: translateY(-50px);
      opacity: 0;
    }
  }
`;
