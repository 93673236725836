import React, { useCallback } from "react";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { Span } from "../../dist/ui/src/FtsText";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import Checkbox from "../../dist/ui/src/components/react/Checkbox";
import { Input } from "../../dist/ui/src/components/react/Input";
import { InputSizes, InputVariants } from "../../dist/types/ui/input";
import { ToggleSwitch } from "../../dist/ui/src/components/react/Toggle";
import { useDispatch, useSelector } from "react-redux";
import { selectListingData } from "../../../../redux/reducers/v2/listing";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";
import { WorkingHoursFromDropdown } from "./WorkingHoursFromDropdown";
import { WorkingHoursToDropdown } from "./WorkingHoursToDropdown";

import { getDayValues } from "../../../../helpers";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { FROM_DEFAULT, TO_DEFAULT } from "../../../../constants";

// export const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const ListingWorkingHours = () => {
  const listingData = useSelector(selectListingData);
  const dispatch = useDispatch();

  const onChange = useCallback(
    (key, val) => {
      dispatch(
        getListingDataByIdSuccess({ data: { ...listingData, [key]: val } }),
      );
    },
    [listingData],
  );

  const handleChangeWorkingHours = useCallback(
    (key, val) => {
      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            workingHours: { ...listingData.workingHours, [key]: val },
          },
        }),
      );
    },
    [listingData],
  );

  const handleChangeSkipWorkingHours = useCallback(() => {
    let newSkipHours = !listingData.skipHours;
    let workingHours = { ...listingData.workingHours };
    weekDays.map((d) => (workingHours[d.toLowerCase()] = " - "));

    if (!newSkipHours) {
      weekDays.map(
        (d) =>
          (workingHours[d.toLowerCase()] = `${FROM_DEFAULT} - ${TO_DEFAULT}`),
      );
    }

    dispatch(
      getListingDataByIdSuccess({
        data: {
          ...listingData,
          skipHours: !listingData.skipHours,
          workingHours: workingHours,
        },
      }),
    );
  }, [listingData]);

  const handleAlwaysOpen = useCallback(() => {
    let newAlwaysOpen = !listingData.alwaysOpen;
    let workingHours = { ...listingData.workingHours };
    weekDays.map((d) => (workingHours[d.toLowerCase()] = " - "));

    if (!newAlwaysOpen) {
      weekDays.map(
        (d) =>
          (workingHours[d.toLowerCase()] = `${FROM_DEFAULT} - ${TO_DEFAULT}`),
      );
    }

    dispatch(
      getListingDataByIdSuccess({
        data: {
          ...listingData,
          workingHours,
          alwaysOpen: newAlwaysOpen,
        },
      }),
    );
  }, [listingData]);
  console.log(listingData);
  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} mt={"2xl"}>
      <FtsColumn size={{ xs: 6 }}>
        <FtsRow columnGap={"lg"} rowGap={"lg"}>
          <FtsColumn size={{ xs: 12 }}>
            <Span
              weight={WeightSize.medium}
              variant={VariantsValues["text-3xs"]}
              color={ColorsValue["blue-accent-500"]}
            >
              WORK HOURS
            </Span>
          </FtsColumn>
          <FtsColumn size={{ xs: 12 }}>
            <ToggleSwitch
              onClick={handleAlwaysOpen}
              checked={listingData.alwaysOpen}
              label={"Are you open 24 hours?"}
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12 }}>
            {!listingData.alwaysOpen && (
              <ToggleSwitch
                onClick={handleChangeSkipWorkingHours}
                checked={listingData.skipHours}
                label={"Skip working hours"}
              />
            )}
          </FtsColumn>
          <FtsColumn size={{ xs: 12 }} gap={"lg"}>
            {!listingData.alwaysOpen && !listingData.skipHours && (
              <FtsWrapper width={"100%"}>
                {weekDays.map((d, index) => {
                  const { day, toValue, fromValue } = getDayValues(
                    d,
                    listingData.workingHours,
                  );

                  return (
                    <FtsRow
                      key={index}
                      columnGap={"lg"}
                      rowGap={"lg"}
                      mt={"lg"}
                    >
                      <FtsColumn
                        size={{ xs: 2 }}
                        align={"center"}
                        display="flex"
                      >
                        <Span
                          variant={VariantsValues["text-3xs"]}
                          color={ColorsValue["greyscale-500"]}
                        >
                          {d.slice(0, 3)}
                        </Span>
                      </FtsColumn>
                      <WorkingHoursFromDropdown
                        onChange={(value) =>
                          handleChangeWorkingHours(day, `${value} - ${toValue}`)
                        }
                        fromValue={fromValue}
                      />
                      <WorkingHoursToDropdown
                        onChange={(value) =>
                          handleChangeWorkingHours(
                            day,
                            `${fromValue} - ${value}`,
                          )
                        }
                        toValue={toValue}
                        fromValue={fromValue}
                      />
                    </FtsRow>
                  );
                })}
              </FtsWrapper>
            )}
          </FtsColumn>
        </FtsRow>
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <FtsColumn size={{ xs: 6 }} mb={"lg"}>
          <Span
            weight={WeightSize.medium}
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["blue-accent-500"]}
          >
            SPECIAL HOURS
          </Span>
        </FtsColumn>
        <FtsRow columnGap={"lg"} rowGap={"lg"}>
          <FtsColumn size={{ xs: 12 }}>
            <Checkbox
              size={CheckboxSizes.sm}
              onClick={() =>
                onChange("towingRecoveryOpen", !listingData.towingRecoveryOpen)
              }
              checked={listingData.towingRecoveryOpen}
              label={"24 hour Towing & Recovery"}
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12 }}>
            <Checkbox
              size={CheckboxSizes.sm}
              onClick={() =>
                onChange(
                  "mobileRoadServiceOpen",
                  !listingData.mobileRoadServiceOpen,
                )
              }
              checked={listingData.mobileRoadServiceOpen}
              label={"24 hour Mobile/Road Service"}
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 12 }}>
            <Input
              onChange={(e) => onChange("specialHours", e.target.value)}
              name={"specialHours"}
              value={listingData.specialHours}
              label={"Special hours"}
              variant={InputVariants.outlined}
              size={InputSizes.sm}
            />
          </FtsColumn>
        </FtsRow>
      </FtsColumn>
    </FtsRow>
  );
};
