import React from "react";
import moment from "moment/moment";
import { EmptyComponent } from "../../EmptyComponent";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
// interface GetStoreStatusPayload {
//   specialHours: string;
//   mobileRoadServiceOpen: boolean;
//   towingRecoveryOpen: boolean;
//   listingCurrentTime: string;
//   onClickHours: () => void;
//   alwaysOpen: boolean;
//   workingHours: WorkingHoursData;
// }
const formatHours = (hours) => {
    if (!hours)
        return "";
    let [time, suffix] = hours.trim().split(" ");
    let newTime = time.split(":");
    if (newTime.length > 1) {
        newTime = newTime.join(":");
    }
    else {
        newTime = `${newTime[0]}:00`;
    }
    return `${newTime} ${suffix}`;
};
export const StoreStatus = (payload) => {
    const { workingHours, alwaysOpen, specialHours, mobileRoadServiceOpen, towingRecoveryOpen, listingCurrentTime, onClickHours, } = payload;
    if (alwaysOpen)
        return React.createElement(Hours, { onClickHours: onClickHours, status: "Open" });
    if (!workingHours)
        return React.createElement(EmptyComponent, null);
    const day = moment().format("dddd").toLowerCase();
    const currentHours = workingHours[day];
    if (!currentHours)
        return React.createElement(Hours, { onClickHours: onClickHours, status: hoursStatuses.NOT_AVAILABLE });
    if (currentHours === " - ") {
        return React.createElement(Hours, { onClickHours: onClickHours, status: hoursStatuses.NOT_AVAILABLE });
    }
    const is24Hours = currentHours.toLowerCase().trim() === "24 hrs -" || currentHours.toLowerCase().trim() === "24 hrs";
    const isByAppointment = currentHours.toLowerCase().trim() === "by appointment -" || currentHours.toLowerCase().trim() === "by appointment";
    const hasSpecialHours = !!specialHours || isByAppointment;
    const currentDate = moment().format("DD/MM/YYYY");
    let currentDateEnd = moment().format("DD/MM/YYYY");
    const [openHours, closeHours] = currentHours.split("-");
    const formatOpenHours = formatHours(openHours);
    const formatCloseHours = formatHours(closeHours);
    const isAM = (str) => str.indexOf("AM") !== -1;
    const openTimeMoment = moment(`${currentDate} ${formatOpenHours}`, "DD/MM/YYYY hh:mm A");
    const closeHoursMoment = moment(`${currentDate} ${formatCloseHours}`, "DD/MM/YYYY hh:mm A");
    const isCloseBeforeStart = closeHoursMoment.isBefore(openTimeMoment);
    if (isAM(formatCloseHours) && isCloseBeforeStart)
        currentDateEnd = moment().add(1, "days").format("DD/MM/YYYY");
    const closeTimeMoment = moment(`${currentDateEnd} ${formatCloseHours}`, "DD/MM/YYYY hh:mm A");
    const isTimeAfterOpenHours = openTimeMoment.isBefore(moment(listingCurrentTime));
    const isTimeBeforeClosedHours = moment(listingCurrentTime).isBefore(closeTimeMoment);
    const isDayAdded = moment(`${currentDate}`).isBefore(moment(`${currentDateEnd}`));
    //OPEN BADGES
    if (is24Hours || mobileRoadServiceOpen || towingRecoveryOpen)
        return React.createElement(Hours, { onClickHours: onClickHours, status: hoursStatuses.OPEN });
    if (isTimeAfterOpenHours && isDayAdded)
        return React.createElement(Hours, { onClickHours: onClickHours, status: hoursStatuses.OPEN });
    if (isTimeAfterOpenHours && isTimeBeforeClosedHours) {
        const isClosingSoon = closeTimeMoment.diff(moment(listingCurrentTime), "minutes") <= 60;
        if (isClosingSoon && !hasSpecialHours)
            return React.createElement(Hours, { onClickHours: onClickHours, status: hoursStatuses.CLOSING_SOON });
        if (isClosingSoon && hasSpecialHours)
            return React.createElement(Hours, { onClickHours: onClickHours, status: hoursStatuses.SPECIAL_HOURS });
        return React.createElement(Hours, { onClickHours: onClickHours, status: hoursStatuses.OPEN });
    }
    //SPECIAL HOURS BADGES
    if (hasSpecialHours)
        return React.createElement(Hours, { onClickHours: onClickHours, status: hoursStatuses.SPECIAL_HOURS });
    //CLOSED BADGES
    return React.createElement(Hours, { onClickHours: onClickHours, status: hoursStatuses.CLOSED });
};
const hoursStatuses = {
    OPEN: "Open",
    CLOSED: "Closed",
    CLOSING_SOON: "Closing Soon",
    SPECIAL_HOURS: "Special hours",
    NOT_AVAILABLE: "Not available",
};
const hoursStatusesColors = {
    [hoursStatuses.OPEN]: ColorsValue["green-success-500"],
    [hoursStatuses.CLOSED]: ColorsValue["red-error-500"],
    [hoursStatuses.CLOSING_SOON]: ColorsValue["yellow-warning-800"],
    [hoursStatuses.SPECIAL_HOURS]: ColorsValue["green-success-500"],
    [hoursStatuses.NOT_AVAILABLE]: ColorsValue["greyscale-300"],
};
const Hours = (props) => {
    const { onClickHours, status } = props;
    return (React.createElement(Button, { px: "sm", size: ButtonSizes.xs, onClick: onClickHours, type: ButtonTypes.text, color: ButtonColorsValue.neutral, disabled: status === hoursStatuses.NOT_AVAILABLE },
        React.createElement(FtsIcons, { iconName: IconNames.Clock }),
        React.createElement(FtsWrapper, { display: "flex", align: "center" },
            "Hours (",
            React.createElement(Span, { color: hoursStatusesColors[status], variant: VariantsValues["text-3xs"], weight: WeightSize.regular }, status),
            ")")));
};
