import { useDispatch, useSelector } from "react-redux";
import {
  selectShareProvidersData,
  selectShareProvidersInvalidFormFields,
} from "../../../../redux/reducers/v2/shareProviders";
import React, { useCallback } from "react";
import {
  getShareProvidersSuccessAction,
  setShareProvidersInvalidFormFields,
} from "../../../../redux/actions/v2/shareProviders";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import isObject from "lodash/isObject";

export const ShareProviderEmails = () => {
  const dispatch = useDispatch();
  const invalidFormFields = useSelector(selectShareProvidersInvalidFormFields);
  const shareProviders = useSelector(selectShareProvidersData);

  const handleSelect = useCallback(
    (key, index) => {
      const newContacts = [...shareProviders[key]];
      newContacts[index].selected = !newContacts[index].selected;
      dispatch(
        getShareProvidersSuccessAction({
          data: {
            ...shareProviders,
            [key]: newContacts,
          },
        }),
      );
    },
    [shareProviders],
  );

  const onChange = useCallback(
    (event) => {
      dispatch(
        getShareProvidersSuccessAction({
          data: {
            ...shareProviders,
            customEmail: event.target.value,
          },
        }),
      );
    },
    [shareProviders],
  );

  const clearErrors = useCallback(() => {
    dispatch(setShareProvidersInvalidFormFields({ data: {} }));
  }, [dispatch]);
  return (
    <FtsWrapper display={"flex"} direction={"column"} gap={"md"}>
      <Span
        mb="sm"
        variant="text-xs"
        weight={WeightSize.medium}
        color={ColorsValue["greyscale-800"]}
      >
        By Email
      </Span>
      <Span
        pb="2xs"
        variant="text-2xs"
        weight={WeightSize.medium}
        color={ColorsValue["greyscale-800"]}
      >
        Account emails
      </Span>
      {shareProviders.userEmailsContacts.map(
        ({ email, firstName = "", selected }, i) => (
          <Checkbox
            key={i}
            size={CheckboxSizes.sm}
            label={`${email} - ${firstName}`}
            checked={selected}
            onClick={() => handleSelect("userEmailsContacts", i)}
          />
        ),
      )}
      {shareProviders.listingEmailsContacts.length ? (
        <>
          <Span
            pb="2xs"
            variant="text-2xs"
            weight={WeightSize.medium}
            color={ColorsValue["greyscale-800"]}
          >
            Additional contacts
          </Span>
          {shareProviders.listingEmailsContacts.map(
            ({ email, firstName = "", selected }, i) => (
              <Checkbox
                key={i}
                size={CheckboxSizes.sm}
                label={`${email} - ${firstName}`}
                checked={selected}
                onClick={() => handleSelect("listingEmailsContacts", i)}
              />
            ),
          )}
        </>
      ) : (
        <EmptyComponent />
      )}

      <Input
        onBlur={clearErrors}
        size={InputSizes.sm}
        onChange={onChange}
        label="Receiving e-mail"
        name="customEmail"
        variant={InputVariants.outlined}
        placeholder="Additional email address"
        value={shareProviders.customEmail}
        errorMessage={
          isObject(invalidFormFields) && invalidFormFields["customEmail"]
        }
      />
    </FtsWrapper>
  );
};
