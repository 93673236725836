import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { P } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import React from "react";
export const TableCellTextSubTitle = (props) => {
    if (!props.subtitle)
        return React.createElement(EmptyComponent, null);
    return (React.createElement(P, { variant: VariantsValues["text-4xs"], color: ColorsValue[props.color || "greyscale-500"] }, props.subtitle));
};
