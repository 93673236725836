import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
export const ModalHeaderComponent = (props) => {
    return (React.createElement(FtsWrapper, { px: {
            xs: "xl",
            sm: "2xl",
            md: "3xl",
            lg: "4xl",
        }, py: {
            xs: "xl",
            sm: "xl",
            md: "2xl",
            lg: "3xl",
        }, gap: "2xl", display: "flex" },
        React.createElement(FtsWrapper, { display: "flex", align: "center", gap: "2xl", flex: 1 },
            props.prependIconProps && React.createElement(FtsIcons, { ...props.prependIconProps }),
            React.createElement(FtsWrapper, { flex: 1, display: "flex", direction: "column", justify: "center" },
                React.createElement(Span, { variant: VariantsValues["text-sm"], color: ColorsValue["greyscale-800"], weight: WeightSize.bold }, props.title),
                props.subtitle && (React.createElement(Span, { variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-800"], mt: "2xs" }, props.subtitle)))),
        props.onClose && (React.createElement(Button, { onClick: props.onClose, size: ButtonSizes.sm, type: ButtonTypes.text, iconName: IconNames.Close, color: ButtonColorsValue.neutral }))));
};
