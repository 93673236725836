import React from "react";
import { Message } from "../../dist/ui/src/components/react/Message";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { useSelector } from "react-redux";
import { selectListingData } from "../../../../redux/reducers/v2/listing";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";

export const ListingProofMessage = () => {
  const listingData = useSelector(selectListingData);

  if (listingData.priceId === 5) return <EmptyComponent />;
  return (
    <FtsColumn size={{ xs: 12 }} mb={"lg"}>
      <Message
        type={"info"}
        title={""}
        subtitle={
          "These updates will be applied on live listing once proof approve."
        }
      />
    </FtsColumn>
  );
};
