import styled from "styled-components";
import { FtsWrapper } from "../../../../FtsWrapper/FtsWrapper";
import { ColorsValue } from "../../../../../../types/ui/colors";
import { Spacing } from "../../../../../../types/ui/spacing";
export const FooterArrow = styled(FtsWrapper) `
  position: relative;
  z-index: 0;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: -3px;
    width: 24px;
    height: 24px;
    background-color: ${(props) => (props.selected ? ColorsValue["green-success-50"] : ColorsValue["greyscale-50"])};
    transform: translate(-50%, 0) rotate(-45deg);
    border-top-right-radius: ${Spacing["2xs"]};
  }
`;
