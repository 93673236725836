import React from "react";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { inlineMessageTypes } from "@find-truck-service/types/constants/inlineMessage";
import { FtsInlineIcon } from "@find-truck-service/ui/src/FtsInlineMessage/FtsInlineIcon";
import { FTSInlineMessage } from "@find-truck-service/ui/src/FtsInlineMessage/FtsInlineMessage";
import { FtsInlineMessageSpan } from "@find-truck-service/ui/src/FtsInlineMessage/FtsInlineMessageSpan";
const messageIcons = {
    [inlineMessageTypes.info]: IconNames.Info,
    [inlineMessageTypes.success]: IconNames.Check,
    [inlineMessageTypes.warning]: IconNames.AlertTriangle,
    [inlineMessageTypes.error]: IconNames.AlertOctagon,
    [inlineMessageTypes.completed]: IconNames.Check,
};
export const InlineMessage = (props) => {
    const { message, type, size, withoutIcon, ...defaultProps } = props;
    return (React.createElement(FTSInlineMessage, { ...defaultProps },
        !withoutIcon && React.createElement(FtsInlineIcon, { iconName: messageIcons[type], type: type, size: size }),
        React.createElement(FtsInlineMessageSpan, { type: type, size: size }, message)));
};
