import React from "react";
import { ListingItemFooter } from "./ListingItemFooter";
import { ListingItemSmContent } from "./ListingItemSmContent";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { getListingItemBg } from "@find-truck-service/ui/src/components/react/ListingItem/helpers";
export const ListingItemSm = (props) => {
    const { type, price, showFooter, boxShadow, priceId } = props;
    const { selected, onSelect, onClickBenefits, primaryActionLabel } = props;
    return (React.createElement(FtsWrapper, { width: "100%", borderRadius: "2xs", boxShadow: boxShadow, bg: getListingItemBg(priceId), display: { xs: "none", sm: "block", md: "none", lg: "none" } },
        React.createElement(ListingItemSmContent, { ...props }),
        showFooter && (React.createElement(ListingItemFooter, { type: type, price: price, priceId: priceId, selected: selected, onSelect: onSelect, onClickBenefits: onClickBenefits, primaryActionLabel: `${primaryActionLabel || "Select"} ${type}` }))));
};
