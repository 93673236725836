import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SideOutSendListingFooter } from "./SideOutSendListingFooter";
import { SideOutSendListingHeader } from "./SideOutSendListingHeader";

import { selectSideOutData } from "../../../redux/reducers/v2/sideOut";
import { ShareProviders } from "../ShareProviders";
import { getShareProvidersRequestAction } from "../../../redux/actions/v2/shareProviders";

import { SideOutLoadingState } from "@find-truck-service/ui/src/components/react/SideOut/Components/SideOutLoadingState";
import { selectSendListingStateIsLoading } from "../../../redux/reducers/v2/sendListing";

export const SideOutSendListing = () => {
  const sideOutData = useSelector(selectSideOutData);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectSendListingStateIsLoading);
  useEffect(() => {
    if (sideOutData?.sendListingAdId || sideOutData?.sendListingUserId) {
      const params = {
        adId: sideOutData.sendListingAdId,
        userId: sideOutData.sendListingUserId,
      };
      dispatch(getShareProvidersRequestAction({ data: params }));
    }
  }, [sideOutData?.sendListingAdId, sideOutData?.sendListingUserId]);

  if (isLoading) return <SideOutLoadingState />;

  return (
    <>
      <SideOutSendListingHeader />
      <ShareProviders />
      <SideOutSendListingFooter />
    </>
  );
};
