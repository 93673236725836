import {
  SET_DATA,
  SET_IS_LOADING,
  SET_TOTAL_SEARCHES,
} from "../../../reducers/v2/search";

export const setSearchData = (payload) => ({
  type: SET_DATA,
  payload,
});

export const setTotalSearches = (payload) => ({
  type: SET_TOTAL_SEARCHES,
  payload,
});

export const setIsLoading = (payload) => ({
  type: SET_IS_LOADING,
  payload,
});
