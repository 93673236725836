import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectListingData } from "../../../redux/reducers/v2/listing";
import { Message } from "@find-truck-service/ui/src/components/react/Message";

import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { ADS_STATUSES_ENUM } from "@find-truck-service/types/constants/adsStatuses";

export const SideOutListingPreviewDraftListingMessage = () => {
  const listingsData = useSelector(selectListingData);

  if (!listingsData) return <EmptyComponent />;

  if (listingsData.status !== ADS_STATUSES_ENUM.DRAFT)
    return <EmptyComponent />;

  return (
    <Message
      type="warning"
      title={""}
      // todo add draft listing appruved message
      subtitle={`Draft created on ${moment(listingsData.createdAt).format(
        "ll",
      )}.`}
    />
  );
};
