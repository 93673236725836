import {
  OPEN_EDIT_LISTING,
  CLOSE_SIDE_OUT,
  OPEN_EDIT_ACCOUNT,
  CLOSE_SUB_SIDE_OUT,
  OPEN_SUB_SIDE_OUT_EDIT_ACCOUNT,
  OPEN_LISTING_PROOF,
  OPEN_SUB_SIDE_OUT_EDIT_LISTING,
  OPEN_SUB_SIDE_OUT_SEND_PROOF,
  OPEN_LISTING_PREVIEW,
  OPEN_SUB_SIDE_OUT_SEND_LISTING,
} from "../../../reducers/v2/sideOut";

export const openEditListingSideOutAction = (payload) => ({
  type: OPEN_EDIT_LISTING,
  payload,
});

export const openEditAccountSideOutAction = (payload) => ({
  type: OPEN_EDIT_ACCOUNT,
  payload,
});

export const openEditAccountSubSideOutAction = (payload) => ({
  type: OPEN_SUB_SIDE_OUT_EDIT_ACCOUNT,
  payload,
});

export const openSendProofSubSideOutAction = (payload) => ({
  type: OPEN_SUB_SIDE_OUT_SEND_PROOF,
  payload,
});

export const openEditListingSubSideOutAction = (payload) => ({
  type: OPEN_SUB_SIDE_OUT_EDIT_LISTING,
  payload,
});

export const openListingProofSideOutAction = (payload) => ({
  type: OPEN_LISTING_PROOF,
  payload,
});

export const openListingPreviewSideOutAction = (payload) => ({
  type: OPEN_LISTING_PREVIEW,
  payload,
});

export const openSendListingSubSideOutAction = (payload) => ({
  type: OPEN_SUB_SIDE_OUT_SEND_LISTING,
  payload,
});

export const closeSideOutAction = () => ({
  type: CLOSE_SIDE_OUT,
});

export const closeSubSideOutAction = () => ({
  type: CLOSE_SUB_SIDE_OUT,
});
