import React, { useEffect, useRef, useState } from "react";
import { ImageItem } from "./ImageItem";
import { useDispatch, useSelector } from "react-redux";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import supportedFileTypes from "../../../../data/supported-file-types";
import { generateListingFileName } from "../../../../utility/uploadFile";
import { selectListingData } from "../../../../redux/reducers/v2/listing";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

const allowedFileTypes = supportedFileTypes
  .map((type) => `.${type.value}`)
  .join(",");

export const ImagePickerRow = () => {
  const dispatch = useDispatch();
  const listingData = useSelector(selectListingData);
  const [imageLink, setImageLink] = useState("");
  const fileInputRef = useRef(null);

  useEffect(() => {
    setImageLink(listingData?.image);
  }, [listingData?.image]);

  const onImageUpdate = (e) => {
    if (!e.target.files?.length) return;

    const fileForUpload = e.target.files[0];

    let image = generateListingFileName(
      listingData,
      fileForUpload?.type.split("/").pop(),
    );

    const data = { ...listingData, image, fileForUpload };
    dispatch(getListingDataByIdSuccess({ data }));
  };

  const resetFileInput = () => {
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const onUpload = () => fileInputRef.current?.click();

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} mt={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          CURRENT IMAGE
        </Span>
      </FtsColumn>
      <FtsColumn size={{ xs: 12 }}>
        {imageLink && (
          <ImageItem name={imageLink} resetFileInput={resetFileInput} />
        )}
        <Button
          onClick={onUpload}
          size={ButtonSizes.sm}
          iconName={IconNames.Upload}
          type={ButtonTypes.outlined}
          color={ButtonColorsValue.accent}
          label={imageLink ? "Change Image" : "Upload Image"}
        />
      </FtsColumn>
      <input
        hidden
        type="file"
        id="update-image"
        ref={fileInputRef}
        onChange={onImageUpdate}
        accept={allowedFileTypes}
      />
    </FtsRow>
  );
};
