import { createSelector } from "reselect";

// For all action that have call to api need to be defined _REQUEST, _SUCCESS and _FAILED action
export const GET_LISTING_DATA_BY_ID_REQUEST =
  "LISTING/GET_LISTING_DATA_BY_ID_REQUEST";
export const GET_LISTING_DATA_BY_ID_SUCCESS =
  "LISTING/GET_LISTING_DATA_BY_ID_SUCCESS";
export const GET_LISTING_DATA_BY_ID_FAILED =
  "LISTING/GET_LISTING_DATA_BY_ID_FAILED";

// For all action where needs to update part of redux state action name needs to have SET_ prefix
export const SET_LISTING_DATA_INVALID_FORM_FIELDS =
  "LISTING/SET_LISTING_DATA_INVALID_FORM_FIELDS";

export const UPDATE_LISTING_DATA_BY_ID_REQUEST =
  "LISTING/UPDATE_LISTING_DATA_BY_ID_REQUEST";
export const UPDATE_LISTING_DATA_BY_ID_SUCCESS =
  "LISTING/UPDATE_LISTING_DATA_BY_ID_SUCCESS";
export const UPDATE_LISTING_DATA_BY_ID_FAILED =
  "LISTING/UPDATE_LISTING_DATA_BY_ID_FAILED";

export const UPDATE_LISTING_PUT_ON_HOLD_BY_ID_REQUEST =
  "LISTING/UPDATE_LISTING_PUT_ON_HOLD_BY_ID_REQUEST";
export const UPDATE_LISTING_PUT_ON_HOLD_BY_ID_SUCCESS =
  "LISTING/UPDATE_LISTING_PUT_ON_HOLD_BY_ID_SUCCESS";
export const UPDATE_LISTING_PUT_ON_HOLD_BY_ID_FAILED =
  "LISTING/UPDATE_LISTING_PUT_ON_HOLD_BY_ID_FAILED";

export const GET_LOCKED_LISTINGS_REQUEST =
  "LISTING/GET_LOCKED_LISTINGS_REQUEST";
export const GET_LOCKED_LISTINGS_SUCCESS =
  "LISTING/GET_LOCKED_LISTINGS_SUCCESS";
export const GET_LOCKED_LISTINGS_FAILED = "LISTING/GET_LOCKED_LISTINGS_FAILED";

export const LOCK_LISTING_REQUEST = "LISTING/LOCK_LISTING_REQUEST";
export const LOCK_LISTING_SUCCESS = "LISTING/LOCK_LISTING_SUCCESS";
export const LOCK_LISTING_FAILED = "LISTING/LOCK_LISTING_FAILED";

export const UNLOCK_LISTING_REQUEST = "LISTING/UNLOCK_LISTING_REQUEST";
export const UNLOCK_LISTING_SUCCESS = "LISTING/UNLOCK_LISTING_SUCCESS";
export const UNLOCK_LISTING_FAILED = "LISTING/UNLOCK_LISTING_FAILED";

export const UPLOAD_LISTING_IMAGE_REQUEST =
  "LISTING/UPLOAD_LISTING_IMAGE_REQUEST";
export const UPLOAD_LISTING_IMAGE_SUCCESS =
  "LISTING/UPLOAD_LISTING_IMAGE_SUCCESS";
export const UPLOAD_LISTING_IMAGE_FAILED =
  "LISTING/UPLOAD_LISTING_IMAGE_FAILED";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  invalidFormFields: null,
  lockedListings: null,
};

/// Reducer
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LISTING_DATA_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        invalidFormFields: null,
      };

    case GET_LISTING_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        error: null,
        invalidFormFields: null,
      };

    case GET_LISTING_DATA_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data,
        invalidFormFields: null,
      };

    case UPDATE_LISTING_DATA_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        invalidFormFields: null,
      };

    case UPDATE_LISTING_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        invalidFormFields: null,
      };

    case UPDATE_LISTING_DATA_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data,
        invalidFormFields: null,
      };

    case UPDATE_LISTING_PUT_ON_HOLD_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        invalidFormFields: null,
      };

    case UPDATE_LISTING_PUT_ON_HOLD_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        invalidFormFields: null,
        putOnHoldDataInDays: null,
      };

    case UPDATE_LISTING_PUT_ON_HOLD_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data,
        invalidFormFields: null,
        putOnHoldDataInDays: null,
      };

    case SET_LISTING_DATA_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidFormFields: action.payload.data,
      };
    case GET_LOCKED_LISTINGS_SUCCESS:
    case UNLOCK_LISTING_SUCCESS:
    case LOCK_LISTING_SUCCESS:
      return { ...state, lockedListings: action.payload.data };

    case GET_LOCKED_LISTINGS_REQUEST:
    case GET_LOCKED_LISTINGS_FAILED:
    case LOCK_LISTING_REQUEST:
    case LOCK_LISTING_FAILED:
    case UNLOCK_LISTING_REQUEST:
    case UNLOCK_LISTING_FAILED:
      return state;

    default:
      return state;
  }
}

/// Selectors
const selectListingState = (state) => state.listingState;

export const selectListingData = createSelector(
  selectListingState,
  (listing) => {
    return listing.data;
  },
);

export const selectListingName = createSelector(
  selectListingState,
  (listing) => {
    return listing.data.name;
  },
);

export const selectLockedListingMessage = createSelector(
  selectListingState,
  (listing) => {
    return listing.data.lockedListingMessage;
  },
);

export const selectListingError = createSelector(
  selectListingState,
  (listing) => {
    return listing.error;
  },
);

export const selectIsLoadingListingData = createSelector(
  selectListingState,
  (listing) => {
    return listing.isLoading;
  },
);

export const selectListingAdsAmenities = createSelector(
  selectListingState,
  (listing) => {
    return listing.data.adsAmenities;
  },
);
export const selectListingAdsServiceAmenities = createSelector(
  selectListingState,
  (listing) => {
    return listing.data.adsServiceAmenities;
  },
);

export const selectListingCategories = createSelector(
  selectListingState,
  (listing) => {
    return listing.data.adsCategories;
  },
);

export const selectListingSubCategories = createSelector(
  selectListingState,
  (listing) => {
    return listing.data.adsSubCategories;
  },
);

export const selectListingRates = createSelector(
  selectListingState,
  (listing) => {
    return listing.data.adsRates;
  },
);
export const selectListingInvalidFormFields = createSelector(
  selectListingState,
  (listing) => {
    return listing.invalidFormFields;
  },
);

export const selectLockedListings = createSelector(
  selectListingState,
  (listing) => {
    return listing.lockedListings;
  },
);
