import styled from "styled-components";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
const getStyles = (props) => {
    const maxHeight = {
        sm: "xxl",
        md: "2xxl",
    };
    const minHeight = {
        sm: "xxl",
        md: "2xxl",
    };
    const paddingY = {
        sm: "lg",
        md: "lg",
    };
    const paddingX = {
        sm: "sm",
        md: "sm",
    };
    return `
        padding: ${Spacing[paddingX[props.size]]} ${Spacing[paddingY[props.size]]};
        max-height:${Spacing[maxHeight[props.size]]};
        min-height:${Spacing[minHeight[props.size]]};
        gap:${Spacing.xs};
        ${props.active && `background-color: ${ColorsValue["blue-accent-50"]};`};

        `;
};
export const FtsTableCell = styled("div") `
  border-spacing: 0 !important;
  display: flex;
  align-items: center;
  ${getStyles};
`;
