import React from "react";
import { FtsWrapper } from "../../../../FtsWrapper/FtsWrapper";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { Error } from "@find-truck-service/ui/src/components/react/Error";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
export const SideOutErrorState = (props) => {
    return (React.createElement(FtsWrapper, { py: "2xl", px: "3xl", display: "flex", align: "center", justify: "center", width: "100%", height: "100%" },
        React.createElement(Error, { iconName: IconNames.AlertTriangle, subtitle: "", title: props.error, outlinedButton: {
                onClick: props.onClick,
                color: ButtonColorsValue.accent,
                label: "Retry",
                iconName: IconNames.Refresh,
            } })));
};
