import React from "react";
import { ModalContentComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalContentComponent";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { Divider } from "@find-truck-service/ui/src/components/react/Divider";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
export const AdsBenefitsModalContent = (props) => {
    const { benefits } = props;
    return (React.createElement(ModalContentComponent, { display: "flex", direction: "column" }, benefits?.map((benefit, index) => (React.createElement(FtsWrapper, { display: "flex", gap: "lg" },
        React.createElement(FtsIcons, { svgProps: {
                width: Spacing["3xl"],
                height: Spacing["3xl"],
            }, iconName: IconNames.Check, pathProps: { stroke: ColorsValue["blue-accent-500"] } }),
        React.createElement(FtsWrapper, { width: "100%" },
            React.createElement(Span, { variant: VariantsValues["text-2xs"] }, benefit),
            benefits[index + 1] && React.createElement(Divider, { verticalOffset: "md" })))))));
};
