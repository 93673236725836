import { Message } from "@find-truck-service/ui/src/components/react/Message";
import React from "react";

import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { useSelector } from "react-redux";
import { selectProofData } from "../../../redux/reducers/v2/proof";
import { PROOF_STATUSES_ENUM } from "@find-truck-service/types/constants/proofStatuses";
import moment from "moment";
import { selectAdminUsers } from "../../../redux/reducers/essentialLists";

export const SideOutListingProofNotSendMessage = () => {
  const proofData = useSelector(selectProofData);
  const adminUsers = useSelector(selectAdminUsers);

  if (proofData.status !== PROOF_STATUSES_ENUM.NOT_SEND)
    return <EmptyComponent />;

  const lastUpdatedDate = moment(proofData.listingData.updatedAt).fromNow();

  const adminName =
    adminUsers.find(
      (admin) => admin.value === proofData.listingData.adminLastUpdatedBy,
    )?.label || "Vendor";
  return (
    <Message
      type="info"
      title={""}
      subtitle={`Proof Not Sent: Listing was edited ${lastUpdatedDate} by ${adminName}.`}
    />
  );
};
