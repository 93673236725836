const singleHoursTypes = {
  CLOSED: "Closed",
  "24_HOURS": "24 hrs",
  BY_APPOINTMENT: "By Appointment",
};

export const formatWorkingHoursTo = (values, day) => {
  if (
    values?.workingHours?.skipHours ||
    values?.skipHours ||
    !values?.workingHours
  ) {
    return "";
  }

  if (
    values.workingHours[`${day}From`]?.value === singleHoursTypes["24_HOURS"]
  ) {
    return "";
  }

  if (
    values.workingHours[`${day}From`]?.value === singleHoursTypes.BY_APPOINTMENT
  ) {
    return "";
  }

  if (values.workingHours[`${day}From`]?.value === singleHoursTypes.CLOSED) {
    return "";
  }

  if (typeof values.workingHours[`${day}To`] === "object") {
    return values.workingHours[`${day}To`]?.value ?? "";
  }

  return values.workingHours[`${day}To`];
};

export const formatWorkingHoursFrom = (values, day) => {
  if (
    values?.workingHours?.skipHours ||
    values?.skipHours ||
    !values?.workingHours
  ) {
    return "";
  }

  if (typeof values.workingHours[`${day}From`] === "object") {
    return values.workingHours[`${day}From`]?.value ?? "";
  }

  return values.workingHours[`${day}From`];
};
