import * as Yup from "yup";
import { REQUIRED_ERROR_MESSAGE } from "../../../../constants";
import { urlValidate } from "@find-truck-service/types/validators/global";

export const listingContactRowValidator = Yup.object().shape({
  phoneNumber: Yup.string().ensure().required(REQUIRED_ERROR_MESSAGE),
  phoneNumberSecondary: Yup.string().ensure(),
  mobile: Yup.string().ensure(),
  afterHours: Yup.string().ensure(),
  website: urlValidate,
  website2: urlValidate,
  email: Yup.string().ensure().email("Invalid email"),
});
