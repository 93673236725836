import styled from "styled-components";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsRow } from "../FtsRow/FtsRow";
import { Spacing } from "@find-truck-service/types/ui/spacing";
export const FtsSideOutWrapperFooter = styled(FtsRow) `
  border-top: ${Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]};
  position: fixed;
  width: inherit;
  bottom: 0;
  z-index: 1;
  background: ${ColorsValue["greyscale-0"]};
`;
