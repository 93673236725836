import styled from "styled-components";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
import { inlineMessageSizes, inlineMessageTypes } from "@find-truck-service/types/constants/inlineMessage";
const inlineIconProps = ["type", "size"];
const messageIconWrapperBackgroundColor = {
    [inlineMessageTypes.info]: ColorsValue["blue-accent-500"],
    [inlineMessageTypes.success]: ColorsValue["green-success-500"],
    [inlineMessageTypes.warning]: ColorsValue["yellow-warning-400"],
    [inlineMessageTypes.error]: ColorsValue["red-error-500"],
    [inlineMessageTypes.completed]: ColorsValue["blue-accent-50"],
};
const messageIconStrokeColor = {
    [inlineMessageTypes.info]: ColorsValue["greyscale-0"],
    [inlineMessageTypes.success]: ColorsValue["greyscale-0"],
    [inlineMessageTypes.warning]: ColorsValue["greyscale-0"],
    [inlineMessageTypes.error]: ColorsValue["greyscale-0"],
    [inlineMessageTypes.completed]: ColorsValue["blue-accent-500"],
};
const inlineMessageSvgIconSize = {
    [inlineMessageSizes.sm]: SvgIconSizeValues.sm,
    [inlineMessageSizes.md]: SvgIconSizeValues.md,
};
export const FtsInlineIcon = styled(FtsIcons)
    .attrs(({ type, size }) => ({
    iconWrapperProps: {
        p: "3xs",
        borderRadius: "3xl",
        bg: messageIconWrapperBackgroundColor[type],
    },
    svgProps: {
        width: inlineMessageSvgIconSize[size],
        height: inlineMessageSvgIconSize[size],
    },
    pathProps: { stroke: messageIconStrokeColor[type] },
}))
    .withConfig({
    shouldForwardProp: (prop) => !inlineIconProps.includes(prop),
}) ``;
