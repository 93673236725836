import {
  GET_LISTING_DATA_BY_ID_REQUEST,
  GET_LISTING_DATA_BY_ID_FAILED,
  GET_LISTING_DATA_BY_ID_SUCCESS,
  SET_LISTING_DATA_INVALID_FORM_FIELDS,
  UPDATE_LISTING_DATA_BY_ID_REQUEST,
  UPDATE_LISTING_DATA_BY_ID_FAILED,
  UPDATE_LISTING_DATA_BY_ID_SUCCESS,
  UPDATE_LISTING_PUT_ON_HOLD_BY_ID_REQUEST,
  UPDATE_LISTING_PUT_ON_HOLD_BY_ID_SUCCESS,
  UPDATE_LISTING_PUT_ON_HOLD_BY_ID_FAILED,
  GET_LOCKED_LISTINGS_REQUEST,
  GET_LOCKED_LISTINGS_SUCCESS,
  GET_LOCKED_LISTINGS_FAILED,
  LOCK_LISTING_REQUEST,
  LOCK_LISTING_SUCCESS,
  LOCK_LISTING_FAILED,
  UNLOCK_LISTING_REQUEST,
  UNLOCK_LISTING_SUCCESS,
  UNLOCK_LISTING_FAILED,
  UPLOAD_LISTING_IMAGE_REQUEST,
  UPLOAD_LISTING_IMAGE_SUCCESS,
  UPLOAD_LISTING_IMAGE_FAILED,
} from "../../../reducers/v2/listing";

// All actions need to have action as suffix in name eg getListingDataByIdRequestAction

export const getListingDataByIdRequest = (payload) => ({
  type: GET_LISTING_DATA_BY_ID_REQUEST,
  payload,
});

export const getListingDataByIdSuccess = (payload) => ({
  type: GET_LISTING_DATA_BY_ID_SUCCESS,
  payload,
});

export const getListingDataByIdFailed = (payload) => ({
  type: GET_LISTING_DATA_BY_ID_FAILED,
  payload,
});

export const updateListingDataByIdRequest = (payload) => ({
  type: UPDATE_LISTING_DATA_BY_ID_REQUEST,
  payload,
});
export const updateListingDataByIdSuccess = () => ({
  type: UPDATE_LISTING_DATA_BY_ID_SUCCESS,
});

export const updateListingDataByIdFailed = (payload) => ({
  type: UPDATE_LISTING_DATA_BY_ID_FAILED,
  payload,
});

export const setListingInvalidFormFields = (payload) => ({
  type: SET_LISTING_DATA_INVALID_FORM_FIELDS,
  payload,
});

export const updateListingPutOnHoldByIdRequest = (payload) => ({
  type: UPDATE_LISTING_PUT_ON_HOLD_BY_ID_REQUEST,
  payload,
});
export const updateListingPutOnHoldByIdSuccess = () => ({
  type: UPDATE_LISTING_PUT_ON_HOLD_BY_ID_SUCCESS,
});

export const updateListingPutOnHoldByIdFailed = (payload) => ({
  type: UPDATE_LISTING_PUT_ON_HOLD_BY_ID_FAILED,
  payload,
});

export const getLockedListingsRequest = () => ({
  type: GET_LOCKED_LISTINGS_REQUEST,
});
export const getLockedListingsSuccess = (payload) => ({
  type: GET_LOCKED_LISTINGS_SUCCESS,
  payload,
});

export const getLockedListingsFailed = (payload) => ({
  type: GET_LOCKED_LISTINGS_FAILED,
  payload,
});

export const lockListingRequest = (payload) => ({
  type: LOCK_LISTING_REQUEST,
  payload,
});
export const lockListingSuccess = (payload) => ({
  type: LOCK_LISTING_SUCCESS,
  payload,
});

export const lockListingsFailed = (payload) => ({
  type: LOCK_LISTING_FAILED,
  payload,
});

export const unLockListingRequest = (payload) => ({
  type: UNLOCK_LISTING_REQUEST,
  payload,
});
export const unLockListingSuccess = (payload) => ({
  type: UNLOCK_LISTING_SUCCESS,
  payload,
});

export const unLockListingsFailed = (payload) => ({
  type: UNLOCK_LISTING_FAILED,
  payload,
});

export const uploadListingImageRequest = (payload) => ({
  type: UPLOAD_LISTING_IMAGE_REQUEST,
  payload,
});
export const uploadListingImageSuccess = (payload) => ({
  type: UPLOAD_LISTING_IMAGE_SUCCESS,
  payload,
});

export const uploadListingImageFailed = (payload) => ({
  type: UPLOAD_LISTING_IMAGE_FAILED,
  payload,
});
