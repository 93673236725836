import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { LineHeightVariantsValues, VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { Spacing } from "@find-truck-service/types/ui/spacing";
const BadgeFontSize = {
    xs: VariantsValues["text-4xs"],
    sm: VariantsValues["text-4xs"],
    md: VariantsValues["text-3xs"],
    lg: VariantsValues["text-2xs"],
};
const BadgePadding = {
    xs: "xs",
    sm: "2xs",
    md: "xs",
    lg: "sm",
};
const SvgSizes = {
    xs: Spacing["0"],
    sm: Spacing["lg"],
    md: Spacing["xl"],
    lg: Spacing["2xl"],
};
const BadgeGaps = {
    xs: "0",
    sm: "2xs",
    md: "xs",
    lg: "sm",
};
const LabelDisplayBySize = {
    xs: "none",
    sm: "block",
    md: "block",
    lg: "block",
};
export const badgeTypes = {
    accent: "accent",
    grey: "grey",
    success: "success",
    warning: "warning",
    error: "error",
    brand: "brand",
};
export const badgeVariants = {
    contained: "contained",
    outlined: "outlined",
    shaded: "shaded",
};
export const badgeRadiusTypes = {
    pill: "pill",
    rounded: "rounded",
};
export const badgeBorderRadius = {
    pill: "4xxl",
    rounded: "2xs",
};
export const badgeBackgroundColors = {
    contained: {
        accent: ColorsValue["blue-accent-500"],
        grey: ColorsValue["greyscale-800"],
        success: ColorsValue["green-success-500"],
        warning: ColorsValue["yellow-warning-400"],
        error: ColorsValue["red-error-500"],
        brand: ColorsValue["red-brand-500"],
    },
    outlined: {
        accent: "transparent",
        grey: "transparent",
        success: "transparent",
        warning: "transparent",
        error: "transparent",
        brand: "transparent",
    },
    shaded: {
        accent: ColorsValue["blue-accent-50"],
        grey: ColorsValue["greyscale-50"],
        success: ColorsValue["green-success-50"],
        warning: ColorsValue["yellow-warning-50"],
        error: ColorsValue["red-error-50"],
        brand: ColorsValue["red-brand-50"],
    },
};
export const badgeBorders = {
    contained: {
        accent: "none",
        grey: "none",
        success: "none",
        warning: "none",
        error: "none",
        brand: "none",
    },
    outlined: {
        accent: `1px solid ${ColorsValue["blue-accent-100"]}`,
        grey: `1px solid ${ColorsValue["greyscale-100"]}`,
        success: `1px solid ${ColorsValue["green-success-100"]}`,
        warning: `1px solid ${ColorsValue["yellow-warning-300"]}`,
        error: `1px solid ${ColorsValue["red-error-100"]}`,
        brand: `1px solid ${ColorsValue["red-brand-200"]}`,
    },
    shaded: {
        accent: `1px solid ${ColorsValue["blue-accent-100"]}`,
        grey: `1px solid ${ColorsValue["greyscale-100"]}`,
        success: `1px solid ${ColorsValue["green-success-100"]}`,
        warning: `1px solid ${ColorsValue["yellow-warning-300"]}`,
        error: `1px solid ${ColorsValue["red-error-100"]}`,
        brand: `1px solid ${ColorsValue["red-brand-200"]}`,
    },
};
export const badgeTextColors = {
    contained: {
        accent: ColorsValue["greyscale-0"],
        grey: ColorsValue["greyscale-0"],
        success: ColorsValue["greyscale-0"],
        warning: ColorsValue["greyscale-0"],
        error: ColorsValue["greyscale-0"],
        brand: ColorsValue["greyscale-0"],
    },
    outlined: {
        accent: ColorsValue["blue-accent-600"],
        grey: ColorsValue["greyscale-800"],
        success: ColorsValue["green-success-600"],
        warning: ColorsValue["yellow-warning-800"],
        error: ColorsValue["red-error-500"],
        brand: ColorsValue["red-brand-500"],
    },
    shaded: {
        accent: ColorsValue["blue-accent-700"],
        grey: ColorsValue["greyscale-800"],
        success: ColorsValue["green-success-700"],
        warning: ColorsValue["yellow-warning-900"],
        error: ColorsValue["red-error-600"],
        brand: ColorsValue["red-brand-500"],
    },
};
export const Badge = (props) => {
    return (React.createElement(FtsWrapper, { display: "flex", align: "center", bg: badgeBackgroundColors[props.variant][props.type], border: badgeBorders[props.variant][props.type], borderRadius: badgeBorderRadius[props.badgeRadiusTypes], width: "fit-content", p: BadgePadding[props.size], gap: BadgeGaps[props.size] },
        props.iconName && (React.createElement(FtsIcons, { iconName: props.iconName, svgProps: {
                width: SvgSizes[props.size],
                height: SvgSizes[props.size],
            }, pathProps: { stroke: badgeTextColors[props.variant][props.type] } })),
        React.createElement(Span, { display: LabelDisplayBySize[props.size], textAlign: "left", variant: BadgeFontSize[props.size], color: badgeTextColors[props.variant][props.type], lineHeightVariant: LineHeightVariantsValues.relaxed, weight: WeightSize.medium }, props.title)));
};
