export const rfc3986EncodeURIComponent = (str) => {
  let string = str.trim();
  return encodeURIComponent(string)
    .replace(/[!'()*]/g, escape)
    .replace(/%20/g, "+");
};
export const rfc3986DecodeURIComponent = (str = "") => {
  let string = str.trim();
  try {
    string = string
      .replace(/\+/g, " ")
      .replace(/%E2%80%99/g, "%27")
      .replace(/%5C/g, "%5C%5C")
      .replace(/%22/g, "'")
      .replace(/%27/g, "'");
    string = decodeURIComponent(string.replace(/\+/g, " "));
    string = string.replace(/\r\n/g, "").replace(/\n/g, "").replace(/\t/g, "");
  } catch (e) {
    string = unescape(string.replace(/\+/g, " "));
  }
  return string;
};
