import { all } from "redux-saga/effects";
import listingWatcher from "./listing";
import accountWatcher from "./account";
import altContactsWatcher from "./altContacts";
import accountProofsWatcher from "./account-proofs";
import proofWatcher from "./proof";
import shareProvidersWatcher from "./shareProviders";
import sendListingWatcher from "./sendListing";

export default function* rootSaga() {
  yield all([
    listingWatcher(),
    accountWatcher(),
    altContactsWatcher(),
    accountProofsWatcher(),
    proofWatcher(),
    shareProvidersWatcher(),
    sendListingWatcher(),
  ]);
}
