import { createSelector } from "reselect";

//API
export const GET_SHARE_PROVIDERS_REQUEST =
  "SHARE_PROVIDERS/GET_SHARE_PROVIDERS_REQUEST";
export const GET_SHARE_PROVIDERS_SUCCESS =
  "SHARE_PROVIDERS/GET_SHARE_PROVIDERS_SUCCESS";
export const GET_SHARE_PROVIDERS_FAILED =
  "SHARE_PROVIDERS/GET_SHARE_PROVIDERS_FAILED";

export const SET_INVALID_FORM_FIELDS =
  "SHARE_PROVIDERS/SET_INVALID_FORM_FIELDS";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  invalidFormFields: null,
};

/// Reducer
export default function (state = initialState, action) {
  switch (action.type) {
    //API
    case GET_SHARE_PROVIDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        invalidFormFields: null,
      };

    case GET_SHARE_PROVIDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        error: null,
        invalidFormFields: null,
      };

    case GET_SHARE_PROVIDERS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data,
        invalidFormFields: null,
      };

    case SET_INVALID_FORM_FIELDS:
      return {
        ...state,
        isLoading: false,
        error: null,
        invalidFormFields: action.payload.data,
      };

    default:
      return state;
  }
}

/// Selectors
const selectShareProvidersState = (state) => state.shareProvidersState;

export const selectShareProvidersData = createSelector(
  selectShareProvidersState,
  (shareProvider) => {
    return shareProvider.data;
  },
);

export const selectShareProvidersError = createSelector(
  selectShareProvidersState,
  (shareProvider) => {
    return shareProvider.error;
  },
);

export const selectShareProvidersIsLoading = createSelector(
  selectShareProvidersState,
  (shareProvider) => {
    return shareProvider.isLoading;
  },
);

export const selectShareProvidersInvalidFormFields = createSelector(
  selectShareProvidersState,
  (shareProvider) => {
    return shareProvider.invalidFormFields;
  },
);

export const selectSelectedEmailsContact = createSelector(
  selectShareProvidersState,
  (shareProvider) => {
    let selectedEmails = [];
    if (shareProvider.data) {
      selectedEmails = [
        ...shareProvider.data.userEmailsContacts
          .filter((e) => e.selected)
          .map((e) => e.email),
        ...shareProvider.data.listingEmailsContacts
          .filter((e) => e.selected)
          .map((e) => e.email),
        shareProvider.data.customEmail,
      ];
    }
    return selectedEmails.filter((e) => !!e);
  },
);

export const selectSelectedPhoneNumbersContact = createSelector(
  selectShareProvidersState,
  (shareProvider) => {
    let selectedPhoneNumbers = [];
    if (shareProvider.data) {
      selectedPhoneNumbers = [
        ...shareProvider.data.userPhoneNumbersContacts
          .filter((e) => e.selected)
          .map((e) => e.phoneNumber),
        ...shareProvider.data.listingPhoneNumbersContacts
          .filter((e) => e.selected)
          .map((e) => e.phoneNumber),
        shareProvider.data.customPhoneNumber,
      ];
    }
    return selectedPhoneNumbers.filter((e) => !!e);
  },
);
