import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Badge } from "@find-truck-service/ui/src/components/react/Badge";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { formatAdsAmenitiesResponse } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Helpers";
export const ListingAmenitiesRow = (props) => {
    const showAmenities = !!props.listingAmenities.length;
    const formattedAmenities = formatAdsAmenitiesResponse(props.listingAmenities, props.amenities);
    return (React.createElement(FtsWrapper, { px: "xl", mb: "lg", py: "2xl", gap: "lg", boxShadow: "sm", display: "flex", direction: "column", bg: ColorsValue["greyscale-0"] },
        React.createElement(Span, { weight: WeightSize.medium, variant: VariantsValues["text-3xs"], color: ColorsValue["blue-accent-500"] }, "AMENITIES"),
        showAmenities && (React.createElement(FtsWrapper, { display: "flex", gap: "sm", wrap: "wrap" }, formattedAmenities.map((amenity) => {
            return (React.createElement(Badge, { key: amenity?.id, variant: "shaded", type: "accent", badgeRadiusTypes: "pill", size: "md", title: amenity.name || "" }));
        }))),
        props.showAddAmenitiesButton && (React.createElement(React.Fragment, null,
            React.createElement(Span, { weight: WeightSize.medium, variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-800"] }, "Help customers find what they need. Add services today."),
            React.createElement(Button, { width: "100%", size: ButtonSizes.md, label: "Add Amenities", iconName: IconNames.Plus, type: ButtonTypes.outlined, onClick: props.onAddAmenities, color: ButtonColorsValue.accent })))));
};
