import { useDispatch, useSelector } from "react-redux";
import { selectProofData } from "../../../redux/reducers/v2/proof";
import React, { useCallback } from "react";
import {
  closeSideOutAction,
  closeSubSideOutAction,
  openEditListingSubSideOutAction,
} from "../../../redux/actions/v2/sideOut";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { approveProofByIdRequestAction } from "../../../redux/actions/v2/proof";
import { PROOF_STATUSES_ENUM } from "@find-truck-service/types/constants/proofStatuses";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { selectSideOutGetListingInfo } from "../../../redux/reducers/v2/sideOut";
import { checkPermissions } from "../../../utility";
import { PERMISSIONS, SUB_PERMISSIONS } from "../../../constants";
import { selectLoggedUserPermissions } from "../../../redux/reducers/auth/loginReducer";

export const PendingListingProofFooter = () => {
  const dispatch = useDispatch();
  const proofData = useSelector(selectProofData);
  const getListingInfo = useSelector(selectSideOutGetListingInfo);
  const loggedUserPermissions = useSelector(selectLoggedUserPermissions);
  const updateListing = useCallback(async () => {
    try {
      dispatch(
        openEditListingSubSideOutAction({
          data: { listingId: proofData.adId },
          onSuccess: () => dispatch(closeSubSideOutAction()),
        }),
      );
    } catch (e) {
      console.log(e);
    }
  }, [proofData]);

  const updateAccount = useCallback(async () => {
    window.open(`/accounts/${proofData.userId}`, "_blank");
  }, [proofData]);

  const approveProof = useCallback(() => {
    try {
      dispatch(
        approveProofByIdRequestAction({
          data: proofData,
          onSuccess: () => {
            dispatch(closeSideOutAction());
            if (getListingInfo && typeof getListingInfo === "function")
              getListingInfo();
          },
        }),
      );
    } catch (e) {
      console.log(e);
    }
  }, [proofData]);

  if (proofData.status !== PROOF_STATUSES_ENUM.PENDING)
    return <EmptyComponent />;

  const havePermissionsForApproveProof = checkPermissions(
    [PERMISSIONS.ADDITIONAL_FEATURES],
    loggedUserPermissions,
    [SUB_PERMISSIONS[PERMISSIONS.ADDITIONAL_FEATURES].APPROVE_PROOF],
  );
  console.log(PERMISSIONS.ADDITIONAL_FEATURES);
  console.log(SUB_PERMISSIONS[PERMISSIONS.ADDITIONAL_FEATURES]);
  console.log(loggedUserPermissions);
  console.log(havePermissionsForApproveProof);
  return (
    <FtsColumn size={{ xs: 12 }} textAlign={"end"}>
      <FtsWrapper
        display={"flex"}
        align={"center"}
        justify={"flex-end"}
        gap={"lg"}
      >
        <Button
          onClick={updateAccount}
          type={ButtonTypes.text}
          color={ButtonColorsValue.accent}
          size={ButtonSizes.sm}
          label={"Account"}
          iconName={IconNames.ExternalLink}
        />
        <Button
          onClick={updateListing}
          type={ButtonTypes.outlined}
          color={ButtonColorsValue.accent}
          size={ButtonSizes.sm}
          label={"Edit"}
          iconName={IconNames.Edit}
        />
        <Button
          disabled={!havePermissionsForApproveProof}
          onClick={approveProof}
          type={ButtonTypes.contained}
          color={ButtonColorsValue.accent}
          size={ButtonSizes.sm}
          label={"Approve"}
          iconName={IconNames.Check}
        />
      </FtsWrapper>
    </FtsColumn>
  );
};
