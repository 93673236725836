import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { P } from "@find-truck-service/ui/src/FtsText";
import { IconNames } from "../../../../FtsIcons/Types/iconNames";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
export const ConfirmationDialogHeaderComponent = (props) => {
    const iconName = props.iconName || props.iconProps?.iconName;
    return (React.createElement(FtsWrapper, { px: {
            xs: "xl",
            sm: "xl",
            md: "2xl",
            lg: "3xl",
        }, py: {
            xs: "xl",
            sm: "2xl",
            md: "3xl",
            lg: "4xl",
        }, gap: "2xl", display: "flex", direction: { xs: "column", sm: "row", md: "row", lg: "row" }, textAlign: { xs: "center", sm: "start", md: "start", lg: "start" } },
        React.createElement(FtsWrapper, { display: "flex", justify: "center", align: "center" }, !!iconName && React.createElement(FtsIcons, { iconName: iconName, ...props.iconProps })),
        React.createElement(FtsWrapper, { width: "100%" },
            React.createElement(P, { variant: VariantsValues["text-sm"], weight: WeightSize.bold }, props.title),
            props.subtitle && (React.createElement(P, { variant: VariantsValues["text-2xs"], mt: "2xs", mb: "0", weight: WeightSize.regular, color: ColorsValue["greyscale-800"], whitespace: "pre-line" }, props.subtitle))),
        React.createElement(FtsWrapper, { display: { xs: "none", sm: "none", md: "block", lg: "block" }, cursor: "pointer", onClick: props.onClose },
            React.createElement(FtsIcons, { iconName: IconNames.Close }))));
};
