/* eslint-disable indent */
import React, { PureComponent, createRef } from "react";
import { Button, Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { connect } from "react-redux";
// import { Trash2 } from "react-feather";
import { Form, Formik } from "formik";

import Section from "./Section";
import ListingInfo from "./sections/listing-info";
import BusinessDetails from "./sections/BusinessDetails";
import Description from "./sections/Description";
import WorkingHours from "./sections/WorkingHours";
import Amenities from "./sections/Amenities";
import Note from "./sections/Note";
import Images from "./sections/Images";

import GoogleMap from "../../../components/fts/formik/google-map";
import Services from "../../../components/fts/services";
import Divider from "../../../components/fts/Divider";
import FormValidationEffect from "../../../components/fts/form-validation-effect";

import { weekdays } from "./data";

// toast styles
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";

import "../../../assets/scss/pages/account-settings.scss";
import "../../../assets/scss/pages/listing-settings.scss";
import "../../../assets/scss/pages/account-listing-shared.scss";
import "../../../assets/scss/components/fts/form-fields.scss";
import PromptWrapper from "../../../components/fts/PromptWrapper";
import FileItem from "../../../components/fts/files/FileItem";
import moment from "moment";
import { toast } from "react-toastify";
import { setUpdateListingTypePayload } from "../../../redux/actions/ads";
import {
  extractFormikAmenitiesData,
  extractFormikCategoriesData,
} from "../../../utility/extract";
import AdsRates from "../../../components/fts/listing-rates";
import { formatAdsRates } from "../../../utility/formatAdsRates";
import {
  formatWorkingHoursFrom,
  formatWorkingHoursTo,
} from "../../../utility/formatWorkingHours";
import {
  openEditListingSideOutAction,
  openListingPreviewSideOutAction,
  openListingProofSideOutAction,
} from "../../../redux/actions/v2/sideOut";

class ListingDetails extends PureComponent {
  formRef = createRef();
  state = { listingFiles: null, listingData: this.props.listingData };

  componentDidMount = () => {
    setTimeout(() => {
      this.formRef?.current?.resetForm && this.formRef.current.resetForm();
    }, 100);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.editMode !== this.props.editMode) {
      if (typeof this.props.getFilesByAssociation === "function") {
        this.props
          .getFilesByAssociation("listings", this.props.listingId, 1, 20)
          .then(({ data }) => {
            this.setState({
              listingFiles: data,
            });
          });
      }
    }
  }

  render() {
    const {
      updateListingFromEmail,
      inProgress,
      editMode,
      uploadingImage,
      imageLink,
      validationSchema,
      onFormSubmission,
      onSubmitError,
      toggleEdit,
      onRemoveListing,
      onRequestUpdate,
      onPreviewListing,
      noteLabelValue,
      onImageUpdate,
      setFileInputRef,
      setFileInputRefVal,
      callToAction,
      resetImageLink,
      updateListingSidebar,
      handleSidebar,

      toggleDuplicateListingModal,
      toggleReassignListingModal,
      onKeyDown,
      accountInfo,
      formikRef,
      listingFormRef,
      preview,
      differences,
      changedServiceAmenities,
      changedAmenities,
      changedServices,
      changedSections,
      right,
      setFormikSidebarRef,
      listingData,
      toggleEditConfirmationModal,
      toggleMultipleListingsModal,
      saveUpdatePayload,
      toggleListingPreviewModal,
      setListingPreviewModalTitle,
      listingLocked,
      upgradeInProgress,
      enableReinitialize = true,
      toggleChangeListingRenewalDate,
    } = this.props;

    // const iconProps = {
    //   size: 20,
    // };
    let { listingFiles } = this.state;
    // let imageLink = this.state.imageLink || this.props.imageLink;
    // console.log(listingData);

    return (
      <>
        <Card>
          <CardBody
            className={`${
              right
                ? "fts-listing-tab-wrapper-right"
                : "fts-listing-tab-wrapper"
            }`}
          >
            {inProgress ? (
              <React.Fragment>
                <Row>
                  <Col sm="12">
                    <Spinner size="sm" className="float-right" />
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <Formik
                innerRef={(e) => {
                  this.formRef.current = e;
                  formikRef && formikRef(e);
                  listingFormRef && listingFormRef(e);
                  setFormikSidebarRef && setFormikSidebarRef(e);
                }}
                enableReinitialize={enableReinitialize}
                onSubmit={onFormSubmission}
                initialValues={listingData} //{formikInitialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={true}
              >
                {(props) => {
                  const {
                    dirty,
                    values,
                    setFieldValue,
                    resetForm,
                    isValid,
                    setFieldError,
                    setFieldTouched,
                    validateForm,
                  } = props;

                  return (
                    <>
                      <FormValidationEffect
                        formik={props}
                        onSubmissionError={onSubmitError}
                      />
                      <PromptWrapper dirty={dirty} />
                      {this.props.action !== "update_listing" ? (
                        <Form autoComplete="off" onKeyDown={onKeyDown}>
                          <Section id="section1" first notWrapped>
                            <ListingInfo
                              getListingInfo={this.props.getListingInfo}
                              openEditListingSideOutAction={
                                this.props.openEditListingSideOutAction
                              }
                              openListingProofSideOutAction={
                                this.props.openListingProofSideOutAction
                              }
                              openListingPreviewSideOutAction={
                                this.props.openListingPreviewSideOutAction
                              }
                              updateListingFromEmail={updateListingFromEmail}
                              listingData={listingData}
                              toggleDuplicateListingModal={
                                toggleDuplicateListingModal
                              }
                              toggleEditConfirmationModal={
                                toggleEditConfirmationModal
                              }
                              toggleReassignListingModal={
                                toggleReassignListingModal
                              }
                              onEditToggle={toggleEdit}
                              onRemoveListing={onRemoveListing}
                              onRequestUpdate={onRequestUpdate}
                              resetImageLink={resetImageLink}
                              onPreviewListing={onPreviewListing}
                              validForm={isValid}
                              resetForm={resetForm}
                              setFieldError={setFieldError}
                              setFieldTouched={setFieldTouched}
                              accountInfo={accountInfo}
                              updateListingSidebar={updateListingSidebar}
                              handleSidebar={handleSidebar}
                              preview={preview}
                              keys={differences && Object.keys(differences)}
                              changedSections={changedSections}
                              right={right}
                              toggleMultipleListingsModal={
                                toggleMultipleListingsModal
                              }
                              saveUpdatePayload={saveUpdatePayload}
                              values={values}
                              validateForm={validateForm}
                              formRef={this.formRef}
                              adminPermissions={this.props.adminPermissions}
                              toggleListingPreviewModal={
                                toggleListingPreviewModal
                              }
                              toggleChangeListingRenewalDate={
                                toggleChangeListingRenewalDate
                              }
                              setListingPreviewModalTitle={
                                setListingPreviewModalTitle
                              }
                              reviewListingData={this.props.reviewListingData}
                              listingLocked={listingLocked}
                              upgradeInProgress={upgradeInProgress}
                              {...{
                                editMode,
                                callToAction,
                              }}
                            />

                            <div
                              className={`${
                                right && changedSections.includes("mapPreview")
                                  ? "section-changed"
                                  : null
                              }`}
                            >
                              <Divider textColor="primary" position="left">
                                Map Preview
                              </Divider>

                              <GoogleMap
                                {...{ editMode, values, setFieldValue }}
                                disableMapScroll={preview}
                                keys={differences && Object.keys(differences)}
                              />
                            </div>
                          </Section>
                          <div
                            className={`${
                              right &&
                              (changedSections.includes("contact") ||
                                changedSections.includes("website"))
                                ? "section-changed"
                                : null
                            }`}
                          >
                            <Section
                              id="section2"
                              title="Business details"
                              notWrapped
                            >
                              <BusinessDetails
                                {...{ editMode }}
                                updateListingFromEmail={updateListingFromEmail}
                                right={right}
                                changedSections={changedSections}
                                keys={differences && Object.keys(differences)}
                                listingData={listingData}
                                collectDataForCopy={
                                  this.props.collectDataForCopy
                                }
                                formRef={this.formRef}
                              />
                            </Section>
                          </div>
                          <div
                            className={`${
                              right && changedSections.includes("description")
                                ? "section-changed"
                                : null
                            }`}
                          >
                            <Section
                              id="section-description"
                              title="Listing extended description"
                              notWrapped
                            >
                              <Description
                                {...{ editMode, setFieldValue, values }}
                                keys={differences && Object.keys(differences)}
                              />
                            </Section>
                          </div>
                          <div
                            className={`${
                              right && changedSections.includes("workingHours")
                                ? "section-changed"
                                : null
                            }`}
                          >
                            <Section
                              id="section3"
                              title="Hours of operation"
                              notWrapped
                            >
                              <WorkingHours
                                {...{
                                  editMode,
                                  weekdays,
                                  preview,
                                }}
                              />
                            </Section>
                          </div>
                          <div
                            className={`${
                              right && changedSections.includes("adsRates")
                                ? "section-changed"
                                : null
                            }`}
                          >
                            <Section
                              id="section5"
                              title={"Standard Rates"}
                              notWrapped
                            >
                              <AdsRates
                                form={props}
                                adsRates={listingData?.adsRates}
                                notWrapped
                                priceId={values?.priceId}
                                {...{
                                  editMode,
                                }}
                                keys={differences && Object.keys(differences)}
                              />
                            </Section>
                          </div>

                          <div
                            className={`${
                              right && changedSections.includes("services")
                                ? "section-changed"
                                : null
                            }`}
                          >
                            <Section
                              id="section4"
                              title={editMode ? "Select services" : "Services"}
                              notWrapped
                            >
                              {listingData?.categories &&
                              Object.keys(listingData?.categories).length ===
                                0 &&
                              !editMode ? (
                                "Listing does not have any services selected, yet."
                              ) : (
                                <Services
                                  isListing={true}
                                  notWrapped
                                  refer={this.formRef}
                                  right={right}
                                  changedServices={changedServices}
                                  priceId={values?.priceId}
                                  {...{
                                    editMode,
                                  }}
                                />
                              )}
                            </Section>
                          </div>
                          {listingData && (
                            <div
                              className={`${
                                right &&
                                changedSections.includes("adsServiceAmenities")
                                  ? "section-changed"
                                  : null
                              }`}
                            >
                              <Section
                                id="section-service-amenities"
                                title={
                                  editMode
                                    ? "Select service amenities"
                                    : "Service amenities"
                                }
                                notWrapped
                              >
                                {listingData?.serviceAmenities &&
                                Object.keys(listingData?.serviceAmenities)
                                  .length === 0 &&
                                !editMode ? (
                                  "Listing does not have any service amenities selected, yet."
                                ) : (
                                  <Amenities
                                    areSearchable={true}
                                    areService={true}
                                    changedServiceAmenities={
                                      changedServiceAmenities
                                    }
                                    right={right}
                                    {...{
                                      editMode,
                                      selectedAmenities:
                                        listingData?.adsServiceAmenities,
                                    }}
                                  />
                                )}
                              </Section>
                            </div>
                          )}
                          {listingData && (
                            <div
                              className={`${
                                right &&
                                changedSections.includes("adsAmenities")
                                  ? "section-changed"
                                  : null
                              }`}
                            >
                              <Section
                                id="section6"
                                title={
                                  editMode ? "Select amenities" : "Amenities"
                                }
                                notWrapped
                              >
                                {Object.keys(
                                  listingData?.adsAmenities ||
                                    listingData?.amenities,
                                ).length === 0 && !editMode ? (
                                  "Listing does not have any amenities selected, yet."
                                ) : (
                                  <Amenities
                                    right={right}
                                    changedAmenities={changedAmenities}
                                    {...{
                                      editMode,
                                      selectedAmenities:
                                        listingData?.adsAmenities,
                                    }}
                                  />
                                )}
                              </Section>
                            </div>
                          )}
                          {!updateListingFromEmail && !preview && (
                            <>
                              <Section
                                id="section-note"
                                title={editMode ? "Add note" : "Notes"}
                                notWrapped
                              >
                                <Note
                                  noteRequired={false}
                                  isAddNoteBtn={false}
                                  name="adminNote"
                                  typeName="adminNoteType"
                                  noSticky
                                  keys={differences && Object.keys(differences)}
                                  {...{
                                    editMode,
                                    noteLabelValue,
                                    setFieldError,
                                    setFieldTouched,
                                  }}
                                />
                              </Section>

                              <Section
                                id="section7"
                                title={editMode ? "Upload image" : "Image"}
                                notWrapped
                              >
                                <Images
                                  onImageUpdate={onImageUpdate}
                                  setFileInputRef={setFileInputRef}
                                  setFileInputRefVal={setFileInputRefVal}
                                  inProgress={uploadingImage}
                                  {...{
                                    editMode,
                                    imageLink,
                                  }}
                                />
                                {editMode ? (
                                  <div
                                    className={
                                      listingFiles?.rows?.length ? "mt-3" : ""
                                    }
                                    style={{ height: 200, overflow: "auto" }}
                                  >
                                    {listingFiles?.rows?.length
                                      ? listingFiles?.rows?.map((e) => {
                                          return (
                                            <div
                                              key={e.id}
                                              className="cursor-pointer"
                                              onClick={() => {
                                                let imageLink =
                                                  "images" +
                                                  e.path.split("images").pop();
                                                onImageUpdate(null, imageLink);
                                              }}
                                            >
                                              <FileItem
                                                isFlagged={isEqual(
                                                  imageLink,
                                                  e.path,
                                                )}
                                                listFile={true}
                                                url={e.path}
                                                dateModified={moment(
                                                  e.updatedAt,
                                                ).format("lll")}
                                                hideRemoveBtn={true}
                                              />
                                            </div>
                                          );
                                        })
                                      : ""}
                                  </div>
                                ) : null}
                              </Section>
                            </>
                          )}
                          {/* //todo todo revert promotions once is ready */}
                          {/*<Section*/}
                          {/*  id="section-promotions"*/}
                          {/*  title="Promotions"*/}
                          {/*  notWrapped*/}
                          {/*>*/}
                          {/*  <Promotions {...{ callToAction }} />*/}
                          {/*</Section>*/}

                          {editMode && !updateListingFromEmail && (
                            <Row className={"mt-1"}>
                              {/* <Col> */}
                              {/* <Button
                                color="primary"
                                type="button"
                                className="account-listing-header-btn transparent"
                                onClick={() => {
                                  if (isValid) {
                                    onRemoveListing();
                                  }
                                }}
                              >
                                <Trash2 {...iconProps} />
                                <Icon name="Delete" {...iconProps} />
                              </Button> */}
                              {/*//todo revert this once is ready*/}
                              {/*<Button*/}
                              {/*  color="secondary"*/}
                              {/*  type="button"*/}
                              {/*  className="preview-listing-btn account-listing-header-btn"*/}
                              {/*  onClick={onPreviewListing}*/}
                              {/*>*/}
                              {/*  <Icon name="Eye" {...iconProps} />*/}
                              {/*  <span className="ml-1 preview-listing-btn-label">*/}
                              {/*    Preview Listing*/}
                              {/*  </span>*/}
                              {/*</Button>*/}
                              {/* </Col> */}
                              <Col className="d-flex justify-content-end">
                                {!updateListingSidebar ? (
                                  <>
                                    <Button
                                      color="tertiary"
                                      type="button"
                                      className="preview-listing-btn account-listing-header-btn mr-1"
                                      onClick={() => {
                                        toggleEditConfirmationModal();
                                      }}
                                    >
                                      <span className="preview-listing-btn-label">
                                        Cancel
                                      </span>
                                    </Button>
                                    <Button
                                      color="secondary"
                                      type="button"
                                      className="mr-1"
                                      onClick={() => {
                                        validateForm();
                                        if (
                                          !Object.keys(
                                            this.formRef.current.errors,
                                          ).length
                                        ) {
                                          toggleMultipleListingsModal();
                                          saveUpdatePayload(values);
                                        } else {
                                          toast.error(
                                            "Unable to proceed. Invalid form!",
                                            {
                                              position:
                                                toast.POSITION.TOP_RIGHT,
                                            },
                                          );
                                        }
                                      }}
                                    >
                                      <span className="preview-listing-btn-label">
                                        Apply to multiple Listings
                                      </span>
                                    </Button>
                                    <Button
                                      color="primary"
                                      type="submit"
                                      className="account-listing-header-btn acc-list-primary-btn"
                                    >
                                      Save changes
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      color="secondary"
                                      type="button"
                                      className="preview-listing-btn account-listing-header-btn mr-1"
                                      onClick={() => {
                                        handleSidebar(false);
                                      }}
                                    >
                                      <span className="preview-listing-btn-label">
                                        Cancel
                                      </span>
                                    </Button>
                                    {this.props.reviewListingData ? (
                                      <Button
                                        color="primary"
                                        type="button"
                                        className="account-listing-header-btn acc-list-primary-btn"
                                        onClick={() => {
                                          let newUpdateListingTypePayload = {
                                            ...this.props
                                              .updateListingTypePayload,
                                          };

                                          const {
                                            userCategories: categories,
                                            userSubCategories: subCategories,
                                          } = extractFormikCategoriesData(
                                            values.categories,
                                          );

                                          const hoursOfOperation = {
                                            alwaysOpen: values.workingHours
                                              .open24hrs
                                              ? 1
                                              : 0,
                                            specialHours: {
                                              towingRecovery:
                                                !!values.workingHours
                                                  .towingRecoveryOpen,
                                              mobileRoad:
                                                !!values.workingHours
                                                  .mobileRoadServiceOpen,
                                            },
                                            weekDays: weekdays.map(
                                              ({ day }) => ({
                                                day,
                                                // "08:00 PM",
                                                from: formatWorkingHoursFrom(
                                                  values,
                                                  day,
                                                ),
                                                // "08:00 PM",
                                                to: formatWorkingHoursTo(
                                                  values,
                                                  day,
                                                ),
                                              }),
                                            ),
                                          };

                                          values.adsRates = formatAdsRates(
                                            values,
                                            this.props.rates,
                                          );
                                          console.log(
                                            "newUpdateListingTypePayload.newListingData: ",
                                            newUpdateListingTypePayload.newListingData,
                                          );
                                          newUpdateListingTypePayload.newListingData =
                                            {
                                              ...values,
                                              userId: parseInt(accountInfo.id),
                                              adNotes: values.adNotes || [],
                                              alwaysOpen: values.workingHours
                                                .open24hrs
                                                ? 1
                                                : 0,
                                              imageLink:
                                                this.state.imageLink ||
                                                values.image,
                                              image:
                                                listingData.image ||
                                                values.image,

                                              // ...values.workingHours,
                                              adsCategories: [...categories],
                                              adsSubCategories: [
                                                ...subCategories,
                                              ],
                                              ...values.addressInfo,
                                              billingAddress: {
                                                ...values.addressInfo,
                                                state:
                                                  typeof values.addressInfo
                                                    .state === "object"
                                                    ? values.addressInfo.state
                                                        .value
                                                    : values.addressInfo.state,
                                              },
                                              latitude:
                                                values.geoLocation.lat.toString(),
                                              longitude:
                                                values.geoLocation.lng.toString(),
                                              ...extractFormikAmenitiesData(
                                                values,
                                              ),
                                              // renewalDate: values.renewalDate[0]?.toString(),
                                              // renewalTimestamp: Array.isArray(
                                              //   values.renewalDate
                                              // )
                                              //   ? rawToUnix(
                                              //       values.renewalDate[0]?.toString()
                                              //     )
                                              //   : rawToUnix(
                                              //       values.renewalDate?.toString()
                                              //     ),
                                              // renewalLaterDate: Array.isArray(
                                              //   values.renewalDate
                                              // )
                                              //   ? rawToUnix(
                                              //       values.renewalDate[0]?.toString()
                                              //     )
                                              //   : rawToUnix(
                                              //       values.renewalDate?.toString()
                                              //     ),
                                              // renewalLaterDate: rawToUnix(values.renewalDate[0]?.toString()),
                                              milemarker: values.mileMarker,
                                              towingRecoveryOpen:
                                                !!values.towingRecoveryOpen,
                                              mobileRoadServiceOpen:
                                                !!values.mobileRoadServiceOpen,
                                              priceId:
                                                typeof values.priceId ===
                                                "object"
                                                  ? values.priceId.value
                                                  : values.priceId,
                                              accountType:
                                                typeof values.accountType ===
                                                "object"
                                                  ? values.accountType.value
                                                  : values.accountType,
                                              state:
                                                typeof values.addressInfo
                                                  .state === "object"
                                                  ? values.addressInfo.state
                                                      .value
                                                  : values.addressInfo.state,
                                              adminStatus:
                                                typeof values.adminStatus ===
                                                "object"
                                                  ? values.adminStatus.value
                                                  : values.adminStatus,
                                              status:
                                                typeof values.status ===
                                                "object"
                                                  ? values.status.value
                                                  : values.status,
                                              serviceType: 0,
                                              hoursOfOperation,
                                              paymentStatus: values.isItPaidFor
                                                ? "paid"
                                                : "not_paid",
                                              // name: rfc3986EncodeURIComponent(
                                              //   values.name
                                              // ),
                                            };
                                          // {
                                          //   ...values,
                                          //   ...extractFormikAmenitiesData(
                                          //     values
                                          //   ),
                                          //   adsCategories: [...categories],
                                          //   adsSubCategories: [
                                          //     ...subCategories,
                                          //   ],
                                          //   hoursOfOperation,
                                          // };

                                          this.props.setUpdateListingTypePayload(
                                            newUpdateListingTypePayload,
                                          );
                                          // setTimeout(() => {
                                          this.props.callToAction(
                                            "preview_listing_details",
                                            false,
                                            {
                                              ...newUpdateListingTypePayload.newListingData,
                                            },
                                            {
                                              ...newUpdateListingTypePayload.newListingData,
                                            },
                                          );
                                          // }, 1000);
                                          // this.props.handleSidebar(false);
                                        }}
                                      >
                                        Next Step: Preview Listing
                                      </Button>
                                    ) : (
                                      <Button
                                        color="primary"
                                        type="submit"
                                        className="account-listing-header-btn acc-list-primary-btn"
                                      >
                                        Update listing
                                      </Button>
                                    )}
                                  </>
                                )}
                              </Col>
                            </Row>
                          )}
                        </Form>
                      ) : null}
                    </>
                  );
                }}
              </Formik>
            )}
          </CardBody>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    adminPermissions: state.auth.login.permissions,
    updateListingTypePayload: state.listingCounts.updateListingTypePayload,
    rates: state.essentialLists.rates,
  };
};

export default connect(mapStateToProps, {
  setUpdateListingTypePayload,
  openEditListingSideOutAction,
  openListingProofSideOutAction,
  openListingPreviewSideOutAction,
})(ListingDetails);

let isEqual = (left, right) => {
  let newLeft = left;
  let newRight = "images" + right.split("images").pop();

  return newLeft === newRight;
};
