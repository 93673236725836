import React from "react";
import { confirmationDialogIconProps } from "./constants";
import { ButtonColorsValue } from "@find-truck-service/types/ui/button";
import { ConfirmationDialog } from "@find-truck-service/ui/src/components/react/ConfirmationDialog";
export const LeavingFTSDialog = (props) => {
    const title = "You are leaving Find Truck Service®";
    const subtitle = "Find Truck Service® doesn't manage the content or security of the external (third-party) website you are about to visit. To continue, click “Proceed”.";
    const { closeLeavingFTSModal, website, showLeavingFTSModal } = props;
    const onWebsiteClick = () => {
        let url = website;
        if (!/^https?:\/\//i.test(url))
            url = "https://" + url;
        closeLeavingFTSModal();
        window.open(url);
    };
    return (React.createElement(ConfirmationDialog, { size: "sm", title: title, subtitle: subtitle, isOpen: showLeavingFTSModal, onClose: closeLeavingFTSModal, iconProps: confirmationDialogIconProps, containedButton: {
            label: "Proceed",
            onClick: onWebsiteClick,
            color: ButtonColorsValue.accent,
        }, textButton: {
            label: "Cancel",
            onClick: closeLeavingFTSModal,
            color: ButtonColorsValue.accent,
        } }));
};
