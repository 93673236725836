import React from "react";
import { PromotionTag } from "./PromotionTag";
import { AuthorizedTag } from "./AuthorizedTag";
import { ListingItemImage } from "./ListingItemImage";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { BASIC_LISTING_PRICE_ID, FREE_LISTING_PRICE_ID } from "../constants";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
// interface ListingItemXsContentProps {
//   city: string;
//   state: string;
//   priceId: number;
//   address: string;
//   inCity: boolean;
//   distance: number;
//   imageSrc: string;
//   interstate?: string;
//   milemarker?: string;
//   listingName: string;
//   showDistance: boolean;
//   compassDirection: string;
//   showPromotionTag?: boolean;
//   showAuthorizedTag?: boolean;
// }
export const ListingItemXsContent = (props) => {
    const { listingName, address, city, state, showAuthorizedTag, showPromotionTag } = props;
    const { inCity, showDistance, distance, interstate, milemarker, compassDirection } = props;
    const { priceId, imageSrc } = props;
    const miles = distance?.toFixed(2);
    const isFree = priceId === FREE_LISTING_PRICE_ID;
    const showBadges = showAuthorizedTag || showPromotionTag;
    const showImage = priceId !== BASIC_LISTING_PRICE_ID && !isFree;
    const locationInfo = `${interstate || ""}${interstate && milemarker ? " & " : ""}${milemarker || ""}`;
    return (React.createElement(FtsWrapper, { flex: 1, py: "lg", px: "xl", display: "flex", direction: "column", gap: "lg" },
        showBadges && (React.createElement(FtsWrapper, { gap: "sm", display: "flex" },
            showAuthorizedTag && React.createElement(AuthorizedTag, null),
            showPromotionTag && React.createElement(PromotionTag, null))),
        React.createElement(FtsWrapper, { display: "flex", width: "100%", direction: "column", gap: "2xs" },
            React.createElement(Span, { weight: WeightSize.medium, color: ColorsValue["greyscale-800"], variant: VariantsValues[isFree ? "text-2xs" : "text-xs"] }, listingName),
            React.createElement(FtsWrapper, null,
                !isFree && (React.createElement(Span, { color: ColorsValue["greyscale-800"], variant: VariantsValues["text-3xs"], weight: WeightSize.regular }, `${address},`)),
                React.createElement(FtsWrapper, { display: "flex", justify: "space-between", gap: "2xs", align: "flex-end" },
                    React.createElement(Span, { color: ColorsValue[isFree ? "greyscale-500" : "greyscale-800"], variant: VariantsValues["text-3xs"], weight: WeightSize.regular, flex: 1 }, `${city}, ${state}`),
                    showDistance && !inCity && miles && (React.createElement(Span, { color: ColorsValue[isFree ? "greyscale-500" : "greyscale-800"], variant: VariantsValues["text-3xs"], weight: WeightSize.regular }, `${miles} mi ${compassDirection}`)))),
            (interstate || milemarker) && (React.createElement(Span, { color: ColorsValue[isFree ? "greyscale-500" : "greyscale-800"], variant: VariantsValues["text-3xs"], weight: WeightSize.regular }, locationInfo))),
        showImage && (React.createElement(ListingItemImage, { width: "100%", height: "100%", src: imageSrc, priceId: priceId }))));
};
