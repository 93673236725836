import React from "react";
import { Span } from "../../../FtsText/index";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues } from "@find-truck-service/types/ui/text";
const TextDivider = (props) => {
    return (React.createElement(FtsWrapper, { display: "flex", align: "center", textAlign: "center", width: "100%" },
        React.createElement(FtsWrapper, { height: "1px", bg: ColorsValue["greyscale-100"], mr: "md", width: "100%" }),
        React.createElement(Span, { variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-500"] }, props.text),
        React.createElement(FtsWrapper, { height: "1px", bg: ColorsValue["greyscale-100"], ml: "md", width: "100%" })));
};
export default TextDivider;
