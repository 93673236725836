import React from "react";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import { hoursListFrom } from "../../../fts/formik/hour-input/options";

export const WorkingHoursFromDropdown = (props) => {
  const { name, fromValue, onChange } = props;
  return (
    <FtsColumn size={{ xs: 5 }}>
      <Dropdown
        size={DropdownSizesValues.sm}
        variant={DropdownVariantsValues.outlined}
        placeholder={""}
        name={name}
        value={{ value: fromValue, label: fromValue }}
        options={hoursListFrom}
        onChange={(val) => onChange(val.value)}
      />
    </FtsColumn>
  );
};
