import { call, put, takeEvery } from "redux-saga/effects";
import FtsV2Instance from "../../../axios/ftsv2.instance";

import { toast } from "react-toastify";

import { GET_SHARE_PROVIDERS_REQUEST } from "../../reducers/v2/shareProviders";
import {
  getShareProvidersFailedAction,
  getShareProvidersSuccessAction,
} from "../../actions/v2/shareProviders";

function* getShareProvidersRequestSaga(action) {
  try {
    const { data } = yield call(
      FtsV2Instance.get,
      "/share-providers/admin/list",
      { params: action.payload.data },
    );

    yield put(
      getShareProvidersSuccessAction({
        data: data.data,
      }),
    );
  } catch (e) {
    console.log(e);
    const message = e.response
      ? e.response.data.message
      : "Error while getting share providers";
    yield put(getShareProvidersFailedAction({ data: message }));
    toast.error(message);
  }
}

export default function* shareProvidersWatcher() {
  yield takeEvery(GET_SHARE_PROVIDERS_REQUEST, getShareProvidersRequestSaga);
}
