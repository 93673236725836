import React, { useCallback, useState } from "react";
import { ListingItemFooter } from "./ListingItemFooter";
import { ListingItemMdContent } from "./ListingItemMdContent";
import { ListingBottomActions } from "./ListingBottomActions";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ButtonDirections, ButtonSizes } from "@find-truck-service/types/ui/button";
import { getListingItemBg } from "@find-truck-service/ui/src/components/react/ListingItem/helpers";
import { FREE_LISTING_PRICE_ID } from "@find-truck-service/ui/src/components/react/ListingItem/constants";
export const ListingItemMd = (props) => {
    const { type, price, showFooter, showBottomActions, priceId, showExpandContent } = props;
    const { selected, onSelect, onClickBenefits, primaryActionLabel, boxShadow } = props;
    const { onClickStatus, onClickNotes, onClickRates, onClickServices, onClickRatings, onClickShare, onClickReport } = props;
    const [expandContent, setExpandContent] = useState(!!showExpandContent);
    const onExpand = useCallback(() => setExpandContent(true), []);
    const showActions = showBottomActions && (expandContent || priceId !== FREE_LISTING_PRICE_ID);
    const showFooterComponent = showFooter && (expandContent || priceId !== FREE_LISTING_PRICE_ID);
    return (React.createElement(FtsWrapper, { width: "100%", borderRadius: "2xs", boxShadow: boxShadow, bg: getListingItemBg(priceId), display: { xs: "none", sm: "none", md: "block", lg: "none" } },
        React.createElement(ListingItemMdContent, { ...props, onExpand: onExpand, expandContent: expandContent }),
        showActions && (React.createElement(ListingBottomActions, { size: ButtonSizes.sm, direction: ButtonDirections.column, onClickStatus: onClickStatus, onClickNotes: onClickNotes, onClickRates: onClickRates, onClickServices: onClickServices, onClickRatings: onClickRatings, onClickShare: onClickShare, onClickReport: onClickReport })),
        showFooterComponent && (React.createElement(ListingItemFooter, { type: type, price: price, priceId: priceId, selected: selected, onSelect: onSelect, onClickBenefits: onClickBenefits, primaryActionLabel: `${primaryActionLabel || "Select"} ${type}` }))));
};
