import React from "react";
import { getBoxShadow } from "../helpers";
import { ListingItemXs } from "./ListingItemXs";
import { ListingItemSm } from "./ListingItemSm";
import { ListingItemMd } from "./ListingItemMd";
import { ListingItemLg } from "./ListingItemLg";
export const ListingItemComponentByBreakpoints = (props) => {
    const boxShadow = props.boxShadow || getBoxShadow(props.priceId);
    return (React.createElement(React.Fragment, null,
        React.createElement(ListingItemXs, { boxShadow: boxShadow, ...props }),
        React.createElement(ListingItemSm, { boxShadow: boxShadow, ...props }),
        React.createElement(ListingItemMd, { boxShadow: boxShadow, ...props }),
        React.createElement(ListingItemLg, { boxShadow: boxShadow, ...props })));
};
