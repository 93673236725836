import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
export const ModalContentComponent = (props) => {
    return (React.createElement(FtsWrapper, { ...props, px: {
            xs: "xl",
            sm: "2xl",
            md: "3xl",
            lg: "4xl",
        }, pb: {
            xs: "xl",
            sm: "xl",
            md: "2xl",
            lg: "3xl",
        } }, props.children));
};
