import React from "react";
import { FtsTableCell } from "@find-truck-service/ui/src/FtsTableCell/FtsTableCell";
import { Dropdown } from "@find-truck-service/ui/src/components/react/Dropdown";
import { TableCellNoData } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellNoData";
import { TableCellTypesValues } from "@find-truck-service/ui/src/components/react/TableCell";
export const TableCellDropdown = (props) => {
    if (!props.dropdownProps?.options?.length)
        return React.createElement(TableCellNoData, { type: TableCellTypesValues.text, ...props });
    return (React.createElement(FtsTableCell, { size: props.size, isLoading: props.isLoading },
        React.createElement(Dropdown, { ...props.dropdownProps })));
};
