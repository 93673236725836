import { createSelector } from "reselect";

//API
export const GET_PROOF_DATA_BY_AD_ID_REQUEST =
  "PROOF/GET_PROOF_DATA_BY_AD_ID_REQUEST";
export const GET_PROOF_DATA_BY_AD_ID_SUCCESS =
  "PROOF/GET_PROOF_DATA_BY_AD_ID_SUCCESS";
export const GET_PROOF_DATA_BY_AD_ID_FAILED =
  "PROOF/GET_PROOF_DATA_BY_AD_ID_FAILED";

export const APPROVE_PROOF_BY_ID_REQUEST = "PROOF/APPROVE_PROOF_BY_ID_REQUEST";
export const APPROVE_PROOF_BY_ID_SUCCESS = "PROOF/APPROVE_PROOF_BY_ID_SUCCESS";
export const APPROVE_PROOF_BY_ID_FAILED = "PROOF/APPROVE_PROOF_BY_ID_FAILED";

//
export const CREATE_PROOF_REQUEST = "PROOF/CREATE_PROOF_REQUEST";
export const CREATE_PROOF_SUCCESS = "PROOF/CREATE_PROOF_SUCCESS";
export const CREATE_PROOF_FAILED = "PROOF/CREATE_PROOF_FAILED";

export const SEND_PROOF_REQUEST = "PROOF/SEND_PROOF_REQUEST";
export const SEND_PROOF_SUCCESS = "PROOF/SEND_PROOF_SUCCESS";
export const SEND_PROOF_FAILED = "PROOF/SEND_PROOF_FAILED";

//STORE

export const SET_PROOF_DATA_INVALID_FORM_FIELDS =
  "PROOF/SET_ACCOUNT_DATA_INVALID_FORM_FIELDS";

export const SET_SEND_PROOF_DATA = "PROOF/SET_SEND_PROOF_DATA";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  sendProofData: null,
  invalidFormFields: null,
};

/// Reducer
export default function (state = initialState, action) {
  switch (action.type) {
    //API
    case SEND_PROOF_REQUEST:
    case GET_PROOF_DATA_BY_AD_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        invalidFormFields: null,
      };

    case SEND_PROOF_SUCCESS:
    case CREATE_PROOF_SUCCESS:
    case GET_PROOF_DATA_BY_AD_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        error: null,
        invalidFormFields: null,
      };

    case SEND_PROOF_FAILED:
    case CREATE_PROOF_FAILED:
    case GET_PROOF_DATA_BY_AD_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data,
        invalidFormFields: null,
      };
    case APPROVE_PROOF_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        invalidFormFields: null,
      };

    case APPROVE_PROOF_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        error: null,
        invalidFormFields: null,
      };

    case APPROVE_PROOF_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data,
        invalidFormFields: null,
      };

    case SET_PROOF_DATA_INVALID_FORM_FIELDS:
      return { ...state, invalidFormFields: action.payload.data };

    case SET_SEND_PROOF_DATA:
      return { ...state, sendProofData: action.payload.data };

    default:
      return state;
  }
}

/// Selectors
const selectProofState = (state) => state.proofState;

export const selectProofData = createSelector(selectProofState, (proof) => {
  return proof.data;
});

export const selectProofError = createSelector(selectProofState, (proof) => {
  return proof.error;
});

export const selectProofIsLoading = createSelector(
  selectProofState,
  (proof) => {
    return proof.isLoading;
  },
);

export const selectProofInvalidFormFields = createSelector(
  selectProofState,
  (proof) => {
    return proof.invalidFormFields;
  },
);

export const selectSendProofData = createSelector(selectProofState, (proof) => {
  return proof.sendProofData;
});

export const selectProofCategories = createSelector(
  selectProofState,
  (proof) => {
    return proof.data.userCategories;
  },
);

export const selectProofSubCategories = createSelector(
  selectProofState,
  (proof) => {
    return proof.data.userSubCategories;
  },
);
