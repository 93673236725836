import React from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
//types
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
//Component
import { InputLabel } from "@find-truck-service/ui/src/components/react/InputLabel";
import { FontSizes, LineHeightByDevice, VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
export const DropdownVariantsValues = {
    contained: "contained",
    outlined: "outlined",
    text: "text",
};
export const DropdownSizesValues = {
    md: "md",
    sm: "sm",
};
export const DropdownTextSizesValues = {
    md: FontSizes["2xs"],
    sm: FontSizes["3xs"],
};
export const DropdownLineHeightTextSizesValues = {
    md: LineHeightByDevice["text-2xs"].relaxed.lg,
    sm: LineHeightByDevice["text-3xs"].relaxed.lg,
};
const paddingsByDropdownVariantAndSize = {
    contained: { sm: Spacing.sm, md: Spacing.md },
    outlined: { sm: Spacing.sm, md: Spacing.md },
    text: { sm: Spacing["0"], md: Spacing["0"] },
};
// @ts-ignore
const defaultPickerStyle = {
    control: (styles, props) => {
        return {
            ...styles,
            width: "100%",
            cursor: "pointer",
            minHeight: "unset",
            borderRadius: `${Spacing["2xs"]}`,
            color: `${ColorsValue["greyscale-800"]}`,
            boxShadow: "unset",
            borderWidth: "0px",
            "&::placeholder": {
                color: `${ColorsValue["greyscale-300"]}`,
                opacity: 1,
            },
            padding: paddingsByDropdownVariantAndSize[props.selectProps.variant][props.selectProps.size],
            fontSize: DropdownTextSizesValues[props.selectProps.size],
            lineHeight: DropdownLineHeightTextSizesValues[props.selectProps.size],
        };
    },
    placeholder: (styles) => ({
        ...styles,
        color: `${ColorsValue["greyscale-300"]}`,
        margin: Spacing["0"],
    }),
    option: (styles, props) => ({
        ...styles,
        textAlign: "left",
        padding: Spacing[props.selectProps.size],
        backgroundColor: props.isSelected
            ? ColorsValue["blue-accent-50"]
            : props.isFocused
                ? ColorsValue["greyscale-50"]
                : "unset",
        borderRadius: Spacing["2xs"],
        outline: props.isFocused ? `2px solid ${ColorsValue["blue-accent-300"]}` : "unset",
        color: props.isSelected ? ColorsValue["greyscale-800"] : ColorsValue["greyscale-500"],
        "&:hover": {
            backgroundColor: ColorsValue["greyscale-50"],
            outline: `2px solid ${ColorsValue["blue-accent-300"]}`,
        },
    }),
    menu: (styles) => ({
        ...styles,
        left: `${Spacing["0"]}`,
    }),
    container: (styles, props) => ({
        ...styles,
        position: props.selectProps.containerPosition || "relative",
    }),
    input: (styles) => ({
        ...styles,
        margin: 0,
        padding: 0,
        color: `${ColorsValue["greyscale-800"]}`,
        letterSpacing: 0,
        fontWeight: WeightSize.regular,
        input: {
            opacity: "1 !important",
        },
    }),
    valueContainer: (styles) => ({
        ...styles,
        boxShadow: "unset",
        padding: Spacing["0"],
        margin: Spacing["0"],
        gap: Spacing["xs"],
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        padding: Spacing["0"],
        margin: Spacing["0"],
        marginLeft: Spacing["3xs"],
        ":hover": {
            backgroundColor: ColorsValue["blue-accent-100"],
        },
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        borderRadius: 0,
        fontSize: `unset`,
        padding: Spacing["0"],
        paddingLeft: Spacing["0"],
        margin: Spacing["0"],
    }),
    menuList: (styles) => ({
        ...styles,
        padding: Spacing["2xs"],
        gap: Spacing["2xs"],
        border: `${Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]}`,
        borderRadius: Spacing["2xs"],
        maxHeight: "250px",
    }),
    singleValue: (styles, props) => ({
        ...styles,
        padding: Spacing["0"],
        margin: Spacing["0"],
        fontWeight: WeightSize.regular,
        letterSpacing: 0,
        fontSize: DropdownTextSizesValues[props.selectProps.size],
        lineHeight: DropdownLineHeightTextSizesValues[props.selectProps.size],
    }),
};
const pickerStyle = {
    [DropdownVariantsValues.outlined]: {
        ...defaultPickerStyle,
        control: (styles, props) => {
            return {
                ...defaultPickerStyle.control(styles, props),
                outline: `${Spacing[props.menuIsOpen ? "3xs" : "4xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-300" : props.menuIsOpen ? "blue-accent-500" : "greyscale-100"]}`,
                "&:hover": {
                    outline: !props.menuIsOpen &&
                        `${Spacing["4xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-700" : "blue-accent-300"]}`,
                },
                "&:active": {
                    outline: `${Spacing["3xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-50" : "blue-accent-500"]}`,
                },
            };
        },
    },
    [DropdownVariantsValues.contained]: {
        ...defaultPickerStyle,
        control: (styles, props) => {
            return {
                ...defaultPickerStyle.control(styles, props),
                backgroundColor: props.menuIsOpen ? ColorsValue["greyscale-0"] : ColorsValue["greyscale-50"],
                outline: `${Spacing[props.menuIsOpen ? "3xs" : "4xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-300" : props.menuIsOpen ? "blue-accent-500" : "greyscale-100"]}`,
                "&:hover": {
                    outline: !props.menuIsOpen &&
                        `${Spacing["4xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-700" : "blue-accent-300"]}`,
                },
                "&:active": {
                    outline: `${Spacing["3xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-50" : "blue-accent-500"]}`,
                },
            };
        },
    },
    [DropdownVariantsValues.text]: {
        ...defaultPickerStyle,
        control: (styles, props) => ({
            ...defaultPickerStyle.control(styles, props),
            //
            // borderBottom: props.menuIsOpen
            //   ? `${Spacing["3xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-50" : "blue-accent-500"]}`
            //   : `${Spacing["4xs"]} solid transparent`,
            //
            // "&:hover": {
            //   borderBottom: props.menuIsOpen
            //     ? `${Spacing["3xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-700" : "blue-accent-300"]}`
            //     : `${Spacing["4xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-50" : "blue-accent-500"]}`,
            // },
            // "&:active": {
            //   borderBottom: `${Spacing["3xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-50" : "blue-accent-500"]}`,
            // },
            //
            // backgroundColor: "transparent",
            // padding: 0,
            // margin: 0,
            // height: "16px",
            // // outline: `${Spacing[props.menuIsOpen || props.selectProps.invalid ? "3xs" : "0"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-300" : "blue-accent-500"]}`,
            // "&:hover": {
            //   outline:
            //     !props.menuIsOpen &&
            //     `${Spacing["4xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-700" : "blue-accent-300"]}`,
            // },
            // "&:active": {
            //   outline: `${Spacing["3xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-50" : "blue-accent-500"]}`,
            // },
        }),
        // valueContainer: (styles: any) => ({
        //   ...styles,
        //   height: "16px",
        //   padding: 0,
        //   paddingRight: Spacing["sm"],
        //   margin: 0,
        // }),
        // dropdownIndicator: (styles: any) => ({
        //   ...styles,
        //   svg: {
        //     width: "16px",
        //     height: "16px",
        //   },
        // }),
        // singleValue: (styles: any) => ({
        //   ...styles,
        //   margin: 0,
        // }),
    },
};
const IndicatorContainer = styled(components.IndicatorsContainer) `
  & > div {
    padding: 0;
  }
`;
export const Dropdown = (props) => {
    return (React.createElement(FtsWrapper, { onClick: props.onWrapperClick, width: "100%", display: "flex", gap: "2xs", direction: "column" },
        !!props.label && (React.createElement(InputLabel, { label: props.label, size: props.size || Spacing.md, required: props.required })),
        React.createElement(FtsWrapper, null,
            React.createElement(Select, { isSearchable: true, isClearable: true, invalid: props.errorMessage, styles: pickerStyle[props.variant || DropdownVariantsValues.outlined], onChange: props.onChange, ...props, ref: props.reference, components: {
                    IndicatorSeparator: () => null,
                    IndicatorsContainer: IndicatorContainer,
                    ...props.components,
                } })),
        !!props.errorMessage && (React.createElement(FtsWrapper, null,
            React.createElement(Span, { variant: VariantsValues["text-3xs"], color: ColorsValue["red-error-500"] }, props.errorMessage)))));
};
