import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FtsSideOutWrapperHeader } from "../dist/ui/src/FtsSideOut/FtsSideOutWrapperHeader";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import {
  closeSideOutAction,
  closeSubSideOutAction,
} from "../../../redux/actions/v2/sideOut";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { setAccountDataAction } from "../../../redux/actions/v2/account";
import { Menu } from "@find-truck-service/ui/src/components/react/Menu";
import { selectAccountData } from "../../../redux/reducers/v2/account";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { FtsWrapper } from "../dist/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "../dist/types/ui/colors";
import { Spacing } from "../dist/types/ui/spacing";
import { Span } from "../dist/ui/src/FtsText";
import Icon from "../../fts/Icons";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { selectSubSideOutComponent } from "../../../redux/reducers/v2/sideOut";

const discountOptions = [
  { value: 5, label: "5%" },
  { value: 10, label: "10%" },
  { value: 15, label: "15%" },
  { value: 20, label: "20%" },
  { value: 25, label: "25%" },
  { value: 30, label: "30%" },
];

export const SideOutEditAccountHeader = () => {
  const dispatch = useDispatch();
  const accountData = useSelector(selectAccountData);
  const buttonRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const subSideOutComponent = useSelector(selectSubSideOutComponent);

  const hideSidebar = useCallback(() => {
    if (subSideOutComponent) return dispatch(closeSubSideOutAction());
    dispatch(closeSideOutAction());
  }, [closeSubSideOutAction, subSideOutComponent, closeSideOutAction]);

  const onChange = useCallback(
    (event) => {
      dispatch(
        setAccountDataAction({
          ...accountData,
          ["quickbook"]: event.target.value,
        }),
      );
    },
    [accountData],
  );

  const onDiscountChange = useCallback(
    (option) => {
      dispatch(
        setAccountDataAction({
          ...accountData,
          discount: option?.value || 0,
        }),
      );
    },
    [accountData],
  );

  return (
    <FtsSideOutWrapperHeader
      bg={ColorsValue["greyscale-100"]}
      py={"lg"}
      px={"3xl"}
    >
      <FtsWrapper>
        <Span
          variant={VariantsValues["text-sm"]}
          color={ColorsValue["greyscale-800"]}
        >
          Edit Account #{accountData.id}
        </Span>
      </FtsWrapper>

      <FtsWrapper display="flex" align={"center"}>
        <FtsWrapper display="flex" align={"center"} width={"160px"}>
          <Span
            mr={"2xs"}
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["greyscale-500"]}
          >
            Discount
          </Span>
          <Dropdown
            size={DropdownSizesValues.sm}
            variant={DropdownVariantsValues.outlined}
            isClearable={false}
            options={discountOptions}
            value={discountOptions.find(
              (e) => e.value === accountData.discount,
            )}
            onChange={onDiscountChange}
          />
        </FtsWrapper>
        <FtsWrapper display="flex" align={"center"} width={"174px"} ml={"md"}>
          <Span
            mr={"2xs"}
            variant={VariantsValues["text-3xs"]}
            color={ColorsValue["greyscale-500"]}
            width={"48px"}
          >
            QB #
          </Span>
          <Input
            name={"quickbook"}
            value={accountData.quickbook}
            onChange={onChange}
            variant={InputVariants.outlined}
            size={InputSizes.sm}
          />
        </FtsWrapper>
        <FtsWrapper
          ref={buttonRef}
          position={"relative"}
          p={"xs"}
          borderRadius={"2xs"}
          border={`${Spacing["4xs"]} solid ${ColorsValue["blue-accent-300"]}`}
          mr={"2xl"}
          ml={"md"}
          onClick={() => setIsMenuOpen((prev) => !prev)}
        >
          <FtsIcons
            iconName={IconNames.MoreVertical}
            pathProps={{ stroke: "#0077CD" }}
          />
          <Menu
            parentRef={buttonRef}
            menuItems={[
              {
                id: 1,
                title: "Lookup IP",
                iconName: IconNames.ExternalLink,
                onClick: () =>
                  window.open(
                    `https://iplocation.io/ip/${accountData.ip}`,
                    "_blank",
                    "noopener,noreferrer",
                  ),
              },
            ]}
            isOpen={isMenuOpen}
          />
        </FtsWrapper>
        <Icon onClick={hideSidebar} size={24} name={"Close"} />
      </FtsWrapper>
    </FtsSideOutWrapperHeader>
  );
};
