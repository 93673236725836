import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import Geocode from "react-geocode";
import { Input } from "../../dist/ui/src/components/react/Input";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { Span } from "../../dist/ui/src/FtsText";
import { InputSizes, InputVariants } from "../../dist/types/ui/input";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "../../dist/types/ui/button";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";

import appConfig from "../../../../configs/appConfig";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import { selectListingData } from "../../../../redux/reducers/v2/listing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

const containerStyle = {
  width: "100%",
  height: "400px",
  position: "relative",
};

Geocode.setApiKey(appConfig.googleApiKey);
Geocode.setLanguage("en");

export const ListingMapInfoRow = () => {
  const listingData = useSelector(selectListingData);
  const dispatch = useDispatch();
  const onChange = useCallback(
    (event) => {
      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            [event.target.name]: event.target.value,
          },
        }),
      );
    },
    [listingData],
  );

  const center = {
    lat: parseFloat(listingData.latitude),
    lng: parseFloat(listingData.longitude),
  };

  const fetchPosition = useCallback(async () => {
    try {
      const address = listingData.address;
      const city = listingData.city;
      const state = listingData.state;
      const zip = listingData.zip;
      const concatenated = `${city} ${address} ${state} ${zip}`;
      const response = await Geocode.fromAddress(concatenated);
      if (response.results.length) {
        dispatch(
          getListingDataByIdSuccess({
            data: {
              ...listingData,
              latitude: response.results[0].geometry.location.lat,
              longitude: response.results[0].geometry.location.lng,
            },
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [listingData]);
  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"}>
      <FtsColumn size={{ xs: 12 }} mt={"2xl"}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          MAP PREVIEW
        </Span>
      </FtsColumn>
      <FtsColumn size={{ xs: 3 }}>
        <Input
          name={"latitude"}
          value={listingData.latitude}
          onChange={onChange}
          label={"Latitude*"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 3 }}>
        <Input
          name={"longitude"}
          value={listingData.longitude}
          onChange={onChange}
          label={"Longitude*"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 3 }}></FtsColumn>
      <FtsColumn size={{ xs: 3 }} align={"flex-end"} display={"flex"}>
        <Button
          onClick={fetchPosition}
          type={ButtonTypes.outlined}
          color={ButtonColorsValue.accent}
          size={ButtonSizes.sm}
          label={"Convert Address to Map"}
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 12 }}>
        <GoogleMap center={center} />
      </FtsColumn>
    </FtsRow>
  );
};

const MapContainer = ({ google, center }) => {
  const listingData = useSelector(selectListingData);
  const dispatch = useDispatch();

  const onDragend = useCallback(
    (_, marker) => {
      const lat = marker.position.lat();
      const lng = marker.position.lng();

      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            latitude: lat,
            longitude: lng,
          },
        }),
      );
    },
    [listingData],
  );
  return (
    <Map
      google={google}
      zoom={15}
      containerStyle={containerStyle}
      initialCenter={center}
      center={center}
    >
      <Marker
        draggable
        name={"Current location"}
        position={center}
        onDragend={onDragend}
      />
    </Map>
  );
};

const GoogleMap = GoogleApiWrapper({
  apiKey: appConfig.googleApiKey,
})(MapContainer);
