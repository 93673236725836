import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoadingListingData,
  selectListingData,
  selectListingError,
} from "../../../redux/reducers/v2/listing";
import { selectSideOutData } from "../../../redux/reducers/v2/sideOut";
import { SideOutListingPreviewFooter } from "./SideOutListingPreviewFooter";
import { SideOutListingPreviewHeader } from "./SideOutListingPreviewHeader";
import { SideOutListingPreviewContent } from "./SideOutListingPreviewContent";
import { getListingDataByIdRequest } from "../../../redux/actions/v2/listings";
import { SideOutErrorState } from "@find-truck-service/ui/src/components/react/SideOut/Components/SideOutErrorState";
import { SideOutLoadingState } from "@find-truck-service/ui/src/components/react/SideOut/Components/SideOutLoadingState";

export const SideOutListingPreview = () => {
  const dispatch = useDispatch();
  const listingData = useSelector(selectListingData);
  const sideOutData = useSelector(selectSideOutData);
  const error = useSelector(selectListingError);
  const isLoading = useSelector(selectIsLoadingListingData);

  useEffect(() => {
    if (sideOutData?.listingId)
      dispatch(
        getListingDataByIdRequest({
          data: { id: sideOutData.listingId },
        }),
      );
  }, [sideOutData]);

  if (isLoading) {
    return <SideOutLoadingState />;
  }

  if (error) {
    return (
      <SideOutErrorState
        error={error}
        onClick={() =>
          dispatch(
            getListingDataByIdRequest({ data: { id: sideOutData.listingId } }),
          )
        }
      />
    );
  }

  if (!listingData) {
    return (
      <SideOutErrorState
        error={error}
        onClick={() =>
          dispatch(
            getListingDataByIdRequest({ data: { id: sideOutData.listingId } }),
          )
        }
      />
    );
  }
  return (
    <>
      <SideOutListingPreviewHeader />
      <SideOutListingPreviewContent />
      <SideOutListingPreviewFooter />
    </>
  );
};
