import styled from "styled-components";
import { device } from "../../../../../../types/ui/breakpoints";
export const StyledImg = styled.img `
  width: ${({ width }) => `${width}px`};
  min-width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  object-fit: cover;

  @media ${device.xs} {
    width: 100%;
    min-width: 100%;
    min-height: fit-content;
  }
`;
