export const FtsTableHeadSizesValues = {
    md: "md",
    sm: "sm",
};
export const FtsTableHeadTypesValues = {
    checkbox: "checkbox",
    text: "text",
};
export const FtsTableHeadVariantsValues = {
    Checkbox: "Checkbox",
    Status: "Status",
    "Sent On": "Sent On",
    "Sent By": "Sent By",
    LID: "LID",
    Company: "Company",
    Street: "Street",
    City: "City",
    ST: "ST",
    Type: "Type",
    Rejected: "Rejected",
    Times: "Times",
    Action: "Action",
};
export const FtsTableHeadWidth = {
    Checkbox: "52px",
    Status: "104px",
    "Sent On": "120px",
    "Sent By": "104px",
    LID: "88px",
    Company: "216px",
    Street: "200px",
    City: "144px",
    ST: "80px",
    Type: "120px",
    Rejected: "120px",
    Times: "120px",
    Action: "112px",
};
