import React from "react";
import { Chips } from "../../Chips";
import { Spacing } from "../../../../../../types/ui/spacing";
import { ColorsValue } from "../../../../../../types/ui/colors";
import { FtsChipsSizes, FtsChipsVariants } from "@find-truck-service/types/ui/chips";
import { LineHeightVariantsValues, WeightSize } from "../../../../../../types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
export const MostPopularTag = () => {
    return (React.createElement(Chips, { label: "Most Popular", prependIcon: { iconName: IconNames.Star }, wrapperProps: {
            bg: ColorsValue["red-brand-500"],
            border: `1px solid ${ColorsValue["red-brand-500"]}`,
            py: "2xs",
            pl: "2xs",
            pr: "sm",
            height: Spacing["3xl"],
        }, labelProps: {
            lineHeightVariant: LineHeightVariantsValues.relaxed,
            color: ColorsValue["greyscale-0"],
            weight: WeightSize.medium,
        }, size: FtsChipsSizes.sm, variant: FtsChipsVariants.contained, color: ColorsValue["red-brand-500"] }));
};
