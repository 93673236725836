import React, { Component } from "react";

import { Button } from "reactstrap";
import FTSModal from "../../../../../components/fts/fts-modal";
import CloseButton from "../../../../../components/fts/CloseButton";
import Icon from "../../../../../components/fts/Icons";
import "../../../../../assets/scss/components/fts/payment-modals.scss";
import Divider from "../../../../../components/fts/Divider";
import PaymentMethods from "../../../../../components/fts/account-cards";
import ConfirmationModal from "../../../../../components/fts/confirmation-modal";
import { toast } from "react-toastify";
import {
  payWithStripe,
  payWithStripeTokenClient,
} from "../../../../../redux/actions/payments";
import { setPromoCode } from "../../../../../redux/actions/promocodes";
import {
  resetDiscount,
  setAllInvoicesCount,
  setAppliedCreditAmount,
  setCreditCardData,
  setIsCreditApplied,
} from "../../../../../redux/actions/invoices";
import { createActivity } from "../../../../../redux/actions/activities";
import { addUserActivity } from "../../../../../redux/actions/user-activities";
import { getUserCards } from "../../../../../redux/actions/users";
import { setUpgradesUnreadCount } from "../../../../../redux/actions/upgrades";
import { connect } from "react-redux";
import { checkPermissions } from "../../../../../utility";
import { PERMISSIONS, SUB_PERMISSIONS } from "../../../../../constants";

class PaymentMethodModal extends Component {
  state = {
    creditCardConfirmationModal: false,
    creditCardForPayment: null,
  };
  handleCeditCardConfirmationModal = (card) => {
    this.setState({
      creditCardConfirmationModal: true,
      creditCardForPayment: card,
    });
  };
  payWithCard = (card) => {
    this.setState({ inProgress: true });
    let { selectedInvoices, discountAmount, promoCode, totalAmount } =
      this.props;

    // let totalAmount = 0;

    let payload = {
      subscription: this.state.activeTab === "recurring",
      promoCodeId: promoCode?.id ? promoCode.id : null,
      amount: totalAmount,
      invoices: selectedInvoices.map(
        ({
          id,
          amount,
          promoCode,
          adId,
          userId,
          invoiceAmount,
          dueAt,
          type,
          invoiceNumber,
          creditUsed,
        }) => ({
          invoiceNumber,
          id,
          amount,
          type,
          promoCode: promoCode && { id: promoCode.id },
          adId,
          userId,
          invoiceAmount,
          dueAt,
          creditUsed,
        }),
      ),
      companyName: selectedInvoices[0]["owner.companyName"],
      city: selectedInvoices[0]["owner.city"],
      state: selectedInvoices[0]["owner.state"],
      newAccount: selectedInvoices[0]["owner.newAccount"],
      ownerFirstName: selectedInvoices[0]["owner.firstName"],
      ownerLastName: selectedInvoices[0]["owner.lastName"],
      credit:
        this.props.appliedCreditAmount < 0 ? 0 : this.props.appliedCreditAmount,
      email: selectedInvoices[0]["owner.email"],

      discountAmount,
      paymentToken: card.token,
      card,
    };
    this.props
      .payWithStripeTokenClient(payload)
      .then(async ({ data }) => {
        const requestedUpdate = this.props.listingData?.update?.requestedUpdate;

        const requestUpdateParsed =
          requestedUpdate && JSON.parse(requestedUpdate);

        if (
          requestUpdateParsed &&
          requestUpdateParsed.priceId < this.props.listingData?.priceId
        ) {
          this.props.setUpgradesUnreadCount(this.props.upgradesUnReadCount + 1);
        }
        if (
          this.props.listingDataRef?.getListingInfo &&
          this.props.listingData?.id
        ) {
          this.props.listingDataRef.getListingInfo(this.props.listingData.id);
        }
        this.props.setPaymentStep(
          "paymentProccessed",
          data.result.id,
          data.result.created,
        );
        this.props.setAppliedCreditAmount(0);
        this.props.setIsCreditApplied(false);
        this.props.setLastPaymentModalTitle("Payment successfully processed");
        this.logSuccessfulPayment(data);
        this.props.gridApi &&
          this.props.gridApi.refreshTable &&
          this.props.gridApi.refreshTable();
        this.props.setPromoCode(null);
        this.props.resetDiscount();
        this.props.setCreditCardData(null);
        this.setState({
          creditCardForPayment: null,
        });
      })
      .catch(async (e) => {
        console.log("ERROR: ", e);

        toast.error(e.response?.data?.message || e.response?.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        this.setState({
          inProgress: false,
        });
      });
  };

  render() {
    const { isOpen, title, closeModal, onClose, setPaymentStep } = this.props;

    const havePermissionsForPayWithExistingCard = checkPermissions(
      [PERMISSIONS.ADDITIONAL_FEATURES],
      this.props.adminUserPermissions,
      [SUB_PERMISSIONS[PERMISSIONS.ADDITIONAL_FEATURES].PAY_WITH_EXISTING_CARD],
    );

    return (
      <FTSModal
        {...{ isOpen, title, closeModal, onClose }}
        modalClasses={"fts-payment-modal"}
        centered={true}
        style={{ maxWidth: "610px" }}
      >
        <div className="payment-modal-body">
          <span className="payment-modal-body-wrapper">
            <span className="payment-method-title">
              <h3>Select payment method</h3>
              <CloseButton onClick={closeModal} />
            </span>
            <span className="payment-method-buttons">
              <Button
                onClick={() => {
                  setPaymentStep("manual");
                }}
                color="secondary"
                className="ach-button"
              >
                <span className="button-body ach">
                  <Icon name="File" size="24" />
                  Log payment
                </span>
              </Button>
              <Button
                onClick={() => {
                  setPaymentStep("creditCard");
                }}
                color="primary"
                className="card-button"
              >
                <span className="button-body">
                  <Icon color={"#fff"} name="Card" size="24" />
                  Proceed with Credit Card
                </span>
              </Button>
            </span>

            {havePermissionsForPayWithExistingCard && (
              <>
                <Divider textColor="primary" position="left">
                  Previously used cards
                </Divider>
                <PaymentMethods
                  wrapperClassName={"cursor-pointer"}
                  payWithCard={this.handleCeditCardConfirmationModal}
                  hideRemoveBtn
                  cards={this.props.accountData?.userCards || []}
                />
              </>
            )}
          </span>
        </div>
        <ConfirmationModal
          title="Confirmation"
          message={"Are you sure you want to pay with this card?"}
          closeModal={() =>
            this.setState({
              creditCardConfirmationModal: false,
              creditCardForPayment: null,
            })
          }
          confirmModal={() => {
            this.setState(
              {
                creditCardConfirmationModal: false,
              },
              () => {
                this.payWithCard(this.state.creditCardForPayment);
              },
            );
          }}
          isOpen={this.state.creditCardConfirmationModal}
        />
      </FTSModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedInvoices: state.invoices.selectedInvoices,
    totalAmount: state.invoices.totalAmount,
    appliedCreditAmount: state.invoices.appliedCreditAmount,
    discountAmount: state.invoices.discountAmount,
    promoCode: state.promoCodes.promoCode,
    creditCardData: state.invoices.creditCardData,
    activitiesTypes: state.essentialLists.activitiesTypes,
    userData: state.auth.login.values,
    invoicesCount: state.invoices.allInvoicesCount,
    upgradesUnReadCount: state.upgrades.upgradesUnReadCount,
    adminUserPermissions: state.auth.login?.values.loggedInUser.permissions,
  };
};

const mapDispatchToProps = {
  payWithStripe,
  setPromoCode,
  resetDiscount,
  setCreditCardData,
  createActivity,
  addUserActivity,
  setAppliedCreditAmount,
  setIsCreditApplied,
  setAllInvoicesCount,
  payWithStripeTokenClient,
  getUserCards,
  setUpgradesUnreadCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodModal);
