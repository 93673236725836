export const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const ALL_DAY = "24 hrs";
export const BY_APPOINTMENT = "By Appointment";
export const CLOSED = "Closed";

export const dontShowWorkingHoursToDropdown = [ALL_DAY, BY_APPOINTMENT, CLOSED];
export const weekDayLabels = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};

export const weekDaysFullName = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const SUNDAY_SHORT = "Sun";
export const SUNDAY = "sunday";
export const SUNDAY_FROM = "24 hrs";
export const FROM_DEFAULT = "5 AM";
export const TO_DEFAULT = "4 PM";

export const descriptionPlaceholder =
  "Write your business description/details here";

export const MINIMUM_CHARGE_FIELD_ID = 5;

export const minimumChargeOptions = [
  { label: "30 min", value: "30 min" },
  { label: "1h", value: "1h" },
  { label: "1h 30 min", value: "1h 30 min" },
  { label: "2h", value: "2h" },
  { label: "2h 30 min", value: "2h 30 min" },
];

export const mapRelatedFields = ["address", "city", "state", "zip"];

export const NO_RESULTS_ERROR = "No results found";
export const GEOLOCATION_ERROR_MESSAGE =
  "No results found for the given address. Please check your address and try again.";

export const listingDraftSteps = {
  BUSINESS_DETAILS: 1,
  CHOOSE_LISTING_TYPE: 2,
  PAYMENT_OVERVIEW: 3,
};

export const mapTypes = {
  HYBRID: "hybrid",
  ROADMAP: "roadmap",
};

export const alwaysOpenWorkingHours = {
  monday: "24 hrs",
  tuesday: "24 hrs",
  wednesday: "24 hrs",
  thursday: "24 hrs",
  friday: "24 hrs",
  saturday: "24 hrs",
  sunday: "24 hrs",
};
