export const ADS_PRICES_LABELS = {
    1: "Premium",
    2: "Standard",
    3: "Basic Plus",
    4: "Basic",
    5: "Free",
};
export var ADS_PRICES_ENUM;
(function (ADS_PRICES_ENUM) {
    ADS_PRICES_ENUM[ADS_PRICES_ENUM["PREMIUM"] = 1] = "PREMIUM";
    ADS_PRICES_ENUM[ADS_PRICES_ENUM["STANDARD"] = 2] = "STANDARD";
    ADS_PRICES_ENUM[ADS_PRICES_ENUM["BASIC_PLUS"] = 3] = "BASIC_PLUS";
    ADS_PRICES_ENUM[ADS_PRICES_ENUM["BASIC"] = 4] = "BASIC";
    ADS_PRICES_ENUM[ADS_PRICES_ENUM["FREE"] = 5] = "FREE";
})(ADS_PRICES_ENUM || (ADS_PRICES_ENUM = {}));
