import React from "react";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { FtsLink } from "@find-truck-service/ui/src/FtsLink/FtsLink";
export const Link = (props) => {
    const { children, iconName, label } = props;
    return (React.createElement(FtsLink, { ...props },
        label,
        !!iconName && React.createElement(FtsIcons, { iconName: iconName }),
        children));
};
