export const inlineMessageTypes = {
    info: "info",
    success: "success",
    warning: "warning",
    error: "error",
    completed: "completed",
};
export const inlineMessageSizes = {
    sm: "sm",
    md: "md",
};
