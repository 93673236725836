import React from "react";
import { StoreStatus } from "./StoreStatus";
import { PromotionTag } from "./PromotionTag";
import { AuthorizedTag } from "./AuthorizedTag";
import { FREE_LISTING_PRICE_ID } from "../constants";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
export const ListingBasicInfoLg = (props) => {
    const { listingName, showAuthorizedTag, showPromotionTag, interstate, milemarker, mobileRoadServiceOpen } = props;
    const { alwaysOpen, workingHours, specialHours, towingRecoveryOpen, listingCurrentTime, onClickHours } = props;
    const { address, city, state, inCity, showDistance, distance, compassDirection, priceId } = props;
    const { website, onWebsiteClick, showWebsite } = props;
    const miles = distance?.toFixed(2);
    const isFree = priceId === FREE_LISTING_PRICE_ID;
    const showBadges = showAuthorizedTag || showPromotionTag;
    const showWebsiteCTA = showWebsite && website && onWebsiteClick;
    const locationInfo = `${interstate || ""}${interstate && milemarker ? " & " : ""}${milemarker || ""}`;
    return (React.createElement(FtsWrapper, { flex: 1, gap: "lg", display: "flex", direction: "column" },
        React.createElement(FtsWrapper, { display: "flex", gap: "lg", wrap: "wrap" },
            React.createElement(Span, { weight: isFree ? WeightSize.regular : WeightSize.semibold, variant: VariantsValues[isFree ? "text-xs" : "text-sm"], color: ColorsValue["greyscale-800"] }, listingName),
            showBadges && (React.createElement(FtsWrapper, { display: "flex", gap: "lg" },
                showAuthorizedTag && React.createElement(AuthorizedTag, null),
                showPromotionTag && React.createElement(PromotionTag, null)))),
        React.createElement(FtsWrapper, { display: "flex", gap: "lg", align: "center", wrap: "wrap" },
            showDistance && !inCity && miles && (React.createElement(Span, { color: ColorsValue["greyscale-800"], variant: VariantsValues["text-3xs"], weight: WeightSize.regular }, `${miles} mi ${compassDirection}`)),
            React.createElement(FtsWrapper, { display: "flex", gap: "2xs", align: "center" },
                React.createElement(FtsIcons, { iconName: IconNames.MapPin, svgProps: { width: "16px", height: "16px" } }),
                React.createElement(Span, { color: ColorsValue["greyscale-800"], variant: VariantsValues["text-3xs"], weight: WeightSize.regular }, `${address}, ${city}, ${state}`)),
            React.createElement(StoreStatus, { alwaysOpen: alwaysOpen, workingHours: workingHours, specialHours: specialHours, mobileRoadServiceOpen: mobileRoadServiceOpen, towingRecoveryOpen: towingRecoveryOpen, listingCurrentTime: listingCurrentTime, onClickHours: onClickHours }),
            (interstate || milemarker) && (React.createElement(FtsWrapper, { display: "flex", gap: "2xs", align: "center" },
                React.createElement(FtsIcons, { iconName: IconNames.Interstate, svgProps: { width: "16px", height: "16px" } }),
                React.createElement(Span, { color: ColorsValue["greyscale-800"], variant: VariantsValues["text-3xs"], weight: WeightSize.regular }, locationInfo))),
            showWebsiteCTA && (React.createElement(Button, { size: ButtonSizes.xs, type: ButtonTypes.text, color: ButtonColorsValue.neutral, onClick: onWebsiteClick, iconName: IconNames.ExternalLink, label: "Website" })))));
};
