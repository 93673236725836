import React, { useCallback } from "react";
import { Modal } from "@find-truck-service/ui/src/components/react/Modal";
import { ModalContentComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalContentComponent";
import { ModalHeaderComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalHeaderComponent";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { WeightSize } from "@find-truck-service/types/ui/text";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { Divider } from "@find-truck-service/ui/src/components/react/Divider";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
export const RemapAdsContact = {
    phoneNumber: "Phone (primary)",
    phoneNumberSecondary: "Phone (secondary)",
    afterHours: "Phone (after hours)",
    fax: "Fax",
    website: "Website",
};
const showCallButtonForKeys = {
    phoneNumber: true,
    phoneNumberSecondary: true,
    afterHours: true,
    fax: false,
};
const ModalComponent = (props) => {
    const { data } = props;
    const handleClickCall = useCallback((number) => {
        if (document) {
            document.location.href = `tel:${number}`;
        }
    }, []);
    if (!data)
        return React.createElement(EmptyComponent, null);
    let remappedKeys = Object.keys(RemapAdsContact).filter((key) => !!data[key]);
    remappedKeys = remappedKeys.filter((key) => key !== "website");
    return (React.createElement(ModalContentComponent, null,
        React.createElement(FtsWrapper, { display: "flex", direction: "column" }, remappedKeys.map((key, index) => {
            return (React.createElement(React.Fragment, null,
                React.createElement(FtsWrapper, { display: "flex", justify: "space-between", align: "center" },
                    React.createElement(FtsWrapper, { display: "flex", direction: "column", gap: "3xs" },
                        React.createElement(Span, { variant: "text-2xs", color: ColorsValue["greyscale-500"], weight: WeightSize.regular }, RemapAdsContact[key]),
                        React.createElement(Span, { variant: "text-2xs", color: ColorsValue["greyscale-800"], weight: WeightSize.medium }, data[key])),
                    showCallButtonForKeys[key] && (React.createElement(Button, { iconName: IconNames.Phone, onClick: () => handleClickCall(data[key]), size: ButtonSizes.md, type: ButtonTypes.contained, color: ButtonColorsValue.accent, label: "Call" }))),
                remappedKeys[index + 1] && React.createElement(Divider, { verticalOffset: "2xl" })));
        }))));
};
export const AdsContactsModal = (props) => {
    const { isOpen, onClose, data } = props;
    return (React.createElement(Modal, { size: "xs", isOpen: isOpen, onBackdropClick: onClose, modalWrapperProps: { isOpen }, modalContentComponent: () => React.createElement(ModalComponent, { data: data }), modalHeaderComponent: () => React.createElement(ModalHeaderComponent, { title: "Contact Info", onClose: onClose }) }));
};
