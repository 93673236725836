import React, { useCallback } from "react";
import Icon from "../../fts/Icons";
import { useDispatch } from "react-redux";
import { Span } from "../dist/ui/src/FtsText";
import { ColorsValue } from "../dist/types/ui/colors";
import { FtsWrapper } from "../dist/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { closeSubSideOutAction } from "../../../redux/actions/v2/sideOut";
import { FtsSideOutWrapperHeader } from "../dist/ui/src/FtsSideOut/FtsSideOutWrapperHeader";

export const SideOutSendListingHeader = () => {
  const dispatch = useDispatch();

  const hideSidebar = useCallback(() => dispatch(closeSubSideOutAction()), []);

  return (
    <FtsSideOutWrapperHeader
      bg={ColorsValue["greyscale-100"]}
      py={"lg"}
      px={"3xl"}
    >
      <FtsWrapper>
        <Span
          variant={VariantsValues["text-sm"]}
          color={ColorsValue["greyscale-800"]}
        >
          Send listing
        </Span>
      </FtsWrapper>

      <FtsWrapper display="flex" align={"center"}>
        <Icon onClick={hideSidebar} size={24} name={"Close"} />
      </FtsWrapper>
    </FtsSideOutWrapperHeader>
  );
};
