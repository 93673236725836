import { alwaysOpenWorkingHours } from "../constants";
export const getWorkingHoursModalData = (listingData) => {
    let workingHours = {
        monday: listingData.workingHours?.monday || "",
        tuesday: listingData.workingHours?.tuesday || "",
        wednesday: listingData.workingHours?.wednesday || "",
        thursday: listingData.workingHours?.thursday || "",
        friday: listingData.workingHours?.friday || "",
        saturday: listingData.workingHours?.saturday || "",
        sunday: listingData.workingHours?.sunday || "",
    };
    if (listingData.alwaysOpen)
        workingHours = alwaysOpenWorkingHours;
    return {
        workingHours,
        specialHours: listingData.specialHours,
        towingRecoveryOpen: listingData.towingRecoveryOpen,
        mobileRoadServiceOpen: listingData.mobileRoadServiceOpen,
    };
};
export const getContactModalData = (listingData) => ({
    fax: listingData.fax,
    afterHours: listingData.afterHours,
    phoneNumber: listingData.phoneNumber,
    phoneNumberSecondary: listingData.phoneNumberSecondary,
});
