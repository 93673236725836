import React from "react";
import styled from "styled-components";
import { FtsWrapper } from "../../../../FtsWrapper/FtsWrapper";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { IconNames } from "../../../../FtsIcons/Types/iconNames";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { Divider } from "@find-truck-service/ui/src/components/react/Divider";
import { ButtonColorsValue, ButtonTypes } from "@find-truck-service/types/ui/button";
export const ListingBottomActions = (props) => {
    const { onClickStatus, onClickNotes, onClickRates, onClickServices, onClickRatings, onClickShare, onClickReport, direction, size, } = props;
    return (React.createElement(FtsWrapper, { flex: 1, py: "2xs", wrap: "nowrap", display: "flex", align: "center", justify: "center", bg: ColorsValue["greyscale-50"], px: { xs: "0", sm: "0", md: "lg", lg: "4xl" } },
        React.createElement(Button, { flex: 1, px: "sm", size: size, label: "Status", direction: direction, type: ButtonTypes.text, onClick: onClickStatus, iconName: IconNames.Status, color: ButtonColorsValue.neutral }),
        React.createElement(CTADivider, null),
        React.createElement(Button, { flex: 1, px: "sm", size: size, label: "Notes", direction: direction, onClick: onClickNotes, type: ButtonTypes.text, iconName: IconNames.Note, color: ButtonColorsValue.neutral }),
        React.createElement(CTADivider, null),
        React.createElement(Button, { flex: 1, px: "sm", size: size, label: "Rates", direction: direction, onClick: onClickRates, type: ButtonTypes.text, iconName: IconNames.Rates, color: ButtonColorsValue.neutral }),
        React.createElement(CTADivider, null),
        React.createElement(Button, { flex: 1, px: "sm", size: size, label: "Services", direction: direction, type: ButtonTypes.text, onClick: onClickServices, iconName: IconNames.Settings, color: ButtonColorsValue.neutral }),
        React.createElement(CTADivider, null),
        React.createElement(Button, { flex: 1, px: "sm", size: size, label: "Ratings", direction: direction, type: ButtonTypes.text, onClick: onClickRatings, iconName: IconNames.Rating, color: ButtonColorsValue.neutral }),
        React.createElement(CTADivider, null),
        React.createElement(Button, { flex: 1, px: "sm", size: size, label: "Share", direction: direction, onClick: onClickShare, type: ButtonTypes.text, iconName: IconNames.Share, color: ButtonColorsValue.neutral }),
        React.createElement(CTADivider, null),
        React.createElement(Button, { flex: 1, px: "sm", size: size, label: "Report", direction: direction, type: ButtonTypes.text, onClick: onClickReport, iconName: IconNames.Report, color: ButtonColorsValue.neutral })));
};
const CTADivider = styled(Divider).attrs({ size: "2xl", color: "greyscale-100", width: Spacing["4xs"] }) ``;
