import React, { useEffect } from "react";
import { FtsModalWrapper } from "@find-truck-service/ui/src/FtsModal/FtsModalWrapper";
import { ConfirmationDialogContainer } from "./styled/ConfirmationDialogContainer";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import styled from "styled-components";
import { ConfirmationDialogFooterComponent, } from "./components/ConfirmationDialogFooter";
import { ConfirmationDialogHeaderComponent } from "./components/ConfirmationDialogHeader";
import { FtsRow } from "../../../FtsRow/FtsRow";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
const ConfirmationDialoglSize = {
    xs: {
        size: { xs: 12, sm: 8, md: 8, lg: 6 },
        offset: { xs: 0, sm: 2, md: 2, lg: 3 },
    },
    sm: {
        size: { xs: 12, sm: 10, md: 10, lg: 8 },
        offset: { xs: 0, sm: 1, md: 1, lg: 2 },
    },
    md: {
        size: { xs: 12, sm: 12, md: 12, lg: 10 },
        offset: { xs: 0, sm: 0, md: 0, lg: 1 },
    },
    lg: {
        size: { xs: 12, sm: 12, md: 12, lg: 12 },
    },
};
export const ConfirmationDialog = (props) => {
    useEffect(() => {
        if (props.isOpen) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "unset";
        }
    }, [props.isOpen]);
    const onCloseInternal = (e) => {
        if (e.target.id === "backdrop_portal_id" && typeof props.onBackdropClick === "function") {
            return props.onBackdropClick(e);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FtsModalWrapper, { id: "backdrop_portal_id", isOpen: props.isOpen, onClick: onCloseInternal },
            React.createElement(ConfirmationDialogContainer, { isOpen: props.isOpen },
                React.createElement(FtsRow, null,
                    React.createElement(FtsModal, { ...ConfirmationDialoglSize[props.size], bg: ColorsValue["greyscale-0"], display: "flex", direction: "column" },
                        React.createElement(ConfirmationDialogHeaderComponent, { title: props.title, onClose: props.onClose, iconName: props.iconName, subtitle: props.subtitle, iconProps: props.iconProps }),
                        React.createElement(ConfirmationDialogFooterComponent, { containedButton: props.containedButton, outlinedButton: props.outlinedButton, textButton: props.textButton })))))));
};
const FtsModal = styled(FtsColumn) ``;
