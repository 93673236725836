import React, { useState } from "react";
import { StyledImg } from "../styled/StyledImage";
import listingItemImagePlaceHolderPremium from "@find-truck-service/ui/src/assets/listingPlaceHolders/premium.png";
import listingItemImagePlaceHolderStandard from "@find-truck-service/ui/src/assets/listingPlaceHolders/standard.png";
import listingItemImagePlaceHolderBasicplus from "@find-truck-service/ui/src/assets/listingPlaceHolders/basicplus.png";
const ListingItemImagePlaceHolders = {
    1: listingItemImagePlaceHolderPremium.toString(),
    2: listingItemImagePlaceHolderStandard.toString(),
    3: listingItemImagePlaceHolderBasicplus.toString(),
    4: "",
    5: "",
};
export const ListingItemImage = (props) => {
    const { width, height, priceId, src } = props;
    const [internalSrc, setInternalSrc] = useState(src || ListingItemImagePlaceHolders[priceId]);
    return (React.createElement(StyledImg, { width: width, height: height, src: internalSrc, alt: "ADS_IMAGE", onError: () => setInternalSrc(ListingItemImagePlaceHolders[priceId]), onErrorCapture: () => setInternalSrc(ListingItemImagePlaceHolders[priceId]) }));
};
