import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { emailAndPhoneNumberValidator } from "./validators/emailAndPhoneNumberValidator";
import { FtsSideOutWrapperFooter } from "../dist/ui/src/FtsSideOut/FtsSideOutWrapperFooter";
import { sendProofRequestAction } from "../../../redux/actions/v2/proof";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import {
  selectSelectedEmailsContact,
  selectSelectedPhoneNumbersContact,
  selectShareProvidersData,
} from "../../../redux/reducers/v2/shareProviders";
import { setShareProvidersInvalidFormFields } from "../../../redux/actions/v2/shareProviders";

export const SideOutSendProofFooter = () => {
  const dispatch = useDispatch();
  const selectedEmailsContact = useSelector(selectSelectedEmailsContact);
  const selectedPhoneNumbersContact = useSelector(
    selectSelectedPhoneNumbersContact,
  );
  const shareProvider = useSelector(selectShareProvidersData);
  const resendProof = useCallback(async () => {
    try {
      await emailAndPhoneNumberValidator.validate(shareProvider);
      dispatch(sendProofRequestAction());
    } catch (e) {
      console.log(e);
      if (e.name !== "ValidationError") return;
      const data = { [e.path]: e.errors[0] };
      dispatch(setShareProvidersInvalidFormFields({ data }));
    }
  }, [shareProvider]);

  return (
    <FtsSideOutWrapperFooter py={"lg"} px={"3xl"}>
      <FtsColumn size={{ xs: 12 }} textAlign={"end"}>
        <FtsWrapper
          gap={"lg"}
          display={"flex"}
          align={"center"}
          justify={"flex-end"}
        >
          <Button
            size={ButtonSizes.sm}
            onClick={resendProof}
            label={"Send Proof"}
            iconName={IconNames.Send}
            type={ButtonTypes.contained}
            color={ButtonColorsValue.accent}
            disabled={
              !selectedEmailsContact.length &&
              !selectedPhoneNumbersContact.length
            }
          />
        </FtsWrapper>
      </FtsColumn>
    </FtsSideOutWrapperFooter>
  );
};
