import { useDispatch, useSelector } from "react-redux";
import {
  selectShareProvidersData,
  selectShareProvidersInvalidFormFields,
} from "../../../../redux/reducers/v2/shareProviders";
import React, { useCallback } from "react";
import {
  getShareProvidersSuccessAction,
  setShareProvidersInvalidFormFields,
} from "../../../../redux/actions/v2/shareProviders";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import Checkbox from "@find-truck-service/ui/src/components/react/Checkbox";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { PhoneNumberInput } from "@find-truck-service/ui/src/components/react/PhoneNumberInput";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import isObject from "lodash/isObject";

export const ShareProvidersPhoneNumbers = () => {
  const dispatch = useDispatch();
  const shareProviders = useSelector(selectShareProvidersData);
  const invalidFormFields = useSelector(selectShareProvidersInvalidFormFields);

  const handleSelect = useCallback(
    (key, index) => {
      const newContacts = [...shareProviders[key]];
      newContacts[index].selected = !newContacts[index].selected;
      dispatch(
        getShareProvidersSuccessAction({
          data: {
            ...shareProviders,
            [key]: newContacts,
          },
        }),
      );
    },
    [shareProviders],
  );

  const onChange = useCallback(
    (event) => {
      dispatch(
        getShareProvidersSuccessAction({
          data: {
            ...shareProviders,
            customPhoneNumber: event.target.value,
          },
        }),
      );
    },
    [shareProviders],
  );

  const clearErrors = useCallback(() => {
    dispatch(setShareProvidersInvalidFormFields({ data: {} }));
  }, [dispatch]);
  return (
    <FtsWrapper display={"flex"} direction={"column"} gap={"lg"}>
      <Span
        mb="2xs"
        variant="text-xs"
        weight={WeightSize.medium}
        color={ColorsValue["greyscale-800"]}
      >
        By Text
      </Span>
      <FtsWrapper display={"flex"} direction={"row"} gap={"lg"}>
        <FtsWrapper gap="sm" display="flex" direction="column" flex={1}>
          <Span
            pb="2xs"
            variant="text-2xs"
            weight={WeightSize.medium}
            color={ColorsValue["greyscale-800"]}
          >
            Account phones
          </Span>
          {shareProviders.userPhoneNumbersContacts.map(
            ({ phoneNumber, selected }, i) => (
              <Checkbox
                key={i}
                size={CheckboxSizes.sm}
                label={phoneNumber}
                checked={selected}
                onClick={() => handleSelect("userPhoneNumbersContacts", i)}
              />
            ),
          )}
        </FtsWrapper>

        {shareProviders.listingPhoneNumbersContacts.length ? (
          <FtsWrapper gap="sm" display="flex" direction="column" flex={1}>
            <Span
              pb="2xs"
              variant="text-2xs"
              weight={WeightSize.medium}
              color={ColorsValue["greyscale-800"]}
            >
              Listing phones
            </Span>
            {shareProviders.listingPhoneNumbersContacts.map(
              ({ phoneNumber, selected }, i) => (
                <Checkbox
                  key={i}
                  size={CheckboxSizes.sm}
                  label={phoneNumber}
                  checked={selected}
                  onClick={() => handleSelect("listingPhoneNumbersContacts", i)}
                />
              ),
            )}
          </FtsWrapper>
        ) : (
          <EmptyComponent />
        )}
      </FtsWrapper>
      <PhoneNumberInput
        onBlur={clearErrors}
        size={InputSizes.sm}
        label="Receiving phone"
        onChange={onChange}
        name="customPhoneNumber"
        variant={InputVariants.outlined}
        value={shareProviders.customPhoneNumber}
        errorMessage={
          isObject(invalidFormFields) && invalidFormFields["customPhoneNumber"]
        }
      />
    </FtsWrapper>
  );
};
