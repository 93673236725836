import styled from "styled-components";
import { FtsWrapper } from "../FtsWrapper/FtsWrapper";
import { ZIndexValues } from "@find-truck-service/types/constants/zIndexValues";
export const FtsLoaderIndicatorWrapper = styled(FtsWrapper) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${ZIndexValues.LOADER_WRAPPER};
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 2s ease;
  opacity: 1;
`;
export const FtsFullPageLoaderIndicatorWrapper = styled(FtsWrapper) `
  left: 0;
  bottom: 0;
  width: 100vw;
  opacity: 0.3;
  height: 100vh;
  z-index: ${ZIndexValues.LOADER_WRAPPER};
  display: flex;
  position: fixed;
  background: #eceff1;
  align-items: center;
  justify-content: center;
`;
