import React from "react";
import { LeavingFTSDialog } from "@find-truck-service/ui/src/components/react/LeavingFTSDialog";
import { ListingDetailsModal } from "@find-truck-service/ui/src/components/react/ListingDetailsModal";
import { AdsContactsModal } from "@find-truck-service/ui/src/components/react/AdsContactsModal";
import { AdsWorkingHoursModal, } from "@find-truck-service/ui/src/components/react/AdsWorkingHoursModal";
import { AdsBenefitsModal } from "@find-truck-service/ui/src/components/react/AdsBenefitsModal";
export const ListingItemModals = (props) => {
    const { contactModalData, closeContactModal } = props;
    const { showBenefits, closeBenefits, onSelect } = props;
    const { showLeavingFTSModal, closeLeavingFTSModal } = props;
    const { closeWorkingHoursModal, workingHoursModalData } = props;
    const { openDetailsModal, listingData, closeListingDetailsModal } = props;
    return (React.createElement(React.Fragment, null,
        listingData?.website && (React.createElement(LeavingFTSDialog, { website: listingData?.website, showLeavingFTSModal: showLeavingFTSModal, closeLeavingFTSModal: closeLeavingFTSModal })),
        React.createElement(AdsWorkingHoursModal, { data: workingHoursModalData, isOpen: !!workingHoursModalData, onClose: closeWorkingHoursModal }),
        React.createElement(AdsBenefitsModal, { onSelect: onSelect, isOpen: showBenefits, onClose: closeBenefits, adType: listingData?.priceId }),
        React.createElement(AdsContactsModal, { data: contactModalData, isOpen: !!contactModalData, onClose: closeContactModal }),
        React.createElement(ListingDetailsModal, { isOpen: openDetailsModal, listingData: listingData, onClose: closeListingDetailsModal })));
};
