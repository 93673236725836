import React from "react";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { weekDays } from "@find-truck-service/ui/src/components/react/AdsWorkingHoursModal";
import { Divider } from "@find-truck-service/ui/src/components/react/Divider";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
export const ListingWorkingHoursRow = (props) => {
    const showSpecialsBox = props.towingRecoveryOpen || props.mobileRoadServiceOpen || !!props.specialHours;
    return (React.createElement(FtsWrapper, { boxShadow: "sm", px: "xl", py: "2xl", bg: ColorsValue["greyscale-0"], mb: "lg", display: "flex", direction: "column", gap: "lg" },
        React.createElement(Span, { weight: WeightSize.medium, variant: VariantsValues["text-3xs"], color: ColorsValue["blue-accent-500"] }, "HOURS"),
        showSpecialsBox ? (React.createElement(FtsWrapper, { p: "lg", border: `${Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]}`, borderRadius: "2xs", display: "flex", direction: "column", gap: "lg" },
            React.createElement(Span, { variant: "text-2xs", weight: WeightSize.semibold, color: ColorsValue["greyscale-800"] }, "Special hours"),
            props.towingRecoveryOpen ? (React.createElement(FtsWrapper, { display: "flex", gap: "lg" },
                React.createElement(FtsIcons, { iconName: "Check", svgProps: { width: SvgIconSizeValues.lg, height: SvgIconSizeValues.lg }, pathProps: { stroke: ColorsValue["blue-accent-500"] } }),
                React.createElement(Span, { variant: VariantsValues["text-2xs"] }, "24 hour Towing & Recovery"))) : (React.createElement(EmptyComponent, null)),
            props.mobileRoadServiceOpen ? (React.createElement(FtsWrapper, { display: "flex", gap: "lg" },
                React.createElement(FtsIcons, { iconName: "Check", svgProps: { width: SvgIconSizeValues.lg, height: SvgIconSizeValues.lg }, pathProps: { stroke: ColorsValue["blue-accent-500"] } }),
                React.createElement(Span, { variant: VariantsValues["text-2xs"] }, "24 hour Mobile/Road Service"))) : (React.createElement(EmptyComponent, null)),
            props.specialHours ? (React.createElement(React.Fragment, null,
                React.createElement(Divider, null),
                React.createElement(Span, { variant: VariantsValues["text-2xs"] }, props.specialHours))) : (React.createElement(EmptyComponent, null)))) : (React.createElement(EmptyComponent, null)),
        React.createElement(FtsWrapper, { p: "lg", border: `${Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]}`, borderRadius: "2xs", display: "flex", direction: "column", gap: "lg" },
            React.createElement(Span, { variant: "text-2xs", weight: WeightSize.semibold, color: ColorsValue["greyscale-800"] }, "Hours of operation"),
            props.alwaysOpen ? (React.createElement(Span, { variant: "text-2xs", weight: WeightSize.medium, color: ColorsValue["greyscale-800"] }, "Open 24/7")) : (React.createElement(FtsWrapper, null, weekDays.map((weekDay, index) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement(FtsWrapper, { display: "flex", justify: "space-between" },
                        React.createElement(Span, { variant: "text-2xs", weight: WeightSize.medium, color: ColorsValue["greyscale-800"] }, weekDay),
                        React.createElement(Span, { variant: "text-2xs", color: ColorsValue["greyscale-800"] }, props[weekDay.toLowerCase()])),
                    weekDays[index + 1] && React.createElement(Divider, { verticalOffset: "lg" })));
            }))))));
};
