import styled from "styled-components";
import { FtsWrapper } from "../FtsWrapper/FtsWrapper";
import { defaultStyleProps } from "../default";
export const SideOutSizesValues = {
    lg: "1264px",
    md: "968px",
    sm: "544px",
};
export const FtsSideOutContainer = styled(FtsWrapper) `
  height: 100%;
  overflow: auto;
  background: #fff;
  position: fixed;
  animation: ${(props) => (props.isOpen ? "slideIn" : "slideOut")} 0.3s ease-out;
  right: 0;
  transition:
    visibility,
    width 0.2s ease-out;
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  @keyframes slideIn {
    from {
      right: -100%;
    }
    to {
      right: 0;
    }
  }

  @keyframes slideOut {
    from {
      right: 0;
    }
    to {
      right: -100%;
    }
  }
  ${defaultStyleProps};
`;
