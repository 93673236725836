import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Divider } from "@find-truck-service/ui/src/components/react/Divider";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { transformContactFields } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Helpers";
export const ListingContactsRow = (props) => {
    const contactFields = transformContactFields(props);
    return (React.createElement(FtsWrapper, { boxShadow: "sm", px: "xl", py: "2xl", bg: ColorsValue["greyscale-0"], mb: "lg" },
        React.createElement(Span, { weight: WeightSize.medium, variant: VariantsValues["text-3xs"], color: ColorsValue["blue-accent-500"] }, "CONTACT"),
        React.createElement(FtsWrapper, { mt: "lg" }, contactFields.map(({ key, name, value }, index) => (React.createElement(FtsWrapper, { key: key },
            !!index && React.createElement(Divider, { verticalOffset: "lg" }),
            React.createElement(FtsWrapper, { display: "flex", justify: "space-between" },
                React.createElement(Span, { variant: "text-2xs", color: ColorsValue["greyscale-800"], weight: WeightSize["medium"] }, name),
                React.createElement(Span, { variant: "text-2xs", color: ColorsValue["greyscale-800"] }, value))))))));
};
