import React from "react";
import { EmptyComponent } from "../../EmptyComponent";
import { FtsWrapper } from "../../../../FtsWrapper/FtsWrapper";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
export const ContactAndStartRepairButton = (props) => {
    const { onClickContact, onStartRepair, direction, showExpandButton, showButtons, onExpand } = props;
    if (!showButtons)
        return React.createElement(EmptyComponent, null);
    if (showExpandButton)
        return (React.createElement(Button, { p: "0", width: "128px", onClick: onExpand, size: ButtonSizes.xs, type: ButtonTypes.text, label: "Expand Actions", iconName: IconNames.ChevronDown, color: ButtonColorsValue.accent }));
    return (React.createElement(FtsWrapper, { display: "flex", gap: "lg", direction: direction },
        !!onClickContact && (React.createElement(Button, { width: "128px", size: ButtonSizes.md, onClick: onClickContact, color: ButtonColorsValue.accent, type: ButtonTypes.outlined, label: "Contact" })),
        !!onStartRepair && (React.createElement(Button, { px: "0", width: "128px", size: ButtonSizes.md, onClick: onStartRepair, color: ButtonColorsValue.neutral, type: ButtonTypes.outlined, label: "Start Repair" }))));
};
