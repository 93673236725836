import React from "react";
import { ImageAndDescription } from "./ImageAndDescription";
import { ListingBasicInfoSmMd } from "./ListingBasicInfoSmMd";
import { ContactAndStartRepairButton } from "./ContactAndStartRepairButton";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { BASIC_LISTING_PRICE_ID, FREE_LISTING_PRICE_ID, } from "@find-truck-service/ui/src/components/react/ListingItem/constants";
// export interface ListingItemSmMdContentProps {
//   city: string;
//   state: string;
//   price?: string;
//   inCity: boolean;
//   address: string;
//   priceId: number;
//   distance: number;
//   imageSrc: string;
//   listingName: string;
//   interstate?: string;
//   milemarker?: string;
//   description: string;
//   onExpand: () => void;
//   showDistance: boolean;
//   expandContent: boolean;
//   compassDirection: string;
//   onClickContact: () => void;
//   onStartRepair?: () => void;
//   showPromotionTag?: boolean;
//   showAuthorizedTag?: boolean;
// }
export const ListingItemMdContent = (props) => {
    const { imageSrc, priceData, description, onStartRepair, onClickContact, onExpand, expandContent } = props;
    const isFree = priceData?.id === FREE_LISTING_PRICE_ID;
    const isFreeOrBasic = isFree || priceData?.id === BASIC_LISTING_PRICE_ID;
    const showExpandButton = isFree && !expandContent;
    const direction = isFreeOrBasic ? "row" : "column";
    return (React.createElement(FtsWrapper, { py: "3xl", px: "4xl", display: "flex", gap: "2xl", direction: "column" },
        React.createElement(FtsWrapper, { display: "flex", gap: "2xl" },
            React.createElement(ListingBasicInfoSmMd, { ...props }),
            React.createElement(ContactAndStartRepairButton, { onExpand: onExpand, direction: direction, onStartRepair: onStartRepair, onClickContact: onClickContact, showExpandButton: showExpandButton, showButtons: !!onClickContact || !!onStartRepair })),
        React.createElement(ImageAndDescription, { imageSrc: imageSrc, description: description, priceData: priceData })));
};
