import React from "react";
import { FtsTableCell } from "@find-truck-service/ui/src/FtsTableCell/FtsTableCell";
import { Badge } from "@find-truck-service/ui/src/components/react/Badge";
import { TableCellNoData } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellNoData";
import { TableCellTypesValues } from "@find-truck-service/ui/src/components/react/TableCell";
export const TableCellBadge = (props) => {
    if (!props.badgeProps?.title)
        return React.createElement(TableCellNoData, { type: TableCellTypesValues.text, ...props });
    return (React.createElement(FtsTableCell, { ...props },
        React.createElement(Badge, { ...props.badgeProps })));
};
