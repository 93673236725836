import { listingDraftSteps } from "./constants";

function formatAdsAssetsPayload(adsAssets, adId) {
  return adsAssets?.map((asset) => ({
    type: asset.type,
    path: asset.fileUrl,
    id: asset.id || null,
    size: asset.fileSize,
    adId: asset.adId || adId,
    versions: asset.versions || null,
    notifyUser: asset.notifyUser || 0,
    lastVersions: asset.lastVersions || 1,
    adminOwnerId: asset.adminOwnerId || null,
  }));
}

export function formatListingDataPayload(listing) {
  const {
    adsRates = [],
    adsAssets = [],
    adsAmenities = [],
    adsCategories = [],
    adsSubCategories = [],
    adsServiceAmenities = [],
    ...rest
  } = listing;

  const listingData = {
    draftStep: listingDraftSteps.CHOOSE_LISTING_TYPE,
    ...rest,
    latitude: rest.latitude.toString(),
    longitude: rest.longitude.toString(),
    alwaysOpen: rest.alwaysOpen ? 1 : 0,
    towingRecoveryOpen: rest.towingRecoveryOpen ? 1 : 0,
    mobileRoadServiceOpen: rest.mobileRoadServiceOpen ? 1 : 0,
  };

  return {
    listingData,
    workingHours: listing.workingHours,
    adsRates,
    adsAssets: formatAdsAssetsPayload(adsAssets, rest.id),
    adsAmenities,
    adsCategories,
    adsSubCategories,
    adsServiceAmenities,
  };
}
