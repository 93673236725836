import {
  APPROVE_PROOF_BY_ID_FAILED,
  APPROVE_PROOF_BY_ID_REQUEST,
  APPROVE_PROOF_BY_ID_SUCCESS,
  GET_PROOF_DATA_BY_AD_ID_FAILED,
  GET_PROOF_DATA_BY_AD_ID_REQUEST,
  GET_PROOF_DATA_BY_AD_ID_SUCCESS,
  CREATE_PROOF_REQUEST,
  CREATE_PROOF_SUCCESS,
  CREATE_PROOF_FAILED,
  SET_SEND_PROOF_DATA,
  SET_PROOF_DATA_INVALID_FORM_FIELDS,
  SEND_PROOF_REQUEST,
  SEND_PROOF_SUCCESS,
  SEND_PROOF_FAILED,
} from "../../../reducers/v2/proof";

export const getProofDataByAdIdRequestAction = (payload) => ({
  type: GET_PROOF_DATA_BY_AD_ID_REQUEST,
  payload,
});
export const getProofDataByAdIdSuccessAction = (payload) => ({
  type: GET_PROOF_DATA_BY_AD_ID_SUCCESS,
  payload,
});

export const getProofDataByAdIdFiledAction = (payload) => ({
  type: GET_PROOF_DATA_BY_AD_ID_FAILED,
  payload,
});

export const approveProofByIdRequestAction = (payload) => ({
  type: APPROVE_PROOF_BY_ID_REQUEST,
  payload,
});

export const approveProofByIdSuccessAction = (payload) => ({
  type: APPROVE_PROOF_BY_ID_SUCCESS,
  payload,
});

export const approveProofByIdFiledAction = (payload) => ({
  type: APPROVE_PROOF_BY_ID_FAILED,
  payload,
});

export const createProofRequestAction = (payload) => ({
  type: CREATE_PROOF_REQUEST,
  payload,
});

export const createProofSuccessAction = (payload) => ({
  type: CREATE_PROOF_SUCCESS,
  payload,
});

export const createProofFiledAction = (payload) => ({
  type: CREATE_PROOF_FAILED,
  payload,
});

export const sendProofRequestAction = (payload) => ({
  type: SEND_PROOF_REQUEST,
  payload,
});

export const sendProofSuccessAction = (payload) => ({
  type: SEND_PROOF_SUCCESS,
  payload,
});

export const sendProofFiledAction = (payload) => ({
  type: SEND_PROOF_FAILED,
  payload,
});

export const setSendProofData = (payload) => ({
  type: SET_SEND_PROOF_DATA,
  payload,
});

export const setProofDataInvalidFormFields = (payload) => ({
  type: SET_PROOF_DATA_INVALID_FORM_FIELDS,
  payload,
});
