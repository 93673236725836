import { createSelector } from "reselect";

//API
export const SEND_LISTING_BY_AD_ID_REQUEST =
  "SEND_LISTING/SEND_LISTING_BY_AD_ID_REQUEST";
export const SEND_LISTING_BY_AD_ID_SUCCESS =
  "SEND_LISTING/SEND_LISTING_BY_AD_ID_SUCCESS";
export const SEND_LISTING_BY_AD_ID_FAILED =
  "SEND_LISTING/SEND_LISTING_BY_AD_ID_FAILED";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  invalidFormFields: null,
};

/// Reducer
export default function (state = initialState, action) {
  switch (action.type) {
    //API
    case SEND_LISTING_BY_AD_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        invalidFormFields: null,
      };

    case SEND_LISTING_BY_AD_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        error: null,
        invalidFormFields: null,
      };

    case SEND_LISTING_BY_AD_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.data,
        invalidFormFields: null,
      };

    default:
      return state;
  }
}

/// Selectors
const selectSendListingState = (state) => state.sendListingState;

export const selectSendListingStateData = createSelector(
  selectSendListingState,
  (sendListingState) => {
    return sendListingState.data;
  },
);

export const selectSendListingStateError = createSelector(
  selectSendListingState,
  (sendListingState) => {
    return sendListingState.error;
  },
);

export const selectSendListingStateIsLoading = createSelector(
  selectSendListingState,
  (sendListingState) => {
    return sendListingState.isLoading;
  },
);

export const selectSendListingStateInvalidFormFields = createSelector(
  selectSendListingState,
  (sendListingState) => {
    return sendListingState.invalidFormFields;
  },
);
