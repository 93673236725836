export const adsBenefitsByPrices = {
    1: [
        "Biggest and most visible listing",
        "Top placement/rotation in city",
        "List under all provided categories",
        "Large image (design included)",
        "Large description section",
        "Large icon on map view",
        "Website link from listing",
        "12 promotions included",
    ],
    2: [
        "Placement below Premium listing",
        "Medium image (design included)",
        "Medium description section",
        "Medium icon on map view",
        "Website link from listing",
        "6 promotions included",
    ],
    3: [
        "Placement below Standard listing",
        "Small image (design included)",
        "Small description section",
        "Small icon on map view",
        "3 promotions included",
    ],
    4: [
        "Placement below Standard listing",
        "Small icon on map view",
        "1 promotion included",
    ],
    5: [],
};
