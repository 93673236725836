import { Modal } from "@find-truck-service/ui/src/components/react/Modal";
import { AdsBenefitsModalHeader } from "@find-truck-service/ui/src/components/react/AdsBenefitsModal/components/AdsBenefitsModalHeader";
import { AdsBenefitsModalFooter } from "@find-truck-service/ui/src/components/react/AdsBenefitsModal/components/AdsBenefitsModalFooter";
import { AdsBenefitsModalContent } from "@find-truck-service/ui/src/components/react/AdsBenefitsModal/components/AdsBenefitsModalContent";
import React from "react";
import { adsBenefitsByPrices } from "@find-truck-service/types/constants/adsBenefitsByPriceId";
import { ADS_PRICES_LABELS } from "@find-truck-service/types/constants/adsPrices";
export const AdsBenefitsModal = (props) => {
    const { isOpen, onClose, onSelect, adType = 1 } = props;
    const adTypeLabel = ADS_PRICES_LABELS[adType];
    const successLabel = `Choose ${adTypeLabel}`;
    const title = `${adTypeLabel} Benefits`;
    const infoLabel = "Close";
    const benefits = adsBenefitsByPrices[adType];
    function onSuccess() {
        onSelect();
        onClose();
    }
    return (React.createElement(Modal, { onBackdropClick: onClose, modalWrapperProps: {
            isOpen: isOpen,
        }, size: "xs", isOpen: isOpen, modalHeaderComponent: () => React.createElement(AdsBenefitsModalHeader, { onClose: onClose, title: title }), modalContentComponent: () => React.createElement(AdsBenefitsModalContent, { benefits: benefits }), modalFooterComponent: () => (React.createElement(AdsBenefitsModalFooter, { onInfo: onClose, infoLabel: infoLabel, onSuccess: onSuccess, successLabel: successLabel })) }));
};
