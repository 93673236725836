import React from "react";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { Dropdown, DropdownSizesValues, DropdownVariantsValues, } from "@find-truck-service/ui/src/components/react/Dropdown";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
const dropdownOptions = [
    { label: "10", value: "10" },
    { label: "25", value: "25" },
    { label: "50", value: "50" },
    { label: "100", value: "100" },
];
const MAX_PAGES_COUNT = 11;
const firstChunkSize = 3;
const secondChunkSize = 5;
const thirdChunkSize = 3;
export const TablePagination = (props) => {
    if (!props.page || !props.pageSize || !props.count)
        return React.createElement(EmptyComponent, null);
    let pagesCount = props.count / props.pageSize;
    if (pagesCount < 1)
        pagesCount = 1;
    let paginationArray = Array.from({ length: pagesCount || 1 }, (_, i) => i + 1);
    if (MAX_PAGES_COUNT < pagesCount) {
        let firstPaginationChunk = paginationArray.slice(0, firstChunkSize); // first 3
        let secondPaginationChunk = paginationArray.slice(firstChunkSize, firstChunkSize + secondChunkSize); // next 5
        let thirdPaginationChunk = paginationArray.slice(pagesCount - thirdChunkSize, pagesCount); // last 3
        if (props.page >= firstChunkSize + secondChunkSize) {
            firstPaginationChunk.pop();
            firstPaginationChunk.push(0);
            secondPaginationChunk = paginationArray.slice(props.page - 3, props.page + 2);
        }
        if (props.page >= pagesCount - thirdChunkSize - firstChunkSize) {
            secondPaginationChunk = paginationArray.slice(pagesCount - thirdChunkSize - secondChunkSize, pagesCount - thirdChunkSize);
        }
        if (props.page < pagesCount - thirdChunkSize - firstChunkSize) {
            thirdPaginationChunk[0] = 0;
        }
        paginationArray = [...firstPaginationChunk, ...secondPaginationChunk, ...thirdPaginationChunk];
    }
    return (React.createElement(FtsWrapper, { display: "flex", px: "2xl", py: "lg", justify: "space-between", width: "100%" },
        React.createElement(FtsWrapper, { width: "168px" },
            React.createElement(Dropdown, { isClearable: false, onChange: (e) => {
                    if (e)
                        props.setPageSize(parseInt(e.value));
                }, value: dropdownOptions.find((e) => parseInt(e.value) === props.pageSize), options: dropdownOptions, menuPlacement: "top", variant: DropdownVariantsValues.outlined, size: DropdownSizesValues.sm, reference: () => { } })),
        React.createElement(FtsWrapper, { display: "flex", gap: "sm" },
            React.createElement(Button, { onClick: () => {
                    props.setPage(props.page - 1);
                }, disabled: !(props.page - 1), iconName: IconNames.ArrowLeft, color: ButtonColorsValue.neutral, size: ButtonSizes.sm, type: ButtonTypes.outlined }),
            paginationArray.map((e, i) => {
                if (!e)
                    return (React.createElement(Button, { disabled: true, key: i, label: "...", color: ButtonColorsValue.neutral, size: ButtonSizes.sm, type: ButtonTypes.outlined }));
                return (React.createElement(Button, { onClick: () => {
                        props.setPage(e);
                    }, disabled: !e, key: i, label: e.toString(), color: ButtonColorsValue.neutral, size: ButtonSizes.sm, type: props.page === e ? ButtonTypes.contained : ButtonTypes.outlined }));
            }),
            React.createElement(Button, { disabled: props.page >= pagesCount, iconName: IconNames.ArrowRight, color: ButtonColorsValue.neutral, size: ButtonSizes.sm, type: ButtonTypes.outlined, onClick: () => {
                    props.setPage(props.page + 1);
                } }))));
};
