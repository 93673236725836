/* eslint-disable indent */
/* eslint-disable no-extra-boolean-cast */
import React, { createRef } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Spinner,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import classnames from "classnames";
// import throttle from "lodash/throttle";
import moment from "moment";
import socketHandler from "../../../utility/socket.js";

import {
  getUserData,
  updateUserData,
  getUserListings,
  getAccountAltContacts,
  getAccountCounts,
  removeAccountInfoAltContact,
} from "../../../redux/actions/users";
import { addUserTodo } from "../../../redux/actions/user-todos";
import { createNewNote, updateNoteById } from "../../../redux/actions/notes";
import { getFilesByAssociation } from "../../../redux/actions/files";
import UserListings from "./UserListings";
import Notes from "./Notes";
import AccountReport from "./AccountReport";
import Marketing from "./Marketing";
import InvoicesPage from "../invoices-page";
import Promotions from "../listing-settings/Promotions";
import AccountTodo from "./AccountTodos";
import UserFiles from "./user-files";
import AccountInfo from "./AccountInfo";
import { formatFormikCategoriesData } from "../../../utility/extract";
import { extractParameter } from "../../../utility/param-extract";
import { extractFormikCategoriesData } from "../../../utility/extract";
import {
  addUserActivity,
  clearUserActivityList,
  setUserActivities,
} from "../../../redux/actions/user-activities";
import { createTodo, assignTodoToAccount } from "../../../redux/actions/todo";

import Icon from "../../../components/fts/Icons";
import ActionBar from "../../../components/fts/action-bar";
import AccountSidebar from "../../../components/fts/account-listing-sidebar";
import ActionSidebar from "../../../components/fts/action-bar/action-sidebar";
import FTSModal from "../../../components/fts/fts-modal";
import CloseButton from "../../../components/fts/CloseButton";
import InfiniteScroll from "react-infinite-scroll-component";
import Checkbox from "../../../components/@vuexy/checkbox/CheckboxesVuexy";
import { Check } from "react-feather";

import ReassignModal from "../../../components/fts/reassign-modal";
import AccountBox from "../../../components/fts/account-box-component";

import { history } from "../../../history";

import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";
import "../../../assets/scss/pages/account-settings.scss";
import "../../../assets/scss/pages/account-listing-shared.scss";
import ConfirmationModal from "../../../components/fts/confirmation-modal";
import withDeviceType from "../../../components/hoc/withDeviceType";
import AccountSubUsers from "./AccountSubUsers";
import {
  resetAccountSettingsState,
  setAccountSettingsState,
} from "../../../redux/actions/accounts";
import { searchVendorAccounts } from "../../../redux/actions/users";
import {
  createActivity,
  fetchActivitiesAccount,
} from "../../../redux/actions/activities";
import { activities, USER_TYPE_COLOR } from "../../../constants";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";
import PaymentsList from "../payments/paymentsList";
import ActivityHistory from "../listing-settings/ActivityHistory";
import queryString from "query-string";
import {
  getUpdatesHistory,
  updateUpdatesHistory,
} from "../../../redux/actions/updates-history";
import { checkPermissions } from "../../../utility/permissionsCheck";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";
import Icons from "../../../components/fts/Icons";
import AccountAccessRequestModal from "../../../components/fts/account-access-request-modal";
import DisableAccessModal from "../../../components/fts/disable-access-modal";
import { updateAccountAccessRequest } from "../../../redux/actions/accountAccessRequests";
import SentProofsAccount from "./SentProofsAccount";

class AccountSettings extends React.Component {
  state = {
    isOpen: false,
    isOpenEditConfirmationModal: false,
    activeTab: this.props.accountSettingsState.activeTab,
    windowWidth: null,
    inProgress: true,
    accountData: null,
    editMode: false,
    sidebar: false,
    updating: false,
    action: "",
    actionItem: null,
    accountListings: [],
    newAltContacts: [],
    removedAltContacts: [],
    updatedAltContact: [],
    accounts: [],
    isOpenReassignConfirmationModal: false,
    hasMore: true,
    page: 1,
    totalRecords: 0,
    pageCount: 0,
    pageSize: 8,
    accountSearchValue: "",
    mainAccount: null,
    isSearching: false,
    promotionsCount: this.props.promotionsCount,
    activityPreloadDate: null,
    havePermission: false,
    accountReportId: null,
    notifyUser: false,
    navBarCollapsed: false,
    collectedDataForCopy: null,
    call: null,
    listingData: null,
    isOpenAccountAccessRequestModal: false,
    isOpenDisableAccessModal: false,
    disableAccountAccessInProgress: false,
  };

  navBarHeight = null;
  accountSettingsTab = null;
  navigationContainer = null;

  accountFormRef = createRef();
  sidebarChild = createRef();
  filesTabRef = createRef();
  filesTableGroupRef = createRef();
  formikSidebarRef = createRef();
  notesRef = createRef();
  promotionRef = createRef();
  invoicesRef = createRef();
  userListingsRef = createRef();
  reportsRef = createRef();
  sentProofsRef = createRef();

  updateNavWidth = () => {
    if (this.accountSettingsTab) {
      if (this.state.activeTab === 8) {
        return;
      }
      if (this.props.isSmallPhone()) {
        this.navigationContainer.style.width = "34px";
      } else if (this.props.isPhoneOrSmaller()) {
        this.navigationContainer.style.width = "62px";
      } else if (this.state.activeTab === 8) {
        this.navigationContainer.style.width = "40px";
      } else {
        this.navigationContainer.style.width = "226px";
      }
    }
  };

  assignToAccount = (toDoId, accountIds) => {
    return this.props.assignTodoToAccount(toDoId, accountIds);
  };

  toggleNotifyUser = (notifyUser) => {
    this.setState({
      notifyUser,
    });
  };

  // handleStickySidebar = () => {
  //   let actionBarHeight =
  //     document.getElementsByClassName("fts-action-bar")[0]?.clientHeight;

  //   if (this.accountSettingsTab) {
  //     this.navigationContainer.style.height = "206px";
  //     this.navigationContainer.style.minHeight = "625px";
  //     this.updateNavWidth();

  //     if (window.scrollY >= actionBarHeight) {
  //       this.accountSettingsTab.style.position = "fixed";
  //       this.accountSettingsTab.style.top = this.navBarHeight + 27 + "px";
  //       this.accountSettingsTab.style.width = "200px";
  //     } else {
  //       this.accountSettingsTab.style.position = "relative";
  //       this.accountSettingsTab.style.top = "0px";
  //     }
  //   }
  // };

  componentDidUpdate(prevProps) {
    if (prevProps.promotionsCount !== this.props.promotionsCount) {
      this.setState({ promotionsCount: this.props.promotionsCount });
    }
  }

  refreshAccountStatus = () => {
    this.setState({
      accountData: { ...this.state.accountData, status: 0 },
    });
  };

  setAccountAccessRequests = (request) => {
    this.setState({
      accountData: {
        ...this.state.accountData,
        accountAccessRequests: request,
      },
    });
  };

  updateAccountSearchResults = () => {
    this.setState({
      isSearching: true,
    });
    const { page, pageSize, sortAccountVendor, accountSearchValue } =
      this.state;
    return this.props
      .searchVendorAccounts({
        page,
        resultsPerPage: pageSize,
        sort: sortAccountVendor?.length
          ? [
              { colId: "adminLastContactDateForSort", sort: "ASC" },
              ...sortAccountVendor,
            ]
          : [
              { colId: "adminLastContactDateForSort", sort: "ASC" },
              { colId: "createdAt", sort: "desc" },
            ],
        searchString: accountSearchValue,
      })
      .then(
        ({
          data: {
            result: { hits, nbHits },
          },
        }) => {
          if (hits?.length === 0) {
            this.setState({
              hasMore: false,
            });
          }
          this.setState({
            columnDefs: this.accountColumns,
            accounts: [...this.state.accounts, ...hits],
            totalRecords: nbHits,
            page: page + 1,
            pageCount: Math.ceil(nbHits / pageSize),
          });

          this.setState({
            isSearching: false,
          });
        },
      );
  };

  updateSearchQuery = (e) => {
    this.setState({
      isSearching: true,
    });
    this.setState(
      { accounts: [], accountSearchValue: e.target.value, page: 1 },
      () => {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.updateAccountSearchResults();
        }, 300);
      },
    );
  };

  handleReassign = async () => {
    if (this.state.inProgress) {
      return;
    }
    this.setState({ inProgress: true });
    const { accountData } = this.state;
    const { userCategories, userSubCategories } = extractFormikCategoriesData(
      accountData.categories,
    );

    const adminUserIds = !accountData.assignedAdmins
      ? []
      : Array.isArray(accountData.assignedAdmins)
      ? accountData.assignedAdmins.map((itm) => itm.value)
      : [accountData.assignedAdmins.value];

    const payload = {
      ...accountData,
      userCategories,
      userSubCategories,
      adminUserIds,
      leadStatus:
        typeof accountData?.leadStatus === "object"
          ? accountData?.leadStatus?.value
          : accountData?.leadStatus,
      adminStatus:
        typeof accountData.adminStatus === "object"
          ? accountData.adminStatus.value
          : accountData.adminStatus,
      state:
        typeof accountData.state === "object"
          ? accountData.state.value
          : accountData.state,
      userTypeNamed:
        typeof accountData.userTypeNamed === "object"
          ? accountData.userTypeNamed.label.toLowerCase()
          : accountData.userTypeNamed,
      userType:
        typeof accountData.userTypeNamed === "object"
          ? accountData.userTypeNamed.value
          : accountData.userType,
      accountLink: this.state.mainAccount.id,
      companyName: rfc3986EncodeURIComponent(accountData.companyName),
      status: 0,
      userRole: 2,
      onBoardingFinished: true,
    };

    delete payload.subUserRole;
    /* eslint-disable  */
    // debugger;
    this.props
      .updateUserData(payload)
      .then(() => {
        this.toggleReassignConfirmationModal();
        toast.success("Account successfully reassigned", {
          position: toast.POSITION.TOP_RIGHT,
        });

        let toAccount = `#${this.state.mainAccount.id} - ${this.state.mainAccount.companyName} - ${this.state.mainAccount.city} - ${this.state.mainAccount.state}`;
        let activity = activities.convertAccount
          .replace(
            "{{admin_name}}",
            `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
          )
          .replace("{{toAccount}}", toAccount)
          .replace("{{href}}", `/accounts/${this.state.mainAccount.id}`);

        const createActivityPayload = {
          userId: accountData.id,
          status: 2,
          // activityType: "convertAccount",
          activityType:
            this.props.activitiesTypes.ACCOUNT_CONVERTED_TO_SUBACOUNT,
          activity: activity,
          adminUserId: this.props.userData.loggedInUser.id,
          iconName: "AccountsActivity",
        };

        this.props
          .createActivity(createActivityPayload)
          .then((data) => {
            this.props.addUserActivity(data.data);
          })
          .catch((err) => {
            console.log(err);
            toast.error("Oops! Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.setState({ inProgress: false });
            this.toggleReassignConfirmationModal();
          });

        this.refreshAccountStatus();

        this.setState({ inProgress: false });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ inProgress: false });
        this.toggleReassignConfirmationModal();
      });
  };

  componentDidMount() {
    const {
      match: { params },
      location,
    } = this.props;
    const searchParams = queryString.parse(location.search);
    if (searchParams["update-history"]) {
      this.setState(
        {
          activityPreloadDate: searchParams["update-history"],
        },
        () => {
          this.callToAction("account_update_history", false, null);
        },
      );
    }

    if (this.props.accountSettingsState.accountId !== params.accountId) {
      // this.props.resetAccountSettingsState();
      this.props.setAccountSettingsState({ accountId: params.accountId });
      this.setState({ activeTab: 0 });
    }

    if (params && params.accountId) {
      this.getAccountInfo(params.accountId);
      this.props.getAccountCounts(params.accountId);
    }

    if (window !== undefined) {
      this.updateWidth();
      window.addEventListener("resize", this.updateWidth);
    }

    this.accountSettingsTab = document.getElementsByClassName(
      "account-settings-tab",
    )[0];
    this.navigationContainer =
      document.getElementsByClassName("fts-nav-container")[0];

    // window.addEventListener("scroll", throttle(this.handleStickySidebar, 20));

    if (params.accountId) {
      socketHandler.handleSocket("subscribeToAccountAccessRequest", {
        userId: params.accountId,
      });
    }

    const noteIdParam = extractParameter("noteId");
    if (noteIdParam) {
      this.openNote({ id: parseInt(noteIdParam) }, false);
    }

    const todoIdParam = extractParameter("toDoId");
    if (todoIdParam) {
      this.openSidebar({ id: parseInt(todoIdParam) }, false);
    }

    const adIdParam = extractParameter("adId");
    if (adIdParam) {
      this.openSidebar({ id: parseInt(adIdParam) }, false, true);
    }

    const promotionIdParam = extractParameter("promotionId");
    if (promotionIdParam) {
      this.callToAction("promotion", true, { id: parseInt(promotionIdParam) });
    }

    const reportIdParam = extractParameter("reportId");
    if (reportIdParam) {
      this.previewAccountReport(reportIdParam, "Report preview");
    }

    const invoiceIdParam = extractParameter("invoiceId");
    if (invoiceIdParam) {
      this.setState({
        activeTab: 2,
      });
    }

    const activeTab = extractParameter("activeTab");
    if (activeTab) {
      this.setState({
        activeTab: parseInt(activeTab),
      });
    }
  }

  componentWillUnmount() {
    const {
      match: { params },
    } = this.props;

    socketHandler.handleSocket("unSubscribeToAccountAccessRequest", {
      userId: params.accountId,
    });

    window.removeEventListener("resize", this.updateWidth);
    // window.removeEventListener("scroll", this.handleStickySidebar);
    this.props.clearUserActivityList();
  }

  setFormikSidebarRef = (ref) => {
    this.formikSidebarRef = ref;
  };

  resetFormikForm = () => {
    this.formikSidebarRef.resetForm();
  };

  updateWidth = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  saveUserFiles = () => {};

  setEditMode = (value) => {
    this.setState({ editMode: value });
    if (this.accountFormRef && this.accountFormRef.current) {
      this.accountFormRef.current.resetForm();
    }
  };

  toggleReassignListingModal = () => {
    if (!this.state.isOpenReassignListingModal) {
      this.setState({
        page: 1,
        totalRecords: 0,
        pageCount: 0,
        accounts: [],
        accountSearchValue: "",
        newAccount: null,
      });
    }
    this.setState(
      {
        isOpenReassignListingModal: !this.state.isOpenReassignListingModal,
      },
      () => {
        this.updateAccountSearchResults();
      },
    );
  };

  toggleReassignConfirmationModal = () => {
    this.setState({
      isOpenReassignConfirmationModal:
        !this.state.isOpenReassignConfirmationModal,
    });
  };

  toggleAccountAccessRequestModal = () => {
    this.setState({
      isOpenAccountAccessRequestModal:
        !this.state.isOpenAccountAccessRequestModal,
    });
  };

  toggleDisableAccessModal = () => {
    this.setState({
      isOpenDisableAccessModal: !this.state.isOpenDisableAccessModal,
    });
  };

  updateAccountCounts = (userId) => {
    this.props.getAccountCounts(userId);
  };

  setAccountListings = (data) => {
    const premiumList = {
      label: "Premium",
      options: (data.premiumData ?? data.premiumListingData ?? []).map(
        (itm) => ({
          value: itm.id,
          label: `#${itm.id} - ${itm.name} ${itm.city} ${itm.state} ${itm.address} `,
        }),
      ),
    };
    const standardList = {
      label: "Standard",
      options: (data.standardData ?? data.standardListingData ?? []).map(
        (itm) => ({
          value: itm.id,
          label: `#${itm.id} - ${itm.name} ${itm.city} ${itm.state} ${itm.address} `,
        }),
      ),
    };
    const basicList = {
      label: "Basic",
      options: (data.basicData ?? data.basicListingData ?? []).map((itm) => ({
        value: itm.id,
        label: `#${itm.id} - ${itm.name} ${itm.city} ${itm.state} ${itm.address} `,
      })),
    };
    const basicPlusList = {
      label: "BasicPlus",
      options: (data.basicPlusData ?? data.basicPlusListingData ?? []).map(
        (itm) => ({
          value: itm.id,
          label: `#${itm.id} - ${itm.name} ${itm.city} ${itm.state} ${itm.address} `,
        }),
      ),
    };
    const freeList = {
      label: "Free",
      options: (data.freeData ?? data.freeListingData ?? []).map((itm) => ({
        value: itm.id,
        label: `#${itm.id} - ${itm.name} ${itm.city} ${itm.state} ${itm.address} `,
      })),
    };
    this.setState({
      accountListings: [
        premiumList,
        standardList,
        basicList,
        basicPlusList,
        freeList,
      ],
    });
  };

  toggle = (tab) => {
    if (tab !== 2 && this.filesTabRef?.closePopover) {
      this.filesTabRef.closePopover();
    }
    this.setState({
      activeTab: tab,
    });

    this.props.setAccountSettingsState({ activeTab: tab });
  };

  getAccountTitle = () => {
    return this.state.inProgress ? (
      <Spinner size="sm" />
    ) : !!this.state.accountData ? (
      `${this.state.accountData.firstName} ${this.state.accountData.lastName}`
    ) : (
      ""
    );
  };

  updatedAdminNote = ({
    adminNote: adminNoteText,
    adminNoteType,
    adminNoteIsSticky,
    userNotes,
  }) => {
    const adminNote = userNotes?.length && userNotes.find((itm) => itm.isAdmin);

    if (adminNote) {
      const adminNoteID = adminNote?.id;
      const payload = {
        isAdmin: true,
        note: adminNoteText,
        noteType:
          adminNoteType !== null
            ? adminNoteType.value
              ? [adminNoteType.value]
              : [adminNoteType]
            : "",
        noteTypeId: Array.isArray(adminNoteType)
          ? adminNoteType.length
            ? adminNoteType[0]?.id
            : null
          : adminNoteType?.id,
        isSticky: adminNoteIsSticky,
        id: adminNoteID,
      };

      return this.props
        .updateNoteById(payload)
        .then(() => {})
        .catch((err) => {
          console.log("err update note = ", err);
        });
    } else {
      const payload = {
        note: adminNoteText,
        noteType: [
          typeof adminNoteType === "object"
            ? adminNoteType.value
            : adminNoteType,
        ],
        note_type_id: adminNoteType.id,
        ownerId: this.state.accountData?.id,
        userId: this.state.accountData?.id,
        isSticky: adminNoteIsSticky,
        isAdmin: true,
        parentId: null,
        noteTypeId: adminNoteType.id ? adminNoteType.id : 1,
      };
      return this.props
        .createNewNote(payload)
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    }
  };

  postAltContactCreatedActivity = async (data, userId) => {
    let activityPayload = {
      status: 1,
      userId: userId,
      activityType: this.props.activitiesTypes.CREATED_ALT_CONTACT,
      activity: activities.createAltContact
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        )
        .replace("{{firstName}}", data.firstName)
        .replace("{{lastName}}", data.lastName),
      adminUserId: this.props.userData.loggedInUser.id,
      iconName: "AccountsActivity",
    };

    await this.props.createActivity(activityPayload);
  };

  postAltContactRemovedActivity = async (data, userId) => {
    let activityPayload = {
      status: 3,
      userId: userId,
      activityType: this.props.activitiesTypes.REMOVED_ALT_CONTACT,
      activity: activities.removeAltContact
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        )
        .replace("{{firstName}}", data.firstName)
        .replace("{{lastName}}", data.lastName),
      adminUserId: this.props.userData.loggedInUser.id,
      iconName: "AccountsActivity",
    };

    await this.props.createActivity(activityPayload);
  };

  updateAccountDataAction = async (payload) => {
    if (this.state.inProgress) {
      return;
    }
    this.setState({ inProgress: true });
    try {
      let {
        types,
        updateUserData,
        getAccountCounts,
        getAccountAltContacts,
        userData,
        createActivity,
        addUserActivity,
        createTodo,
        addUserTodo,
      } = this.props;

      let { accountData } = this.state;

      await this.updatedAdminNote(payload);

      let { data: updatedUser } = await updateUserData({
        ...payload,
        onboardingFinished: true,
      });

      let { user: userDataRes } = updatedUser;

      await getAccountCounts(payload.id);

      let salesTodoId = types.find((type) => type.description === "Lead").id;

      let { data: altContactsRes } = await getAccountAltContacts(payload.id);

      const additionalData = formatFormikCategoriesData({
        categories: userDataRes.userCategories,
        subCategories: userDataRes.userSubCategories,
      });

      const assignedAdmins = userDataRes.assignedAdmins?.length
        ? userDataRes.assignedAdmins.map((itm) => ({
            value: itm.id,
            label: `${itm.firstName} ${itm.lastName}`,
          }))
        : userDataRes.assignedAdmins;

      const adminNote = userDataRes.userNotes?.length
        ? userDataRes.userNotes.find((itm) => itm.isAdmin)
        : null;

      const noteData = {
        adminNote: adminNote ? adminNote.note : "",
        adminNoteType: adminNote ? adminNote.noteType[0] : "",
        adminNoteIsSticky: adminNote ? adminNote.isSticky : false,
      };

      const altContacts = altContactsRes.map((itm) => ({
        ...itm,
        adminNotes: itm.note,
        name: `${itm.firstName} ${itm.lastName}`,
        noteType: itm.noteType ? itm.noteType[0] : "",
      }));

      const newContacts = this.state.newAltContacts;
      const removedContacts = this.state.removedAltContacts;

      let createdAlts = newContacts.filter(function (cv) {
        return !removedContacts.find(function (e) {
          return e.id === cv.id;
        });
      });

      let removedAlts = removedContacts.filter(function (cv) {
        return !newContacts.find(function (e) {
          return e.id === cv.id;
        });
      });

      for (const createdAlt of createdAlts) {
        await this.postAltContactCreatedActivity(
          createdAlt,
          this.state.accountData?.id,
        );
      }

      for (const removedAlt of removedAlts) {
        await this.postAltContactRemovedActivity(
          removedAlt,
          this.state.accountData?.id,
        );
      }

      const followUpTodoPayload = {
        adminIds: [],
        listingIds: [],
        ownerId: accountData.id,
        // admin_owner_id: userDataRes.assignedAdmins[0].id,
        // adminOwnerId: userDataRes.assignedAdmins[0].id,
        reminderDate: moment().format("YYYY-MM-DD"),
        reminderTime: moment().format("kk:mm"),
        title: `Lead todo - Lead on ${accountData.companyName}`,
        todo: "test",
        todoPriority: "",
        todoStatus: "active",
        todoType: "lead",
        todo_type_id: salesTodoId,
        todoForSales: true,
      };

      let isUnAssigned =
        accountData.assignedAdmins[0] &&
        accountData.assignedAdmins[0]?.value !==
          userDataRes.assignedAdmins[0]?.id &&
        userDataRes.adminStatus !== 3;
      let isAssigned =
        userDataRes.assignedAdmins &&
        userDataRes.assignedAdmins.length &&
        this.state.accountData.assignedAdmins[0]?.value !==
          userDataRes.assignedAdmins[0]?.id &&
        userDataRes.adminStatus === 2;

      if (isUnAssigned) {
        let activity = activities.unassignedAccountUser
          .replace(
            "{{admin_name}}",
            `${userData.loggedInUser.firstName} ${userData.loggedInUser.lastName}`,
          )
          .replace(
            "{{assigned_admin}}",
            `${accountData.assignedAdmins[0].label}`,
          );

        let createActivityPayload = {
          userId: userDataRes.id,
          status: 2,
          activityType: this.props.activitiesTypes.UNASSIGNED_ACCOUNT_USER,
          activity: activity,
          adminUserId: userData.loggedInUser.id,
          createdAt: moment().subtract(1, "second").toISOString(),
          iconName: "AccountsActivity",
        };

        const { data } = await createActivity(createActivityPayload);
        // let {
        //   data: { todo },
        // } = await createTodo(followUpTodoPayload);
        await addUserActivity(data);
      }

      if (isAssigned) {
        let { assignedAdmins } = userDataRes;

        followUpTodoPayload.adminOwnerId = assignedAdmins[0].id;
        followUpTodoPayload.admin_owner_id = assignedAdmins[0].id;

        let activity = activities.assignedAccountUser
          .replace(
            "{{admin_name}}",
            `${userData.loggedInUser.firstName} ${userData.loggedInUser.lastName}`,
          )
          .replace(
            "{{assigned_admin}}",
            `${assignedAdmins[0].firstName} ${assignedAdmins[0].lastName}`,
          );

        const createActivityPayload = {
          userId: userDataRes.id,
          status: 2,
          activityType: this.props.activitiesTypes.ASSIGNED_ACCOUNT_USER,
          activity: activity,
          adminUserId: userData.loggedInUser.id,
          iconName: "AccountsActivity",
        };

        const { data } = await createActivity(createActivityPayload);
        await addUserActivity(data);

        let {
          data: { todo },
        } = await createTodo(followUpTodoPayload);
        await addUserTodo(todo);
        this.assignToAccount(todo.id, [this.state.accountData.id]);
      }

      window.scrollTo(0, 0);

      let updatedUserPayload = {
        status: 2,
        userId: userDataRes.id,
        activityType: this.props.activitiesTypes.UPDATED_ACCOUNT,
        activity: activities.updateAccount.replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        ),
        adminUserId: this.props.userData.loggedInUser.id,
        iconName: "Updates",
      };

      await createActivity(updatedUserPayload);

      await toast.success("Account successfully updated", {
        position: toast.POSITION.TOP_RIGHT,
      });

      let { newAltContacts, updatedAltContact, removedAltContacts } =
        this.state;
      // if (newAltContacts.length) {
      let {
        data: { results: updates },
      } = await this.props.getUpdatesHistory({
        userId: this.state.accountData?.id,
      });

      let lastUpdate = updates[0];
      if (lastUpdate) {
        let beforeUpdate = JSON.parse(lastUpdate?.beforeUpdate);
        let afterUpdate = JSON.parse(lastUpdate?.afterUpdate);
        beforeUpdate.altContact = [...updatedAltContact, ...removedAltContacts];
        afterUpdate.altContact = [...newAltContacts];
        afterUpdate = JSON.stringify(afterUpdate);
        beforeUpdate = JSON.stringify(beforeUpdate);

        await this.props.updateUpdatesHistory({
          data: { afterUpdate, beforeUpdate },
          id: lastUpdate.id,
        });
      }
      // }

      await this.props
        .fetchActivitiesAccount(userDataRes.id)
        .then(({ data }) => this.props.setUserActivities(data));
      this.setState({
        newAltContacts: [],
        updatedAltContact: [],
        inProgress: false,
        editMode: false,
        accountData: {
          altContacts,
          categories: { ...additionalData },
          ...userDataRes,
          ...noteData,
          assignedAdmins,
        },
      });
    } catch (e) {
      console.log(e);
    }

    // this.updatedAdminNote(payload).then(() => {
    //   this.props
    //     .updateUserData({
    //       ...payload,
    //     })
    //     .then((response) => {
    //       this.props.getAccountCounts(payload.id);
    //
    //       let salesTodoId = this.props.types.filter((type) => {
    //         return type.type !== "note" && type.description === "Lead";
    //       })[0]?.id;
    //
    //       this.setState({ inProgress: true });
    //       Promise.all([this.props.getAccountAltContacts(payload.id)])
    //         .then(([altContactsRes]) => {
    //           let userDataRes = response.data.user;
    //           const additionalData = formatFormikCategoriesData({
    //             categories: userDataRes.userCategories,
    //             subCategories: userDataRes.userSubCategories,
    //           });
    //
    //           const assignedAdmins = userDataRes.assignedAdmins?.length
    //             ? userDataRes.assignedAdmins.map((itm) => ({
    //                 value: itm.id,
    //                 label: `${itm.firstName} ${itm.lastName}`,
    //               }))
    //             : userDataRes.assignedAdmins;
    //
    //           const adminNote = userDataRes.userNotes?.length
    //             ? userDataRes.userNotes.find((itm) => itm.isAdmin)
    //             : null;
    //
    //           const noteData = {
    //             adminNote: adminNote ? adminNote.note : "",
    //             adminNoteType: adminNote ? adminNote.noteType[0] : "",
    //             adminNoteIsSticky: adminNote ? adminNote.isSticky : false,
    //           };
    //
    //           const altContacts = altContactsRes.data.map((itm) => ({
    //             ...itm,
    //             adminNotes: itm.note,
    //             name: `${itm.firstName} ${itm.lastName}`,
    //             noteType: itm.noteType ? itm.noteType[0] : "",
    //           }));
    //
    //           const followUpTodoPayload = {
    //             adminIds: [],
    //             listingIds: [],
    //             ownerId: this.state.accountData.id,
    //             // admin_owner_id: userDataRes.assignedAdmins[0].id,
    //             // adminOwnerId: userDataRes.assignedAdmins[0].id,
    //             reminderDate: moment().format("YYYY-MM-DD"),
    //             reminderTime: moment().format("kk:mm"),
    //             title: `Lead todo - Lead on ${this.state.accountData.companyName}`,
    //             todo: "test",
    //             todoPriority: "",
    //             todoStatus: "active",
    //             todoType: "Lead",
    //             todo_type_id: salesTodoId,
    //             todoForSales: true,
    //           };
    //
    //           if (
    //             this.state.accountData.assignedAdmins[0] &&
    //             this.state.accountData.assignedAdmins[0]?.value !==
    //               userDataRes.assignedAdmins[0]?.id &&
    //             userDataRes.adminStatus !== 3
    //           ) {
    //             let activity = activities.unassignedAccountUser
    //               .replace(
    //                 "{{admin_name}}",
    //                 `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`
    //               )
    //               .replace(
    //                 "{{assigned_admin}}",
    //                 `${this.state.accountData.assignedAdmins[0].label}`
    //               );
    //
    //             const createActivityPayload = {
    //               userId: userDataRes.id,
    //               status: 2,
    //               activityType: "unassignedAccountUser",
    //               activity: activity,
    //               adminUserId: userDataRes.assignedAdmins[0].id,
    //             };
    //
    //             this.props
    //               .createActivity(createActivityPayload)
    //               .then(({ data }) => {
    //                 this.props.addUserActivity(data);
    //               })
    //               .catch((err) => {
    //                 console.log(err);
    //                 toast.error(
    //                   "Oops! Something went wrong. Please try again.",
    //                   {
    //                     position: toast.POSITION.TOP_RIGHT,
    //                   }
    //                 );
    //               });
    //           }
    //
    //           if (
    //             userDataRes.assignedAdmins &&
    //             userDataRes.assignedAdmins.length &&
    //             this.state.accountData.assignedAdmins[0]?.value !==
    //               userDataRes.assignedAdmins[0]?.id &&
    //             userDataRes.adminStatus !== 3
    //           ) {
    //             followUpTodoPayload.adminOwnerId =
    //               userDataRes.assignedAdmins[0].id;
    //             followUpTodoPayload.admin_owner_id =
    //               userDataRes.assignedAdmins[0].id;
    //
    //             let activity = activities.assignedAccountUser
    //               .replace(
    //                 "{{admin_name}}",
    //                 `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`
    //               )
    //               .replace(
    //                 "{{assigned_admin}}",
    //                 `${userDataRes.assignedAdmins[0].firstName} ${userDataRes.assignedAdmins[0].lastName}`
    //               );
    //
    //             const createActivityPayload = {
    //               userId: userDataRes.id,
    //               status: 2,
    //               activityType: "assignedAccountUser",
    //               activity: activity,
    //               adminUserId: userDataRes.assignedAdmins[0].id,
    //             };
    //
    //             this.props
    //               .createActivity(createActivityPayload)
    //               .then(({ data }) => {
    //                 this.props.addUserActivity(data);
    //               })
    //               .catch((err) => {
    //                 console.log(err);
    //                 toast.error(
    //                   "Oops! Something went wrong. Please try again.",
    //                   {
    //                     position: toast.POSITION.TOP_RIGHT,
    //                   }
    //                 );
    //               });
    //
    //             this.props
    //               .createTodo(followUpTodoPayload)
    //               .then(async ({ data: { todo } }) => {
    //                 this.props.addUserTodo(todo);
    //                 this.assignToAccount(todo.id, [this.state.accountData.id]);
    //                 toast.success("Account successfully reasigned", {
    //                   position: toast.POSITION.TOP_RIGHT,
    //                 });
    //               });
    //           }
    //           window.scrollTo(0, 0);
    //           toast.success("Account successfully updated", {
    //             position: toast.POSITION.TOP_RIGHT,
    //           });
    //
    //           this.setState({
    //             inProgress: false,
    //             editMode: false,
    //             accountData: {
    //               altContacts,
    //               categories: { ...additionalData },
    //               ...userDataRes,
    //               ...noteData,
    //               assignedAdmins,
    //             },
    //           });
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //           this.setState({ inProgress: false });
    //         });
    //     })
    //     .catch((err) => {
    //       this.setState({ inProgress: false });
    //       console.log("====================================");
    //       console.log(err);
    //       console.log("====================================");
    //     });
    // });
  };

  getAccountAltContactInfo = (accountId) => {
    this.props
      .getAccountAltContacts(accountId)
      .then(({ data: altContactsData }) => {
        const altContacts = altContactsData.map((itm) => ({
          ...itm,
          adminNotes: itm.note,
          name: `${itm.firstName} ${itm.lastName}`,
          noteType: itm.noteType ? itm.noteType[0] : "",
        }));

        const accountData = {
          ...this.state.accountData,
          altContacts,
        };

        this.setState({
          accountData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAccountInfo = (accountId) => {
    this.setState({ inProgress: true });
    Promise.all([
      this.props.getUserData(accountId),
      this.props.getAccountAltContacts(accountId),
    ])
      .then(([userDataRes, altContactsRes]) => {
        if (!userDataRes.data) {
          return history.push("/account-does-not-exist");
        }

        let { adminPermissions } = this.props;

        let havePermission = !checkPermissions(
          [
            {
              id: 4,
              subCat: [
                getPermissionIdBasedOnAdminStatus(userDataRes.data.adminStatus),
              ],
            },
          ],
          adminPermissions,
        );

        this.setState({ havePermission });
        const additionalData = formatFormikCategoriesData({
          categories: userDataRes.data.userCategories,
          subCategories: userDataRes.data.userSubCategories,
        });
        const assignedAdmins = userDataRes.data.assignedAdmins?.length
          ? userDataRes.data.assignedAdmins.map((itm) => ({
              value: itm.id,
              label: `${itm.firstName} ${itm.lastName}`,
            }))
          : userDataRes.data.assignedAdmins;
        const adminNote = userDataRes.data.userNotes?.length
          ? userDataRes.data.userNotes.find((itm) => itm.isAdmin)
          : null;
        const noteData = {
          adminNote: adminNote ? adminNote.note : "",
          adminNoteType: adminNote ? adminNote.noteType[0] : "",
          adminNoteIsSticky: adminNote ? adminNote.isSticky : false,
        };
        const altContacts = altContactsRes.data.map((itm) => ({
          ...itm,
          adminNotes: itm.note,
          name: `${itm.firstName} ${itm.lastName}`,
          noteType: itm.noteType ? itm.noteType[0] : "",
        }));

        this.setState({
          inProgress: false,
          editMode: false,
          accountData: {
            altContacts,
            categories: { ...additionalData },
            ...userDataRes.data,
            ...noteData,
            assignedAdmins,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ inProgress: false });
      });
  };

  setPromotionsCount = (number) => {
    this.setState({
      promotionsCount: this.state.promotionsCount + parseInt(number),
    });
  };

  toggleEdit = () => {
    this.setState({ editMode: !this.state.editMode }, () => {
      this.state.editMode && this.accountFormRef.current.resetForm();
    });
  };

  callToAction = (action, update, item) => {
    if (!action) return;
    this.setState({
      action,
      actionItem: item,
      sidebar: true,
      updating: !!update,
    });
  };

  handleSidebar = (bool, update, closeWithoutPrompt) => {
    if (bool === false && this.formikSidebarRef?.dirty && !closeWithoutPrompt) {
      return this.setState({ isOpen: true });
    } else {
      this.setState({ sidebar: bool, updating: !!update });
    }
  };

  closeSideOutAction = () => {
    this.setState({ sidebar: false, update: false, isOpen: false });
  };

  onKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  }

  onPrefillListingData = () => {
    return this.accountFormRef?.current?.values;
  };

  setActionItem = (actionItem) => {
    this.setState({ actionItem: { ...actionItem } });
  };

  onPromotionCreate = () => {
    setTimeout(() => {
      this.promotionRef.onSubmitQuery();
    }, 1000);
  };

  openNote = (data) => {
    this.callToAction("note", true, { id: data.id });
  };

  openSidebar = (data, note, updateListing) => {
    if (data) {
      if (updateListing) {
        this.callToAction("update_listing", false, { id: data.id });
      } else {
        if (!note) {
          this.callToAction("todo", true, { id: data.id });
        } else {
          this.setState({
            activeTab: 0,
          });
          this.props.setAccountSettingsState({ activeTab: 0 });

          this.callToAction("todo", true, { id: data.toDoId });
        }
      }
    } else if (this.props.location && this.props.location.search) {
      let data = this.props.location.data;
      if (data) {
        this.setState({
          activeTab: 0,
        });
        this.props.setAccountSettingsState({ activeTab: 0 });

        this.callToAction("todo", true, { id: data.toDoId });
      }
    }
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  toggleEditConfirmationModal = () => {
    if (this.accountFormRef?.current?.dirty) {
      this.setState({
        isOpenEditConfirmationModal: !this.state.isOpenEditConfirmationModal,
      });
    } else {
      this.toggleEdit();
    }
  };

  onModalClosed = () => {};

  onReassignChange = (mainAccount, account) => {
    if (mainAccount?.id === account.id) {
      this.setState({ mainAccount: null });
    } else {
      this.setState({
        mainAccount: account,
      });
    }
  };

  getEmailsCounter = () => {
    let counter = 0;
    this.props.emailsFolders &&
      this.props.emailsFolders.forEach((folder) => {
        counter += folder.count;
      });
    return counter;
  };
  setNewAltContacts = (payload) => {
    let { newAltContacts } = this.state;

    this.setState({ newAltContacts: [...newAltContacts, payload] });
  };
  removeAltContacts = (id) => {
    let { removedAltContacts, accountData } = this.state;

    let oldAltContact = accountData.altContacts.find((e) => e.id === id);

    this.setState({
      removedAltContacts: [...removedAltContacts, oldAltContact],
    });
  };

  updateAltContactAction = (payload, id) => {
    let { newAltContacts, updatedAltContact, accountData } = this.state;
    let oldAltContact = accountData.altContacts.find((e) => e.id === id);
    this.setState({
      newAltContacts: [...newAltContacts, payload],
      updatedAltContact: [...updatedAltContact, oldAltContact],
    });
  };

  setActivityPreloadDate = (date) => {
    this.setState({
      activityPreloadDate: date,
    });
  };

  previewAccountReport = (id, title) => {
    this.setState(
      {
        accountReportId: id,
        accountReportTitle: title,
      },
      () => {
        this.callToAction("report_preview", false);
      },
    );
  };

  updateAccountActivities = () => {
    this.props
      .fetchActivitiesAccount(this.state.accountData.id)
      .then(({ data }) => this.props.setUserActivities(data));
  };

  handleNavBarCollapse = () => {
    this.setState({
      navBarCollapsed: !this.state.navBarCollapsed,
    });
  };

  handleDeleteAltContacts = async () => {
    let promises = [];
    for (const altContact of this.state.newAltContacts) {
      promises.push(this.props.removeAccountInfoAltContact(altContact.id));
    }

    Promise.all(promises)
      .then(async () => {
        this.getAccountAltContactInfo(
          this.props.accountSettingsState?.accountId,
        );
        if (this.accountFormRef && this.accountFormRef.current) {
          this.accountFormRef.current.resetForm();
        }
        return;
      })
      .catch((err) => {
        console.log("ERROR", err);
        toast.error(
          "Oops! Something went wrong while removing alt contacts. Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
          },
        );
        throw new Error(err);
      });
  };

  collectDataForCopy = (data, call) => {
    this.setState({
      collectedDataForCopy: data,
      call,
    });
  };

  setListingData = (data) => {
    this.setState({ listingData: data });
  };

  confirmDisableAccessHandler = () => {
    if (this.state.disableAccountAccessInProgress) return;
    this.setState({ disableAccountAccessInProgress: true });
    this.props
      .updateAccountAccessRequest({
        ...this.state.accountData?.accountAccessRequests,
        status: 3,
      })
      .then(() => {
        toast.success("Remote access disabled successfully");
        this.createRemoteAccessDisableActivity();

        this.setState({
          accountData: {
            ...this.state.accountData,
            accountAccessRequests: null,
          },
        });

        this.toggleDisableAccessModal();
      })
      .catch((err) => {
        console.log("err = ", err);
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        this.setState({
          disableAccountAccessInProgress: false,
        });
      });
  };

  createRemoteAccessDisableActivity = async () => {
    let activityPayload = {
      status: 1,
      userId: this.state.accountData?.id,
      activityType: this.props.activitiesTypes?.REMOTE_ACCESS_DISABLE,
      activity: activities?.remoteAccessDisable.replace(
        "{{admin_name}}",
        `${this.props.userData?.loggedInUser?.firstName} ${this.props.userData?.loggedInUser?.lastName}`,
      ),
      adminUserId: this.props.userData?.id,
      iconName: "AccountsActivity",
    };

    await this.props.createActivity(activityPayload);
  };

  onMessage = (data) => {
    const { messageBody: accountAccessRequests } = data;
    this.setState({
      accountData: { ...this.state.accountData, accountAccessRequests },
    });
  };

  render() {
    let {
      windowWidth,
      inProgress,
      accountData,
      activeTab,
      editMode,
      sidebar,
      updating,
      action,
      actionItem,
      isOpen,
      isOpenEditConfirmationModal,
      mainAccount,
      accountSearchValue,
      navBarCollapsed,
      isOpenAccountAccessRequestModal,
      isOpenDisableAccessModal,
    } = this.state;

    const { accountId } = this.props.match.params;
    const navItems = [
      {
        iconName: "Profile",
        label: "Account",
        disabled: !checkPermissions(
          [{ id: 3, subCat: [25] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "Listings",
        label: `Listings (${this.props.counts.listingsCount ?? 0})`,
        disabled: !checkPermissions(
          [{ id: 3, subCat: [26] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "File",
        label: `Invoices (${this.props.invoicesCount ?? 0})`,
        disabled: !checkPermissions(
          [{ id: 3, subCat: [27] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "Payment",
        label: `Payments (${this.props.totalRecordsPayments ?? 0})`,
        disabled: !checkPermissions(
          [{ id: 3, subCat: [28] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "Promotion",
        label: `Promotions (${this.state.promotionsCount || 0})`,
        disabled: !checkPermissions(
          [{ id: 3, subCat: [29] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "Report",
        label: "Reporting",
        disabled: !checkPermissions(
          [{ id: 3, subCat: [30] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "Marketing",
        label: `Communications (${this.props.emailsCount})`,
        disabled: !checkPermissions(
          [{ id: 3, subCat: [31] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "SendProof",
        label: "Sent proofs",
        disabled: !checkPermissions(
          [{ id: 3, subCat: [50] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "Todos",
        label: `Todo's (${this.props.counts.todosCount ?? 0})`,
        disabled: !checkPermissions(
          [{ id: 3, subCat: [32] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "TodoNote",
        label: `Notes (${this.props.counts.notesCount ?? 0})`,
        disabled: !checkPermissions(
          [{ id: 3, subCat: [33] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "Files",
        label: `Files (${this.props.counts.filesCount ?? 0})`,
        disabled: !checkPermissions(
          [{ id: 3, subCat: [34] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "ListingActivity",
        label: "Activity history",
        disabled: !checkPermissions(
          [{ id: 3, subCat: [35] }],
          this.props.adminPermissions,
        ),
      },
      {
        iconName: "Profile",
        label: `Users (${this.props.counts.subusersCount ?? 0})`,
        disabled: !checkPermissions(
          [{ id: 3, subCat: [36] }],
          this.props.adminPermissions,
        ),
      },
    ];

    return (
      <React.Fragment>
        {this.state.havePermission && (
          <div className={"account-no-permission"}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <h1>You don't have permission for this account</h1>
          </div>
        )}
        <div className="fts-action-bar-wrapper">
          <ActionBar
            adminPermissions={this.props.adminPermissions}
            isDeleted={accountData?.isDeleted}
            account
            accountData={accountData}
            callToAction={this.callToAction}
            toggle={this.toggle}
          />
        </div>
        <div className="acc-list-flex-wrapper">
          <div
            className={`fts-account-nav-wrapper ${
              windowWidth >= 769 ? "nav-vertical" : "account-setting-wrapper"
            }`}
          >
            <div
              style={{
                maxWidth: activeTab === 8 || navBarCollapsed ? 65 : 216,
                marginLeft: activeTab === 8 || 0,
              }}
              className="fts-nav-container"
            >
              <Nav className="account-settings-tab nav-left mr-0 mr-sm-3" tabs>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <Icon name="ArrowLeft" size={20} />
                    {!navBarCollapsed && activeTab !== 8 && (
                      <span className="d-md-inline-block d-none align-middle ml-1">
                        Back
                      </span>
                    )}
                  </NavLink>
                </NavItem>
                {activeTab !== 8 && !navBarCollapsed && (
                  <NavItem
                  // style={{
                  //   marginLeft: activeTab === 5 ? "-20px" : 0,
                  // }}
                  >
                    {this.state.accountData && (
                      <NavLink>
                        <AccountBox
                          accountInfo={this.state.accountData}
                          listingNav={true}
                        />
                      </NavLink>
                    )}
                  </NavItem>
                )}

                {navItems.map(({ iconName, label, disabled }, index) => (
                  <NavItem key={label}>
                    <NavLink
                      style={
                        disabled ? { opacity: 0.2, cursor: "default" } : null
                      }
                      className={classnames({
                        active: activeTab === index,
                      })}
                      onClick={() => {
                        if (disabled) return;
                        this.toggle(index);
                        // if (navLink !== "/tasks-management") {
                        //   return this.props.history.push(navLink);
                        // } else {
                        //   return this.props.history.push({
                        //     pathname: navLink,
                        //     search: `?accountId=${this.state.accountData.id}`,
                        //   });
                        // }
                      }}
                    >
                      <Icon name={iconName} size={20} />
                      <span className="d-md-inline-block d-none align-middle ml-1">
                        {navBarCollapsed || activeTab === 8 ? "" : label}
                      </span>
                    </NavLink>
                  </NavItem>
                ))}

                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.handleNavBarCollapse();
                    }}
                    className={`nav-collapse-button no-select ${
                      navBarCollapsed ? "collapsed-button" : ""
                    }`}
                    disabled={activeTab === 8}
                  >
                    <Icon name="CollapseLeft" size={16} color={"#7D8C91"} />
                    <span
                      className="d-md-inline-block d-none align-middle ml-1"
                      style={{ color: "#8C919F" }}
                    >
                      {navBarCollapsed || activeTab === 8 ? "" : "Collapse"}
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            {!!accountData && (
              <TabContent activeTab={activeTab}>
                <TabPane tabId={0}>
                  <span className="brand-border-top">
                    <Card>
                      <span
                        className={"brand-border"}
                        style={{
                          background: USER_TYPE_COLOR[accountData.userType],
                        }}
                      />

                      <CardBody className="fts-account-tab-wrapper">
                        <div style={{ padding: "0 2.5rem" }}>
                          <AccountInfo
                            adminPermissions={this.props.adminPermissions}
                            removeAltContacts={this.removeAltContacts}
                            updateAltContactAction={this.updateAltContactAction}
                            setNewAltContacts={this.setNewAltContacts}
                            userData={this.props.userData}
                            getAccountInfo={this.getAccountInfo}
                            getFilesByAssociation={
                              this.props.getFilesByAssociation
                            }
                            {...{ accountData, inProgress, editMode }}
                            accountAccessRequests={
                              this.state.accountData?.accountAccessRequests
                            }
                            toggleReassignListingModal={
                              this.toggleReassignListingModal
                            }
                            userListingsRef={this.userListingsRef}
                            onUserUpdate={this.updateAccountDataAction}
                            getAltContactInfo={this.getAccountAltContactInfo}
                            accountFormRef={this.accountFormRef}
                            onEditToggle={this.toggleEdit}
                            toggleModal={this.toggleModal}
                            toggleEditConfirmationModal={
                              this.toggleEditConfirmationModal
                            }
                            callToAction={this.callToAction}
                            onKeyDown={this.onKeyDown}
                            collectDataForCopy={this.collectDataForCopy}
                            isOpenAccountAccessRequestModal={
                              this.state.isOpenAccountAccessRequestModal
                            }
                            toggleAccountAccessRequestModal={
                              this.toggleAccountAccessRequestModal
                            }
                            toggleDisableAccessModal={
                              this.toggleDisableAccessModal
                            }
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </span>
                </TabPane>
                <TabPane tabId={1}>
                  <UserListings
                    getUserListings={this.props.getUserListings}
                    onRef={(ref) => (this.userListingsRef = ref)}
                    saveData={this.setAccountListings}
                    callToAction={this.callToAction}
                    accountData={accountData}
                    {...{ accountId }}
                  />
                </TabPane>
                <TabPane tabId={2}>
                  <InvoicesPage
                    accountId={accountData.id}
                    invoiceToOpen={parseInt(extractParameter("invoiceId"))}
                    invoicesRef={this.invoicesRef}
                    accountData={accountData}
                  />
                </TabPane>
                <TabPane tabId={3}>
                  <PaymentsList
                    accountId={accountData.id}
                    invoiceToOpen={parseInt(extractParameter("invoiceId"))}
                    invoicesRef={this.invoicesRef}
                    accountData={accountData}
                  />
                </TabPane>
                <TabPane tabId={4}>
                  <Promotions
                    adminIsMerchant={accountData.adminIsMerchant}
                    accountId={accountData.id}
                    onRef={(ref) => (this.promotionRef = ref)}
                    setPromotionsCount={this.setPromotionsCount}
                  />
                </TabPane>
                <TabPane tabId={5}>
                  {activeTab === 5 && (
                    <AccountReport
                      callToAction={this.callToAction}
                      accountId={this.props.match.params.accountId}
                      accountData={this.state.accountData}
                      previewAccountReport={this.previewAccountReport}
                      onRef={(ref) => (this.reportsRef = ref)}
                    />
                  )}
                </TabPane>
                <TabPane tabId={6}>
                  <Marketing
                    accountId={accountData.id}
                    accountData={accountData}
                  />
                </TabPane>
                <TabPane tabId={7}>
                  {activeTab === 7 && (
                    <SentProofsAccount userId={accountData.id} />
                  )}
                </TabPane>
                <TabPane tabId={8}>
                  {activeTab === 8 && (
                    <AccountTodo
                      accountInfo={accountData}
                      accountId={accountData.id}
                    />
                  )}
                </TabPane>
                <TabPane tabId={9}>
                  <Notes
                    title="Notes"
                    isListing={false}
                    getUserData={this.props.getUserData}
                    getAccountInfo={this.getAccountInfo}
                    userId={accountData.id}
                    onRef={(ref) => (this.notes = ref)}
                    adminData={this.props.userData}
                    sidebarChild={this.sidebarChild}
                    state={this.state}
                    {...{ accountId }}
                    openSidebar={this.openSidebar}
                    callToAction={this.callToAction}
                    updateAccountCounts={this.updateAccountCounts}
                  />
                </TabPane>
                <TabPane tabId={10}>
                  {activeTab === 10 && (
                    <UserFiles
                      accountData={accountData}
                      imageLink={accountData.image}
                      filesTabRef={(ref) => (this.filesTabRef = ref)}
                      filesTableGroupRef={(ref) =>
                        (this.filesTableGroupRef = ref)
                      }
                      getFilesByAssociation={this.props.getFilesByAssociation}
                      saveData={this.saveUserFiles}
                      accountCompany={accountData.companyName}
                      getAccountInfo={this.getAccountInfo}
                      toggleNotifyUser={this.toggleNotifyUser}
                      notifyUser={this.state.notifyUser}
                      {...{ accountId }}
                    />
                  )}
                </TabPane>
                <TabPane tabId={11}>
                  {activeTab === 11 && (
                    <ActivityHistory
                      accountId={accountId}
                      callToAction={this.callToAction}
                      setActivityPreloadDate={this.setActivityPreloadDate}
                    />
                  )}
                </TabPane>
                <TabPane tabId={12}>
                  <CardBody className="fts-account-tab-wrapper">
                    <AccountSubUsers {...{ accountId, accountData }} />
                  </CardBody>
                </TabPane>
              </TabContent>
            )}

            <ActionSidebar
              invoicesRef={this.invoicesRef}
              toggleTableOverlay={this.promotionRef.toggleTableOverlay}
              onSubmitQuery={this.promotionRef.onSubmitQuery}
              setFormikSidebarRef={this.setFormikSidebarRef}
              resetFormikForm={this.resetFormikForm}
              adminData={this.props.userData}
              accountInfo={{
                ...this.state.accountData,
                userId: this.props.userData.id,
              }}
              show={sidebar}
              action={action}
              updating={updating}
              setEditMode={this.setEditMode}
              actionItem={actionItem}
              listings={this.state.accountListings}
              handleSidebar={this.handleSidebar}
              promotionType="accountPromotion"
              getAccountInfo={this.getAccountInfo}
              filesTableGroupRef={this.filesTableGroupRef}
              onPrefillListingData={this.onPrefillListingData}
              noMarker={
                action === "update_listing" || action === "payment_overview"
              }
              callToAction={this.callToAction}
              // onPromotionCreate={this.onPromotionCreate}
              setPromotionsCount={this.setPromotionsCount}
              setLastPaymentModalTitle={this.setLastPaymentModalTitle}
              setActivityPreloadDate={this.setActivityPreloadDate}
              activityPreloadDate={this.state.activityPreloadDate}
              accountReportId={this.state.accountReportId}
              accountReportTitle={this.state.accountReportTitle}
              refetchReports={this.reportsRef.refetchReports}
              key={this.state.accountReportTitle}
              sentProofsRef={this.sentProofsRef}
              updateAccountActivities={this.updateAccountActivities}
              promotionRef={this.promotionRef}
              setListingData={this.setListingData}
              listingData={this.state.setListingData}
              toggleReassignListingModal={this.toggleReassignListingModal}
              forceShowAssociate
            />
          </div>

          <FTSModal
            isOpen={this.state.isOpenReassignListingModal}
            modalClasses={"fts-reassign-acc-list-modal"}
            centered={true}
            size="lg"
            footerCTA={() => {
              return (
                <>
                  <Button
                    onClick={this.toggleReassignListingModal}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      this.toggleReassignConfirmationModal();
                      this.toggleReassignListingModal();
                    }}
                    color="primary"
                    disabled={!mainAccount}
                  >
                    Link account
                  </Button>
                </>
              );
            }}
          >
            <div className="fts-reassign-acc-list-modal-body">
              <span className="fts-reassign-acc-list-modal-body-wrapper">
                <span className="fts-reassign-acc-list-modal-title">
                  <h3>Choose account to link to</h3>
                  <CloseButton onClick={this.toggleReassignListingModal} />
                </span>
                <FormGroup className="position-relative has-icon-left">
                  <Input
                    type="text"
                    placeholder="Search account"
                    className="fts-reassign-acc-list-modal-search"
                    onChange={this.updateSearchQuery}
                    value={accountSearchValue}
                  />
                  <div className="form-control-position">
                    <Icon name="Search" size={18} />
                  </div>
                </FormGroup>
                <div
                  style={{ height: "350px", overflowY: "scroll" }}
                  id="scrollableDiv_ReassignListing"
                >
                  <InfiniteScroll
                    dataLength={this.state.accounts.length}
                    next={this.updateAccountSearchResults}
                    hasMore={true}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>You have seen all accounts</b>
                      </p>
                    }
                    loader={
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          marginTop: "1rem",
                          marginBottom: "2rem",
                        }}
                      >
                        {accountSearchValue &&
                          this.state.accounts.length === 0 &&
                          this.state.isSearching === false && (
                            <p>No results found</p>
                          )}

                        {this.state.hasMore && (
                          <>
                            <span
                              style={{ marginRight: "1rem", color: "#32383A" }}
                            >
                              <Spinner size="sm" />
                            </span>
                            <span style={{ color: "#32383A" }}>
                              Loading more items
                            </span>
                          </>
                        )}
                      </div>
                    }
                    scrollableTarget="scrollableDiv_ReassignListing"
                  >
                    {this.state.accounts.map((account, index) => {
                      if (
                        account.id !==
                          parseInt(this.state.accountData.accountLink) &&
                        account.id !== this.state.accountData.id
                      ) {
                        return (
                          <div
                            className="reassign-row"
                            key={index}
                            onClick={() => {
                              this.onReassignChange(mainAccount, account);
                            }}
                          >
                            <Checkbox
                              className="reassign-checkbox"
                              color="#647074"
                              icon={<Check className="vx-icon" size={16} />}
                              defaultChecked={false}
                              checked={mainAccount?.id === account.id}
                            />
                            #{account.id} - {account.companyName},{" "}
                            {account.city}, {account.state}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </InfiniteScroll>
                </div>
              </span>
            </div>
          </FTSModal>

          {!!accountData && (
            <AccountSidebar
              adminID={this.props.userData.loggedInUser.id}
              onRef={(ref) => (this.sidebarChild = ref)}
              accountID={this.props.match.params.accountId}
              show={activeTab === 0 && !editMode}
              callToAction={this.callToAction}
              isListing={false}
              switchSideTab={this.toggle}
              openSidebar={this.openSidebar}
              activities={[]}
            />
          )}
        </div>
        <ContextMenu
          style={{
            minWidth: 250,
            zIndex: 1000,
            display: !this.state.collectedDataForCopy && "none",
          }}
          id="contextMenuAccountSettings"
          preventHideOnScroll={false}
        >
          <MenuItem
            onClick={() =>
              navigator.clipboard.writeText(this.state.collectedDataForCopy)
            }
          >
            <FaRegCopy size={18} className="copy" />
            <span className="ml-1 text-dark">Copy</span>
          </MenuItem>

          <MenuItem>
            <a
              className="text-decoration-none text-dark d-flex align-items-center w-100"
              href={`tel:${this.state.collectedDataForCopy}`}
            >
              <Icons name="PhoneBlue" />
              <div className="ml-1">
                Call - {this.state.collectedDataForCopy}
              </div>
            </a>
          </MenuItem>
        </ContextMenu>

        {isOpenAccountAccessRequestModal && (
          <AccountAccessRequestModal
            accountId={this.state.accountData?.id}
            accountLink={this.state.accountData?.accountLink}
            closeModal={this.toggleAccountAccessRequestModal}
            setAccountAccessRequests={this.setAccountAccessRequests}
            getAccountInfo={this.getAccountInfo}
          />
        )}

        {isOpenDisableAccessModal && (
          <DisableAccessModal
            toggleModal={this.toggleDisableAccessModal}
            closeModal={this.toggleDisableAccessModal}
            confirmAction={this.confirmDisableAccessHandler}
            disableAccountAccessInProgress={
              this.state.disableAccountAccessInProgress
            }
            title={"Disable Remote Access"}
            confirmBtnText={"Yes, Disable"}
            Message={() => {
              return (
                <div>
                  <p>
                    This will prevent Find Truck Service support from having
                    remote access to this account. Are you sure you want to
                    disable remote access?
                  </p>
                </div>
              );
            }}
          />
        )}

        <ReassignModal
          reassignAccount
          closeModal={this.toggleReassignConfirmationModal}
          confirmModal={this.handleReassign}
          oldAccount={this.state.accountData}
          newAccount={mainAccount}
          isOpen={this.state.isOpenReassignConfirmationModal}
        />

        <ConfirmationModal
          title="Unsaved Changes"
          message="Are you sure you want to proceed with this action?"
          onClose={this.onModalClosed}
          closeModal={this.toggleModal}
          confirmModal={this.closeSideOutAction}
          {...{ isOpen }}
        />
        <ConfirmationModal
          title="Unsaved Changes"
          message="Are you sure you want to proceed with this action?"
          closeModal={this.toggleEditConfirmationModal}
          confirmModal={async () => {
            if (this.state.newAltContacts.length) {
              await this.handleDeleteAltContacts();
            }
            this.toggleEditConfirmationModal();
            this.toggleEdit();
            if (this.accountFormRef && this.accountFormRef.current) {
              this.accountFormRef.current.resetForm();
            }
          }}
          isOpen={isOpenEditConfirmationModal}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    counts: state.accountCounts,
    accountSettingsState: state.accountCounts.accountSettingsState,
    promotionsCount: state.promotions.totalActiveRecordsAccount,
    promotions: state.promotions,
    types: state.essentialLists.types,
    activitiesTypes: state.essentialLists.activitiesTypes,
    invoicesCount: state.invoices.allInvoicesCount,
    totalRecordsPayments: state.invoices.allPaymentsCount,
    emailsCount: state.marketingReducer.emailsCount,
    adminPermissions: state.auth.login.permissions,
  };
};

const DeviceTypeWrapper = withDeviceType(AccountSettings);

export default connect(mapStateToProps, {
  getUserData,
  updateUserData,
  getAccountCounts,
  getUserListings,
  getFilesByAssociation,
  createNewNote,
  updateNoteById,
  getAccountAltContacts,
  setAccountSettingsState,
  resetAccountSettingsState,
  searchVendorAccounts,
  createActivity,
  addUserActivity,
  clearUserActivityList,
  createTodo,
  addUserTodo,
  assignTodoToAccount,
  getUpdatesHistory,
  updateUpdatesHistory,
  fetchActivitiesAccount,
  setUserActivities,
  removeAccountInfoAltContact,
  updateAccountAccessRequest,
})(DeviceTypeWrapper);

export const getPermissionIdBasedOnAdminStatus = (adminStatus) => {
  let subPermissionId;
  switch (adminStatus) {
    case 1:
      subPermissionId = 45;
      break;
    case 2:
      subPermissionId = 46;
      break;
    case 3:
      subPermissionId = 47;
      break;
    case 6:
      subPermissionId = 48;
      break;
    case 11:
      subPermissionId = 49;
      break;

    default:
      subPermissionId = 45;
      break;
  }
  return subPermissionId;
};
