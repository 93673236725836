import React from "react";
import { PendingListingProofFooter } from "./PendingListingProofFooter";
import { NotSendListingProofFooter } from "./NotSendListingProofFooter";
import { RejectedListingProofFooter } from "./RejectedListingProofFooter";
import { FtsSideOutWrapperFooter } from "../dist/ui/src/FtsSideOut/FtsSideOutWrapperFooter";

export const SideOutListingProofFooter = () => {
  return (
    <FtsSideOutWrapperFooter py={"lg"} px={"3xl"}>
      <RejectedListingProofFooter />
      <PendingListingProofFooter />
      <NotSendListingProofFooter />
    </FtsSideOutWrapperFooter>
  );
};
