import { combineReducers } from "redux";
import calenderReducer from "./calendar/";
import promotionsReducer from "./promotions/";
import invoicesReducer from "./invoices/";
import marketingReducer from "./marketing/";
import promoCodesReducer from "./promocodes/";
import customizer from "./customizer/";
import auth from "./auth/";
import categoriesList from "./categories";
import userTypesList from "./user-types";
import todoTypesList from "./todo-types";
import permissionsList from "./permissions";
import adsList from "./ads";
import approvedIPs from "./approvedIPs";
import adminUsersList from "./adminUsers";
import serviceAmenitiesList from "./serviceAmenities";
import amenitiesList from "./amenities";
import subcategoriesList from "./subcategories";
import essentialLists from "./essentialLists";
import searchFilter from "./searchFilter";
import accountNotes from "./user-notes";
import accountTodos from "./user-todos";
import accountActivities from "./user-activities";
import ListingReducer from "./listings";
import AccountReducer from "./accounts";
import remindersReducer from "./task-managment";
import SalesReducer from "./sales";
import ActivitiesReducer from "./activities";
import NotificationReducer from "./notifications";
import RenewalsFilterReducer from "./renewals";
import UpgradesReducer from "./upgrades";
import AccountListingsReducer from "./accountListings";
import AccountInvoicesReducer from "./accountInvoices";
import ListingInvoicesReducer from "./listingInvoices";
import AccountPaymentsReducer from "./accountPayments";
import ListingPaymentsReducer from "./listingPayments";
import sideOutState from "./v2/sideOut";
import listingState from "./v2/listing";
import accountState from "./v2/account";
import creditCardsState from "./v2/creditCards";
import altContactsState from "./v2/altContacts";
import searchState from "./v2/search";
import accountProofsState from "./v2/account-proofs";
import proofState from "./v2/proof";
import { connectRouter } from "connected-react-router";
import shareProvidersState from "./v2/shareProviders";
import sendListingState from "./v2/sendListing";

const rootReducer = (allReducers) =>
  combineReducers({
    calendar: calenderReducer,
    promotions: promotionsReducer,
    invoices: invoicesReducer,
    promoCodes: promoCodesReducer,
    customizer,
    marketingReducer,
    adsList,
    adminUsersList,
    approvedIPs,
    serviceAmenitiesList,
    amenitiesList,
    permissionsList,
    userTypesList,
    todoTypesList,
    categoriesList,
    subcategoriesList,
    auth,
    essentialLists,
    searchFilter,
    accountNotes,
    accountTodos,
    accountActivities,
    listingCounts: ListingReducer,
    accountCounts: AccountReducer,
    remindersReducer,
    sales: SalesReducer,
    activities: ActivitiesReducer,
    notifications: NotificationReducer,
    renewalsFilter: RenewalsFilterReducer,
    upgrades: UpgradesReducer,
    accountListings: AccountListingsReducer,
    accountInvoices: AccountInvoicesReducer,
    listingInvoices: ListingInvoicesReducer,
    router: connectRouter(allReducers),
    accountPayments: AccountPaymentsReducer,
    listingPayments: ListingPaymentsReducer,

    // All new reducer need to have state in suffix e.g. listingsState
    listingState,
    sideOutState,
    accountState,
    creditCardsState,
    altContactsState,
    searchState,
    accountProofsState,
    proofState,
    shareProvidersState,
    sendListingState,
  });

export default rootReducer;
