import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { selectListingData } from "../../../../redux/reducers/v2/listing";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";

export const ImageItem = ({ name, resetFileInput }) => {
  const dispatch = useDispatch();
  const listingData = useSelector(selectListingData);

  const onRemoveImage = () => {
    resetFileInput();
    const data = { ...listingData, image: "", fileForUpload: null };
    dispatch(getListingDataByIdSuccess({ data }));
  };

  return (
    <FileItemWrapper>
      <FtsWrapper display="flex" align="center" gap="lg">
        <FtsIcons
          iconName={IconNames.PhotoIcon}
          svgProps={{ width: 40, height: 40 }}
        />
        <Span
          overflowWrap={"anywhere"}
          weight={WeightSize.medium}
          color={ColorsValue["greyscale-800"]}
          variant={VariantsValues["text-2xs"]}
          align={"left"}
        >
          {name}
        </Span>
      </FtsWrapper>
      <Button
        size={ButtonSizes.sm}
        onClick={onRemoveImage}
        type={ButtonTypes.text}
        iconName={IconNames.Close}
        color={ButtonColorsValue.neutral}
      />
    </FileItemWrapper>
  );
};

const FileItemWrapper = styled(FtsWrapper).attrs({
  bg: ColorsValue["greyscale-50"],
  justify: "space-between",
  borderRadius: "2xs",
  display: "flex",
  align: "center",
  gap: "lg",
  mb: "md",
  p: "lg",
})``;
