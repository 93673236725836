import React from "react";
import { useSelector } from "react-redux";
import { selectProofData } from "../../../redux/reducers/v2/proof";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ListingItem } from "@find-truck-service/ui/src/components/react/ListingItem";
import { SideOutListingProofRejectedMessage } from "./SideOutListingProofRejectedMessage";
import { ListingItemsTypeValues } from "@find-truck-service/types/ui/listingItemComponent";
import { SideOutListingProofNotSendMessage } from "./SideOutListingProofNotSendMessage";
import { SideOutListingProofPendingMessage } from "./SideOutListingProofPendingMessage";

export const SideOutListingProofContent = () => {
  const proofData = useSelector(selectProofData);

  return (
    <FtsWrapper py={"2xl"} px={"3xl"} height={"100%"}>
      <SideOutListingProofRejectedMessage />
      <SideOutListingProofNotSendMessage />
      <SideOutListingProofPendingMessage />

      <FtsWrapper mt={"3xl"}>
        <ListingItem
          listingData={proofData.listingData}
          type={ListingItemsTypeValues.SEARCH}
        />
      </FtsWrapper>
    </FtsWrapper>
  );
};
