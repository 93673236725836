import React, { useCallback } from "react";
import {
  closeSideOutAction,
  closeSubSideOutAction,
  openEditListingSubSideOutAction,
  openListingProofSideOutAction,
  openSendListingSubSideOutAction,
} from "../../../redux/actions/v2/sideOut";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonTypes,
} from "@find-truck-service/types/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { selectListingData } from "../../../redux/reducers/v2/listing";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { FtsSideOutWrapperFooter } from "@find-truck-service/ui/src/FtsSideOut/FtsSideOutWrapperFooter";
import { selectSideOutGetListingInfo } from "../../../redux/reducers/v2/sideOut";

export const SideOutListingPreviewFooter = () => {
  const dispatch = useDispatch();
  const listingData = useSelector(selectListingData);
  const getListingInfo = useSelector(selectSideOutGetListingInfo);

  const updateListing = useCallback(() => {
    dispatch(
      openEditListingSubSideOutAction({
        onSuccess: () => {
          dispatch(closeSubSideOutAction());
          if (listingData.priceId !== 5)
            return dispatch(
              openListingProofSideOutAction({
                data: { proofAdId: listingData.id },
              }),
            );

          if (getListingInfo && typeof getListingInfo === "function") {
            getListingInfo();
          }
          dispatch(closeSideOutAction());
        },
      }),
    );
  }, [listingData]);

  const updateAccount = useCallback(() => {
    window.open(`/accounts/${listingData.userId}`, "_blank");
  }, [listingData]);

  const sendListing = useCallback(() => {
    // dispatch(setSendProofData({}));
    dispatch(
      openSendListingSubSideOutAction({
        data: {
          sendListingAdId: listingData.id,
          sendListingUserId: listingData.userId,
        },
      }),
    );
  }, [listingData]);

  return (
    <FtsSideOutWrapperFooter py={"lg"} px={"3xl"}>
      <FtsColumn size={{ xs: 12 }} textAlign={"end"}>
        <FtsWrapper
          display={"flex"}
          align={"center"}
          justify={"flex-end"}
          gap={"lg"}
        >
          <Button
            onClick={updateAccount}
            type={ButtonTypes.text}
            color={ButtonColorsValue.accent}
            size={ButtonSizes.sm}
            label={"Account"}
            iconName={IconNames.ExternalLink}
          />
          <Button
            onClick={updateListing}
            type={ButtonTypes.outlined}
            color={ButtonColorsValue.accent}
            size={ButtonSizes.sm}
            label={"Edit"}
            iconName={IconNames.Edit}
          />

          <Button
            onClick={sendListing}
            type={ButtonTypes.contained}
            color={ButtonColorsValue.accent}
            size={ButtonSizes.sm}
            label={"Send listing"}
            iconName={IconNames.Send}
          />
        </FtsWrapper>
      </FtsColumn>
    </FtsSideOutWrapperFooter>
  );
};
