import React from "react";
import { SelectedButton } from "./SelectedButton";
import { FtsRow } from "../../../../FtsRow/FtsRow";
import { FtsColumn } from "../../../../FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "../../../../../../types/ui/button";
import { getShowBenefits } from "@find-truck-service/ui/src/components/react/ListingItem/helpers";
// interface ListingItemXsFooterProps {
//   priceId: number;
//   selected?: boolean;
//   onSelect: () => void;
//   primaryActionLabel: string;
//   onClickPreview: () => void;
//   onClickBenefits?: () => void;
// }
export const ListingItemXsFooter = (props) => {
    const { selected, onSelect, onClickBenefits, onClickPreview, primaryActionLabel, priceId } = props;
    const showBenefits = getShowBenefits(priceId);
    const showBenefitsCTA = showBenefits && onClickBenefits;
    return (React.createElement(FtsRow, { rowGap: "lg", columnGap: "lg", p: "xl", pt: "0" },
        React.createElement(FtsColumn, { size: { xs: 6 } },
            React.createElement(Button, { width: "100%", label: "Preview", size: ButtonSizes.md, onClick: onClickPreview, color: ButtonColorsValue.neutral, type: ButtonTypes.outlined })),
        showBenefitsCTA && (React.createElement(FtsColumn, { size: { xs: 6 } },
            React.createElement(Button, { width: "100%", size: ButtonSizes.md, onClick: onClickBenefits, color: ButtonColorsValue.neutral, type: ButtonTypes.outlined, label: "Benefits" }))),
        React.createElement(FtsColumn, { size: { xs: showBenefitsCTA ? 12 : 6 } },
            !selected && (React.createElement(Button, { width: "100%", onClick: onSelect, size: ButtonSizes.md, color: ButtonColorsValue.accent, type: ButtonTypes.outlined, label: `${primaryActionLabel}` })),
            selected && React.createElement(SelectedButton, { type: ButtonTypes.outlined, width: "100%", onClick: onSelect }))));
};
