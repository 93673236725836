import React, { useEffect, useState } from "react";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { FullSizeModalContainer } from "@find-truck-service/ui/src/FtsModal/FtsModalContainer";
import { FtsFullSizeModalWrapper } from "@find-truck-service/ui/src/FtsModal/FtsModalWrapper";
export const FullSizeModal = (props) => {
    const [maxHeight, setMaxHeight] = useState("100dvh");
    const ModalContentComponent = props.modalContentComponent || EmptyComponent;
    const ModalHeaderComponent = props.modalHeaderComponent || EmptyComponent;
    const ModalFooterComponent = props.modalFooterComponent || EmptyComponent;
    useEffect(() => {
        if (!props.isOpen)
            return;
        visualViewport?.addEventListener("resize", () => {
            if (visualViewport?.height)
                setMaxHeight(visualViewport?.height + "px");
        });
    }, [visualViewport, props.isOpen]);
    useEffect(() => {
        if (props.isOpen)
            document.body.style.overflow = "hidden";
        else
            document.body.style.overflow = "unset";
    }, [props.isOpen]);
    return (React.createElement(FtsFullSizeModalWrapper, { isOpen: props.isOpen },
        React.createElement(FullSizeModalContainer, { isOpen: props.isOpen, maxHeight: maxHeight },
            React.createElement(ModalHeaderComponent, null),
            React.createElement(ModalContentComponent, null),
            React.createElement(ModalFooterComponent, null))));
};
