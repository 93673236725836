import styled from "styled-components";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span, TextStyle } from "@find-truck-service/ui/src/FtsText";
import { LineHeightVariantsValues, VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { inlineMessageSizes, inlineMessageTypes } from "@find-truck-service/types/constants/inlineMessage";
const inlineMessageProps = ["type", "size"];
const messageColor = {
    [inlineMessageTypes.info]: ColorsValue["blue-accent-500"],
    [inlineMessageTypes.success]: ColorsValue["green-success-500"],
    [inlineMessageTypes.warning]: ColorsValue["yellow-warning-800"],
    [inlineMessageTypes.error]: ColorsValue["red-error-500"],
    [inlineMessageTypes.completed]: ColorsValue["greyscale-300"],
};
const messageSizeStyle = {
    [inlineMessageSizes.sm]: `${TextStyle({
        weight: WeightSize.regular,
        variant: VariantsValues["text-3xs"],
        lineHeightVariant: LineHeightVariantsValues.tight,
    })}`,
    [inlineMessageSizes.md]: `${TextStyle({
        weight: WeightSize.regular,
        variant: VariantsValues["text-2xs"],
        lineHeightVariant: LineHeightVariantsValues.tight,
    })}`,
};
const messageDecorationStyle = {
    [inlineMessageTypes.completed]: `text-decoration: line-through;`,
};
const messageStyle = (props) => `
  ${messageSizeStyle[props.size]}
  color: ${messageColor[props.type]};
  ${messageDecorationStyle[props.type]};
`;
const StyledInlineMessage = styled(Span).withConfig({
    shouldForwardProp: (prop) => !inlineMessageProps.includes(prop),
}) ``;
export const FtsInlineMessageSpan = styled(StyledInlineMessage) `
  ${(props) => messageStyle(props)}
`;
