import React from "react";
import { getListingItemBg } from "../helpers";
import { ListingItemXsFooter } from "./ListingItemXsFooter";
import { ListingItemXsHeader } from "./ListingItemXsHeader";
import { ListingItemXsContent } from "./ListingItemXsContent";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
export const ListingItemXs = (props) => {
    const { type, price, showPrice, showFooter, priceId, boxShadow, onShowListingDetails } = props;
    const { selected, onSelect, onClickBenefits, onClickPreview, primaryActionLabel } = props;
    const showListingDetails = !!onShowListingDetails && props.showListingDetailsCTA;
    return (React.createElement(FtsWrapper, { width: "100%", borderRadius: "2xs", boxShadow: boxShadow, bg: getListingItemBg(priceId), display: { xs: "block", sm: "none", md: "none", lg: "none" } },
        showPrice && React.createElement(ListingItemXsHeader, { type: type, price: price, priceId: priceId }),
        React.createElement(ListingItemXsContent, { ...props }),
        showFooter && (React.createElement(ListingItemXsFooter, { priceId: priceId, selected: selected, onSelect: onSelect, onClickPreview: onClickPreview, onClickBenefits: onClickBenefits, primaryActionLabel: `${primaryActionLabel || "Select"} ${type}` })),
        showListingDetails && (React.createElement(FtsColumn, { size: { xs: 12 }, p: "xl", pt: "0" },
            React.createElement(Button, { width: "100%", size: ButtonSizes.md, label: "Listing Details", type: ButtonTypes.outlined, onClick: onShowListingDetails, color: ButtonColorsValue.neutral })))));
};
