import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { NO_DATA_CELL_MESSAGE } from "@find-truck-service/types/ui/table-cell";
import { FtsTableCell } from "@find-truck-service/ui/src/FtsTableCell/FtsTableCell";
export const TableCellNoData = (props) => {
    return (React.createElement(FtsTableCell, { active: props.active, isLoading: props.isLoading, onClick: props.onClick, size: props.size },
        React.createElement(Span, { variant: VariantsValues["text-3xs"], color: ColorsValue["greyscale-800"] }, NO_DATA_CELL_MESSAGE)));
};
