/* eslint-disable indent */
import React, { createRef } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Input,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { history } from "../../../history";
import SwitchFilter from "./SwitchFilter";
import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import { defineAccountColumns, defineListingColumns } from "./columns";
import {
  searchVendorAccounts,
  searchVendorAccountsUser,
} from "../../../redux/actions/users";
import {
  updateSearchFilter,
  clearSearchFilter,
} from "../../../redux/actions/searchFilter";
import { searchListings } from "../../../redux/actions/listings";
import SearchFilter from "./SearchFilter";

import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";
import "../../../assets/scss/plugins/tables/_agGridStyleOverride.scss";
import "../../../assets/scss/pages/users.scss";
import "../../../assets/scss/components/fts/form-fields.scss";

import { ContextMenu, MenuItem, hideMenu } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";
import { isRefreshing } from "../../../axios/fts.instance";
import Icons from "../../../components/fts/Icons";

const initialPaging = {
  page: 1,
  pageUser: 1,
  pagePremium: 1,
  pageStandard: 1,
  pageBasicPlus: 1,
  pageBasic: 1,
  pageFree: 1,
};
const sortTypes = {
  USERS: "users",
  VENDOR: "vendors",
  PREMIUM: "premium",
  STANDARD: "standard",
  BASIC_PLUS: "basicPlus",
  BASIC: "basic",
  FREE: "free",
};
class AccountsList extends React.Component {
  constructor(props) {
    super(props);

    this.accountColumnsVendor = defineAccountColumns(
      this.props.types,
      this.collectDataForCopy,
    );
    this.accountColumnsUser = defineAccountColumns(
      this.props.types,
      this.collectDataForCopy,
    );
    // this.listingColumns = defineListingColumns(this.props.types);
    this.listingColumnsPremium = defineListingColumns(
      this.props.types,
      this.collectDataForCopy,
    );
    this.listingColumnsStandard = defineListingColumns(
      this.props.types,
      this.collectDataForCopy,
    );
    this.listingColumnsBasicPlus = defineListingColumns(
      this.props.types,
      this.collectDataForCopy,
    );
    this.listingColumnsBasic = defineListingColumns(
      this.props.types,
      this.collectDataForCopy,
    );
    this.listingColumnsFree = defineListingColumns(
      this.props.types,
      this.collectDataForCopy,
    );
  }

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };
  state = {
    collectedDataForCopy: "",
    activeTabListing: "premium",
    activeTabAccount: "vendors",
    rowData: null,
    rowDataUser: null,
    premiumListingData: null,
    standardListingData: null,
    basicPlusListingData: null,
    basicListingData: null,
    freeListingData: null,
    inProgress: false,
    isVisible: true,
    collapse: true,
    initialSearch: true,
    page: 1,
    pageUser: 1,
    pagePremium: 1,
    pageStandard: 1,
    pageBasicPlus: 1,
    pageBasic: 1,
    pageFree: 1,
    searchType: "listing",
    totalRecords: 0,
    totalRecordsUser: 0,
    totalRecordsPremium: 0,
    totalRecordsStandard: 0,
    totalRecordsFree: 0,
    totalRecordsBasicPlus: 0,
    totalRecordsBasic: 0,
    pageCount: 0,
    pageCountUser: 0,
    pageCountPremium: 0,
    pageCountFree: 0,
    pageCountStandard: 0,
    pageCountBasic: 0,
    pageCountBasicPlus: 0,
    pageSize: 10,
    pageSizeUser: 10,
    pageSizePremium: 10,
    pageSizeStandard: 10,
    pageSizeBasicPlus: 10,
    pageSizeBasic: 10,
    accountSearchValue: "",
    listingSearchValue: "",
    pageSizeFree: 10,
    query: this.props.storedFilter,
    defaultColDef: {
      sortable: true,
    },
    columnDefs: this.accountColumns,
    sortAccountVendor: "",
    sortAccountUser: "",
    sortPremiumListing: "",
    sortBasicListing: "",
    sortBasicPlusListing: "",
    sortStandardListing: "",
    sortFreeListing: "",
  };

  searchRef = createRef();

  toggleCollapse = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  pageSizes = [10, 20, 25, 50, 100];

  componentDidMount() {
    const { listState } = this.props.storedFilter;

    if (listState?.sortPremiumListing) {
      const colIndex = this.listingColumnsPremium.findIndex(
        (e) => e.field === listState.sortPremiumListing[0].colId,
      );
      this.listingColumnsPremium[colIndex] = {
        ...this.listingColumnsPremium[colIndex],
        sort: listState.sortPremiumListing[0].sort,
      };
    }

    if (listState?.sortStandardListing) {
      const colIndex = this.listingColumnsStandard.findIndex(
        (e) => e.field === listState.sortStandardListing[0].colId,
      );
      this.listingColumnsStandard[colIndex] = {
        ...this.listingColumnsStandard[colIndex],
        sort: listState.sortStandardListing[0].sort,
      };
    }

    if (listState?.sortFreeListing) {
      const colIndex = this.listingColumnsFree.findIndex(
        (e) => e.field === listState.sortFreeListing[0].colId,
      );
      this.listingColumnsFree[colIndex] = {
        ...this.listingColumnsFree[colIndex],
        sort: listState.sortFreeListing[0].sort,
      };
    }

    if (listState?.sortBasicListing) {
      const colIndex = this.listingColumnsBasic.findIndex(
        (e) => e.field === listState.sortBasicListing[0].colId,
      );
      this.listingColumnsBasic[colIndex] = {
        ...this.listingColumnsBasic[colIndex],
        sort: listState.sortBasicListing[0].sort,
      };
    }

    if (listState?.sortBasicPlusListing) {
      const colIndex = this.listingColumnsBasicPlus.findIndex(
        (e) => e.field === listState.sortBasicPlusListing[0].colId,
      );
      this.listingColumnsBasicPlus[colIndex] = {
        ...this.listingColumnsBasicPlus[colIndex],
        sort: listState.sortBasicPlusListing[0].sort,
      };
    }
    if (listState?.sortAccountVendor) {
      const colIndex = this.accountColumnsVendor.findIndex(
        (e) => e.field === listState?.sortAccountVendor[0]?.colId,
      );
      this.accountColumnsVendor[colIndex] = {
        ...this.accountColumnsVendor[colIndex],
        sort: listState?.sortAccountVendor[0]?.sort,
      };
    }

    if (listState?.sortAccountUsers) {
      const colIndex = this.accountColumnsUser.findIndex(
        (e) => e.field === listState.sortAccountUsers[0]?.colId,
      );
      this.accountColumnsUser[colIndex] = {
        ...this.accountColumnsUser[colIndex],
        sort: listState.sortAccountUsers[0]?.sort,
      };
    }
    if (listState) {
      this.setState(listState, async () => {
        await Promise.all([
          this.updateListingSearchResults(),
          this.updateAccountSearchResults(),
          this.updateAccountSearchResultsUsers(),
        ]);
        this.setState({ inProgress: false });
      });
    } else {
      this.startSearching();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.updateSearchFilter({
        listState: {
          ...this.state,
          rowData: null,
          premiumListingData: null,
          standardListingData: null,
          basicPlusListingData: null,
          basicListingData: null,
          freeListingData: null,
          inProgress: true,
        },
      });
    }
  }

  updateSearchType = (name) => {
    this.setState({
      searchType: name,
    });
  };

  updateQuery = (e) => {
    console.log(e);
    this.setState({
      query: {
        ...this.state.query,
        [e.target.name]: e.target.value,
      },
    });
  };

  onStateUpdate = (data) => {
    const state = data || null;
    this.setState({
      query: { ...this.state.query, state },
    });
  };

  onUpdateActivationStatus = (data) => {
    const status = data || null;
    this.setState({
      query: { ...this.state.query, status },
    });
  };

  onStatusUpdate = (data) => {
    const adminStatus = data || null;
    this.setState({
      query: { ...this.state.query, adminStatus },
    });
  };

  onSubmitQuery = async () => {
    const { query, inProgress } = this.state;

    if (inProgress) {
      return;
    }

    this.setState({ inProgress: true });

    const selectedFilters = (({
      id,
      address,
      city,
      zip,
      status,
      state,
      firstName,
      lastName,
      phoneNumber,
      adminStatus,
      email,
      name,
      serviceAmenities,
      subCategory,
      includeInAuthorizedPromotions,
      isPrivate,
      // excludeRenewalEmails,
    }) => ({
      id,
      address,
      city,
      zip,
      status,
      state,
      firstName,
      lastName,
      phoneNumber,
      adminStatus,
      email,
      name,
      serviceAmenities,
      subCategory,
      includeInAuthorizedPromotions,
      isPrivate,
      // excludeRenewalEmails,
    }))(query);

    this.props.updateSearchFilter(selectedFilters);

    try {
      await Promise.all([
        this.updateListingSearchResults(),
        this.updateAccountSearchResults(),
        this.updateAccountSearchResultsUsers(),
      ]);
      this.setState({ inProgress: false });
    } catch (error) {
      if (error?.response?.status) {
        switch (error.response.status) {
          case 500:
            toast.error(error.response.data, {
              position: toast.POSITION.TOP_RIGHT,
            });
            break;

          default:
            toast.error("Something went wrong. Try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            break;
        }
      }
      this.setState({ inProgress: false });
    }
  };

  updateAccountSearchResultsUsers = () => {
    const {
      query,
      pageUser,
      pageSizeUser,
      sortAccountVendor,
      accountSearchValue,
    } = this.state;
    const apiAvailableAccount = !!this.gridApiAccountUser;
    if (apiAvailableAccount) {
      this.gridApiAccountUser.showLoadingOverlay();
    }

    return this.props
      .searchVendorAccountsUser({
        phoneNumber: query.phoneNumber,
        firstName: query.firstName,
        lastName: query.lastName,
        email: query.email,
        zip: query.zip,
        id: query.id,
        companyName: query.name,
        address: query.address,
        city: query.city,
        page: pageUser,
        resultsPerPage: pageSizeUser,
        state: query.state ? query.state.value : "",
        adminStatus: query.adminStatus ? query.adminStatus.value : "",
        status: query.status ? query.status.value : "",
        adminIsMerchant: !!query.includeInAuthorizedPromotions,
        havePrivateLocations: query.isPrivate,
        // excludeRenewalEmails: query.excludeRenewalEmails,
        // excludeDowngrades: query.excludeDowngrades,
        sort: sortAccountVendor,
        searchString: accountSearchValue,
      })
      .then(
        ({
          data: {
            result: { hits, nbHits },
          },
        }) => {
          if (apiAvailableAccount) {
            this.gridApiAccountUser.hideOverlay();
          }
          this.setState({
            columnDefs: this.accountColumns,
            rowDataUser: hits,
            totalRecordsUser: nbHits,
            pageCountUser: Math.ceil(nbHits / pageSizeUser),
          });
        },
      );
  };

  updateAccountSearchResults = () => {
    const { query, page, pageSize, sortAccountVendor, accountSearchValue } =
      this.state;
    const apiAvailableAccount = !!this.gridApiAccount;
    if (apiAvailableAccount) {
      this.gridApiAccount.showLoadingOverlay();
    }
    return this.props
      .searchVendorAccounts({
        page,
        resultsPerPage: pageSize,
        state: query.state ? query.state.value : "",
        adminStatus: query.adminStatus ? query.adminStatus.value : "",
        status: query.status ? query.status.value : "",
        phoneNumber: query.phoneNumber,
        companyName: query.name,
        firstName: query.firstName,
        lastName: query.lastName,
        email: query.email,
        zip: query.zip,
        id: query.id,
        address: query.address,
        city: query.city,
        adminIsMerchant: !!query.includeInAuthorizedPromotions,
        havePrivateLocations: query.isPrivate,
        // excludeRenewalEmails: query.excludeRenewalEmails,
        // excludeDowngrades: query.excludeDowngrades,
        sort: sortAccountVendor,
        searchString: accountSearchValue,
      })
      .then(
        ({
          data: {
            result: { hits, nbHits },
          },
        }) => {
          if (apiAvailableAccount) {
            this.gridApiAccount.hideOverlay();
          }
          this.setState({
            columnDefs: this.accountColumns,
            rowData: hits,
            totalRecords: nbHits,
            pageCount: Math.ceil(nbHits / pageSize),
          });
        },
      );
  };

  updateListingSearchResults = () => {
    const {
      query,
      pagePremium,
      pageStandard,
      pageBasicPlus,
      pageBasic,
      pageFree,
      pageSizePremium,
      pageSizeStandard,
      pageSizeBasicPlus,
      pageSizeBasic,
      pageSizeFree,
      listingColumns,
      listingSearchValue,
      sortPremiumListing,
      sortBasicListing,
      sortBasicPlusListing,
      sortStandardListing,
      sortFreeListing,
    } = this.state;
    console.log(query);
    const payload = {
      address: query.address,
      name: query.name,
      zip: query.zip,
      id: query.id,
      firstName: query.firstName,
      lastName: query.lastName,
      phoneNumber: query.phoneNumber,
      adsSubCategoriesStrings: query.subCategory ? query.subCategory : "",
      email: query.email,
      city: query.city,
      state: query.state ? query.state.value : "",
      adminStatus: query.adminStatus ? query.adminStatus.value : "",
      status: query.status ? query.status.value : "",
      includeInAuthorizedPromotions: query.includeInAuthorizedPromotions,
      isPrivate: query.isPrivate,
      // excludeRenewalEmails: query.excludeRenewalEmails,
      // excludeDowngrades: query.excludeDowngrades,
    };
    if (query.serviceAmenities && query.serviceAmenities.length > 0) {
      payload.adsServiceAmenitiesStrings = query.serviceAmenities.map(
        (e) => e.name,
      );
    }

    const promises = [
      this.props.searchListings({
        ...payload,
        priceId: 1,
        page: pagePremium,
        resultsPerPage: pageSizePremium,
        searchString: listingSearchValue,
        sort: sortPremiumListing,
      }),
      this.props.searchListings({
        ...payload,
        priceId: 2,
        page: pageStandard,
        resultsPerPage: pageSizeStandard,
        searchString: listingSearchValue,
        sort: sortStandardListing,
      }),
      this.props.searchListings({
        ...payload,
        priceId: 3,
        page: pageBasicPlus,
        resultsPerPage: pageSizeBasicPlus,
        searchString: listingSearchValue,
        sort: sortBasicPlusListing,
      }),
      this.props.searchListings({
        ...payload,
        priceId: 4,
        page: pageBasic,
        resultsPerPage: pageSizeBasic,
        searchString: listingSearchValue,
        sort: sortBasicListing,
      }),
      this.props.searchListings({
        ...payload,
        priceId: 5,
        page: pageFree,
        resultsPerPage: pageSizeFree,
        searchString: listingSearchValue,
        sort: sortFreeListing,
      }),
    ];
    const apiAvailableListing = !!this.gridApiPremium;

    if (apiAvailableListing) {
      this.gridApiPremium.showLoadingOverlay();
      this.gridApiStandard.showLoadingOverlay();
      this.gridApiBasicPlus.showLoadingOverlay();
      this.gridApiBasic.showLoadingOverlay();
      this.gridApiFree.showLoadingOverlay();
    }

    return Promise.all(promises)
      .then(
        ([premiumList, standardList, basicPlusList, basicList, freeList]) => {
          if (apiAvailableListing) {
            this.gridApiPremium.hideOverlay();
            this.gridApiStandard.hideOverlay();
            this.gridApiBasicPlus.hideOverlay();
            this.gridApiBasic.hideOverlay();
            this.gridApiFree.hideOverlay();
          }

          this.setState({
            premiumListingData: premiumList.data.result.hits,
            standardListingData: standardList.data.result.hits,
            basicPlusListingData: basicPlusList.data.result.hits,
            basicListingData: basicList.data.result.hits,
            freeListingData: freeList.data.result.hits,
            columnDefs: listingColumns,
            totalRecordsPremium: premiumList.data.result.nbHits,
            totalRecordsStandard: standardList.data.result.nbHits,
            totalRecordsBasicPlus: basicPlusList.data.result.nbHits,
            totalRecordsBasic: basicList.data.result.nbHits,
            totalRecordsFree: freeList.data.result.nbHits,
            pageCountPremium: Math.ceil(
              premiumList.data.result.nbHits / pageSizePremium,
            ),
            pageCountStandard: Math.ceil(
              standardList.data.result.nbHits / pageSizeStandard,
            ),
            pageCountBasicPlus: Math.ceil(
              basicPlusList.data.result.nbHits / pageSizeBasicPlus,
            ),
            pageCountBasic: Math.ceil(
              basicList.data.result.nbHits / pageSizeBasic,
            ),
            pageCountFree: Math.ceil(
              freeList.data.result.nbHits / pageSizeFree,
            ),
          });
        },
      )
      .catch((err) => {
        console.log("====================================");
        console.log("err = ", err);
        console.log("====================================");
      });
  };

  updatePremiumListingResults = () => {
    const {
      query,
      pagePremium,
      pageSizePremium,
      listingColumns,
      sortPremiumListing,
      listingSearchValue,
    } = this.state;

    const payload = {
      ...query,
      state: query.state ? query.state.value : "",
      adminStatus: query.adminStatus ? query.adminStatus.value : "",
      status: query.status ? query.status.value : "",
      // adsSubCategoriesStrings: query.subCategory ? query.subCategory : "",
    };

    const apiAvailableListing = !!this.gridApiPremium;

    if (apiAvailableListing) {
      this.gridApiPremium.showLoadingOverlay();
    }

    this.props
      .searchListings({
        ...payload,
        priceId: 1,
        page: pagePremium,
        resultsPerPage: pageSizePremium,
        sort: sortPremiumListing,
        searchString: listingSearchValue,
      })
      .then((res) => {
        if (apiAvailableListing) {
          this.gridApiPremium.hideOverlay();
        }
        this.setState({
          inProgress: false,
          premiumListingData: res.data.result.hits,
          columnDefs: listingColumns,
          totalRecordsPremium: res.data.result.nbHits,
          pageCountPremium: Math.ceil(res.data.result.nbHits / pageSizePremium),
        });
      })
      .catch((error) => {
        console.log("====================================");
        console.log("err = ", error);
        console.log("====================================");
      });
  };

  updateStandardListingResults = () => {
    const {
      query,
      pageStandard,
      pageSizeStandard,
      listingColumns,
      sortStandardListing,
      listingSearchValue,
    } = this.state;

    const payload = {
      ...query,
      state: query.state ? query.state.value : "",
      adminStatus: query.adminStatus ? query.adminStatus.value : "",
      status: query.status ? query.status.value : "",
    };

    const apiAvailableListing = !!this.gridApiStandard;

    if (apiAvailableListing) {
      this.gridApiStandard.showLoadingOverlay();
    }

    this.props
      .searchListings({
        ...payload,
        priceId: 2,
        page: pageStandard,
        resultsPerPage: pageSizeStandard,
        sort: sortStandardListing,
        searchString: listingSearchValue,
      })
      .then((res) => {
        if (apiAvailableListing) {
          this.gridApiStandard.hideOverlay();
        }
        this.setState({
          inProgress: false,
          standardListingData: res.data.result.hits,
          columnDefs: listingColumns,
          totalRecordsStandard: res.data.result.nbHits,
          pageCountStandard: Math.ceil(
            res.data.result.nbHits / pageSizeStandard,
          ),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateBasicPlusListingResults = () => {
    const {
      query,
      pageBasicPlus,
      pageSizeBasicPlus,
      listingColumns,
      sortBasicPlusListing,
      listingSearchValue,
    } = this.state;

    const payload = {
      ...query,
      state: query.state ? query.state.value : "",
      adminStatus: query.adminStatus ? query.adminStatus.value : "",
      status: query.status ? query.status.value : "",
    };

    const apiAvailableListing = !!this.gridApiBasicPlus;

    if (apiAvailableListing) {
      this.gridApiBasicPlus.showLoadingOverlay();
    }

    this.props
      .searchListings({
        ...payload,
        priceId: 3,
        page: pageBasicPlus,
        resultsPerPage: pageSizeBasicPlus,
        sort: sortBasicPlusListing,
        searchString: listingSearchValue,
      })
      .then((res) => {
        if (apiAvailableListing) {
          this.gridApiBasicPlus.hideOverlay();
        }
        this.setState({
          inProgress: false,
          basicPlusListingData: res.data.result.hits,
          columnDefs: listingColumns,
          totalRecordsBasicPlus: res.data.result.nbHits,
          pageCountBasicPlus: Math.ceil(
            res.data.result.nbHits / pageSizeBasicPlus,
          ),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateBasicListingResults = () => {
    const {
      query,
      pageBasic,
      pageSizeBasic,
      listingColumns,
      sortBasicListing,
      listingSearchValue,
    } = this.state;

    const payload = {
      ...query,
      state: query.state ? query.state.value : "",
      adminStatus: query.adminStatus ? query.adminStatus.value : "",
      status: query.status ? query.status.value : "",
    };

    const apiAvailableListing = !!this.gridApiBasic;

    if (apiAvailableListing) {
      this.gridApiBasic.showLoadingOverlay();
    }

    this.props
      .searchListings({
        ...payload,
        priceId: 4,
        page: pageBasic,
        resultsPerPage: pageSizeBasic,
        sort: sortBasicListing,
        searchString: listingSearchValue,
      })
      .then((res) => {
        if (apiAvailableListing) {
          this.gridApiBasic.hideOverlay();
        }

        this.setState({
          inProgress: false,
          basicListingData: res.data.result.hits,
          columnDefs: listingColumns,
          totalRecordsBasic: res.data.result.nbHits,
          pageCountBasic: Math.ceil(res.data.result.nbHits / pageSizeBasic),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateFreeListingResults = () => {
    const {
      query,
      pageFree,
      pageSizeFree,
      listingColumns,
      sortFreeListing,
      listingSearchValue,
    } = this.state;

    const payload = {
      ...query,
      state: query.state ? query.state.value : "",
      adminStatus: query.adminStatus ? query.adminStatus.value : "",
      status: query.status ? query.status.value : "",
    };

    const apiAvailableListing = !!this.gridApiFree;

    if (apiAvailableListing) {
      this.gridApiFree.showLoadingOverlay();
    }

    this.props
      .searchListings({
        ...payload,
        priceId: 5,
        page: pageFree,
        resultsPerPage: pageSizeFree,
        sort: sortFreeListing,
        searchString: listingSearchValue,
      })
      .then((res) => {
        if (apiAvailableListing) {
          this.gridApiFree.hideOverlay();
        }

        this.setState({
          inProgress: false,
          freeListingData: res.data.result.hits,
          columnDefs: listingColumns,
          totalRecordsFree: res.data.result.nbHits,
          pageCountFree: Math.ceil(res.data.result.nbHits / pageSizeFree),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onGridReadyAccount = (params) => {
    this.gridApiAccount = params.api;
    this.gridColumnApiAccount = params.columnApi;

    if (localStorage.getItem("accountColumnState") !== null) {
      this.gridColumnApiAccount.setColumnState(
        JSON.parse(localStorage.getItem("accountColumnState")),
      );
    }
  };

  onGridReadyAccountUser = (params) => {
    this.gridApiAccountUser = params.api;
    this.gridColumnApiAccountUser = params.columnApi;

    if (localStorage.getItem("accountUserColumnState") !== null) {
      this.gridColumnApiAccountUser.setColumnState(
        JSON.parse(localStorage.getItem("accountUserColumnState")),
      );
    }
  };

  onGridReadyPremium = (params) => {
    this.gridApiPremium = params.api;
    this.gridColumnApiPremium = params.columnApi;

    if (localStorage.getItem("premiumColumnState") !== null) {
      this.gridColumnApiPremium.setColumnState(
        JSON.parse(localStorage.getItem("premiumColumnState")),
      );
    }
  };

  onGridReadyStandard = (params) => {
    this.gridApiStandard = params.api;
    this.gridColumnApiStandard = params.columnApi;

    if (localStorage.getItem("standardColumnState") !== null) {
      this.gridColumnApiStandard.setColumnState(
        JSON.parse(localStorage.getItem("standardColumnState")),
      );
    }
  };

  onGridReadyBasicPlus = (params) => {
    this.gridApiBasicPlus = params.api;
    this.gridColumnApiBasicPlus = params.columnApi;

    if (localStorage.getItem("basicPlusColumnState") !== null) {
      this.gridColumnApiBasicPlus.setColumnState(
        JSON.parse(localStorage.getItem("basicPlusColumnState")),
      );
    }
  };

  onGridReadyBasic = (params) => {
    this.gridApiBasic = params.api;
    this.gridColumnApiBasic = params.columnApi;

    if (localStorage.getItem("basicColumnState") !== null) {
      this.gridColumnApiBasic.setColumnState(
        JSON.parse(localStorage.getItem("basicColumnState")),
      );
    }
  };

  onGridReadyFree = (params) => {
    this.gridApiFree = params.api;
    this.gridColumnApiFree = params.columnApi;

    if (localStorage.getItem("freeColumnState") !== null) {
      this.gridColumnApiFree.setColumnState(
        JSON.parse(localStorage.getItem("freeColumnState")),
      );
    }
  };

  saveStateAccount = () => {
    let accountColumnState = this.gridColumnApiAccount.getColumnState();
    localStorage.setItem(
      "accountColumnState",
      JSON.stringify(accountColumnState),
    );
  };

  saveStateAccountUser = () => {
    let accountUserColumnState = this.gridColumnApiAccountUser.getColumnState();
    localStorage.setItem(
      "accountUserColumnState",
      JSON.stringify(accountUserColumnState),
    );
  };

  saveStatePremium = () => {
    let premiumColumnState = this.gridColumnApiPremium.getColumnState();
    localStorage.setItem(
      "premiumColumnState",
      JSON.stringify(premiumColumnState),
    );
  };

  saveStateStandard = () => {
    let standardColumnState = this.gridColumnApiStandard.getColumnState();
    localStorage.setItem(
      "standardColumnState",
      JSON.stringify(standardColumnState),
    );
  };

  saveStateBasicPlus = () => {
    let basicPlusColumnState = this.gridColumnApiBasicPlus.getColumnState();
    localStorage.setItem(
      "basicPlusColumnState",
      JSON.stringify(basicPlusColumnState),
    );
  };

  saveStateBasic = () => {
    let basicColumnState = this.gridColumnApiBasic.getColumnState();
    localStorage.setItem("basicColumnState", JSON.stringify(basicColumnState));
  };

  saveStateFree = () => {
    let freeColumnState = this.gridColumnApiFree.getColumnState();
    localStorage.setItem("freeColumnState", JSON.stringify(freeColumnState));
  };

  resetApiAccount = () => {
    this.gridApiAccount = null;
  };

  resetApiAccountUser = () => {
    this.gridApiAccountUser = null;
  };

  resetApiPremium = () => {
    this.gridApiPremium = null;
  };

  resetApiStandard = () => {
    this.gridApiStandard = null;
  };

  resetApiBasicPlus = () => {
    this.gridApiBasicPlus = null;
  };

  resetApiBasic = () => {
    this.gridApiBasic = null;
  };

  resetApiFree = () => {
    this.gridApiFree = null;
  };

  debounce = setTimeout(() => {}, 300);

  updateSearchQuery = (e) => {
    if (isRefreshing) return;
    if (this.state.searchType === "account") {
      this.setState(
        {
          accountSearchValue: e.target.value,

          ...initialPaging,
        },
        () => {
          clearTimeout(this.debounce);
          this.debounce = setTimeout(() => {
            this.updateAccountSearchResults();
            this.updateAccountSearchResultsUsers();
          }, 300);
        },
      );
    } else {
      this.setState(
        {
          listingSearchValue: e.target.value,
          ...initialPaging,
        },
        () => {
          //todo use another debounce function
          clearTimeout(this.debounce);
          this.debounce = setTimeout(() => {
            this.updateListingSearchResults();
          }, 300);
        },
      );
    }
  };

  filterSize = (type, val) => {
    const { activeTabListing } = this.state;
    let currentPage = `page${type.split("pageSize").pop()}`;

    if (this.state.searchType === "account") {
      currentPage =
        this.state.activeTabAccount === "vendors" ? "page" : "pageUser";
    }

    this.setState(
      {
        [type]: val,
        [currentPage]: 1,
      },
      () => {
        if (this.state.searchType === "account") {
          if (this.state.activeTabAccount === "vendors") {
            this.updateAccountSearchResults();
          } else {
            this.updateAccountSearchResultsUsers();
          }
        } else {
          switch (activeTabListing) {
            case "premium":
              this.updatePremiumListingResults();
              break;
            case "standard":
              this.updateStandardListingResults();
              break;
            case "basicPlus":
              this.updateBasicPlusListingResults();
              break;
            case "basic":
              this.updateBasicListingResults();
              break;

            default:
              this.updateFreeListingResults();
              break;
          }
        }
      },
    );
  };

  onPageChanged = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ page: pageNum }, () => {
      this.updateAccountSearchResults();
    });
  };

  onPageChangedUser = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageUser: pageNum }, () => {
      this.updateAccountSearchResultsUsers();
    });
  };

  onPageChangedPremium = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pagePremium: pageNum }, () => {
      this.updatePremiumListingResults();
    });
  };

  onPageChangedStandard = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageStandard: pageNum }, () => {
      this.updateStandardListingResults();
    });
  };

  onPageChangedBasicPlus = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageBasicPlus: pageNum }, () => {
      this.updateBasicPlusListingResults();
    });
  };

  onPageChangedBasic = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageBasic: pageNum }, () => {
      this.updateBasicListingResults();
    });
  };

  onPageChangedFree = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageFree: pageNum }, () => {
      this.updateFreeListingResults();
    });
  };

  clearAllFilters = () => {
    let stateOptions;

    this.props.clearSearchFilter();
    stateOptions = {
      query: {
        ...this.state.query,
        id: "",
        name: "",
        phoneNumber: "",
        address: "",
        city: "",
        state: "",
        adminStatus: "",
        status: "",
        firstName: "",
        lastName: "",
        email: "",
        subCategory: "",
        serviceAmenities: [],
        includeInAuthorizedPromotions: 0,
        isPrivate: false,
        // excludeRenewalEmails: 0,
        // excludeDowngrades: 0,
      },
      listingSearchValue: "",
      accountSearchValue: "",
    };
    this.setState(stateOptions);
    this.startSearching();
  };

  startSearching = () => {
    const stateOptions = {
      pageBasic: 1,
      pageBasicPlus: 1,
      pagePremium: 1,
      pageStandard: 1,
      pageFree: 1,
      pageSizeBasic: 10,
      pageSizeBasicPlus: 10,
      pageSizePremium: 10,
      pageSizeStandard: 10,
      pageSizeFree: 10,
      rowData: null,
      page: 1,
      pageSize: 10,
      premiumListingData: null,
      freeListingData: null,
      basicListingData: null,
      basicPlusListingData: null,
      standardListingData: null,
    };

    this.setState(stateOptions, () => {
      this.onSubmitQuery();
    });

    if (this.state.initialSearch) {
      this.setState({
        initialSearch: false,
      });
    }
  };

  toggleListingTab = (tab) => {
    if (this.state.activeTabListing !== tab) {
      this.setState({ activeTabListing: tab });
      if (this.state.listingSearchValue) {
        this.updateListingByType(tab);
      }
    }
  };

  toggleAccountTab = (tab) => {
    if (this.state.activeTabAccount !== tab) {
      this.setState({ activeTabAccount: tab });
    }
  };

  handleKeyPress = (e) => {
    if (e.charCode === 13) {
      this.startSearching();
    }
  };

  generateTabsListing = () => {
    const tabs = [
      {
        label: "Premium",
        type: "premium",
        total: this.state.totalRecordsPremium,
      },
      {
        label: "Standard",
        type: "standard",
        total: this.state.totalRecordsStandard,
      },
      {
        label: "BasicPlus",
        type: "basicPlus",
        total: this.state.totalRecordsBasicPlus,
      },
      { label: "Basic", type: "basic", total: this.state.totalRecordsBasic },
      { label: "Free", type: "free", total: this.state.totalRecordsFree },
    ];

    return tabs.map((itm) => (
      <NavItem key={itm.type}>
        <NavLink
          className={classnames({
            active: this.state.activeTabListing === itm.type,
          })}
          onClick={() => {
            this.toggleListingTab(itm.type);
          }}
        >
          {itm.label} ({Number(itm.total.toFixed(1)).toLocaleString()})
        </NavLink>
      </NavItem>
    ));
  };

  generateTabsAccount = () => {
    const tabs = [
      {
        label: "Vendor Accounts",
        type: "vendors",
        total: this.state.totalRecords,
      },
      {
        label: "User Accounts",
        type: "users",
        total: this.state.totalRecordsUser,
      },
    ];

    return tabs.map((itm) => (
      <NavItem key={itm.type}>
        <NavLink
          className={classnames({
            active: this.state.activeTabAccount === itm.type,
          })}
          onClick={() => {
            this.toggleAccountTab(itm.type);
          }}
        >
          {itm.label} ({Number(itm.total.toFixed(1)).toLocaleString()})
        </NavLink>
      </NavItem>
    ));
  };

  onRowClicked = ({ data }) => {
    if (this.state.searchType === "listing") {
      history.push(`/listings/${data.id}`, { url: data.seo_url_identifier });
    } else {
      history.push(`/accounts/${data.id}`);
    }
  };

  onSortChanged = (params, type) => {
    switch (type) {
      case sortTypes.VENDOR:
        this.setState({ sortAccountVendor: params.api.getSortModel() });
        return this.updateAccountSearchResults();
      case sortTypes.USERS:
        this.setState({ sortAccountUsers: params.api.getSortModel() });
        return this.updateAccountSearchResultsUsers();

      case sortTypes.PREMIUM:
        this.setState({ sortPremiumListing: params.api.getSortModel() });
        return this.updatePremiumListingResults();
      case sortTypes.BASIC:
        this.setState({ sortBasicListing: params.api.getSortModel() });
        return this.updateBasicListingResults();
      case sortTypes.BASIC_PLUS:
        this.setState({ sortBasicPlusListing: params.api.getSortModel() });
        return this.updateBasicPlusListingResults();
      case sortTypes.STANDARD:
        this.setState({ sortStandardListing: params.api.getSortModel() });
        return this.updateStandardListingResults();
      case sortTypes.FREE:
        this.setState({ sortFreeListing: params.api.getSortModel() });
        return this.updateFreeListingResults();
      default:
        return null;
    }
  };

  updateListingByType = (type) => {
    switch (type) {
      case "premium":
        this.updatePremiumListingResults();
        break;
      case "standard":
        this.updateStandardListingResults();
        break;
      case "basicPlus":
        this.updateBasicPlusListingResults();
        break;
      case "basic":
        this.updateBasicListingResults();
        break;

      default:
        this.updateFreeListingResults();
        break;
    }
  };
  render() {
    const {
      activeTabListing,
      activeTabAccount,
      searchType,
      query,
      isVisible,
      rowData,
      rowDataUser,
      premiumListingData,
      pageCountUser,
      pageCountPremium,
      pageCountStandard,
      pageCountBasicPlus,
      pageCountBasic,
      pageCountFree,
      standardListingData,
      basicPlusListingData,
      basicListingData,
      freeListingData,
      pageSizePremium,
      pageSizeStandard,
      pageSizeBasicPlus,
      pageSizeBasic,
      pageSizeFree,
      defaultColDef,
      pageSize,
      pageSizeUser,
      totalRecords,
      totalRecordsUser,
      totalRecordsPremium,
      totalRecordsStandard,
      totalRecordsBasicPlus,
      totalRecordsBasic,
      totalRecordsFree,
      pageCount,
      page,
      pageUser,
      pagePremium,
      pageStandard,
      pageBasicPlus,
      pageBasic,
      pageFree,
      inProgress,
      accountSearchValue,
      listingSearchValue,
    } = this.state;

    const accountTotal = totalRecords + totalRecordsUser;
    const listingTotal =
      totalRecordsBasic +
      totalRecordsBasicPlus +
      totalRecordsFree +
      totalRecordsPremium +
      totalRecordsStandard;

    return (
      <Row className="app-user-list">
        <Col sm="12">
          <Card
            className={classnames("card-action card-reload search-card", {
              "d-none": isVisible === false,
            })}
          >
            <CardBody
              onKeyPress={this.handleKeyPress.bind(this)}
              className="search-box"
            >
              <SearchFilter
                {...{
                  query,
                }}
                updateState={this.onStateUpdate}
                updateActivationStatus={this.onUpdateActivationStatus}
                updateStatus={this.onStatusUpdate}
                onInputUpdate={this.updateQuery}
                clearAllFilters={this.clearAllFilters}
                startSearching={this.startSearching}
                collapse={this.state.collapse}
                toggleCollapse={this.toggleCollapse}
                inProgress={this.inProgress}
                ref={this.searchRef}
              />
            </CardBody>
          </Card>
        </Col>

        {(rowData === null ||
          premiumListingData === null ||
          standardListingData === null ||
          basicPlusListingData === null ||
          basicListingData === null ||
          freeListingData === null) &&
          inProgress && (
            <Col sm="12" className="text-center mt-5">
              <Spinner />
            </Col>
          )}

        {(rowData !== null ||
          premiumListingData !== null ||
          standardListingData !== null ||
          basicPlusListingData !== null ||
          basicListingData !== null ||
          freeListingData !== null) &&
          !inProgress && (
            <Col sm="12">
              <Card>
                <SwitchFilter
                  {...{ searchType, listingTotal, accountTotal }}
                  updateSearchType={this.updateSearchType}
                />
                <div className="pt-0">
                  <TabContent activeTab={searchType}>
                    <TabPane tabId="account">
                      <div className="result-header d-flex align-items-center">
                        <div>
                          <p className="result-title">
                            Accounts (
                            {Number(accountTotal.toFixed(1)).toLocaleString()})
                          </p>
                        </div>
                        <div className="ml-auto d-flex align-items-center pr-1">
                          <div className="filter-section mr-1">
                            <Input
                              type="text"
                              placeholder="Search..."
                              onChange={this.updateSearchQuery}
                              value={accountSearchValue}
                            />
                          </div>
                          <Button
                            type="button"
                            className="p-1"
                            color="primary"
                            outline
                            onClick={() => {
                              history.push("/new-customer");
                            }}
                          >
                            + Create New
                          </Button>
                        </div>
                      </div>
                      <Nav className="fts-nav-tabs mb-0" tabs>
                        {this.generateTabsAccount()}
                      </Nav>
                      <TabContent activeTab={activeTabAccount}>
                        <TabPane tabId="vendors">
                          <FTSDataTable
                            onSortChanged={(params) =>
                              this.onSortChanged(params, sortTypes.VENDOR)
                            }
                            onScroll={hideMenu}
                            isSearch
                            pageSizes={this.pageSizes}
                            currentPageSize={pageSize}
                            pageSizePropName={"pageSize"}
                            currentPage={page}
                            pageCount={pageCount}
                            onGridReadyInit={this.onGridReadyAccount}
                            saveState={this.saveStateAccount}
                            filterSize={this.filterSize}
                            rowData={rowData}
                            onPageChange={this.onPageChanged}
                            resetApi={this.resetApiAccount}
                            onRowClicked={this.onRowClicked}
                            columnDefs={this.accountColumnsVendor}
                            {...{
                              defaultColDef,
                              totalRecords,
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="users">
                          <FTSDataTable
                            onSortChanged={(params) =>
                              this.onSortChanged(params, sortTypes.USERS)
                            }
                            onScroll={hideMenu}
                            isSearch
                            pageSizes={this.pageSizes}
                            currentPageSize={pageSizeUser}
                            pageSizePropName={"pageSizeUser"}
                            currentPage={pageUser}
                            pageCount={pageCountUser}
                            onGridReadyInit={this.onGridReadyAccountUser}
                            saveState={this.saveStateAccountUser}
                            filterSize={this.filterSize}
                            rowData={rowDataUser}
                            onPageChange={this.onPageChangedUser}
                            resetApi={this.resetApiAccountUser}
                            onRowClicked={this.onRowClicked}
                            totalRecords={totalRecordsUser}
                            columnDefs={this.accountColumnsUser}
                            {...{
                              defaultColDef,
                            }}
                          />
                        </TabPane>
                      </TabContent>
                    </TabPane>

                    <TabPane tabId="listing">
                      <div className="result-header d-flex align-items-center">
                        <div>
                          <p className="result-title">
                            Listings (
                            {Number(listingTotal.toFixed(1)).toLocaleString()})
                          </p>
                        </div>
                        <div className="ml-auto d-flex align-items-center">
                          <div className="filter-section mr-1">
                            <Input
                              type="text"
                              placeholder="Search..."
                              onChange={this.updateSearchQuery}
                              value={listingSearchValue}
                            />
                          </div>
                        </div>
                      </div>
                      <Nav className="fts-nav-tabs mb-0" tabs>
                        {this.generateTabsListing()}
                      </Nav>
                      <TabContent activeTab={activeTabListing}>
                        <TabPane tabId="premium">
                          <FTSDataTable
                            onSortChanged={(params) =>
                              this.onSortChanged(params, sortTypes.PREMIUM)
                            }
                            onScroll={hideMenu}
                            isSearch
                            pageSizes={this.pageSizes}
                            totalRecords={totalRecordsPremium}
                            currentPageSize={pageSizePremium}
                            pageSizePropName={"pageSizePremium"}
                            currentPage={pagePremium}
                            pageCount={pageCountPremium}
                            onGridReadyInit={this.onGridReadyPremium}
                            saveState={this.saveStatePremium}
                            filterSize={this.filterSize}
                            rowData={premiumListingData}
                            onPageChange={this.onPageChangedPremium}
                            resetApi={this.resetApiPremium}
                            onRowClicked={this.onRowClicked}
                            columnDefs={this.listingColumnsPremium}
                            {...{
                              defaultColDef,
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="standard">
                          <FTSDataTable
                            onSortChanged={(params) =>
                              this.onSortChanged(params, sortTypes.STANDARD)
                            }
                            onScroll={hideMenu}
                            isSearch
                            pageSizes={this.pageSizes}
                            currentPageSize={pageSizeStandard}
                            currentPage={pageStandard}
                            pageSizePropName={"pageSizeStandard"}
                            totalRecords={totalRecordsStandard}
                            pageCount={pageCountStandard}
                            onGridReadyInit={this.onGridReadyStandard}
                            saveState={this.saveStateStandard}
                            filterSize={this.filterSize}
                            rowData={standardListingData}
                            onPageChange={this.onPageChangedStandard}
                            resetApi={this.resetApiStandard}
                            onRowClicked={this.onRowClicked}
                            columnDefs={this.listingColumnsStandard}
                            {...{
                              defaultColDef,
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="basicPlus">
                          <FTSDataTable
                            onSortChanged={(params) =>
                              this.onSortChanged(params, sortTypes.BASIC_PLUS)
                            }
                            onScroll={hideMenu}
                            isSearch
                            pageSizes={this.pageSizes}
                            currentPageSize={pageSizeBasicPlus}
                            currentPage={pageBasicPlus}
                            pageSizePropName={"pageSizeBasicPlus"}
                            totalRecords={totalRecordsBasicPlus}
                            pageCount={pageCountBasicPlus}
                            onGridReadyInit={this.onGridReadyBasicPlus}
                            saveState={this.saveStateBasicPlus}
                            filterSize={this.filterSize}
                            rowData={basicPlusListingData}
                            onPageChange={this.onPageChangedBasicPlus}
                            resetApi={this.resetApiBasicPlus}
                            onRowClicked={this.onRowClicked}
                            columnDefs={this.listingColumnsBasicPlus}
                            {...{
                              defaultColDef,
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="basic">
                          <FTSDataTable
                            onSortChanged={(params) =>
                              this.onSortChanged(params, sortTypes.BASIC)
                            }
                            onScroll={hideMenu}
                            isSearch
                            pageSizes={this.pageSizes}
                            currentPageSize={pageSizeBasic}
                            currentPage={pageBasic}
                            pageSizePropName={"pageSizeBasic"}
                            totalRecords={totalRecordsBasic}
                            pageCount={pageCountBasic}
                            onGridReadyInit={this.onGridReadyBasic}
                            saveState={this.saveStateBasic}
                            filterSize={this.filterSize}
                            rowData={basicListingData}
                            onPageChange={this.onPageChangedBasic}
                            resetApi={this.resetApiBasic}
                            onRowClicked={this.onRowClicked}
                            columnDefs={this.listingColumnsBasic}
                            {...{
                              defaultColDef,
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="free">
                          <FTSDataTable
                            onSortChanged={(params) =>
                              this.onSortChanged(params, sortTypes.FREE)
                            }
                            onScroll={hideMenu}
                            isSearch
                            pageSizes={this.pageSizes}
                            currentPageSize={pageSizeFree}
                            currentPage={pageFree}
                            pageSizePropName={"pageSizeFree"}
                            totalRecords={totalRecordsFree}
                            pageCount={pageCountFree}
                            onGridReadyInit={this.onGridReadyFree}
                            saveState={this.saveStateFree}
                            filterSize={this.filterSize}
                            rowData={freeListingData}
                            onPageChange={this.onPageChangedFree}
                            resetApi={this.resetApiFree}
                            onRowClicked={this.onRowClicked}
                            columnDefs={this.listingColumnsFree}
                            {...{
                              defaultColDef,
                            }}
                          />
                        </TabPane>
                      </TabContent>
                    </TabPane>
                  </TabContent>
                </div>
              </Card>
            </Col>
          )}
        <ContextMenu
          style={{ minWidth: 250 }}
          id="contextmenu"
          preventHideOnScroll={false}
        >
          <MenuItem
            onClick={() =>
              navigator.clipboard.writeText(this.state.collectedDataForCopy)
            }
          >
            <FaRegCopy size={18} className="copy" />
            <span className="ml-1 text-dark">Copy</span>
          </MenuItem>
          {this.state.call && this.state.collectedDataForCopy && (
            <MenuItem>
              <a
                className="text-decoration-none text-dark d-flex align-items-center w-100"
                href={`tel:${this.state.collectedDataForCopy}`}
              >
                <Icons name="PhoneBlue" />
                <div className="ml-1">
                  Call - {this.state.collectedDataForCopy}
                </div>
              </a>
            </MenuItem>
          )}
        </ContextMenu>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    storedFilter: state.searchFilter,
    types: state.essentialLists.types,
  };
};

export default connect(mapStateToProps, {
  searchVendorAccounts,
  searchVendorAccountsUser,
  searchListings,
  updateSearchFilter,
  clearSearchFilter,
})(AccountsList);
