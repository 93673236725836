import {
  GET_ACCOUNT_PROOFS_FAILED,
  GET_ACCOUNT_PROOFS_REQUEST,
  GET_ACCOUNT_PROOFS_SUCCESS,
  SET_ACCOUNT_PROOFS_PAGE_SIZE,
  SET_ACCOUNT_PROOFS_PAGE,
  SET_ACCOUNT_PROOFS_SORT_ORDER,
  SET_ACCOUNT_PROOFS_SORT_COLUMN_ID,
} from "../../../reducers/v2/account-proofs";

export const getAccountProofsRequest = (payload) => ({
  type: GET_ACCOUNT_PROOFS_REQUEST,
  payload,
});

export const getAccountProofsSuccess = (payload) => ({
  type: GET_ACCOUNT_PROOFS_SUCCESS,
  payload,
});

export const getAccountProofsFailed = (payload) => ({
  type: GET_ACCOUNT_PROOFS_FAILED,
  payload,
});

export const setAccountProofsPageSize = (payload) => ({
  type: SET_ACCOUNT_PROOFS_PAGE_SIZE,
  payload,
});

export const setAccountProofsPage = (payload) => ({
  type: SET_ACCOUNT_PROOFS_PAGE,
  payload,
});

export const setAccountProofsSortOrder = (payload) => ({
  type: SET_ACCOUNT_PROOFS_SORT_ORDER,
  payload,
});

export const setAccountProofsSortColumnId = (payload) => ({
  type: SET_ACCOUNT_PROOFS_SORT_COLUMN_ID,
  payload,
});
