import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
import { Divider } from "@find-truck-service/ui/src/components/react/Divider";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
export const SpecialHoursSection = (props) => {
    const { specialHours, towingRecoveryOpen, mobileRoadServiceOpen } = props;
    const specialHoursText = specialHours?.trim();
    const showDivider = (towingRecoveryOpen || mobileRoadServiceOpen) && !!specialHoursText;
    const showCheckboxes = towingRecoveryOpen || mobileRoadServiceOpen;
    const showSpecialHours = showCheckboxes || !!specialHoursText;
    if (!showSpecialHours)
        return React.createElement(EmptyComponent, null);
    return (React.createElement(FtsWrapper, { gap: "lg", display: "flex", direction: "column", bg: ColorsValue["green-success-50"], px: { xs: "xl", sm: "2xl", md: "3xl", lg: "4xl" }, py: { xs: "lg", sm: "lg", md: "2xl", lg: "2xl" } },
        React.createElement(Span, { variant: "text-2xs", weight: WeightSize.bold, color: ColorsValue["greyscale-800"] }, "Special hours"),
        showCheckboxes && (React.createElement(FtsWrapper, { rowGap: "sm", columnGap: "lg", display: "flex", justify: "space-between", direction: { xs: "column", sm: "row", md: "row", lg: "row" } },
            towingRecoveryOpen && (React.createElement(FtsWrapper, { display: "flex", align: "center", gap: "sm" },
                React.createElement(FtsIcons, { iconName: IconNames.Check, pathProps: { stroke: ColorsValue["greyscale-800"] }, svgProps: { width: SvgIconSizeValues.lg, height: SvgIconSizeValues.lg } }),
                React.createElement(Span, { variant: "text-2xs", color: ColorsValue["greyscale-800"] }, "24 hour Towing & Recovery"))),
            mobileRoadServiceOpen && (React.createElement(FtsWrapper, { display: "flex", align: "center", gap: "sm" },
                React.createElement(FtsIcons, { iconName: IconNames.Check, pathProps: { stroke: ColorsValue["greyscale-800"] }, svgProps: { width: SvgIconSizeValues.lg, height: SvgIconSizeValues.lg } }),
                React.createElement(Span, { variant: "text-2xs", color: ColorsValue["greyscale-800"] }, "24 hour Mobile/Road Service"))))),
        showDivider && React.createElement(Divider, { color: "green-success-300" }),
        !!specialHoursText && (React.createElement(Span, { variant: "text-2xs", color: ColorsValue["greyscale-800"] }, specialHoursText))));
};
