import React from "react";
import { FtsTableCell } from "@find-truck-service/ui/src/FtsTableCell/FtsTableCell";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import styled, { keyframes } from "styled-components";
export const TableCellSkeletonLoader = (props) => {
    return (React.createElement(FtsTableCell, { ...props },
        React.createElement(Skeleton, null)));
};
const breatheAnimation = keyframes `
  0% { background-color: hsl(200, 20%, 80%) }
  100% { background-color: hsl(200, 20%, 95%) }
`;
const Skeleton = styled(FtsWrapper) `
  min-width: fit-content;
  min-height: 20px;
  flex: 1;
  border-radius: 4px;
  animation: ${breatheAnimation} 0.8s linear infinite alternate;
`;
