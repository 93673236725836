export const IconNames = {
    Achievement: "Achievement",
    AlertOctagon: "AlertOctagon",
    AlertTriangle: "AlertTriangle",
    Americanexpress: "Americanexpress",
    Amex: "Amex",
    Apple: "Apple",
    ArrowDown: "ArrowDown",
    ArrowLeft: "ArrowLeft",
    ArrowRight: "ArrowRight",
    ArrowUp: "ArrowUp",
    BgElements: "BgElements",
    BgElementsDark: "BgElementsDark",
    Check: "Check",
    ChevronDown: "ChevronDown",
    ChevronLeft: "ChevronLeft",
    ChevronRight: "ChevronRight",
    ChevronUp: "ChevronUp",
    Clock: "Clock",
    Close: "Close",
    CloseCircleFilled: "CloseCircleFilled",
    CreditCard: "CreditCard",
    Danger: "Danger",
    Dinersclub: "Dinersclub",
    Discover: "Discover",
    Edit: "Edit",
    Email: "Email",
    ExternalLink: "ExternalLink",
    EyeIcon: "EyeIcon",
    Facebook: "Facebook",
    Filter: "Filter",
    Financing: "Financing",
    Google: "Google",
    Gps: "Gps",
    Info: "Info",
    Interstate: "Interstate",
    Jcb: "Jcb",
    Listing: "Listing",
    Loader: "Loader",
    Logo: "Logo",
    Lupe: "Lupe",
    MapPin: "MapPin",
    Mastercard: "Mastercard",
    Menu: "Menu",
    Minus: "Minus",
    MoreVertical: "MoreVertical",
    Note: "Note",
    PasswordCheckError: "PasswordCheckError",
    PasswordCheckPassed: "PasswordCheckPassed",
    PayPal: "PayPal",
    Phone: "Phone",
    PhotoIcon: "PhotoIcon",
    Plus: "Plus",
    Rates: "Rates",
    Rating: "Rating",
    Refresh: "Refresh",
    Repairs: "Repairs",
    Report: "Report",
    Search: "Search",
    Send: "Send",
    Settings: "Settings",
    Share: "Share",
    Smartphone: "Smartphone",
    Sortable: "Sortable",
    Star: "Star",
    Status: "Status",
    StepActive: "StepActive",
    StepChecked: "StepChecked",
    Trash: "Trash",
    Truck: "Truck",
    Unionpay: "Unionpay",
    Upload: "Upload",
    Users: "Users",
    Vendors: "Vendors",
    Visa: "Visa",
};
