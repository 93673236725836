import React, { Component, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Phone, User, MapPin, Briefcase, Hash, Mail } from "react-feather";
import { Row, Col, FormGroup, Button, CustomInput } from "reactstrap";
import FTSAxios from "../../../axios/fts.instance";

import StatesPicker from "../../../components/fts/states-picker";
import StatusFilter from "../../../components/fts/status-filter";
import { phoneNumberMask } from "../../../utility/masks";
import ActivationPicker from "../../../components/fts/activation-picker";
import ServicesPicker from "../../../components/fts/services-picker/index";
import AmenitiesPicker from "../../../components/fts/amenities-picker/index";
import { Modal } from "@find-truck-service/ui/src/components/react/Modal";
import { ModalContentComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalContentComponent";
import "../../../assets/scss/components/fts/search-filter.scss";
import * as Icon from "react-feather";
import SearchInput from "../../../components/fts/search-input/index";
import { ModalHeaderComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalHeaderComponent";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { InputSizes, InputVariants } from "@find-truck-service/types/ui/input";

import { Input } from "@find-truck-service/ui/src/components/react/Input";
import {
  FtsChipsSizes,
  FtsChipsVariants,
} from "@find-truck-service/types/ui/chips";
import { Chips } from "@find-truck-service/ui/src/components/react/Chips";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import {
  LineHeightVariantsValues,
  VariantsValues,
  WeightSize,
} from "@find-truck-service/types/ui/text";
import { Loader } from "@find-truck-service/ui/src/components/react/Loader";
import { ModalFooterComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalFooterComponent";
import { P } from "@find-truck-service/ui/src/FtsText";
import { toast } from "react-toastify";
import {
  selectIsLoading,
  selectSearchData,
  selectTotalSearches,
} from "../../../redux/reducers/v2/search";
import {
  setIsLoading,
  setSearchData,
  setTotalSearches,
} from "../../../redux/actions/v2/search";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import { selectStates } from "../../../redux/reducers/essentialLists";
import { Divider } from "@find-truck-service/ui/src/components/react/Divider";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { checkPermissions } from "../../../utility";
import { PERMISSIONS, SUB_PERMISSIONS } from "../../../constants";

class SearchFilter extends Component {
  state = {
    collapsed: this.props.collapse,
    totalSearchesModalOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.collapse !== this.props.collapse) {
      this.setState({ collapsed: this.props.collapse });
    }
  }

  toggleTotalSearchesModal = () => {
    this.setState({
      totalSearchesModalOpen: !this.state.totalSearchesModalOpen,
    });
  };

  render() {
    const {
      query,
      onInputUpdate,
      updateState,
      updateStatus,
      updateActivationStatus,
      inProgress,
      clearAllFilters,
      startSearching,
      toggleCollapse,
    } = this.props;

    const havePermissionsForTotalSearch = checkPermissions(
      [PERMISSIONS.ADDITIONAL_FEATURES],
      this.props.adminUserPermissions,
      [SUB_PERMISSIONS[PERMISSIONS.ADDITIONAL_FEATURES].GET_TOTAL_SEARCHES],
    );

    return (
      <>
        <div className="search-header">
          <p className="search-title">Search for accounts and listings</p>
          <button
            className="colapse-button"
            onClick={() => {
              toggleCollapse();
            }}
          >
            {this.state.collapsed ? (
              <Icon.ChevronUp size={17} className="chevron" />
            ) : (
              <Icon.ChevronDown size={17} className="chevron" />
            )}
            {this.state.collapsed ? "Collapse Filters" : "Expand Filters"}
          </button>
        </div>

        {this.state.collapsed ? (
          <div className="collapse-row">
            <Row>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<Hash size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="id"
                    value={query.id}
                    placeholder="ID"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<Briefcase size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="name"
                    value={query.name}
                    placeholder="Company Name"
                    sidebarCollapsed={
                      this.props.app.customizer.sidebarCollapsed
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<Phone size={15} />}
                    masked
                    mask={phoneNumberMask}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="phoneNumber"
                    value={query.phoneNumber}
                    placeholder="Phone"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<MapPin size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="address"
                    value={query.address}
                    placeholder="Address"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<MapPin size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="city"
                    value={query.city}
                    placeholder="City"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <StatesPicker
                    name="state"
                    isSearch
                    value={query.state}
                    placeholder="State"
                    onStateChange={(option) => {
                      updateState(option);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<User size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="firstName"
                    value={query.firstName}
                    placeholder="First Name"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<User size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="lastName"
                    value={query.lastName}
                    placeholder="Last Name"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<Mail size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="email"
                    name="email"
                    value={query.email}
                    placeholder="Email"
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <StatusFilter
                    isSearch
                    placeholder="Label"
                    value={query.adminStatus}
                    onStateChange={(option) => {
                      updateStatus(option);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <ActivationPicker
                  isDisabled={false}
                  isSearch
                  placeholder="Status"
                  isClearable
                  name="status"
                  value={query.status}
                  onStateChange={(option) => {
                    updateActivationStatus(option);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ServicesPicker
                  query={query}
                  onSelect={(param) => {
                    onInputUpdate({
                      target: { value: param, name: "subCategory" },
                    });
                  }}
                />
              </Col>
              <Col md={6}>
                <AmenitiesPicker
                  query={query}
                  onSelect={(param) => {
                    onInputUpdate({
                      target: { value: param, name: "serviceAmenities" },
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className={"mt-2"}>
              <Col md={4} className="d-flex align-items-center">
                <CustomInput
                  checked={!!query.isPrivate}
                  type="switch"
                  id="isPrivate"
                  name="isPrivate"
                  inline
                  onChange={() =>
                    onInputUpdate({
                      target: {
                        value: !query.isPrivate,
                        name: "isPrivate",
                      },
                    })
                  }
                />
                <span className="switch-text">Show private locations only</span>
              </Col>
              <Col md={4} className="d-flex align-items-center">
                <CustomInput
                  checked={query.includeInAuthorizedPromotions ? 1 : 0}
                  type="switch"
                  id="includeInAuthorizedPromotions"
                  name="includeInAuthorizedPromotions"
                  inline
                  onChange={() =>
                    onInputUpdate({
                      target: {
                        value: query.includeInAuthorizedPromotions ? 0 : 1,
                        name: "includeInAuthorizedPromotions",
                      },
                    })
                  }
                />
                <span className="switch-text">
                  Show authorized vendors only
                </span>
              </Col>
              <Col
                md={4}
                className="d-flex align-items-center justify-content-end"
              >
                <div className="d-flex">
                  {havePermissionsForTotalSearch && (
                    <Button
                      type="button"
                      className="total-search-button"
                      color="white"
                      onClick={this.toggleTotalSearchesModal}
                      disabled={inProgress}
                    >
                      Total searches
                    </Button>
                  )}
                </div>
                <div className="d-flex">
                  <Button
                    type="button"
                    className="clear-button"
                    color="white"
                    onClick={clearAllFilters}
                    disabled={inProgress}
                  >
                    Clear
                  </Button>
                  <Button
                    type="button"
                    className="search-button"
                    color="primary"
                    onClick={startSearching}
                  >
                    Search
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        ) : null}
        <TotalSearchesModal
          onBackdropClick={this.toggleTotalSearchesModal}
          onClose={this.toggleTotalSearchesModal}
          isOpen={this.state.totalSearchesModalOpen}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app: state.customizer,
    adminUserPermissions: state.auth.login?.values.loggedInUser.permissions,
  };
};
export default connect(mapStateToProps, {})(SearchFilter);

const TotalSearchesModal = (props) => {
  const { isOpen, onClose, onBackdropClick } = props;

  const periods = ["1y", "180d", "90d", "30d"];

  return (
    <Modal
      size={"xs"}
      isOpen={isOpen}
      modalWrapperProps={{ isOpen }}
      onBackdropClick={onBackdropClick}
      modalContentComponent={() => <ModalComponent periods={periods} />}
      modalFooterComponent={() => (
        <TotalSearchesModalFooterComponent onInfo={onClose} />
      )}
      modalHeaderComponent={() => (
        <ModalHeaderComponent title={"Total searches"} onClose={onClose} />
      )}
    />
  );
};

const CustomChip = (props) => {
  return (
    <Chips
      minWidth={"60px"}
      cursor={"pointer"}
      onClick={() => props.onCLick(props.label)}
      label={props.label}
      size={FtsChipsSizes.md}
      justify={"center"}
      labelProps={{
        lineHeightVariant: LineHeightVariantsValues.relaxed,
        color: props.isSelected
          ? ColorsValue["greyscale-50"]
          : ColorsValue["blue-accent-500"],
        weight: WeightSize.medium,
      }}
      variant={
        props.isSelected
          ? FtsChipsVariants.contained
          : FtsChipsVariants.outlined
      }
      // color={ColorsValue["greyscale-50"]}
      color={ColorsValue["blue-accent-500"]}
    />
  );
};

const ModalComponent = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(selectSearchData);
  const totalSearches = useSelector(selectTotalSearches);
  const isLoading = useSelector(selectIsLoading);
  const states = useSelector(selectStates);

  const onChange = useCallback(
    (e) => {
      dispatch(
        setSearchData({ data: { ...data, [e.target.name]: e.target.value } }),
      );
    },
    [data],
  );

  const setPeriod = useCallback(
    (period) => {
      dispatch(setSearchData({ data: { ...data, period } }));
    },
    [data],
  );

  if (isLoading) {
    return (
      <FtsWrapper position="relative" minHeight="160px">
        <Loader />
      </FtsWrapper>
    );
  }
  const options = [
    { value: 25, label: "25 miles" },
    { value: 20, label: "20 miles" },
    { value: 15, label: "15 miles" },
    { value: 10, label: "10 miles" },
    { value: 5, label: "5 miles" },
  ];
  return (
    <ModalContentComponent>
      <FtsWrapper display="flex" gap={"lg"} align="end" mb={"lg"}>
        {props.periods.map((label, index) => (
          <CustomChip
            key={index}
            onCLick={setPeriod}
            label={label}
            isSelected={data?.period === label}
          />
        ))}

        <Dropdown
          size={DropdownSizesValues.sm}
          variant={DropdownVariantsValues.outlined}
          options={options}
          required
          name={"range"}
          value={options.find((e) => e.value === data?.range)}
          onChange={(val) =>
            onChange({ target: { name: "range", value: val?.value || "" } })
          }
        />
      </FtsWrapper>
      <FtsWrapper display="flex" gap={"lg"}>
        <Input
          width={"100%"}
          name={"city"}
          value={data?.city}
          onChange={onChange}
          label={"City"}
          required
          variant={InputVariants.outlined}
          size={InputSizes.sm}
        />

        <Dropdown
          size={DropdownSizesValues.sm}
          variant={DropdownVariantsValues.outlined}
          options={states}
          required
          label={"State"}
          name={"state"}
          value={states.find((e) => e.value === data?.state)}
          onChange={(val) =>
            onChange({ target: { name: "state", value: val?.label || "" } })
          }
        />
      </FtsWrapper>

      <FtsWrapper
        gap={"lg"}
        mt={"lg"}
        border={`${Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]} `}
        borderRadius={"2xs"}
        overflow={"auto"}
        maxHeight={"50vh"}
      >
        {totalSearches &&
          Object.entries(totalSearches).map(([key, value], i) => {
            if (!i) {
              return (
                <FtsWrapper
                  position={"sticky"}
                  top={"0"}
                  py={"lg"}
                  px={"2xl"}
                  bg={ColorsValue["greyscale-50"]}
                  key={key}
                  display="flex"
                  justify={"space-between"}
                >
                  <P
                    display="flex"
                    align="center"
                    variant={VariantsValues["text-3xs"]}
                    color={ColorsValue["greyscale-1000"]}
                  >
                    {key}:{" "}
                  </P>
                  <P
                    weight={WeightSize.bold}
                    variant={VariantsValues["text-xs"]}
                    color={ColorsValue["greyscale-1000"]}
                  >
                    {new Intl.NumberFormat().format(value)}
                  </P>
                </FtsWrapper>
              );
            }
            return (
              <>
                <FtsWrapper
                  key={key}
                  display="flex"
                  justify={"space-between"}
                  py={"lg"}
                  px={"2xl"}
                >
                  <P
                    display="flex"
                    align="center"
                    variant={VariantsValues["text-3xs"]}
                    color={ColorsValue["greyscale-1000"]}
                  >
                    {key}:{" "}
                  </P>
                  <P
                    weight={WeightSize.semibold}
                    variant={VariantsValues["text-3xs"]}
                    color={ColorsValue["greyscale-1000"]}
                  >
                    {new Intl.NumberFormat().format(value)}
                  </P>
                </FtsWrapper>
                {i < Object.keys(totalSearches).length - 1 && (
                  <FtsWrapper px={"2xl"}>
                    <Divider />
                  </FtsWrapper>
                )}
              </>
            );
          })}
      </FtsWrapper>
    </ModalContentComponent>
  );
};

const TotalSearchesModalFooterComponent = (props) => {
  const { successLabel = "Search", infoLabel = "Close", onInfo } = props;

  const dispatch = useDispatch();
  const searchData = useSelector(selectSearchData);

  const onSearch = useCallback(async () => {
    try {
      dispatch(setIsLoading({ data: true }));

      const { data } = await FTSAxios.post("admin/reports/total-searches", {
        period: searchData.period,
        city: searchData.city,
        state: searchData.state,
        range: searchData.range,
      });

      dispatch(setTotalSearches({ data: data.totalSearches }));
    } catch (e) {
      const message = "Oops! Something went wrong!";
      console.log(e);
      toast.error(message);
    } finally {
      dispatch(setIsLoading({ data: false }));
    }
  }, [searchData]);

  return (
    <ModalFooterComponent
      successDisabled={
        !searchData?.city || !searchData?.state || !searchData.period
      }
      successLabel={successLabel}
      onSuccess={onSearch}
      infoLabel={infoLabel}
      onInfo={onInfo}
    />
  );
};
