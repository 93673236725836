import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "@find-truck-service/types/ui/button";
import { DescriptionWrapper } from "@find-truck-service/ui/src/components/react/ListingItem/styled/DescriptionWrapper";
import { InlineMessage } from "@find-truck-service/ui/src/components/react/InlineMessage";
import { inlineMessageSizes, inlineMessageTypes } from "@find-truck-service/types/constants/inlineMessage";
export const ListingDescriptionRow = (props) => {
    const subtitle = `(${props.price?.label?.toUpperCase()} ${props.price?.descriptionLines?.toString()} LINES)`;
    const showSubtitle = !!props.price?.descriptionLines;
    const showWarningMsg = !props.price?.descriptionLines && props.showBenefits;
    const showDescription = !!props.price?.descriptionLines && !!props.description;
    return (React.createElement(FtsWrapper, { px: "xl", mb: "lg", py: "2xl", gap: "lg", boxShadow: "sm", display: "flex", direction: "column", bg: ColorsValue["greyscale-0"] },
        React.createElement(FtsWrapper, { display: "flex" },
            React.createElement(Span, { weight: WeightSize.medium, variant: VariantsValues["text-3xs"], color: ColorsValue["blue-accent-500"] }, "DESCRIPTION"),
            showSubtitle && (React.createElement(Span, { ml: "2xs", weight: WeightSize.medium, variant: VariantsValues["text-3xs"], color: ColorsValue["greyscale-500"] }, subtitle))),
        showDescription && (React.createElement(DescriptionWrapper, { priceId: props.priceId, dangerouslySetInnerHTML: {
                __html: props.description,
            }, variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-800"] })),
        props.showAddDescriptionButton && (React.createElement(React.Fragment, null,
            React.createElement(Span, { weight: WeightSize.medium, variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-800"] }, "You didn\u2019t add a business description."),
            React.createElement(Button, { width: "100%", size: ButtonSizes.md, iconName: IconNames.Plus, type: ButtonTypes.outlined, onClick: props.onAddDescription, color: ButtonColorsValue.accent, label: "Add Business Description" }))),
        showWarningMsg && (React.createElement(InlineMessage, { size: inlineMessageSizes.md, type: inlineMessageTypes.warning, message: "Not available for this listing." }))));
};
