import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";

import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { Span } from "../../dist/ui/src/FtsText";

import Checkbox from "../../dist/ui/src/components/react/Checkbox";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectListingCategories,
  selectListingData,
  selectListingSubCategories,
} from "../../../../redux/reducers/v2/listing";
import { Accordion } from "../../dist/ui/src/components/react/Accordion";
import {
  selectCategories,
  selectSubCategories,
} from "../../../../redux/reducers/essentialLists";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { CheckboxSizes } from "@find-truck-service/types/ui/checkbox";

export const CategoriesPicker = () => {
  const dispatch = useDispatch();
  const selectedCategories = useSelector(selectListingCategories);
  const selectedSubCategories = useSelector(selectListingSubCategories);
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const listingData = useSelector(selectListingData);

  const onChangeSubCategory = useCallback(
    (subCategory) => {
      const isChecked = selectedSubCategories.find(
        (i) => i.subCategoryId === subCategory.id,
      );
      console.log("subCategory: ", subCategory);
      if (isChecked) {
        return dispatch(
          getListingDataByIdSuccess({
            data: {
              ...listingData,
              adsSubCategories: selectedSubCategories.filter(
                (e) => e.subCategoryId !== subCategory.id,
              ),
            },
          }),
        );
      }
      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            adsSubCategories: [
              ...selectedSubCategories,
              { adId: listingData.id, subCategoryId: subCategory.id },
            ],
          },
        }),
      );
    },
    [listingData],
  );

  const toggleShowAccordionDescription = useCallback(
    (category) => {
      const isChecked = selectedCategories.find(
        (i) => i.mainCategoryId === category.id,
      );

      if (isChecked) {
        const subCategoriesForFilter = subCategories
          .filter((e) => e.categoryId === category.id)
          .map((e) => e.id);
        return dispatch(
          getListingDataByIdSuccess({
            data: {
              ...listingData,
              adsCategories: selectedCategories.filter(
                (e) => e.mainCategoryId !== category.id,
              ),
              adsSubCategories: selectedSubCategories.filter(
                (e) => subCategoriesForFilter.indexOf(e.subCategoryId) === -1,
              ),
            },
          }),
        );
      }

      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            adsCategories: [
              ...selectedCategories,
              {
                adId: listingData.id,
                mainCategoryId: category.id,
              },
            ],
          },
        }),
      );
    },
    [listingData],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} mt={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          SEARCH CATEGORIES
        </Span>
      </FtsColumn>
      {categories.map((e) => (
        <FtsColumn key={e.id} size={{ xs: 4 }}>
          <Accordion
            title={e.value}
            show={!!selectedCategories.find((i) => i.mainCategoryId === e.id)}
            onClick={() => toggleShowAccordionDescription(e)}
          >
            <FtsWrapper
              display={"flex"}
              direction={"column"}
              gap={"md"}
              p={"lg"}
            >
              {subCategories
                .filter((f) => f.categoryId === e.id)
                .map((g) => (
                  <Checkbox
                    size={CheckboxSizes.sm}
                    key={g.id}
                    label={g.value}
                    onClick={() => onChangeSubCategory(g)}
                    checked={selectedSubCategories.find(
                      (h) => h.subCategoryId === g.id,
                    )}
                  />
                ))}
            </FtsWrapper>
          </Accordion>
        </FtsColumn>
      ))}
    </FtsRow>
  );
};
