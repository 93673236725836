import React from "react";
import { ListingBasicInfoLg } from "./ListingBasicInfoLg";
import { ImageAndDescription } from "./ImageAndDescription";
import { ContactAndStartRepairButton } from "./ContactAndStartRepairButton";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { BASIC_LISTING_PRICE_ID, FREE_LISTING_PRICE_ID, } from "@find-truck-service/ui/src/components/react/ListingItem/constants";
// export interface ListingItemLgContentProps {
//   city: string;
//   state: string;
//   price?: string;
//   inCity: boolean;
//   address: string;
//   priceId: number;
//   distance: number;
//   website?: string;
//   imageSrc: string;
//   listingName: string;
//   interstate?: string;
//   milemarker?: string;
//   description: string;
//   alwaysOpen: boolean;
//   specialHours: string;
//   onExpand: () => void;
//   showWebsite?: boolean;
//   showDistance: boolean;
//   expandContent: boolean;
//   compassDirection: string;
//   onClickHours: () => void;
//   onClickContact: () => void;
//   onStartRepair?: () => void;
//   listingCurrentTime: string;
//   showPromotionTag?: boolean;
//   showAuthorizedTag?: boolean;
//   onWebsiteClick?: () => void;
//   towingRecoveryOpen: boolean;
//   workingHours: WorkingHoursData;
//   mobileRoadServiceOpen: boolean;
// }
export const ListingItemLgContent = (props) => {
    const { imageSrc, description, onStartRepair, onClickContact, expandContent, onExpand, priceData } = props;
    const isFree = priceData?.id === FREE_LISTING_PRICE_ID;
    const isFreeOrBasic = isFree || priceData?.id === BASIC_LISTING_PRICE_ID;
    const showExpandButton = isFree && !expandContent;
    const direction = isFreeOrBasic ? "row" : "column";
    return (React.createElement(FtsWrapper, { py: "3xl", px: "4xl", display: "flex", gap: "lg" },
        React.createElement(FtsWrapper, { display: "flex", gap: "3xl", direction: "column", flex: 1 },
            React.createElement(ListingBasicInfoLg, { ...props }),
            React.createElement(ImageAndDescription, { imageSrc: imageSrc, description: description, priceData: priceData })),
        React.createElement(ContactAndStartRepairButton, { onExpand: onExpand, direction: direction, onStartRepair: onStartRepair, onClickContact: onClickContact, showExpandButton: showExpandButton, showButtons: !!onClickContact || !!onStartRepair })));
};
