import React from "react";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { Message, messageTypes } from "@find-truck-service/ui/src/components/react/Message";
import { RichTextWrapper } from "@find-truck-service/ui/src/components/react/RichTextEditor/RichTextWrapper";
import { ListingItemImage } from "@find-truck-service/ui/src/components/react/ListingItem/Components/ListingItemImage";
import { ListingNameRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingNameRow";
import { ListingContactsRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingContactsRow";
import { ListingAmenitiesRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingAmenitiesRow";
import { ListingDescriptionRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingDescriptionRow";
import { ListingWorkingHoursRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingWorkingHoursRow";
import { ListingServicesAmenitiesRow } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Components/ListingServicesRow";
import FeaturedVendorsSliderMobile from "@find-truck-service/ui/src/components/react/FeaturedVendorsSliderMobile";
import { InlineMessage } from "@find-truck-service/ui/src/components/react/InlineMessage";
import { inlineMessageSizes, inlineMessageTypes } from "@find-truck-service/types/constants/inlineMessage";
export const PreviewListingWithEditButtonsContent = (props) => {
    const { price, listingData, onAddDescription, onAddAmenities, onAddServices } = props;
    const showImage = price?.showImage;
    const showDescription = price?.descriptionLines || props.showBenefits;
    const showAmenities = listingData.adsAmenities?.length || props.showBenefits;
    const showServices = listingData.adsServiceAmenities?.length || props.showBenefits;
    const showFeaturedVendors = props?.showFeaturedVendors && props?.featuredVendors?.length;
    const showAddAmenitiesButton = !!onAddAmenities && !listingData.adsAmenities?.length;
    const showAddServicesButton = !!onAddServices && !listingData.adsServiceAmenities?.length;
    const showAddDescriptionButton = !!onAddDescription && !listingData.description && !!price?.descriptionLines;
    if (!listingData)
        return React.createElement(EmptyComponent, null);
    return (React.createElement(FtsWrapper, { bg: ColorsValue["greyscale-50"], p: "0", pt: "xl", overflow: "auto" },
        showImage && (React.createElement(FtsWrapper, { px: "xl", mb: "2xl" },
            React.createElement(ListingItemImage, { width: "100%", height: "100%", src: props.imageSrc, priceId: price?.id }))),
        !showImage && props.showBenefits && (React.createElement(FtsWrapper, { px: "xl", pb: "2xl" },
            React.createElement(Message, { type: messageTypes.warning, title: "Image not available for this listing" }))),
        React.createElement(ListingNameRow, { name: listingData.name, city: listingData.city, state: listingData.state, address: listingData.address, interstate: listingData.interstate, milemarker: listingData.milemarker }),
        showFeaturedVendors && (React.createElement(React.Fragment, null,
            React.createElement(FtsWrapper, { px: "xl", pb: "md" },
                React.createElement(InlineMessage, { size: inlineMessageSizes.md, type: inlineMessageTypes.warning, message: "Premium vendors visible on free listings." })),
            React.createElement(FeaturedVendorsSliderMobile, { featuredVendors: props.featuredVendors }))),
        React.createElement(ListingContactsRow, { fax: listingData.fax || "", website: listingData.website || "", afterHours: listingData.afterHours || "", phoneNumber: listingData.phoneNumber || "", phoneNumberSecondary: listingData.phoneNumberSecondary || "", showWebsite: !!price?.showWebsite && !!listingData.website }),
        showDescription && (React.createElement(RichTextWrapper, null,
            React.createElement(ListingDescriptionRow, { price: price, priceId: price?.id, showBenefits: props.showBenefits, onAddDescription: onAddDescription, description: listingData.description, showAddDescriptionButton: showAddDescriptionButton }))),
        React.createElement(ListingWorkingHoursRow, { mobileRoadServiceOpen: listingData.mobileRoadServiceOpen, towingRecoveryOpen: listingData.towingRecoveryOpen, specialHours: listingData.specialHours, alwaysOpen: listingData.alwaysOpen, monday: listingData.workingHours?.monday || "", tuesday: listingData.workingHours?.tuesday || "", wednesday: listingData.workingHours?.wednesday || "", thursday: listingData.workingHours?.thursday || "", friday: listingData.workingHours?.friday || "", saturday: listingData.workingHours?.saturday || "", sunday: listingData.workingHours?.sunday || "" }),
        showServices && (React.createElement(ListingServicesAmenitiesRow, { onAddServices: onAddServices, serviceAmenities: props.serviceAmenities, showAddServicesButton: showAddServicesButton, listingServiceAmenities: listingData.adsServiceAmenities || [] })),
        showAmenities && (React.createElement(ListingAmenitiesRow, { amenities: props.amenities, onAddAmenities: onAddAmenities, showAddAmenitiesButton: showAddAmenitiesButton, listingAmenities: listingData.adsAmenities || [] }))));
};
