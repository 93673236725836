import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSideOutData } from "../../../redux/reducers/v2/sideOut";

import { SideOutListingProofHeader } from "./SideOutListingProofHeader";
import { SideOutListingProofFooter } from "./SideOutListingProofFooter";

import { SideOutLoadingState } from "../dist/ui/src/components/react/SideOut/Components/SideOutLoadingState";
import { SideOutErrorState } from "../dist/ui/src/components/react/SideOut/Components/SideOutErrorState";
import { getProofDataByAdIdRequestAction } from "../../../redux/actions/v2/proof";
import {
  selectProofData,
  selectProofError,
  selectProofIsLoading,
} from "../../../redux/reducers/v2/proof";
import { SideOutListingProofContent } from "./SideOutListingProofContent";
// Components

export const SideOutListingProof = () => {
  const dispatch = useDispatch();
  const proofData = useSelector(selectProofData);
  const sideOutData = useSelector(selectSideOutData);
  const error = useSelector(selectProofError);
  const isLoading = useSelector(selectProofIsLoading);

  useEffect(() => {
    if (sideOutData?.proofAdId)
      dispatch(
        getProofDataByAdIdRequestAction({
          data: { adId: sideOutData.proofAdId },
        }),
      );
  }, [sideOutData]);

  if (isLoading) {
    return <SideOutLoadingState />;
  }

  if (error) {
    return (
      <SideOutErrorState
        error={error}
        onClick={() =>
          dispatch(getProofDataByAdIdRequestAction(sideOutData.proofAdId))
        }
      />
    );
  }

  if (!proofData) {
    return (
      <SideOutErrorState
        error={error}
        onClick={() =>
          dispatch(getProofDataByAdIdRequestAction(sideOutData.proofAdId))
        }
      />
    );
  }

  return (
    <>
      <SideOutListingProofHeader />
      <SideOutListingProofContent />
      <SideOutListingProofFooter />
    </>
  );
};
