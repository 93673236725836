import React from "react";
import { FtsTableHeadTypesValues } from "@find-truck-service/types/ui/table-head";
import { FtsTableHead } from "@find-truck-service/ui/src/FtsTableHead/FtsTableHead";
import { TableCellText } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellText";
import { TableCellCheckbox } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellCheckbox";
export const TableHead = (props) => {
    if (props.type === FtsTableHeadTypesValues.checkbox) {
        return (React.createElement(FtsTableHead, { size: props.size, variant: props.variant, type: props.type, sortable: props.sortable },
            React.createElement(TableCellCheckbox, { onClick: props.onClick, type: props.type, isLoading: props.isLoading, subtitle: props.variant, size: props.size, title: props.title, href: props.href, checkboxProps: props.checkboxProps, active: props.active })));
    }
    return (React.createElement(FtsTableHead, { size: props.size, variant: props.variant, type: props.type, sortable: props.sortable },
        React.createElement(TableCellText, { onClick: props.onClick, type: props.type, isLoading: props.isLoading, subtitle: props.variant, size: props.size, title: props.title, href: props.href, iconProps: props.iconProps, active: props.active })));
};
