import React from "react";
import { dontShowWorkingHoursToDropdown } from "../../../../constants";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { hoursListTo } from "../../../fts/formik/hour-input/options";
import {
  Dropdown,
  DropdownSizesValues,
  DropdownVariantsValues,
} from "@find-truck-service/ui/src/components/react/Dropdown";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";

export const WorkingHoursToDropdown = (props) => {
  const { name, toValue, onChange, fromValue } = props;
  const showToDropdown = !dontShowWorkingHoursToDropdown.includes(fromValue);

  if (!showToDropdown) return <EmptyComponent />;

  return (
    <FtsColumn size={{ xs: 5 }}>
      <Dropdown
        size={DropdownSizesValues.sm}
        variant={DropdownVariantsValues.outlined}
        placeholder={""}
        name={name}
        value={{ value: toValue, label: toValue }}
        options={hoursListTo}
        onChange={(val) => onChange(val.value)}
      />
    </FtsColumn>
  );
};
