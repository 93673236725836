/* eslint-disable no-prototype-builtins */
export const serialize = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p) && !!obj[p] && obj[p].length !== 0) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export const generateFiltersBasedOnData = (data, accountType) => {
  const list = accountType ? [accountType] : [];

  const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  for (var property in data) {
    if (data[property] !== null && data[property] !== "") {
      let propertyValue = data[property];
      if (typeof data[property] === "string") {
        propertyValue =
          data[property].indexOf(" ") > -1 || format.test(data[property])
            ? `"${data[property]}"`
            : data[property];
        if (property === "phoneNumber") {
          list.push(
            `${property}='${propertyValue}' AND mobilePhone='${propertyValue}' AND phoneNumberSecondary='${propertyValue}'`,
          );
        } else {
          list.push(`${property}='${propertyValue}'`);
        }
      } else if (typeof data[property] === "object" && data[property].length) {
        for (let i = 0; i < data[property].length; i++) {
          list.push(`${property}='${data[property][i]}'`);
        }
      } else {
        list.push(`${property}='${propertyValue}'`);
      }
    }
  }

  return list.join(" AND ");
};

export const generateFilters = (data, initFilter) => {
  const filter = [initFilter];
  const multi_match = [];

  const operator = {
    filter: {
      userId: "term",
      adminIsMerchant: "term",
      includeInAuthorizedPromotions: "term",
      isPrivate: "term",
      havePrivateLocations: "term",
      includeInFeaturedVendors: "term",
      address: "match_phrase_prefix",
      adminStatus: "term",
      leadStatus: "term",
      lastContactedBy: "term",
      adminAccountOwner: "term",
      companyName: "match_phrase_prefix",
      id: "term",
      state: "match_phrase_prefix",
      status: "term",
      zip: "match_phrase_prefix",
      name: "match_phrase_prefix",
      priceId: "term",
      city: "match_phrase_prefix",
      adsSubCategoriesStrings: "match_phrase_prefix",
      excludeRenewalEmails: "term",
      paymentSubscriptionId: "exists",
      createdByAdmin: "term",
      userTypeNamed: "term",
    },
    should: {
      adsServiceAmenitiesStrings: "match_phrase_prefix",
      firstName: "match_phrase_prefix",
      lastName: "match_phrase_prefix",
      phoneNumber: "term",
      phoneNumberSecondary: "term",
      mobilePhone: "term",
      mobileNumber: "term",
      fax: "match_phrase_prefix",
      email: "match_phrase_prefix",
      altContactFirstName: "match_phrase_prefix",
      altContactLastName: "match_phrase_prefix",
      altContactMobileNumber: "match_phrase_prefix",
      altContactPhoneNumber: "match_phrase_prefix",
      altContactPhoneNumberSecondary: "match_phrase_prefix",
    },
  };

  for (let [key, value] of Object.entries(data)) {
    if (key === "createdByAdmin") {
      // console.log("createdByAdmin", value);
    }

    if (key === "includeInAuthorizedPromotions") value = !!value;
    if (key === "includeInFeaturedVendors") value = !!value;
    if (key === "havePrivateLocations") value = !!value;
    if (key === "isPrivate") value = !!value;
    if (value || value === 0) {
      if (key === "createdByAdmin") {
        value = value ? true : false;
      }

      if (operator.filter[key]) {
        if (key === "paymentSubscriptionId") {
          filter.push({ [operator.filter[key]]: { field: key } });
        } else {
          filter.push({ [operator.filter[key]]: { [key]: value } });
        }
      }

      if (operator.should[key]) {
        if (key === "adsServiceAmenitiesStrings") {
          for (const adsService of value) {
            multi_match.push({
              bool: {
                should: [
                  {
                    match_phrase_prefix: {
                      adsServiceAmenitiesStrings: adsService,
                    },
                  },
                ],
              },
            });
          }
        }

        if (key === "phoneNumber") {
          value = value.replace(/_/g, "");
          multi_match.push({
            bool: {
              should: [
                { match_phrase_prefix: { phoneNumber: value } },
                { match_phrase_prefix: { phoneNumberSecondary: value } },
                { match_phrase_prefix: { mobilePhone: value } },
                { match_phrase_prefix: { mobileNumber: value } },
                { match_phrase_prefix: { telephone: value } },
                { match_phrase_prefix: { fax: value } },
                { match_phrase_prefix: { altContactMobileNumber: value } },
                { match_phrase_prefix: { altContactPhoneNumber: value } },
                { match_phrase_prefix: { afterHours: value } },
                {
                  match_phrase_prefix: {
                    altContactPhoneNumberSecondary: value,
                  },
                },
              ],
            },
          });
        }

        if (key === "email") {
          multi_match.push({
            bool: {
              should: [
                { match_phrase_prefix: { email: value } },
                { match_phrase_prefix: { additionalEmail: value } },
                { match_phrase_prefix: { altContactEmail: value } },
                { match_phrase_prefix: { marketingEmail: value } },
                { match_phrase_prefix: { marketingAdditionalEmail: value } },
              ],
            },
          });
        }

        if (key === "firstName") {
          multi_match.push({
            bool: {
              should: [
                { match_phrase_prefix: { firstName: value } },
                { match_phrase_prefix: { altContactFirstName: value } },
              ],
            },
          });
        }
        //
        if (key === "lastName") {
          multi_match.push({
            bool: {
              should: [
                { match_phrase_prefix: { lastName: value } },
                { match_phrase_prefix: { altContactLastName: value } },
              ],
            },
          });
        }
      }
    }
  }
  return { filter, multi_match };
};

export const removeSpecialCharacters = (str) => {
  if (!str) return "";
  return str.replace(/[^a-zA-Z0-9 ]/g, "");
};

export const toLowerCare = (str) => {
  if (!str) return "";

  return str.toLowerCase();
};

export const toSnakeCase = (str) => {
  if (!str) return "";

  return str.replace(/ /g, "_");
};

export const generateAdsRatesKey = (rateName, rateId) => {
  return `${toSnakeCase(
    toLowerCare(removeSpecialCharacters(rateName)),
  )}_${rateId}`;
};
