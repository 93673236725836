import { createSelector } from "reselect";
import { SORTING_TYPES_VALUES } from "@find-truck-service/types/constants/sorting";

export const GET_ACCOUNT_PROOFS_REQUEST = "PROOFS/GET_ACCOUNT_SEARCH_REQUEST";
export const GET_ACCOUNT_PROOFS_SUCCESS = "PROOFS/GET_ACCOUNT_SEARCH_SUCCESS";
export const GET_ACCOUNT_PROOFS_FAILED = "PROOFS/GET_ACCOUNT_SEARCH_FAILED";
export const SET_ACCOUNT_PROOFS_PAGE_SIZE =
  "PROOFS/SET_ACCOUNT_PROOFS_PAGE_SIZE";
export const SET_ACCOUNT_PROOFS_PAGE = "PROOFS/SET_ACCOUNT_PROOFS_PAGE";
export const SET_ACCOUNT_PROOFS_SORT_ORDER =
  "PROOFS/SET_ACCOUNT_PROOFS_SORT_ORDER";
export const SET_ACCOUNT_PROOFS_SORT_COLUMN_ID =
  "PROOFS/SET_ACCOUNT_PROOFS_SORT_COLUMN_ID";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  count: 100,
  invalidFormFields: null,
  page: 1,
  pageSize: 10,
  sortOrder: SORTING_TYPES_VALUES.ASC,
  sortColumnId: "status",
};

export default function (state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case GET_ACCOUNT_PROOFS_REQUEST:
      return { ...state, isLoading: true };
    case GET_ACCOUNT_PROOFS_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        count: action.payload.data.count || 0,
        isLoading: false,
        error: null,
      };
    case GET_ACCOUNT_PROOFS_FAILED:
      return {
        ...state,
        data: null,
        isLoading: false,
        error: action.payload.data,
      };
    case SET_ACCOUNT_PROOFS_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload.data,
      };

    case SET_ACCOUNT_PROOFS_PAGE:
      return {
        ...state,
        page: action.payload.data,
      };

    case SET_ACCOUNT_PROOFS_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.payload.data,
      };
    case SET_ACCOUNT_PROOFS_SORT_COLUMN_ID:
      return {
        ...state,
        sortColumnId: action.payload.data,
      };
    default:
      return state;
  }
}

/// Selectors
const selectProofState = (state) => state.accountProofsState;

export const selectAccountProofsData = createSelector(
  selectProofState,
  (proof) => {
    return proof.data;
  },
);

export const selectAccountProofsIsLoading = createSelector(
  selectProofState,
  (proof) => {
    return proof.isLoading;
  },
);

export const selectAccountProofsPage = createSelector(
  selectProofState,
  (proof) => {
    return proof.page;
  },
);

export const selectAccountProofsPageSize = createSelector(
  selectProofState,
  (proof) => {
    return proof.pageSize;
  },
);

export const selectAccountProofsCount = createSelector(
  selectProofState,
  (proof) => {
    return proof.count;
  },
);

export const selectAccountProofsSortOrder = createSelector(
  selectProofState,
  (proof) => {
    return proof.sortOrder;
  },
);
export const selectAccountProofsSortColumnId = createSelector(
  selectProofState,
  (proof) => {
    return proof.sortColumnId;
  },
);

export const selectAccountProofsError = createSelector(
  selectProofState,
  (proof) => {
    return proof.error;
  },
);
