import { call, put, select, takeEvery } from "redux-saga/effects";
import FtsV2Instance from "../../../axios/ftsv2.instance";
import {
  APPROVE_PROOF_BY_ID_REQUEST,
  CREATE_PROOF_REQUEST,
  GET_PROOF_DATA_BY_AD_ID_REQUEST,
  selectProofData,
  SEND_PROOF_REQUEST,
} from "../../reducers/v2/proof";
import {
  approveProofByIdFiledAction,
  approveProofByIdSuccessAction,
  createProofFiledAction,
  createProofSuccessAction,
  getProofDataByAdIdFiledAction,
  getProofDataByAdIdSuccessAction,
  sendProofFiledAction,
  sendProofSuccessAction,
} from "../../actions/v2/proof";
import { toast } from "react-toastify";
import { formatListingDataPayload } from "../listing/helpers";

import { handleSagaErrorMsg } from "../../../utility/handleSagaErrorMsg";
import { SagaErrorMsg } from "../../../constants";

import { closeSubSideOutAction } from "../../actions/v2/sideOut";

import {
  selectSelectedEmailsContact,
  selectSelectedPhoneNumbersContact,
} from "../../reducers/v2/shareProviders";

function* getProofDataByAdIdRequestSaga(action) {
  try {
    const { data } = yield call(
      FtsV2Instance.get,
      `/proofs/admin/get-proof-by-ad-id/${action.payload.data.adId}`,
    );

    yield put(
      getProofDataByAdIdSuccessAction({
        data: { ...data.data, listingData: JSON.parse(data.data.listingData) },
      }),
    );
  } catch (e) {
    console.log(e);
    const message = e.response
      ? e.response.data.message
      : "Error while getting proof";
    yield put(getProofDataByAdIdFiledAction({ data: message }));
    toast.error(message);
  }
}

function* approveProofByIdRequestSaga(action) {
  try {
    const payload = formatListingDataPayload(action.payload.data.listingData);

    const { data } = yield call(FtsV2Instance.put, "/proofs/admin/approve", {
      id: action.payload.data.id,
      approvedListingData: payload,
    });

    if (
      action.payload.onSuccess &&
      typeof action.payload.onSuccess === "function"
    ) {
      action.payload.onSuccess();
    }

    yield put(
      approveProofByIdSuccessAction({
        data: { ...data.data, listingData: JSON.parse(data.data.listingData) },
      }),
    );
  } catch (e) {
    console.log(e);
    const message = e.response
      ? e.response.data.message
      : "Error while approve proof";
    yield put(approveProofByIdFiledAction({ data: message }));
    toast.error(message);
  }
}

export function* createProofRequestSaga(action) {
  try {
    const { data } = yield call(
      FtsV2Instance.post,
      "/proofs/admin/create-proof",
      {
        adId: action.payload.data.adId,
        listingData: action.payload.data.listingData,
      },
    );

    yield put(
      createProofSuccessAction({
        data: { ...data.data, listingData: JSON.parse(data.data.listingData) },
      }),
    );
  } catch (e) {
    console.log(e);
    const message = e.response
      ? e.response.data.message
      : "Error while creating proof";
    yield put(createProofFiledAction({ data: message }));
    toast.error(message);
  }
}

const SEND_PROOF_ENDPOINT = "/proofs/admin/send";
export function* sendProofRequestSaga() {
  try {
    const proofData = yield select(selectProofData);

    const selectedEmailsContact = yield select(selectSelectedEmailsContact);
    const selectedPhoneNumbersContact = yield select(
      selectSelectedPhoneNumbersContact,
    );

    const payload = {
      sendToEmails: selectedEmailsContact,
      sendToNumbers: selectedPhoneNumbersContact,
      id: proofData.id,
    };

    const { data } = yield call(
      FtsV2Instance.put,
      SEND_PROOF_ENDPOINT,
      payload,
    );

    const listingData = JSON.parse(data.data.listingData);
    const actionData = { ...data.data, listingData };
    yield put(sendProofSuccessAction({ data: actionData }));
    yield put(closeSubSideOutAction());
  } catch (e) {
    console.log(e);
    const message = handleSagaErrorMsg(e, SagaErrorMsg.SEND_PROOF_ERROR);
    yield put(sendProofFiledAction({ data: message }));
    toast.error(message);
  }
}

export default function* proofWatcher() {
  yield takeEvery(
    GET_PROOF_DATA_BY_AD_ID_REQUEST,
    getProofDataByAdIdRequestSaga,
  );
  yield takeEvery(APPROVE_PROOF_BY_ID_REQUEST, approveProofByIdRequestSaga);
  yield takeEvery(CREATE_PROOF_REQUEST, createProofRequestSaga);
  yield takeEvery(SEND_PROOF_REQUEST, sendProofRequestSaga);
}
