import React from "react";
import { FtsTableCell } from "@find-truck-service/ui/src/FtsTableCell/FtsTableCell";
import { TableCellIcon } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellIcon";
import { TableCellNoData } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellNoData";
import { TableCellTextTitle } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellTextTitle";
import { TableCellTextSubTitle } from "@find-truck-service/ui/src/components/react/TableCell/Components/TableCellTextSubTitle";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { TableCellTypesValues } from "@find-truck-service/ui/src/components/react/TableCell";
export const TableCellText = (props) => {
    if (!props.title && !props.subtitle) {
        return React.createElement(TableCellNoData, { type: TableCellTypesValues.text, ...props });
    }
    return (React.createElement(FtsTableCell, { active: props.active, isLoading: props.isLoading, onClick: props.onClick, size: props.size },
        React.createElement(FtsWrapper, null,
            React.createElement(TableCellTextTitle, { color: props.color, title: props.title }),
            React.createElement(TableCellTextSubTitle, { color: props.color, subtitle: props.subtitle })),
        React.createElement(TableCellIcon, { ...props.iconProps })));
};
