import { contactFields } from "@find-truck-service/ui/src/components/react/PreviewListingModal/Constants";
import { RemapAdsContact } from "@find-truck-service/ui/src/components/react/AdsContactsModal";
export const transformContactFields = (props) => {
    const filteredContactFields = contactFields.filter((key) => props[key]);
    return filteredContactFields
        .map((key) => ({
        key,
        name: RemapAdsContact[key],
        value: props[key]?.trim(),
    }))
        .filter(({ key }) => key !== "website" || props.showWebsite);
};
export function formatAdsAmenitiesResponse(adsAmenities, amenities) {
    const formattedAmenities = [];
    adsAmenities.map((adsAmenity) => {
        const amenity = amenities.find((a) => a.id === adsAmenity.amenityId);
        if (amenity)
            formattedAmenities.push(amenity);
    });
    return formattedAmenities;
}
export function formatAdsServiceAmenitiesResponse(adsServiceAmenities, serviceAmenities) {
    const servicesAmenities = [];
    adsServiceAmenities.map((adsServiceAmenity) => {
        const serviceAmenity = serviceAmenities.find((a) => a.id === adsServiceAmenity.serviceAmenityId);
        if (serviceAmenity)
            servicesAmenities.push(serviceAmenity);
    });
    return servicesAmenities;
}
