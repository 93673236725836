import styled from "styled-components";
import { Span } from "../../../../FtsText";
import { getHeightByPriceId } from "../helpers";
import { DESCRIPTION_WIDTH } from "@find-truck-service/ui/src/components/react/ListingItem/constants";
export const DescriptionWrapper = styled(Span)
    .attrs(({ variant }) => ({
    className: `ql-editor ${variant}`,
}))
    .withConfig({
    shouldForwardProp: (prop) => !["priceId"].includes(prop),
}) `
  width: 100%;
  display: block;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
  max-width: ${DESCRIPTION_WIDTH};
  max-height: ${({ priceId }) => `${getHeightByPriceId(priceId)}px`};
`;
