// Common
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Redux
import { getListingDataByIdRequest } from "../../../redux/actions/v2/listings";
import { EditListingForm } from "../EditListingForm";
import {
  selectIsLoadingListingData,
  selectListingData,
  selectListingError,
} from "../../../redux/reducers/v2/listing";
// Components
import { SideOutEditListingHeader } from "./SideOutEditListingHeader";
import { SideOutEditListingFooter } from "./SideOutEditListingFooter";
import { SideOutErrorState } from "../dist/ui/src/components/react/SideOut/Components/SideOutErrorState";
import { SideOutLoadingState } from "../dist/ui/src/components/react/SideOut/Components/SideOutLoadingState";
import { selectSideOutData } from "../../../redux/reducers/v2/sideOut";

export const SideOutEditListing = () => {
  const dispatch = useDispatch();
  const listingData = useSelector(selectListingData);
  const error = useSelector(selectListingError);
  const isLoading = useSelector(selectIsLoadingListingData);
  const sideOutData = useSelector(selectSideOutData);

  const getListingData = useCallback(() => {
    dispatch(
      getListingDataByIdRequest({ data: { id: sideOutData?.listingId } }),
    );
  }, [sideOutData?.listingId]);

  useEffect(() => {
    if (sideOutData?.listingId) getListingData();
  }, [sideOutData?.listingId]);

  if (isLoading) {
    return <SideOutLoadingState />;
  }

  if (error) {
    return (
      <>
        {/*<SideOutEditListingHeader />*/}
        <SideOutErrorState error={error} onClick={getListingData} />
      </>
    );
  }

  if (!listingData) {
    return (
      <>
        {/*<SideOutEditListingHeader />*/}
        <SideOutErrorState error={error} onClick={getListingData} />
      </>
    );
  }
  console.log(listingData);

  return (
    <>
      <SideOutEditListingHeader />
      <EditListingForm />
      <SideOutEditListingFooter />
    </>
  );
};
