import React from "react";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { Spacing } from "@find-truck-service/types/ui/spacing";
const FeaturedVendorsSliderMobile = (props) => {
    if (!props.featuredVendors?.length)
        return React.createElement(EmptyComponent, null);
    return (React.createElement(FtsWrapper, { display: { xs: "flex", sm: "flex", md: "none", lg: "none" }, overflow: "scroll", gap: "lg", py: "lg", px: "xl", bg: ColorsValue["greyscale-100"] }, props.featuredVendors?.map((ad) => (React.createElement(FtsWrapper, { key: ad.id, display: "flex", direction: "column", gap: "md" },
        React.createElement(FtsWrapper, null,
            React.createElement("img", { width: 208, height: 168, src: ad?.image, alt: "ADS_IMAGE", style: { borderRadius: Spacing["2xs"] } })),
        React.createElement(Span, { variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-800"] }, `${ad?.city}, ${ad?.state}`))))));
};
export default FeaturedVendorsSliderMobile;
