import styled from "styled-components";
import { device } from "@find-truck-service/types/ui/breakpoints";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
const columnProps = ["size", "offset"];
export const FtsColumn = styled(FtsWrapper).withConfig({
    shouldForwardProp: (prop) => !columnProps.includes(prop),
}) `
  grid-column: span ${(props) => props.size.lg || props.size.md || props.size.sm || props.size.xs || 1};

  ${(props) => {
    let res = ``;
    for (const [key, val] of Object.entries(device)) {
        const sizeValue = props.size[key];
        if (sizeValue === 0) {
            res += `@media ${val} { display: none; };`;
        }
        if (sizeValue !== undefined) {
            let offset = "span";
            let size = sizeValue;
            let gridColumn = `${offset} ${size}`;
            const offsetValue = props.offset ? props.offset[key] : undefined;
            if (offsetValue !== undefined) {
                offset = offsetValue + 1;
                size = offset + sizeValue;
                gridColumn = `${offset} / ${size}`;
            }
            res += `@media ${val} { grid-column: ${gridColumn};};`;
        }
    }
    return res;
}}
`;
