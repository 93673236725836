import { PreviewListingContent } from "../Components/PreviewListingContent";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { PreviewListingWithEditButtonsContent } from "../Components/PreviewListingWithEditButtonsContent";
import { PreviewListingModalContentTypes } from "@find-truck-service/types/constants/previewListingModalContentTypes";
export const contactFields = ["phoneNumber", "phoneNumberSecondary", "afterHours", "fax", "website"];
export const modalContentComponents = {
    [PreviewListingModalContentTypes.SEARCH_RESULTS]: EmptyComponent,
    [PreviewListingModalContentTypes.PREVIEW_LISTING]: PreviewListingContent,
    [PreviewListingModalContentTypes.PREVIEW_WITH_EDIT_BUTTONS]: PreviewListingWithEditButtonsContent,
};
