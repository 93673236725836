import React, { useCallback } from "react";
import Icon from "../../fts/Icons";
import {
  closeSideOutAction,
  closeSubSideOutAction,
} from "../../../redux/actions/v2/sideOut";
import { useDispatch, useSelector } from "react-redux";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { selectSideOutGetListingInfo } from "../../../redux/reducers/v2/sideOut";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { FtsSideOutWrapperHeader } from "../dist/ui/src/FtsSideOut/FtsSideOutWrapperHeader";

export const SideOutListingProofHeader = () => {
  const dispatch = useDispatch();

  const getListingInfo = useSelector(selectSideOutGetListingInfo);

  const hideSidebar = useCallback(() => {
    if (getListingInfo && typeof getListingInfo === "function")
      getListingInfo();
    dispatch(closeSideOutAction());
    dispatch(closeSubSideOutAction());
  }, []);

  return (
    <FtsSideOutWrapperHeader
      bg={ColorsValue["greyscale-100"]}
      py={"lg"}
      px={"3xl"}
    >
      <FtsWrapper>
        <Span
          variant={VariantsValues["text-sm"]}
          color={ColorsValue["greyscale-800"]}
        >
          Preview proof
        </Span>
      </FtsWrapper>

      <FtsWrapper display="flex" align={"center"}>
        <Icon onClick={hideSidebar} size={24} name={"Close"} />
      </FtsWrapper>
    </FtsSideOutWrapperHeader>
  );
};
