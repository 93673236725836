import React from "react";
import { Span } from "../../../../FtsText";
import { MostPopularTag } from "./MostPopularTag";
import { SelectedButton } from "./SelectedButton";
import { FooterArrow } from "../styled/StyledFooterWrapper";
import { FtsWrapper } from "../../../../FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Button } from "@find-truck-service/ui/src/components/react/Button";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { getShowBenefits, getShowMostPopularTag, getShowPrice } from "../helpers";
import { ButtonColorsValue, ButtonSizes, ButtonTypes } from "../../../../../../types/ui/button";
// interface ListingItemSmFooterProps extends DefaultProps {
//   onClickBenefits?: () => void;
//   primaryActionLabel: string;
//   onSelect?: () => void;
//   selected?: boolean;
//   priceId: number;
//   price?: string;
//   type?: string;
// }
export const ListingItemFooter = (props) => {
    const { onClickBenefits, selected, priceId, price, type, onSelect, primaryActionLabel } = props;
    const showBenefits = getShowBenefits(priceId);
    const showPrice = getShowPrice(priceId);
    const showBenefitsCTA = showBenefits && !!onClickBenefits;
    const showMostPopularTag = getShowMostPopularTag(priceId);
    return (React.createElement(FtsWrapper, { p: "sm" },
        React.createElement(FooterArrow, { selected: selected }),
        React.createElement(FtsWrapper, { bg: ColorsValue[selected ? "green-success-50" : "greyscale-50"], px: { lg: "3xl", md: "3xl", sm: "lg", xs: "lg" }, justify: "space-between", borderRadius: "2xs", display: "flex", align: "center", gap: "sm", py: "lg" },
            React.createElement(FtsWrapper, { flex: 1, wrap: "wrap", display: "flex", align: "center", gap: { lg: "md", md: "sm", sm: "sm", xs: "sm" } },
                React.createElement(Span, { weight: WeightSize.semibold, variant: VariantsValues["text-sm"], color: ColorsValue["greyscale-800"], display: { lg: "block", md: "none", sm: "none", xs: "none" } }, type),
                React.createElement(Span, { weight: WeightSize.semibold, variant: VariantsValues["text-xs"], color: ColorsValue["greyscale-800"], display: { lg: "none", md: "block", sm: "block", xs: "block" } }, type),
                showPrice && (React.createElement(React.Fragment, null,
                    React.createElement(Span, { weight: WeightSize.regular, variant: VariantsValues["text-sm"], color: ColorsValue["greyscale-800"], display: { lg: "block", md: "none", sm: "none", xs: "none" } }, `$${price}/year`),
                    React.createElement(Span, { weight: WeightSize.regular, variant: VariantsValues["text-xs"], color: ColorsValue["greyscale-800"], display: { lg: "none", md: "block", sm: "block", xs: "none" } }, `$${price}/year`))),
                showMostPopularTag && React.createElement(MostPopularTag, null)),
            React.createElement(FtsWrapper, { display: "flex", gap: "lg" },
                showBenefitsCTA && (React.createElement(Button, { type: ButtonTypes.outlined, color: ButtonColorsValue.neutral, onClick: onClickBenefits, size: ButtonSizes.md, label: "Benefits" })),
                !selected && (React.createElement(Button, { onClick: onSelect, size: ButtonSizes.md, color: ButtonColorsValue.accent, type: ButtonTypes.outlined, label: `${primaryActionLabel}` })),
                selected && React.createElement(SelectedButton, { type: ButtonTypes.text, onClick: onSelect })))));
};
