import React from "react";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Divider } from "@find-truck-service/ui/src/components/react/Divider";
import { Modal } from "@find-truck-service/ui/src/components/react/Modal";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { ModalHeaderComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalHeaderComponent";
import { ModalContentComponent } from "@find-truck-service/ui/src/components/react/Modal/ModalContentComponent";
import { SpecialHoursSection } from "@find-truck-service/ui/src/components/react/AdsWorkingHoursModal/components/SpecialHoursSection";
export const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
function formatHoursValue(hours) {
    let workingHoursValue = hours;
    if (!workingHoursValue || workingHoursValue === "-")
        workingHoursValue = "Closed";
    else if (workingHoursValue.toLowerCase().startsWith("closed"))
        workingHoursValue = "Closed";
    else if (workingHoursValue.toLowerCase().startsWith("24 hrs"))
        workingHoursValue = "Open 24 Hours";
    else if (workingHoursValue.toLowerCase().startsWith("by appointment"))
        workingHoursValue = "By Appointment";
    return workingHoursValue;
}
const ModalComponent = ({ workingHours, ...rest }) => {
    if (!workingHours)
        return React.createElement(EmptyComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalContentComponent, null,
            React.createElement(FtsWrapper, { display: "flex", direction: "column" }, weekDays.map((weekDay, index) => {
                let workingHoursValue = formatHoursValue(workingHours[weekDay.toLowerCase()]);
                return (React.createElement(React.Fragment, null,
                    React.createElement(FtsWrapper, { display: "flex", justify: "space-between", gap: "lg", align: "center" },
                        React.createElement(Span, { variant: "text-2xs", weight: WeightSize.bold, color: ColorsValue["greyscale-800"] }, weekDay),
                        React.createElement(Span, { variant: "text-2xs", color: ColorsValue["greyscale-800"] }, workingHoursValue)),
                    weekDays[index + 1] && React.createElement(Divider, { verticalOffset: "xl" })));
            }))),
        React.createElement(SpecialHoursSection, { ...rest })));
};
const ModalHeader = (props) => {
    const { onClose } = props;
    return React.createElement(ModalHeaderComponent, { title: "Working Hours", onClose: onClose });
};
export const AdsWorkingHoursModal = (props) => {
    const { isOpen, onClose, data } = props;
    return (React.createElement(Modal, { size: "xs", isOpen: isOpen, onBackdropClick: onClose, modalWrapperProps: { isOpen }, modalContentComponent: () => React.createElement(ModalComponent, { ...data }), modalHeaderComponent: () => React.createElement(ModalHeader, { onClose: onClose }) }));
};
