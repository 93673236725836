import styled from "styled-components";
import { FtsContainer } from "@find-truck-service/ui/src/FtsContainer/FtsContainer";
import { device } from "@find-truck-service/types/ui/breakpoints";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
export const FtsModalContainer = styled(FtsContainer).withConfig({
    shouldForwardProp: (prop) => !["isOpen"].includes(prop),
}) `
  z-index: 2;
  width: 100%;

  @media ${device.lg} {
    animation: ${(props) => (props.isOpen ? `slideIn 0.3s ease-in-out` : "slideOut 0.3s ease-in-out")};
  }

  @media ${device.xs} {
    position: absolute;
    width: 100%;
    top: unset;
    left: unset;
    transform: unset;
    bottom: 0;
    margin-left: ${Spacing["0"]};
    margin-right: ${Spacing["0"]};
    padding: ${Spacing["0"]};
    animation: ${(props) => (props.isOpen ? `slideInXs 0.3s ease-in-out` : "slideOutXs 0.3s ease-in-out")};
  }

  @keyframes slideInXs {
    from {
      transform: translateY(100%);
      opacity: 90%;
    }
    to {
      transform: translateY(0%);
      opacity: 100%;
    }
  }
  @keyframes slideOutXs {
    from {
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 100%;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(-50px);
      opacity: 60%;
    }
    to {
      opacity: 100%;
    }
  }
  @keyframes slideOut {
    from {
      opacity: 1;
    }
    to {
      transform: translateY(-50px);
      opacity: 0;
    }
  }
`;
export const FullSizeModalContainer = styled(FtsWrapper) `
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${Spacing["0"]};
  transition: max-height 0.2s ease-in-out;
  max-height: ${(props) => props.maxHeight};
  background-color: ${ColorsValue["greyscale-0"]};
  animation: ${(props) => (props.isOpen ? `slideInXs 0.3s ease-in-out` : "slideOutXs 0.3s ease-in-out")};

  @keyframes slideInXs {
    from {
      transform: translateY(100%);
      opacity: 90%;
    }
    to {
      transform: translateY(0%);
      opacity: 100%;
    }
  }
  @keyframes slideOutXs {
    from {
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 100%;
    }
  }
`;
