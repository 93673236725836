import React, { useState } from "react";
import { getListingImageSrc } from "./helpers";
import { specificationsByListingType } from "@find-truck-service/ui/src/components/react/ListingItem/constants";
import { ListingItemModals } from "@find-truck-service/ui/src/components/react/ListingItem/Components/ListingItemModals";
import { ListingItemComponentByBreakpoints } from "@find-truck-service/ui/src/components/react/ListingItem/Components/ListingItemComponentByBreakpoints";
import { getContactModalData, getWorkingHoursModalData, } from "@find-truck-service/ui/src/components/react/AdsWorkingHoursModal/helpers";
//TODO listing details modal CTAs
//TODO pobrisati listing details viska fajlove
//TODO chcek njihova imena
//TODO srediti listingName
//TODO servis mora vratiti exact value
//TODO check logic for showExpandContent
//TODO refactor ListingItemComponentProps
//TODO check all children components type
//TODO featured vendors dodati
//TODO boxShadow?: keyof Shadows; //check do we need this?
//TODO check missing props inCity, distance, showDistance, compassDirection
export const ListingItem = (props) => {
    const [workingHoursModalData, setWorkingHoursModalData] = useState();
    const [contactModalData, setContactModalData] = useState();
    const [showLeavingFTSModal, setShowLeavingFTSModal] = useState(false);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [showBenefits, setShowBenefits] = useState(false);
    const { type: listingItemType, listingData, footerActions, selected } = props;
    const { id: priceId, price, showWebsite, label: type } = listingData.price || {};
    const listingName = listingData.name || "";
    const imageSrc = getListingImageSrc(listingData.approved, listingData.image);
    const listingItemSpecs = specificationsByListingType[listingItemType];
    //TODO delete this
    const inCity = false;
    const distance = 5;
    const showDistance = false;
    const compassDirection = "";
    const otherListingProps = {
        inCity,
        distance,
        listingName,
        showDistance,
        compassDirection,
        selected,
        showWebsite,
        imageSrc,
        type,
        price,
        priceId,
        priceData: listingData.price,
    };
    const onWebsiteClick = () => setShowLeavingFTSModal(true);
    const closeLeavingFTSModal = () => setShowLeavingFTSModal(false);
    const closeWorkingHoursModal = () => setWorkingHoursModalData(undefined);
    const onClickHours = () => setWorkingHoursModalData(getWorkingHoursModalData(listingData));
    const closeContactModal = () => setContactModalData(undefined);
    const onClickContact = () => setContactModalData(getContactModalData(listingData));
    const closeListingDetailsModal = () => setOpenDetailsModal(false);
    const onShowListingDetails = () => setOpenDetailsModal(true);
    const onClickBenefits = () => setShowBenefits(true);
    const closeBenefits = () => setShowBenefits(false);
    const internalActions = { onClickHours, onWebsiteClick, onClickContact, onClickBenefits, onShowListingDetails };
    return (React.createElement(React.Fragment, null,
        React.createElement(ListingItemComponentByBreakpoints, { ...listingData, ...footerActions, ...internalActions, ...listingItemSpecs, ...otherListingProps }),
        React.createElement(ListingItemModals, { listingData: listingData, showBenefits: showBenefits, closeBenefits: closeBenefits, onSelect: footerActions?.onSelect, openDetailsModal: openDetailsModal, contactModalData: contactModalData, closeContactModal: closeContactModal, showLeavingFTSModal: showLeavingFTSModal, closeLeavingFTSModal: closeLeavingFTSModal, workingHoursModalData: workingHoursModalData, closeWorkingHoursModal: closeWorkingHoursModal, closeListingDetailsModal: closeListingDetailsModal })));
};
