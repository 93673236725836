import { Message } from "@find-truck-service/ui/src/components/react/Message";
import React from "react";

import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { useSelector } from "react-redux";
import { selectProofData } from "../../../redux/reducers/v2/proof";
import { PROOF_STATUSES_ENUM } from "@find-truck-service/types/constants/proofStatuses";

export const SideOutListingProofPendingMessage = () => {
  const proofData = useSelector(selectProofData);

  if (proofData.status !== PROOF_STATUSES_ENUM.PENDING)
    return <EmptyComponent />;

  return (
    <Message
      type="warning"
      title={"Proof sent!"}
      subtitle={`Nov 26, 2024 at 2:35PM by ${proofData.sentBy}.`}
    />
  );
};
