import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectListingData } from "../../../redux/reducers/v2/listing";
import { Message } from "@find-truck-service/ui/src/components/react/Message";

import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { ADS_STATUSES_ENUM } from "@find-truck-service/types/constants/adsStatuses";

export const SideOutListingPreviewCreatedListingMessage = () => {
  const listingsData = useSelector(selectListingData);
  const { proof } = listingsData;

  if (!listingsData) return <EmptyComponent />;
  if (proof) return <EmptyComponent />;
  if (listingsData.status !== ADS_STATUSES_ENUM.ACTIVE)
    return <EmptyComponent />;

  return (
    <Message
      type="info"
      title={""}
      subtitle={`Listing created on ${moment(listingsData.createdAt).format(
        "ll",
      )}.`}
    />
  );
};
