import { FtsModalWrapper } from "@find-truck-service/ui/src/FtsModal/FtsModalWrapper";
import React, { useEffect } from "react";
import { FtsModalContainer } from "@find-truck-service/ui/src/FtsModal/FtsModalContainer";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import styled from "styled-components";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
const ModalSize = {
    xs: {
        size: { xs: 12, sm: 8, md: 8, lg: 6 },
        offset: { xs: 0, sm: 2, md: 2, lg: 3 },
    },
    sm: {
        size: { xs: 12, sm: 10, md: 10, lg: 8 },
        offset: { xs: 0, sm: 1, md: 1, lg: 2 },
    },
    md: {
        size: { xs: 12, sm: 12, md: 12, lg: 10 },
        offset: { xs: 0, sm: 0, md: 0, lg: 1 },
    },
    lg: {
        size: { xs: 12, sm: 12, md: 12, lg: 12 },
    },
};
export const Modal = (props) => {
    const ModalContentComponent = props.modalContentComponent || EmptyComponent;
    const ModalHeaderComponent = props.modalHeaderComponent || EmptyComponent;
    const ModalFooterComponent = props.modalFooterComponent || EmptyComponent;
    useEffect(() => {
        if (props.isOpen) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "unset";
        }
    }, [props.isOpen]);
    const onCloseInternal = (e) => {
        if (e.target.id === "backdrop_portal_id" && typeof props.onBackdropClick === "function") {
            return props.onBackdropClick(e);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FtsModalWrapper, { id: "backdrop_portal_id", isOpen: props.isOpen, onClick: onCloseInternal },
            React.createElement(FtsModalContainer, { isOpen: props.isOpen },
                React.createElement(FtsRow, null,
                    React.createElement(FtsModal, { ...ModalSize[props.size], bg: ColorsValue["greyscale-0"], display: "flex", direction: "column" }, props.isOpen && (React.createElement(React.Fragment, null,
                        React.createElement(ModalHeaderComponent, null),
                        React.createElement(ModalContentComponent, null),
                        React.createElement(ModalFooterComponent, null)))))))));
};
const FtsModal = styled(FtsColumn) ``;
