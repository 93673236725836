import React from "react";
import { ImageAndDescription } from "./ImageAndDescription";
import { ListingBasicInfoSmMd } from "./ListingBasicInfoSmMd";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
// interface ListingItemSmContentProps {
//   city: string;
//   state: string;
//   price?: string;
//   inCity: boolean;
//   address: string;
//   priceId: number;
//   distance: number;
//   imageSrc: string;
//   listingName: string;
//   interstate?: string;
//   milemarker?: string;
//   description: string;
//   showDistance: boolean;
//   compassDirection: string;
//   showPromotionTag?: boolean;
//   showAuthorizedTag?: boolean;
// }
export const ListingItemSmContent = (props) => {
    const { imageSrc, description, priceData } = props;
    return (React.createElement(FtsWrapper, { p: "2xl", display: "flex", gap: "2xl", direction: "column" },
        React.createElement(ListingBasicInfoSmMd, { ...props }),
        React.createElement(ImageAndDescription, { imageSrc: imageSrc, description: description, priceData: priceData })));
};
