import React from "react";
const Facebook = (props) => {
    return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props.svgProps },
        React.createElement("g", { clipPath: "url(#clip0_597_26356)" },
            React.createElement("path", { d: "M12 0C8.8174 0 5.76515 1.26428 3.51472 3.51472C1.26428 5.76515 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76515 22.7357 8.8174 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0Z", fill: "#039BE5", ...props.pathProps }),
            React.createElement("path", { d: "M13.6245 15.18H16.73L17.2176 12.0252H13.6239V10.301C13.6239 8.99048 14.0521 7.82837 15.278 7.82837H17.2479V5.07532C16.9018 5.02859 16.1698 4.92627 14.7866 4.92627C11.8984 4.92627 10.2051 6.45153 10.2051 9.92648V12.0252H7.23608V15.18H10.2051V23.8509C10.7931 23.9393 11.3887 23.9993 12.0001 23.9993C12.5527 23.9993 13.0921 23.9488 13.6245 23.8768V15.18Z", fill: "white", ...props.pathProps })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_597_26356" },
                React.createElement("rect", { width: "24", height: "24", fill: "white", ...props.pathProps })))));
};
export default Facebook;
