import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
export const confirmationDialogIconProps = {
    iconName: IconNames.AlertTriangle,
    iconWrapperProps: {
        p: "md",
        borderRadius: "4xl",
        bg: ColorsValue["yellow-warning-50"],
    },
    svgProps: {
        width: SvgIconSizeValues.xl,
        height: SvgIconSizeValues.xl,
    },
    pathProps: { stroke: ColorsValue["yellow-warning-800"] },
};
